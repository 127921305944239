import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { cloneDeep } from 'lodash';
import { createStructuredSelector } from 'reselect';
import { withTranslation } from 'react-i18next';
import {
  DataTable,
  FormWithTableItem,
  GenericInput,
  ModalUploadFile,
  ModalViewMedia,
} from '../../../components/common';
import PageTitle from '../../../components/PageTitle';
import RouteNames from '../../App/RouteNames';
import { uploadMultiPartFiles, readFileFromS3 } from '../../App/actions';
import { makeGetS3Config } from '../../App/selectors';
import { getConfigCorrespondence, modifyConfigCorrespondence, createConfigCorrespondence } from '../actions';
import { getIndexData, parseToMutationRequestPostMethod, getBase64 } from '../../../utils/utils';

class TemplatesConfig extends React.PureComponent {
  state = {
    data: [],
    isLoading: false,
    itemConfig: {},
    isModify: true,
  };

  componentDidMount() {
    this.dogetConfigCorrespondence();
  }

  dogetConfigCorrespondence = () => {
    const { getConfigCorrespondence } = this.props;
    this.setState({ isLoading: true });
    getConfigCorrespondence('', ({ success, data }) => {
      this.setState({ isLoading: false });
      if (success) {
        this.setState({
          data: data && data.templates ? data.templates : [],
          itemConfig: data,
          isModify: !!(data && data.id),
        });
      }
    });
  };

  onChange = ({ index, name, value }) => {
    const { data } = this.state;
    const newData = cloneDeep(data);
    newData[getIndexData({ index, data: newData })][name] = value;
    this.setState({ data: newData });
  };

  addNewItem = () => {
    try {
      const { data } = this.state;
      let newData = cloneDeep(data);
      let lastIndex = 0;
      newData.forEach(val => {
        if (val.index > lastIndex) lastIndex = val.index;
      });
      newData = [
        {
          index: lastIndex + 1,
          filePath: '',
          description: '',
          type: null,
          status: null,
          templateType: 'EMAIL',
          messageType: 'CUSTOMER',
        },
        ...newData,
      ];
      this.setState({ data: newData });
    } catch (error) {
      console.log(error);
    }
  };

  onRemoveItem = ({ index }) => {
    const { data } = this.state;
    const newData = cloneDeep(data);
    newData[getIndexData({ index, data: newData })] = {
      index: newData[getIndexData({ index, data: newData })].index,
    };
    this.setState({ data: newData });
  };

  onHandleSave = () => {
    const { data, itemConfig, isModify } = this.state;
    const { modifyConfigCorrespondence, createConfigCorrespondence } = this.props;
    const templates = cloneDeep(data);
    if (isModify) {
      modifyConfigCorrespondence({ id: itemConfig.id, templates }, () => this.dogetConfigCorrespondence());
    } else {
      const templatesCreate = templates.map(val => {
        const { index, ...rest } = val;
        return rest;
      });
      createConfigCorrespondence({ templates: templatesCreate }, () => this.dogetConfigCorrespondence());
    }
  };

  onCancel = () => {
    this.setState({ base64FileSelect: null, fileSelect: null, isOpen: false, itemSelect: null });
  };

  onToggleModalUpload = ({ itemSelect, indexSelect }) => {
    const { isOpen } = this.state;
    const { readFileFromS3, s3Config } = this.props;
    if (itemSelect.filePath) {
      readFileFromS3({ ...s3Config, url: itemSelect.filePath }, ({ success, data }) => {
        if (success) {
          getBase64(data, result => {
            this.setState({ base64FileSelect: result, indexSelect, fileSelect: data, isOpen: !isOpen, itemSelect });
          });
        } else {
          this.setState({ fileSelect: data, indexSelect, isOpen: !isOpen });
        }
      });
    } else {
      this.setState({ isOpen: !isOpen, itemSelect, indexSelect });
    }
  };

  onToggleModalViewFileUpload = ({ itemSelect }) => {
    const { readFileFromS3, s3Config } = this.props;
    if (itemSelect.filePath) {
      readFileFromS3({ ...s3Config, url: itemSelect.filePath }, ({ success, data }) => {
        if (success) {
          getBase64(data, result => {
            this.setState({ base64FileSelectViewFile: result, fileSelectViewFile: data, isOpenModalViewFile: true });
          });
        }
      });
    }
  };

  onHandleUpload = ({ files }) => {
    const { itemSelect, isOpen, indexSelect, data } = this.state;
    const { uploadMultiPartFiles } = this.props;
    if (files && files[0]) {
      const formData = new FormData();
      let query = '';
      query = `{"query": "mutation{uploadMultipartFiles(input: ${parseToMutationRequestPostMethod(
        { category: 'CORRESPONDENCE', correspondenceType: itemSelect.type || null, name: itemSelect.name },
        ['category', 'correspondenceType']
      )}){absoluteFile}}"}`;
      formData.append('graphql', query);
      formData.append('file', files[0]);
      uploadMultiPartFiles(formData, res => {
        if (res && res.success) {
          if (res.data.data && res.data.data.data && res.data.data.data.uploadMultipartFiles) {
            try {
              const pathSelect = res.data.data.data.uploadMultipartFiles[0];
              const newData = cloneDeep(data);
              newData[indexSelect].filePath = pathSelect.absoluteFile;
              // newData[indexSelect].description = `${itemSelect.type ? itemSelect.type.toLowerCase() : ''} ${
              //   pathSelect.absoluteFile
              // }`;
              this.setState({ isOpen: !isOpen, itemSelect: null, data: newData });
            } catch (error) {
              console.log(error);
              this.setState({ isOpen: !isOpen, itemSelect: null });
            }
          }
        }
      });
    } else {
      this.setState({ isOpen: !isOpen, itemSelect: null });
    }
  };

  render() {
    const { t, permissions } = this.props;
    const { modeCreateCorrespondenceTemplate, modeGetCorrespondenceTemplate, modeModifyCorrespondenceTemplate } =
      permissions || {};
    if (!modeGetCorrespondenceTemplate) return '';
    const {
      data,
      isLoading,
      isOpen,
      itemSelect,
      base64FileSelect,
      fileSelect,
      isOpenModalViewFile,
      base64FileSelectViewFile,
      fileSelectViewFile,
    } = this.state;
    const tableColumns = [
      {
        name: 'type',
        label: 'label.type',
        required: true,
        style: { minWidth: '250px' },
        render: (name, item) => {
          return (
            <GenericInput
              value={item.type}
              name="type"
              type="select"
              tOptions="selections:notificationType"
              onChange={({ name, value }) => this.onChange({ index: item.index, name, value })}
              wrapperClass="col-md-12"
              menuPortalTarget
              required
            />
          );
        },
      },
      {
        name: 'accountCategory',
        label: 'label.accountCategory',
        style: { minWidth: '180px' },
        render: (name, item) => {
          return (
            <GenericInput
              value={item.accountCategory}
              name="accountCategory"
              type="select"
              tOptions="selections:accountCategory"
              onChange={({ name, value }) => this.onChange({ index: item.index, name, value })}
              wrapperClass="col-md-12"
              menuPortalTarget
              required
            />
          );
        },
      },
      {
        name: 'description',
        label: 'label.description',
        style: { minWidth: '250px' },
        render: (name, item) => {
          return (
            <GenericInput
              value={item.description}
              name="description"
              onChange={({ name, value }) => this.onChange({ index: item.index, name, value })}
              wrapperClass="col-md-12"
              type="textarea"
            />
          );
        },
      },
      {
        name: 'messageType',
        label: 'label.receiver',
        tooltipLabel: 'message.receiverNote',
        style: { minWidth: '200px' },
        render: (name, item) => {
          return (
            <GenericInput
              value={item.messageType}
              name="messageType"
              type="select"
              tOptions="selections:messageType"
              onChange={({ name, value }) => this.onChange({ index: item.index, name, value })}
              wrapperClass="col-md-12"
              menuPortalTarget
            />
          );
        },
      },
      {
        name: 'templateType',
        label: 'label.templateType',
        style: { minWidth: '200px' },
        render: (name, item) => {
          return (
            <GenericInput
              value={item.templateType}
              name="templateType"
              type="select"
              tOptions="selections:notificationTemplateType"
              onChange={({ name, value }) => this.onChange({ index: item.index, name, value })}
              wrapperClass="col-md-12"
              menuPortalTarget
            />
          );
        },
      },
      {
        name: 'uploadFile',
        label: 'label.view',
        render: (colName, item, index) => (
          <button
            type="button"
            className="btn btn-outline-success btn-sm"
            onClick={() => this.onToggleModalViewFileUpload({ itemSelect: item, indexSelect: index })}
          >
            {t('label.view')}
          </button>
        ),
      },
      {
        name: 'uploadFile',
        label: 'label.uploadEmailTemplate',
        style: { minWidth: '300px' },
        render: (colName, item, index) => (
          <GenericInput
            value={item.filePath}
            wrapperClass="col-md-12 text-link-upload"
            fa="fa fa-external-link"
            onClick={() => this.onToggleModalUpload({ itemSelect: item, indexSelect: index })}
            onChange={({ name, value }) => {}}
            name="uploadFile"
            type="textarea"
            disabled={!item || !item.templateType || item.templateType === 'SMS'}
          />
        ),
      },
      {
        name: 'smsTemplate',
        label: 'label.smsTemplate',
        tooltipHtml: <span dangerouslySetInnerHTML={{ __html: t('message.smsTemplateNote') }} />,
        style: { minWidth: '250px' },
        render: (name, item) => {
          return (
            <GenericInput
              value={item.smsTemplate}
              name="smsTemplate"
              onChange={({ name, value }) => this.onChange({ index: item.index, name, value })}
              wrapperClass="col-md-12"
              type="textarea"
              disabled={!item || !item.templateType || item.templateType === 'EMAIL'}
            />
          );
        },
      },
      {
        name: 'emailSubject',
        label: 'label.emailSubject',
        style: { minWidth: '250px' },
        render: (name, item) => {
          return (
            <GenericInput
              value={item.emailSubject}
              name="emailSubject"
              onChange={({ name, value }) => this.onChange({ index: item.index, name, value })}
              wrapperClass="col-md-12"
            />
          );
        },
      },
      {
        name: 'status',
        label: 'label.status',
        required: true,
        style: { minWidth: '140px' },
        render: (colName, item, index) => (
          <GenericInput
            value={item.status}
            wrapperClass="col-md-12"
            type="select"
            tOptions="selections:commonStatus"
            onChange={({ name, value }) => this.onChange({ index: item.index, name, value })}
            name="status"
            menuPortalTarget
            required
          />
        ),
      },
    ];

    return (
      <div>
        <PageTitle
          linkTo={RouteNames.correspondenceConfigTemplate.path}
          titleBtn={t('label.back')}
          titleRight={t('label.correspondenceTemplateConfiguration')}
        />
        <FormWithTableItem title={t('label.correspondenceTemplateConfiguration')} subClass="border-bottom">
          <br />
          <div className="form-group col-md-12 buttons-attibute">
            {modeCreateCorrespondenceTemplate === 2 && (
              <button
                type="button"
                className="button button-border black x-small"
                onClick={this.addNewItem}
              >
                +
                {t('label.addNewCorrespondence')}
              </button>
            )}
            {modeModifyCorrespondenceTemplate === 2 && (
              <button
                type="button"
                className="button button-border x-small float-right"
                onClick={() => this.onHandleSave()}
              >
                {t('label.saveConfig')}
              </button>
            )}
            <button
              type="button"
              onClick={this.dogetConfigCorrespondence}
              className="button mr-2 button-border black x-small float-right"
            >
              {t('label.cancel')}
            </button>
          </div>
          <br />
          <DataTable columns={tableColumns} data={data || []} isLoading={isLoading} isFixedHeaderTable />
        </FormWithTableItem>
        <ModalUploadFile
          title="label.uploadFile"
          isOpen={isOpen}
          onCancel={this.onCancel}
          onUpload={this.onHandleUpload}
          itemSelect={itemSelect}
          base64FileSelect={base64FileSelect}
          fileSelect={fileSelect}
          type={itemSelect ? itemSelect.type : null}
        />
        <ModalViewMedia
          isOpen={isOpenModalViewFile}
          base64FileSelect={base64FileSelectViewFile}
          fileSelect={fileSelectViewFile}
          onCancel={() =>
            this.setState({
              isOpenModalViewFile: false,
              base64FileSelectViewFile: null,
              fileSelectViewFile: null,
            })
          }
          fileNameDownload="file_download.html"
          type="HTML"
        />
      </div>
    );
  }
}

TemplatesConfig.propTypes = {
  getConfigCorrespondence: PropTypes.func.isRequired,
  modifyConfigCorrespondence: PropTypes.func.isRequired,
  createConfigCorrespondence: PropTypes.func.isRequired,
  uploadMultiPartFiles: PropTypes.func.isRequired,
};

TemplatesConfig.defaultProps = {};

const mapStateToProps = createStructuredSelector({
  s3Config: makeGetS3Config() || {},
  // paymentMethodUrls: makeGetPaymentMethodUrls() || {},
});

export default withTranslation('common')(
  connect(mapStateToProps, {
    getConfigCorrespondence,
    modifyConfigCorrespondence,
    createConfigCorrespondence,
    uploadMultiPartFiles,
    readFileFromS3,
  })(withRouter(TemplatesConfig))
);
