import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import SearchComponent from '../PricingHub/SearchComponent';

class TopupDetailsSearchForm extends SearchComponent {
  searchInputs = [
    {
      label: 'common:label.userId',
      name: 'userId',
    },
    {
      label: 'common:label.startDate',
      name: 'startDate',
      type: 'date',
      isClearable: true,
    },
    {
      label: 'common:label.endDate',
      name: 'endDate',
      type: 'date',
      isClearable: true,
    },
  ];

  btnSpan = 'col-md-3';
}

TopupDetailsSearchForm.propTypes = {
  onSubmit: PropTypes.func,
  isSubmitting: PropTypes.bool,
};

TopupDetailsSearchForm.defaultProps = {
  isSubmitting: false,
};

export default withTranslation(['common', 'selections'])(TopupDetailsSearchForm);
