import { cloneDeep } from 'lodash';
import pdfConfig from './helper';
import { getLabelOptions, formatMoneyValueToExport, redFontPdfFile } from '../utils';
import { convertBoolLabel } from '../exportHelper';

const HeaderTable = ({ t, columnsTable }) => columnsTable.map(val => t(val.exportLabel) || t(val.label));

const convertJson2Pdf = ({ data, t, title, fileName, columnsTable, ...rest }) => {
  const Header = cloneDeep(HeaderTable({ t, columnsTable }));
  const newListData = data.map(item => {
    const newItem = [];
    if (item && typeof item === 'object') {
      columnsTable.forEach(val => {
        if (item[val.name] && typeof item[val.name] === 'number') {
          newItem.push({
            content: formatMoneyValueToExport(item[val.name]),
            styles: val.isSupportRedFront && item[val.name] < 0 ? redFontPdfFile : null,
          });
        } else if (typeof item[val.name] === 'boolean') {
          newItem.push({ content: convertBoolLabel({ t, value: item[val.name] }) });
        } else if (val.isRenderT) {
          newItem.push({
            content: getLabelOptions({
              item,
              t,
              fieldOption: val.fieldOption || null,
              fieldName: val.name,
            }),
          });
        } else {
          newItem.push({ content: item[val.name] ? item[val.name] : '' });
        }
      });
    }
    return newItem;
  });
  return pdfConfig({ data: newListData, Header, title, fileName, fontSize: 3, cellPadding: 2, ...rest });
};

export default convertJson2Pdf;
