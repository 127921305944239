import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { cloneDeep, orderBy } from 'lodash';
import { withTranslation } from 'react-i18next';
import { countryList } from '../../../constantsApp/countryList';
import { GenericInput, FormCollapse, DataTable } from '../../common';
import PricingSection from './PricingSection';

const lisTransactionTypeSupportRateWhenInactive = ['FORWARD_RECURRING', 'ARREARS_RECURRING', 'VOLUME_DISCOUNT'];

const provisioningNamesCollapsible = [
  {
    id: 1,
    title: 'label.provisioningNames',
    state: 'provisioningNames',
  },
];

const defaultFlatPricing = {
  prices: [],
  grants: [],
  accumulators: [],
};

const defaultRecurringPricing = {
  purchaseProration: 'PRORATE',
  cancelProration: 'PRORATE',
  upgradeProration: 'PRORATE',
  downgradeProration: 'PRORATE',
  prices: [],
  grants: [],
  accumulators: [],
};

const defaultCustomerPricing = {
  index: 1,
  salesChannel: 'NONE',
  marketSegment: 'NONE',
  accountType: 'NONE',
  accountSubType: 'NONE',
  prices: [],
  grants: [],
  accumulators: [],
};

const defaultRecurringTiered = {
  purchaseProration: 'PRORATE',
  cancelProration: 'PRORATE',
  upgradeProration: 'PRORATE',
  downgradeProration: 'PRORATE',
  unit: 'MONTHS',
  frequency: 1,
  tierPricing: [
    {
      id: `TO-EMBRIX-${Math.floor(Math.random() * 100000000000)}`,
      name: '',
      description: '',
      tierType: 'PROSPECTIVE',
      tierReference: 'BALANCE',
      tiers: [
        {
          minimum: '0',
          maximum: '0',
          // prices: [],
          prices: [],
          grants: [],
          accumulators: [],
        },
      ],
    },
  ],
};

const defaultAttributeBased = {
  index: 1,
  rateUnit: 'NONE',
  timeUnit: 'NONE',
  zoneUnit: 'NONE',
  impactUnit: 'NONE',
  holidayName: 'NONE',
  code: '',
  prices: [],
  grants: [],
  accumulators: [],
};

const defaultTiered = {
  id: `TO-EMBRIX-${Math.floor(Math.random() * 100000000000)}`,
  name: '',
  description: '',
  priceOfferId: '',
  tierType: null,
  tierReference: null,
  accumulatorId: null,
  volumeTreatment: 'ACTUAL',
  evaluationStart: 0,
  evaluationEnd: 0,
  offsetMonths: 0,
  nextPricingTerm: 0,
  tiers: [
    {
      maximum: '0',
      minimum: '0',
      prices: [],
      grants: [],
      accumulators: [],
    },
  ],
};

const defaultUsageAttributeBase = {
  index: 1,
  name: 'usage',
  description: 'usage',
  usageAttributePricingData: [
    {
      attribute: '',
      value: 'NONE',
    },
  ],
  prices: [],
  grants: [],
  accumulators: [],
};

const defaultComplex = {
  id: `TO-EMBRIX-${Math.floor(Math.random() * 10000000000000)}`,
  index: 1,
  name: '',
  description: '',
  purchaseProration: null,
  cancelProration: null,
  upgradeProration: null,
  downgradeProration: null,
  unit: null,
  frequency: 1,
};

const mapPricingFromItem = ({
  pricingModel,
  flatPricing,
  recurringPricing,
  customerPricing,
  tierPricing,
  txnPricing,
  transactionType,
  complexPricing,
  pricings,
  isChangeModel,
  usageAttributePricing,
}) => {
  if (pricingModel === 'FLAT')
    return !flatPricing || flatPricing.length === 0 ? [{ ...defaultFlatPricing }] : flatPricing;
  if (pricingModel === 'RECURRING')
    return !recurringPricing || recurringPricing.length === 0 || isChangeModel
      ? [{ ...defaultRecurringPricing }]
      : recurringPricing;
  if (pricingModel === 'CUSTOMER_ATTRIBUTE_BASED')
    return !customerPricing || customerPricing.length === 0 ? [{ ...defaultCustomerPricing }] : customerPricing;
  if (pricingModel === 'RECURRING_TIERED')
    return !recurringPricing || recurringPricing.length === 0 || isChangeModel
      ? [{ ...defaultRecurringTiered }]
      : recurringPricing;
  if (pricingModel === 'TRANSACTION_ATTRIBUTE_BASED')
    return !txnPricing || txnPricing.length === 0 ? [{ ...defaultAttributeBased }] : txnPricing[0].txnPrices;
  if (pricingModel === 'TIERED') {
    return !tierPricing || !tierPricing.length ? [{ ...defaultTiered }] : tierPricing;
  }
  if (
    pricingModel === 'COMPLEX' &&
    (transactionType === 'USAGE' || transactionType === 'FORWARD_RECURRING' || transactionType === 'ARREARS_RECURRING')
  ) {
    return !complexPricing || !complexPricing.length
      ? [{ ...defaultComplex }]
      : orderBy(complexPricing, ['index'], ['asc']);
  }

  if (pricingModel === 'USAGE_ATTRIBUTE_BASED') {
    return !usageAttributePricing || usageAttributePricing.length === 0
      ? [{ ...defaultUsageAttributeBase }]
      : usageAttributePricing;
  }

  return pricings;
};

class PricingOfferForm extends Component {
  state = {
    wasValidated: false,
    formValid: true,
    isSelectingItemId: false,
    itemId: null,
    pricingModel: '',
    transactionType: '',
    isOpenModalBilledService: false,
    activeTab: {},
    provisioningNames: null,
    isOpenModalProvisioningName: false,
    isMultipleSelectProvisioningName: false,
    itemProvisioningNameSelected: null,
    indexProvisioningNameSelected: null,
  };

  static getDerivedStateFromProps(props, state) {
    if (state.oldItem === props.item) return null;
    return {
      ...props.item,
      pricings: mapPricingFromItem(props.item || {}) || [],
      item: props.item,
      oldItem: props.item,
    };
  }

  onToggleTab = activeTabName => {
    const { activeTab } = this.state;
    if (activeTab.name === activeTabName) {
      this.setState({
        activeTab: { name: activeTabName, isActive: !activeTab.isActive },
      });
    } else {
      this.setState({ activeTab: { name: activeTabName, isActive: true } });
    }
  };

  render() {
    const {
      creatingMode,
      optionsAccumulatorId,
      optionsGrantId,
      t,
      usageContainerRecordList,
      isShowBilledService,
      onCancel,
    } = this.props;
    const { pricings, pricingModel, item, transactionType, activeTab, provisioningNames, itemId } = this.state;
    const dataCopy2Create = cloneDeep(item);
    if (dataCopy2Create.id) delete dataCopy2Create.id;
    if (dataCopy2Create.name) delete dataCopy2Create.name;
    if (dataCopy2Create.description) delete dataCopy2Create.description;

    const newStatusOptionsWithPermission = cloneDeep(t('selections:statusPricing')());

    const tabelColumnProvisioningNames = [
      {
        name: 'name',
        label: 'label.provisioningName',
        style: { width: '280px' },
        required: true,
      },
    ];

    let listTaxCodeFields = [];
    const additionalFields = [];

    if (itemId) {
      listTaxCodeFields = [
        {
          name: 'taxCode',
          label: 'common:label.taxCode',
          wrapperClass: 'col-md-4',
          readOnly: true,
        },
        {
          label: 'common:label.realTimeTaxation',
          wrapperClass: 'col-md-4 mt-4 pt-1',
          name: 'ratingTimeTaxation',
          type: 'checkbox',
        },
      ];
    }

    if (lisTransactionTypeSupportRateWhenInactive.includes(transactionType)) {
      additionalFields.push({
        label: 'common:label.rateWhenInactive',
        wrapperClass: 'mt-4 pt-2',
        name: 'rateWhenInactive',
        type: 'checkbox',
        tooltipDetails: 'common:message.rateWhenInactiveInfoPO',
      });
    }

    let listInputs = [
      {
        label: 'common:label.id',
        wrapperClass: 'col-md-4',
        name: 'id',
      },
      {
        label: 'common:label.name',
        wrapperClass: 'col-md-4',
        name: 'name',
        required: true,
      },
      {
        label: 'common:label.useNameAsInvoiceDescription',
        wrapperClass: 'col-md-4 mt-4 pt-1',
        name: 'offerNameAsTxnDescr',
        type: 'checkbox',
        tooltipDetails: t('message.useNameAsInvoiceDescriptionTooltip'),
      },
      {
        label: 'common:label.description',
        wrapperClass: 'col-md-4',
        name: 'description',
        type: 'textarea',
        required: true,
      },
      {
        name: 'omsTaskType',
        label: 'common:label.type',
        wrapperClass: 'col-md-4',
        type: 'select',
        tOptions: 'selections:omsTaskType',
        isClearable: true,
      },
      {
        name: 'pricingType',
        label: 'common:label.pricingType',
        wrapperClass: 'col-md-4',
        tOptions: 'selections:pricingType',
        isClearable: true,
        type: 'select',
      },
      {
        label: 'common:label.billableServiceSelect',
        wrapperClass: 'col-md-4',
        name: 'billableServiceId',
        required: true,
        type: 'popup',
      },
      {
        label: 'common:label.itemId',
        wrapperClass: 'col-md-4',
        name: 'itemId',
        required: true,
        type: 'popup',
      },
      ...listTaxCodeFields,
      {
        name: 'serviceType',
        label: 'common:label.serviceType',
        wrapperClass: 'col-md-4',
        type: 'select',
        tOptions: 'selections:serviceType',
        isClearable: true,
        required: true,
      },
      {
        name: 'transactionType',
        label: 'common:label.transactionType',
        wrapperClass: 'col-md-4',
        type: 'select',
        tOptions: 'selections:transactionType',
        required: true,
        isClearable: true,
      },
      {
        name: 'pricingModel',
        label: 'common:label.pricingModel',
        wrapperClass: 'col-md-4',
        type: 'select',
        tOptions: 'selections:pricingModel',
        isClearable: true,
        required: true,
      },
      {
        name: 'status',
        label: 'common:label.status',
        wrapperClass: 'col-md-4',
        type: 'select',
        options: newStatusOptionsWithPermission,
        isClearable: true,
      },
      {
        label: 'common:label.effectiveDate',
        wrapperClass: 'col-md-4',
        name: 'effectiveDate',
        type: 'date',
        required: true,
      },
      {
        name: 'startDate',
        label: 'common:label.startDate',
        wrapperClass: 'col-md-4',
        type: 'date',
        isClearable: true,
      },
      {
        label: 'common:label.startUnit',
        wrapperClass: 'col-md-4',
        type: 'select',
        name: 'startUnit',
        tOptions: 'selections:timeUnit',
      },
      {
        label: 'common:label.startDuration',
        wrapperClass: 'col-md-4',
        type: 'number',
        name: 'startDuration',
      },
      {
        name: 'endDate',
        label: 'common:label.endDate',
        wrapperClass: 'col-md-4',
        type: 'date',
        isClearable: true,
      },
      {
        label: 'common:label.endUnit',
        wrapperClass: 'col-md-4',
        type: 'select',
        name: 'endUnit',
        tOptions: 'selections:timeUnit',
      },
      {
        label: 'common:label.endDuration',
        wrapperClass: 'col-md-4',
        type: 'number',
        name: 'endDuration',
      },
      {
        label: 'common:label.minimumQuantity',
        wrapperClass: 'col-md-4',
        type: 'number',
        name: 'minimumQuantity',
      },
      {
        label: 'common:label.maximumQuantity',
        wrapperClass: 'col-md-4',
        type: 'number',
        name: 'maximumQuantity',
      },
      {
        name: 'salesChannel',
        label: 'common:label.salesChannel',
        wrapperClass: 'col-md-4',
        type: 'select',
        tOptions: 'selections:salesChannel',
        isClearable: true,
      },
      {
        name: 'marketSegment',
        label: 'common:label.marketSegment',
        wrapperClass: 'col-md-4',
        type: 'select',
        tOptions: 'selections:marketSegment',
        isClearable: true,
      },
      {
        name: 'accountType',
        label: 'common:label.accountType',
        wrapperClass: 'col-md-4',
        type: 'select',
        tOptions: 'selections:accountType',
        isClearable: true,
      },
      {
        name: 'accountSubType',
        label: 'common:label.accountSubType',
        wrapperClass: 'col-md-4',
        type: 'select',
        tOptions: 'selections:accountSubType',
        isClearable: true,
      },
      {
        name: 'country',
        label: 'common:label.country',
        wrapperClass: 'col-md-4',
        options: countryList,
        isClearable: true,
        type: 'select',
      },
      {
        label: 'common:label.accountingCode',
        wrapperClass: 'col-md-4',
        name: 'accountingCode',
      },
      {
        label: 'common:label.serviceAddOn',
        wrapperClass: 'col-md-4',
        name: 'serviceAddOn',
      },
      {
        label: 'common:label.externalId',
        wrapperClass: 'col-md-4',
        name: 'externalId',
      },
      {
        label: 'common:label.externalName',
        wrapperClass: 'col-md-4',
        name: 'externalName',
      },
      {
        label: 'common:label.isProvisionable',
        wrapperClass: 'col-md-4 mt-4 pt-2',
        name: 'isProvisionable',
        type: 'checkbox',
      },
      ...additionalFields,
    ];

    if (!isShowBilledService) {
      listInputs = listInputs.filter(val => val.name !== 'billableServiceId');
    }

    return (
      <div>
        <div className="form-row">
          {listInputs.map(input => {
            if (input.name === 'ratingTimeTaxation') {
              return (
                <GenericInput
                  {...input}
                  key={input.name}
                  value={this.state[input.name] !== false}
                  onChange={() => {}}
                  disabled
                  readOnly
                />
              );
            }
            return (
              <GenericInput
                {...input}
                key={input.name}
                value={this.state[input.name]}
                onChange={() => {}}
                disabled
                readOnly
              />
            );
          })}
          <div className="col-md-12 p-0 mt-3 mb-3">
            {provisioningNamesCollapsible.map(item => (
              <FormCollapse
                key={item.id}
                title={item.title}
                isActive={item.state === activeTab.name && activeTab.isActive}
                onToggleTab={this.onToggleTab}
                state={item.state}
              >
                <DataTable
                  columns={tabelColumnProvisioningNames}
                  data={provisioningNames && provisioningNames.length ? provisioningNames : []}
                  isSupportRemoveIndex
                />
              </FormCollapse>
            ))}
          </div>
          <div className="col-md-12">
            <PricingSection
              pricings={cloneDeep(pricings)}
              pricingModel={pricingModel}
              creatingMode={creatingMode}
              optionsAccumulatorId={optionsAccumulatorId}
              optionsGrantId={optionsGrantId}
              transactionType={transactionType}
              usageContainerRecordList={usageContainerRecordList}
            />
          </div>

          <div className="form-group col-md-12 action-buttons mt-3">
            <button onClick={onCancel} type="button" className="button button-border black x-small  float-right">
              {t('label.cancel')}
            </button>
          </div>
        </div>
      </div>
    );
  }
}

PricingOfferForm.propTypes = {
  onSubmit: PropTypes.func,
  item: PropTypes.objectOf(PropTypes.any),
  isSubmitting: PropTypes.bool,
  creatingMode: PropTypes.bool,
  getPriceOfferAudit: PropTypes.func.isRequired,
  optionsAccumulatorId: PropTypes.arrayOf(PropTypes.any),
  optionsGrantId: PropTypes.arrayOf(PropTypes.any),
  usageContainerRecordList: PropTypes.arrayOf(PropTypes.any),
  isShowBilledService: PropTypes.bool,
  t: PropTypes.func,
  onCancel: PropTypes.func,
};

PricingOfferForm.defaultProps = {
  onSubmit: () => {},
  item: {},
  isSubmitting: false,
  creatingMode: true,
  optionsAccumulatorId: [],
  optionsGrantId: [],
  usageContainerRecordList: [],
  isShowBilledService: false,
  t: () => {},
  onCancel: () => {},
};

export default withTranslation(['common'])(PricingOfferForm);
