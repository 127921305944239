import gql from 'graphql-tag';

export default dummy => {
  const getJobScheduleConfig = `getJobScheduleConfig(dummy: "${dummy || ''}")`;
  return gql`
    query {
      ${getJobScheduleConfig} {
        id
        userId
        name
        scheduleFrequency
        exceptionStartTime
        exceptionEndTime
        description
        jobScheduleTime
        configJobList {
          index
          jobNames
          userId
        }
      }
    }
  `;
};
