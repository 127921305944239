import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { cloneDeep } from 'lodash';
import PageTitle from '../../../components/PageTitle';
import RouteNames from '../../App/RouteNames';
import TitleFrom from '../../../components/common/TitleFrom';
import { GenericInput, ModalViewNote, ModalWithItem } from '../../../components/common';
import { makeGetOperatorRecordLog } from '../selectors';
import { makeGetS3Config } from '../../App/selectors';
import { createAndProcessUsageRecord } from '../actions';
import { getConfigUsageTypeConfig } from '../../App/actions';

const upDownVolumnFields = [
  {
    name: 'uploadVolume',
    label: 'label.uploadVolume',
    type: 'number',
  },
  {
    name: 'downloadVolume',
    label: 'label.downloadVolume',
    type: 'number',
  },
];

const messageNotesUsageTool = t => <div dangerouslySetInnerHTML={{ __html: t('message.messageNotesUsageTool') }} />;

const RecordLog = ({ createAndProcessUsageRecord, permissions, getConfigUsageTypeConfig }) => {
  const { t } = useTranslation('common');
  const formRef = useRef();
  const [wasValidated, setValidate] = useState(false);
  const [data, setData] = useState({ usageRecIndicator: 'POSTPAID' });
  const [responseData, setResponseData] = useState({});
  const [isOpenModalNotesAction, setIsOpenModalNotesAction] = useState(false);
  const [isOpenModalItem, setIsOpenModalItem] = useState(false);
  const [defaultOptionUsageType, setDefaultOptionUsageType] = useState([]);

  const { modeTestUsage } = permissions;

  const validate = (out = false) => {
    const formValid = formRef && formRef.current.checkValidity();
    const { elements } = formRef.current;
    // console.log('validate')
    for (let i = 0; i < elements.length; i++) {
      if (!elements[i].validity.valid) {
        console.log(elements[i].name, 'invalid');
      }
    }
    if (!formValid && out) {
      toast.error(t('message.mandatory'));
    }
    return formValid;
  };

  let listFileds = [
    {
      name: 'provisioningId',
      label: 'label.provisioningId',
      required: true,
    },
    {
      name: 'serviceType',
      label: 'label.serviceType',
      tOptions: 'selections:serviceType',
      type: 'select',
      required: true,
    },
    {
      name: 'usageRecIndicator',
      label: 'label.recordType',
      tOptions: 'selections:usageRecIndicator',
      type: 'select',
      required: true,
    },
    {
      name: 'unit',
      label: 'label.unit',
      tOptions: 'selections:scaleUnit',
      type: 'select',
      required: true,
    },
    {
      name: 'startDate',
      label: 'label.startDate',
      type: 'datetime',
      required: true,
    },
    {
      name: 'endDate',
      label: 'label.endDate',
      type: 'datetime',
    },
    {
      name: 'quantity',
      label: 'label.quantity',
      type: 'number',
    },
    {
      name: 'usageType',
      label: 'label.usageType',
      type: 'select',
      options: defaultOptionUsageType || [],
    },
    {
      name: 'usageClass',
      label: 'label.usageClass',
    },
    {
      name: 'apartyNumber',
      label: 'label.aPartyNumber',
    },
    {
      name: 'bpartyNumber',
      label: 'label.bPartyNumber',
    },
    {
      name: 'flexattr1',
      label: 'label.flexattr1',
    },
    {
      name: 'flexattr2',
      label: 'label.flexattr2',
    },
    {
      name: 'flexattr3',
      label: 'label.flexattr3',
    },
    {
      name: 'flexattr4',
      label: 'label.flexattr4',
    },
    {
      name: 'flexattr5',
      label: 'label.flexattr5',
    },
    {
      name: 'accumulatorAmount',
      label: 'label.accumulatorAmount',
      type: 'number',
    },
  ];

  if (data.serviceType === 'DATA') {
    listFileds = [...listFileds, ...upDownVolumnFields];
  }

  const onToggleModalNotesAction = () => setIsOpenModalNotesAction(!isOpenModalNotesAction);
  const onToggleModalItem = () => setIsOpenModalItem(!isOpenModalItem);

  const onCreateAndProcessUsageRecord = e => {
    e.preventDefault();
    setValidate(true);
    if (!validate(true)) {
      return false;
    }
    return createAndProcessUsageRecord(cloneDeep(data), res => {
      if (res.success) {
        // setData({});
        setResponseData(res.data);
        setValidate(false);
        setIsOpenModalItem(true);
      }
    });
  };

  useEffect(() => {
    getConfigUsageTypeConfig('', ({ success, data }) => {
      let usageTypeList = [];

      if (success && data?.configUsageTypeService) {
        data.configUsageTypeService.forEach(val => {
          usageTypeList = [...usageTypeList, ...val.configUsageTypeList];
        });

        const defaultOptionUsageType = usageTypeList.map(val => {
          return { label: val.usagetype, value: val.usagetype };
        });
        setDefaultOptionUsageType(defaultOptionUsageType);
      }
    });
  }, [getConfigUsageTypeConfig]);

  if (!modeTestUsage) return null;

  const onChange = ({ name, value }) => {
    data[name] = value;
    if (name === 'serviceType' && value !== 'DATA') {
      data.uploadVolume = null;
      data.downloadVolume = null;
    }
    setData({ ...data });
  };

  return (
    <div className="col-md-12">
      <PageTitle
        linkTo={RouteNames.invoiceUsage.path}
        titleBtn={t('label.back')}
        items={[
          { name: t('navbar:billingHub.subMain.billing'), url: RouteNames.invoiceUsage.path },
          { name: t('usagePage:sidebar.testUsage') },
        ]}
        isNoDefaultButton
      />
      <br />
      <div className="card card-statistics mb-30">
        <TitleFrom title={t('label.testUsageTool')}>
          <button
            type="button"
            className="button button-border gray ml-2"
            onClick={() => setIsOpenModalNotesAction(true)}
          >
            {t('label.quickNotes')}
            <i className="fa fa-external-link ml-2" aria-hidden="true" />
          </button>
        </TitleFrom>

        <div className="col-md-12 mb-30">
          <form
            onSubmit={onCreateAndProcessUsageRecord}
            className={`needs-validation row card-body ${wasValidated ? 'was-validated' : ''}`}
            noValidate
            ref={formRef}
          >
            <div className="col-md-12 row">
              {listFileds.map(item => (
                <GenericInput {...item} wrapperClass="col-md-4" value={data[item.name]} onChange={onChange} />
              ))}
            </div>
            <div className="col-md-12 mt-2">
              <button type="submit" className="button button-border x-small float-right">
                {t('label.testUsage')}
              </button>
              <button
                type="button"
                className="button button-border black x-small mr-2 float-right"
                onClick={() => setData({ usageRecIndicator: 'POSTPAID' })}
              >
                {t('label.clear')}
              </button>
            </div>
          </form>
        </div>
      </div>
      <ModalViewNote
        isOpen={isOpenModalNotesAction}
        message={messageNotesUsageTool(t)}
        onToggle={onToggleModalNotesAction}
      />
      <ModalWithItem
        // wrapperClass="modal-custom modal-70"
        modalTitle={t('label.details')}
        isOpen={isOpenModalItem}
        onToggle={onToggleModalItem}
      >
        <div className="col-md-12 pt-3 pb-3 pt-0">
          <GenericInput
            readOnly
            name="provisioningId"
            label="label.provisioningId"
            wrapperClass="col-md-4"
            value={responseData.provisioningId}
          />
          <GenericInput
            readOnly
            name="ratingResult"
            label="label.result"
            wrapperClass="col-md-12 mt-2"
            type="textarea"
            value={responseData.ratingResult}
          />
          <button
            type="button"
            onClick={onToggleModalItem}
            className="button button-border black x-small float-right mt-3"
          >
            {t('label.back')}
          </button>
        </div>
      </ModalWithItem>
    </div>
  );
};
RecordLog.propTypes = {
  createAndProcessUsageRecord: PropTypes.func.isRequired,
  getConfigUsageTypeConfig: PropTypes.func.isRequired,
};

RecordLog.defaultProps = {};

const mapStateToProps = createStructuredSelector({
  listOperatorRecordLog: makeGetOperatorRecordLog() || [],
  s3Config: makeGetS3Config() || {},
});
export default connect(mapStateToProps, { createAndProcessUsageRecord, getConfigUsageTypeConfig })(RecordLog);
