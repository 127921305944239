export default {
  sidebar: {
    productFamily: 'Familia de Productos',
    resources: 'Recursos',
    usageRating: 'Tarificación de Uso',
    usageMapping: 'Mapeo de Uso',
    currency: 'Divisa',
    grants: 'Subsidios',
    accumulators: 'Acumuladores',
    // accumulators: 'Accumulators',
    rateUnit: 'Unidad Tarifaria',
    rateUnitMap: 'Mapa de Unidad Tarifaria',
    timeUnit: 'Unidad de Tiempo',
    zoneUnit: 'Unidad de Zona',
    customRecords: 'Registro Personalizado',
    fileMapping: 'Asignación de Archivos',
    processingStreams: 'Procesando Flujos',
    taxation: 'Impuestos',
    taxCodeConfig: 'Configuración de Código de Impuestos',
    thirdPartyTaxConfig: 'Configuración de Impuestos de Terceros',
    regionMapping: 'Mapeo de Región',
    zeroPriceUsage: 'Uso a Precio Cero',
    operatorMap: 'Mapeo Operador',
    trunkRule: 'Regla de Troncal',
    unitOfMeasure: 'Unidad de Medida',
    usageType: 'Tipo de Uso',
    voicePlans: 'Planes de Voz',
    countryCodes: 'Códigos de País',
    PNNList: 'Lista PNN',
    ratingConfig: 'Configuración de Tasación',
    pacProductCodes: 'Códigos de Producto PAC',
    prepaidConfig: 'Configuración Prepago',
    topupOffers: 'Ofertas de Recarga',
    taxTypeConfig: 'Configuración de Tipo de Impuestos',
    pricingConfig: 'Configuración de Precios',
    regulatoryProductCodes: 'Códigos de Producto Regulatorios',
    multiRatingConfig: 'Configuración Multi-Tarifa',
  },
};
