import gql from 'graphql-tag';
import { parseToMutationRequest } from '../../../utils/utils';

export default data => {
  const dataCreate = parseToMutationRequest(data, [
    'index',
    'jurisdiction',
    'taxPercent',
    'minimumThreshold',
    'maximumThreshold',
    'additionalTaxRate',
    'transactionId',
    'serviceId',
    'type',
    'minAccumulatorThreshold',
    'maxAccumulatorThreshold',
    'accumulatorMinTrigger',
  ]);
  const createTaxConfig = `createTaxConfig(input: ${dataCreate})`;
  return gql`
  mutation {
    ${createTaxConfig} {
      id
    }
  }
`;
};
