import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import SearchComponent from '../../PricingHub/SearchComponent';

class OrderSearchForm extends SearchComponent {
  searchInputs = [
    {
      label: 'label.startDate',
      name: 'startDate',
      type: 'date',
    },
    {
      label: 'label.endDate',
      name: 'endDate',
      type: 'date',
    },
    {
      label: 'label.orderType',
      name: 'orderType',
      type: 'select',
      tOptions: 'selections:types',
      isClearable: true,
    },
    {
      label: 'label.accountType',
      name: 'accountType',
      type: 'select',
      tOptions: 'selections:accountType',
      isClearable: true,
    },
    {
      label: 'label.orderStatus',
      name: 'status',
      type: 'select',
      tOptions: 'selections:statusOrder',
      isClearable: true,
    },
    {
      label: 'label.organization',
      name: 'company',
    },
    { label: 'label.lineOfBusiness', name: 'lineOfBusiness' },
    {
      label: 'common:label.groupBy',
      name: 'groupBy',
      type: 'checkbox',
      wrapperClass: 'form-group mt-4 pt-2',
      tooltipDetails: 'common:message.groupByOrderReportInfo',
    },
  ];

  btnSpan = 'col-md-12';
}

OrderSearchForm.propTypes = {
  onSubmit: PropTypes.func,
  isSubmitting: PropTypes.bool,
};

OrderSearchForm.defaultProps = {
  isSubmitting: false,
};

export default withTranslation('common')(OrderSearchForm);
