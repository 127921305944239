import * as types from './types';

export function getChartOfAccount(payload, cb) {
  return {
    type: types.GET_CHART_OF_ACCOUNT,
    payload,
    cb,
  };
}
export function searchItems(payload, cb) {
  return {
    type: types.SEARCH_ITEMS,
    payload,
    cb,
  };
}

export function searchItemsSuccess(payload) {
  return {
    type: types.SEARCH_ITEMS_SUCCESS,
    payload,
  };
}

export function searchItemsFailure(payload) {
  return {
    type: types.SEARCH_ITEMS_FAILURE,
    payload,
  };
}

export function setParamsItems(payload) {
  return {
    type: types.SET_PARAMS_ITEMS,
    payload,
  };
}

export function getItemDetail(payload, cb) {
  return {
    type: types.GET_ITEM_DETAIL,
    payload,
    cb,
  };
}

export function getItemDetailSuccess(payload) {
  return {
    type: types.GET_ITEM_DETAIL_SUCCESS,
    payload,
  };
}

export function getItemDetailFailure(payload) {
  return {
    type: types.GET_ITEM_DETAIL_FAILURE,
    payload,
  };
}

export function createItem(payload, cb) {
  return {
    type: types.CREATE_ITEM,
    payload,
    cb,
  };
}

export function createItemSuccess() {
  return {
    type: types.CREATE_ITEM_SUCCESS,
  };
}

export function createItemFailure(payload) {
  return {
    type: types.CREATE_ITEM_FAILURE,
    payload,
  };
}

// ------------priceOffers
export function setParamsPriceOffers(payload) {
  return {
    type: types.SET_PARAMS_PRICE_OFFERS,
    payload,
  };
}

export function searchPriceOffers(payload, cb) {
  return {
    type: types.SEARCH_PRICE_OFFERS,
    payload,
    cb,
  };
}

export function searchPriceOffersSuccess(payload) {
  return {
    type: types.SEARCH_PRICE_OFFERS_SUCCESS,
    payload,
  };
}

export function searchPriceOffersFailure(payload) {
  return {
    type: types.SEARCH_PRICE_OFFERS_FAILURE,
    payload,
  };
}

export function getPriceOfferDetail(payload, cb) {
  return {
    type: types.GET_PRICE_OFFER_DETAIL,
    payload,
    cb,
  };
}

export function getPriceOfferDetailSuccess(payload) {
  return {
    type: types.GET_PRICE_OFFER_DETAIL_SUCCESS,
    payload,
  };
}

export function getPriceOfferDetailFailure(payload) {
  return {
    type: types.GET_PRICE_OFFER_DETAIL_FAILURE,
    payload,
  };
}

export function createPriceOffer(payload, cb) {
  return {
    type: types.CREATE_PRICE_OFFER,
    payload,
    cb,
  };
}

export function createPriceOfferSuccess() {
  return {
    type: types.CREATE_PRICE_OFFER_SUCCESS,
  };
}

export function createPriceOfferFailure(payload) {
  return {
    type: types.CREATE_PRICE_OFFER_FAILURE,
    payload,
  };
}

// modify item
export function modifyItem(payload, cb) {
  return {
    type: types.MODIFY_ITEM,
    payload,
    cb,
  };
}

export function modifyItemSuccess(payload) {
  return {
    type: types.MODIFY_ITEM_SUCCESS,
    payload,
  };
}

// update item status
export function updateItemStatus(payload, cb) {
  return {
    type: types.UPDATE_ITEM_STATUS,
    payload,
    cb,
  };
}

export function updateItemStatusSuccess(payload) {
  return {
    type: types.UPDATE_ITEM_STATUS_SUCCESS,
    payload,
  };
}

// modify price offer
export function modifyPriceOffer(payload, cb) {
  return {
    type: types.MODIFY_PRICE_OFFER,
    payload,
    cb,
  };
}

export function modifyPriceOfferSuccess(payload) {
  return {
    type: types.MODIFY_PRICE_OFFER_SUCCESS,
    payload,
  };
}

// update price offer status
export function updatePriceOfferStatus(payload, cb) {
  return {
    type: types.UPDATE_PRICE_OFFER_STATUS,
    payload,
    cb,
  };
}

export function updatePriceOfferStatusSuccess(payload) {
  return {
    type: types.UPDATE_PRICE_OFFER_STATUS_SUCCESS,
    payload,
  };
}

export function getUsageContainerSpec(cb, payload) {
  return {
    type: types.GET_USAGE_CONTAINER_SPEC,
    cb,
    payload,
  };
}

// Discount Offer
export function searchDiscountOffers(payload, cb) {
  return {
    type: types.SEARCH_DISCOUNT_OFFERS,
    payload,
    cb,
  };
}

export function searchDiscountOffersSuccess(payload) {
  return {
    type: types.SEARCH_DISCOUNT_OFFERS_SUCCESS,
    payload,
  };
}

export function createDisscountOffer(payload, cb) {
  return {
    type: types.CREATE_DISCOUNT_OFFER,
    payload,
    cb,
  };
}

export function getDiscountOfferDetail(payload, cb) {
  return {
    type: types.GET_DISCOUNT_OFFER_DETAIL,
    payload,
    cb,
  };
}

export function getDiscountOfferDetailSuccess(payload) {
  return {
    type: types.GET_DISCOUNT_OFFER_DETAIL_SUCCESS,
    payload,
  };
}

export function modifyDiscountOffer(payload, cb) {
  return {
    type: types.MODIFY_DISCOUNT_OFFER,
    payload,
    cb,
  };
}

export function updateDiscountOfferStatus(payload, cb) {
  return {
    type: types.UPDATE_DISCOUNT_OFFER_STATUS,
    payload,
    cb,
  };
}

export function getProductFamilyConfig(cb) {
  return {
    type: types.GET_PRODUCT_FAMILY_CONFIG,
    cb,
  };
}

export function getProductFamilyConfigSuccess(payload, cb) {
  return {
    type: types.GET_PRODUCT_FAMILY_CONFIG_SUCCESS,
    payload,
    cb,
  };
}

export function modifyProductFamilyConfig(payload) {
  return {
    type: types.MODIFY_PRODUCT_FAMILY_CONFIG,
    payload,
  };
}

export function createProductFamilyConfig(payload, cb) {
  return {
    type: types.CREATE_PRODUCT_FAMILY_CONFIG,
    payload,
    cb,
  };
}

// Item Services
export function searchItemServices(payload, cb) {
  return {
    type: types.SEARCH_ITEM_SERVICE,
    payload,
    cb,
  };
}

export function searchItemServicesSuccess(payload, cb) {
  return {
    type: types.SEARCH_ITEM_SERVICE_SUCCESS,
    payload,
    cb,
  };
}

export function modifyItemServices(payload, cb) {
  return {
    type: types.MODIFY_ITEM_SERVICE,
    payload,
    cb,
  };
}

export function createItemServices(payload, cb) {
  return {
    type: types.CREATE_ITEM_SERVICE,
    payload,
    cb,
  };
}

export function getItemServiceSelect(payload, cb) {
  return {
    type: types.SEARCH_ITEM_SERVICE_SELECT,
    payload,
    cb,
  };
}

export function searchAllItemServices(payload, cb) {
  return {
    type: types.SEARCH_ALL_ITEM_SERVICE,
    payload,
    cb,
  };
}

export function searchAllItemServicesSuccess(payload, cb) {
  return {
    type: types.SEARCH_ALL_ITEM_SERVICE_SUCCESS,
    payload,
    cb,
  };
}

export function getItemServiceProcessStats(payload, cb) {
  return {
    type: types.GET_ITEM_SERVICE_PROCESS_STATS,
    payload,
    cb,
  };
}

export function getItemServiceFileUploadErrors(payload, cb) {
  return {
    type: types.GET_IETM_SERVICE_FILE_UPLOAD_ERRORS,
    payload,
    cb,
  };
}

// Discount trigger
export function searchDiscountTriggers(payload, cb) {
  return {
    type: types.SEARCH_DISCOUNT_TRIGGER,
    payload,
    cb,
  };
}

export function searchDiscountTriggersSuccess(payload) {
  return {
    type: types.SEARCH_DISCOUNT_TRIGGER_SUCCESS,
    payload,
  };
}

export function getDiscountTriggerByName(payload, cb) {
  return {
    type: types.GET_DISCOUNT_TRIGGER_BY_NAME,
    payload,
    cb,
  };
}

export function createDiscountTrigger(payload, cb) {
  return {
    type: types.CREATE_DISCOUNT_TRIGGER,
    payload,
    cb,
  };
}

export function modifyDiscountTrigger(payload, cb) {
  return {
    type: types.MODIFY_DISCOUNT_TRIGGER,
    payload,
    cb,
  };
}

export function getPriceOfferAudit(payload, cb) {
  return {
    type: types.GET_PRICE_OFFER_AUDIT,
    payload,
    cb,
  };
}

export function convertJsonToPriceOffer(payload, cb) {
  return {
    type: types.CONVERT_JSON_TO_PRICE_OFFER,
    payload,
    cb,
  };
}

export function getDiscountOfferAudit(payload, cb) {
  return {
    type: types.GET_DISCOUNT_OFFER_AUDIT,
    payload,
    cb,
  };
}

export function convertJsonToDiscountOffer(payload, cb) {
  return {
    type: types.CONVERT_JSON_TO_DISCOUNT_OFFER,
    payload,
    cb,
  };
}

export function searchProductFamilyConfig(payload, cb) {
  return {
    type: types.SEARCH_PRODUCT_FAMILY_CONFIG,
    payload,
    cb,
  };
}

export function searchProductFamilyConfigSuccess(payload, cb) {
  return {
    type: types.SEARCH_PRODUCT_FAMILY_CONFIG_SUCCESS,
    payload,
    cb,
  };
}
