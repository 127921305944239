import gql from 'graphql-tag';
import { parseToMutationRequest } from '../../utils/utils';

export default data => {
  const dataFilter = parseToMutationRequest(data, ['type', 'scheduleFrequency']);
  const getJobErrorDetails = `getJobErrorDetails( input: ${dataFilter} )`;
  return gql`
    query {
      ${getJobErrorDetails} {
        id
        reason
        scheduleDate
        type
        jobs {
          index
          batchId
          reason
          payload
          status
          accountId
        }   
      }
    }
  `;
};
