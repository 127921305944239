import * as types from './types';

export function getJobConfig(payload, cb) {
  return {
    type: types.GET_JOBCONFIG,
    payload,
    cb,
  };
}

export function modifyJobConfig(payload, cb) {
  return {
    type: types.MODIFY_JOBCONFIG,
    payload,
    cb,
  };
}

export function createJobConfig(payload, cb) {
  return {
    type: types.CREATE_JOBCONFIG,
    payload,
    cb,
  };
}

export function getJobScheduleByDate(payload, cb) {
  return {
    type: types.GET_JOBSCHEDULE,
    payload,
    cb,
  };
}

export function modifyJobSchedule(payload, cb) {
  return {
    type: types.MODIFY_JOBSCHEDULE,
    payload,
    cb,
  };
}

export function createJobScheduleByDate(payload, cb) {
  return {
    type: types.CREATE_JOBSCHEDULE,
    payload,
    cb,
  };
}

export function processJobSchedule(payload, cb, isProcessAll) {
  return {
    type: types.PROCESS_JOBSCHEDULE,
    payload,
    cb,
    isProcessAll,
  };
}

export function retryJobSchedule(payload, cb) {
  return {
    type: types.RETRY_JOBSCHEDULE,
    payload,
    cb,
  };
}

export function updateStatusJobSchedule(payload, cb) {
  return {
    type: types.UPDATE_STATUS_JOBSCHEDULE,
    payload,
    cb,
  };
}

export function getCcpDatetime(cb) {
  return {
    type: types.GET_JOB_CCP_DATETIME,
    cb,
  };
}

export function getJobErrors(payload, cb) {
  return {
    type: types.GET_JOB_ERRORS,
    payload,
    cb,
  };
}

export function getJobErrorDetails(payload, cb) {
  return {
    type: types.GET_JOB_ERROR_DETAILS,
    payload,
    cb,
  };
}

export function recreateJobScheduleByDate(payload, cb) {
  return {
    type: types.RECREATE_JOBSCHEDULE,
    payload,
    cb,
  };
}

export function resetSubsequentJobsFromErrorJob(payload, cb) {
  return {
    type: types.RESET_SUBSEQUENT_JOBS_FROM_ERROR_JOB,
    payload,
    cb,
  };
}

export function getJobScheduleConfigByFrequency(payload, cb) {
  return {
    type: types.GET_JOB_SCHEDULE_CONFIG_BY_FREQUENCY,
    payload,
    cb,
  };
}

export function getJobScheduleConfigByFrequencyDailySuccess(payload, cb) {
  return {
    type: types.GET_JOB_SCHEDULE_CONFIG_BY_FREQUENCY_DAILY_SUCCESS,
    payload,
    cb,
  };
}

export function getJobScheduleConfigByFrequencyScheduledSuccess(payload, cb) {
  return {
    type: types.GET_JOB_SCHEDULE_CONFIG_BY_FREQUENCY_SCHEDULED_SUCCESS,
    payload,
    cb,
  };
}
