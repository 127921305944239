import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { cloneDeep } from 'lodash';
import { withTranslation } from 'react-i18next';
import { createStructuredSelector } from 'reselect';
import { getLastIndex, validate, getPageTotalCount } from '../../../../utils/utils';
import { TableCollectionSchedule, FormAddCollectionSchedule } from '../../../../components/CollectionsHub';
import { DataTable, ModalWithItem, GenericInput, TablePagination } from '../../../../components/common';
import { makeGetPermissionsCollectionManagement } from '../../../App/selectors';
import {
  getCollectionScheduleByType,
  createCollectionSchedule,
  modifyCollectionSchedule,
  searchCollectionSchedules,
  getCollectionScheduleAudit,
  convertJsonToConfigCollectionSchedule,
} from '../../actions';
import { makeGetListCollectionActions } from '../../selectors';
import {
  checkPermissionCreateCollectionSheduleConfig,
  checkPermissionModifyCollectionSheduleConfig,
} from '../../CheckPermission';
import ModalSearchCollectionProfile from '../../../../components/common/ModalSearchCollectionProfile';

const auditHistoryColumns = [
  {
    name: 'id',
    label: 'label.id',
  },
  {
    name: 'effectiveDate',
    label: 'label.effectiveDate',
  },
  {
    name: 'createdDate',
    label: 'label.createdDate',
  },
  {
    name: 'collectionProfile',
    label: 'label.collectionProfile',
  },
];

const tableColumnsDetails = [
  {
    name: 'offsetdays',
    label: 'label.offsetDays',
  },
  {
    name: 'action',
    label: 'label.action',
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:collectionAction')().find(val => val.value === item.action) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
  },
  {
    name: 'actionWorkingDay',
    label: 'label.workingDay',
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:collectionActionWorkingDay')().find(val => val.value === item.actionWorkingDay) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
  },
  {
    name: 'description',
    label: 'label.description',
  },
];

class CollectionSchedule extends React.PureComponent {
  formRef = React.createRef();

  constructor() {
    super();
    this.state = {
      page: 0,
      size: 20,
      data: [],
      isOpenModalDetails: false,
      dataSelect: null,
      newSchedule: {},
      wasValidated: false,
      isOpenAuditModal: false,
      dataAuditHistory: [],
      isOpenAuditScheduleDetails: false,
      isOpenModalHistoryDetails: false,
      itemDetails: {},
    };
    this.isEmptyListServer = true;
  }

  componentDidMount() {
    this.doSearchCollectionSchedules();
  }

  doSearchCollectionSchedules = () => {
    const { searchCollectionSchedules } = this.props;
    const { page, size } = this.state;

    const payload = {
      page: page + 1,
      size,
    };
    searchCollectionSchedules(payload, ({ data }) => {
      this.setState({ data: data || [], totalCount: getPageTotalCount({ ...this.state, items: data || [] }) });
    });
  };

  onSelectTableRow = item => {
    let { rowSelectedItem } = this.state;
    if (rowSelectedItem === item) {
      rowSelectedItem = null;
    } else {
      rowSelectedItem = item;
    }
    this.setState({ rowSelectedItem });
  };

  onChangeRowValue = (index, type, val) => {
    try {
      const { dataSelect } = this.state;
      const newData = cloneDeep(dataSelect);
      newData.collectionSchedule[index][type] = val;
      this.setState({ dataSelect: newData });
    } catch (error) {
      console.log(error);
    }
  };

  onChangeNewValue = ({ name, value }) => {
    const { newSchedule } = this.state;
    newSchedule[name] = value;
    this.setState({
      newSchedule: { ...newSchedule },
    });
  };

  onSubmit = evt => {
    evt.preventDefault();
    const { dataSelect } = this.state;
    const { createCollectionSchedule, modifyCollectionSchedule, t } = this.props;
    this.setState({ wasValidated: true });
    if (!validate(true, this.formRef, t)) {
      return false;
    }
    this.setState({ wasValidated: false });

    if (!dataSelect.isNew) {
      const { isNew, collectionSchedule, collectionProfile, ...payload } = dataSelect;
      let newCollectionSchedule = null;
      if (collectionSchedule && collectionSchedule.length) {
        const existData = collectionSchedule.filter(val => !val.isNew);
        const newData = collectionSchedule
          .filter(val => val.isNew)
          .map((item, index) => {
            const { isNew, ...rest } = item;
            return { ...rest, index: getLastIndex({ data: existData }) + index };
          });
        newCollectionSchedule = [...existData, ...newData];
      }
      return modifyCollectionSchedule({ ...payload, collectionSchedule: newCollectionSchedule }, response => {
        if (response && response.modifyCollectionSchedule && response.modifyCollectionSchedule.id) {
          const { data } = this.state;
          const newData = cloneDeep(data);
          const indexModify =
            newData && newData.length ? newData.findIndex(val => val.id === response.modifyCollectionSchedule.id) : -1;
          if (indexModify !== -1) {
            newData[indexModify].collectionSchedule = response.modifyCollectionSchedule.collectionSchedule || null;
          }
          this.setState({ data: newData, isOpenModalDetails: false });
        }
      });
    }

    const { isNew, collectionSchedule, ...payload } = dataSelect;
    let newCollectionSchedule = null;
    if (collectionSchedule && collectionSchedule.length) {
      newCollectionSchedule = collectionSchedule.map((val, idx) => {
        const { index, isNew, ...rest } = val;
        return { ...rest, index: idx + 1 };
      });
    }
    return createCollectionSchedule({ ...payload, collectionSchedule: newCollectionSchedule }, response => {
      if (response && response.createCollectionSchedule && response.createCollectionSchedule.id) {
        const { data } = this.state;
        const newData = cloneDeep(data);
        newData.push(response.createCollectionSchedule);
        this.setState({ data: newData, isOpenModalDetails: false });
      }
    });
  };

  handleClickAddNewRecord = () => {
    const { dataSelect, newSchedule } = this.state;
    const newData = cloneDeep(dataSelect);

    try {
      if (!newData.collectionSchedule) newData.collectionSchedule = [];
      newData.collectionSchedule.push({
        ...cloneDeep(newSchedule),
        index: getLastIndex({ data: newData.collectionSchedule }),
        isNew: true,
      });
      this.setState({ dataSelect: { ...newData }, newSchedule: {} });
    } catch (error) {
      console.log(error);
    }
  };

  handleClickRemoveRecord = item => {
    const { dataSelect } = this.state;
    const newData = cloneDeep(dataSelect);
    const indexRemove = newData.collectionSchedule.findIndex(i => i.index === item.index);
    if (item.isNew) {
      newData.collectionSchedule.splice(indexRemove, 1);
    } else {
      newData.collectionSchedule[indexRemove] = { index: item.index };
    }
    this.setState({ dataSelect: newData });
  };

  onToggleModal = ({ item }) => {
    const { isOpenModalDetails } = this.state;
    this.setState({ isOpenModalDetails: !isOpenModalDetails, dataSelect: cloneDeep(item) });
  };

  onToggleModalAudit = ({ item }) => {
    const { isOpenAuditModal } = this.state;
    this.setState({ dataAuditHistory: [] });
    if (!isOpenAuditModal) {
      const { getCollectionScheduleAudit } = this.props;
      getCollectionScheduleAudit(item.id, ({ success, data }) => {
        if (success) this.setState({ dataAuditHistory: data || [] });
      });
    }

    this.setState({ isOpenAuditModal: !isOpenAuditModal });
  };

  onToggleModalCollectionProfile = ({ itemSelected }) => {
    const { isOpenModalCollectionProfile } = this.state;
    this.setState({
      isOpenModalCollectionProfile: !isOpenModalCollectionProfile,
      collectionProfileSelected: itemSelected || null,
    });
  };

  onSelectCollectionProfile = (id, row) => {
    if (row) {
      const { dataSelect } = this.state;
      const newData = cloneDeep(dataSelect);
      newData.collectionProfile = row.collectionProfile;
      this.setState({
        isOpenModalCollectionProfile: false,
        collectionProfileSelected: null,
        dataSelect: newData,
      });
    }
  };

  onChange = ({ name, value }) => {
    const { dataSelect } = this.state;
    dataSelect[name] = value;
    this.setState({ dataSelect: { ...dataSelect } });
  };

  onPageChange = page => {
    this.setState({ page }, () => this.doSearchCollectionSchedules());
  };

  onSizeChange = size => {
    this.setState({ size, page: 0 }, () => this.doSearchCollectionSchedules());
  };

  onToggleModalDetails = ({ item }) => {
    const { convertJsonToConfigCollectionSchedule } = this.props;
    const { isOpenAuditScheduleDetails } = this.state;
    this.setState({ itemDetails: {} });

    if (item)
      convertJsonToConfigCollectionSchedule(
        { id: item?.id || null, effectiveDate: item?.effectiveDate || null },
        ({ success, data }) => {
          if (success) this.setState({ itemDetails: data || {} });
        }
      );

    this.setState({ isOpenAuditScheduleDetails: !isOpenAuditScheduleDetails });
  };

  render() {
    const {
      dataSelect,
      data,
      isOpenModalDetails,
      newSchedule,
      isOpenModalCollectionProfile,
      collectionProfileSelected,
      wasValidated,
      totalCount,
      page,
      size,
      isOpenAuditModal,
      dataAuditHistory,
      isOpenAuditScheduleDetails,
      itemDetails,
    } = this.state;
    const { permissionsCollections, t } = this.props;
    const listKeyCollectionSchedule =
      dataSelect?.collectionSchedule && dataSelect.collectionSchedule.length > 0
        ? dataSelect?.collectionSchedule.map(item => item.action)
        : [];
    let listOptions = t('selections:collectionAction')();

    if (listKeyCollectionSchedule && listKeyCollectionSchedule.length) {
      listOptions = listOptions.filter(val => !listKeyCollectionSchedule.includes(val.value));
    }

    let modeCreateCollectionSheduleConfig = 0;
    let modeModifyCollectionSheduleConfig = 0;

    if (permissionsCollections && permissionsCollections.collectionModulePermissions) {
      const listPermission = permissionsCollections.collectionModulePermissions;
      modeCreateCollectionSheduleConfig = checkPermissionCreateCollectionSheduleConfig({ listPermission });
      modeModifyCollectionSheduleConfig = checkPermissionModifyCollectionSheduleConfig({ listPermission });
    }

    const columns = [
      {
        name: 'id',
        label: 'label.id',
      },
      {
        name: 'collectionProfile',
        label: 'label.collectionProfile',
      },
      {
        name: 'schedule',
        label: 'label.schedule',
        render: (colName, item) => (
          <button className="btn btn-outline-success btn-sm" type="button" onClick={() => this.onToggleModal({ item })}>
            {t('common:label.view')}
          </button>
        ),
      },
      {
        name: 'audit',
        label: 'label.auditHistory',
        render: (colName, item) => (
          <button
            className="button x-small mr-2 btn-config-item"
            type="button"
            onClick={() => this.onToggleModalAudit({ item })}
          >
            A
          </button>
        ),
      },
    ];

    return (
      <div>
        <DataTable columns={columns} data={data} />
        {modeCreateCollectionSheduleConfig === 2 && (
          <div className="col-md-12 mt-3 mb-3 ml-3">
            <button
              type="button"
              className="button button-border black x-small"
              onClick={() => this.onToggleModal({ item: { isNew: true } })}
            >
              {`+ ${t('label.addNewSchedule')}`}
            </button>
          </div>
        )}
        <div className="mb-0 height-pagination mt-4">
          <TablePagination
            pageNumber={page}
            pageSize={size}
            totalCount={totalCount}
            onPageChange={this.onPageChange}
            onSizeChange={this.onSizeChange}
          />
        </div>

        <ModalWithItem
          isOpen={isOpenModalDetails}
          onToggle={this.onToggleModal}
          wrapperClass="modal-custom modal-70 bd-example-modal-lg modal-selector"
          modalTitle={t('label.collectionSchedule')}
        >
          <form
            onSubmit={this.onSubmit}
            className={`needs-validation ${wasValidated ? 'was-validated' : ''}`}
            ref={this.formRef}
            noValidate
          >
            <div className="col-md-12 row mb-3">
              <GenericInput
                name="id"
                label="label.id"
                value={dataSelect ? dataSelect.id : null}
                wrapperClass="col-md-4"
                onChange={this.onChange}
                readOnly
                disabled
              />
              <GenericInput
                name="collectionProfile"
                label="label.collectionProfile"
                value={dataSelect ? dataSelect.collectionProfile : null}
                wrapperClass="col-md-4"
                onClick={() =>
                  this.onToggleModalCollectionProfile({
                    itemSelected: dataSelect ? dataSelect.collectionProfile || null : null,
                  })
                }
                required
                fa="fa fa-external-link"
              />
            </div>
            <div className="col-md-12 pt-3 action-buttons">
              {modeCreateCollectionSheduleConfig === 2 && (
                <FormAddCollectionSchedule
                  onChange={this.onChangeNewValue}
                  itemNew={newSchedule}
                  onAddNew={this.handleClickAddNewRecord}
                  modeCreateCollectionSheduleConfig={modeCreateCollectionSheduleConfig}
                  listOptionActions={listOptions}
                  isEnableAddBtn={!!(newSchedule.offsetdays && newSchedule.action && newSchedule.description)}
                />
              )}
              <TableCollectionSchedule
                data={dataSelect && dataSelect.collectionSchedule ? dataSelect.collectionSchedule : []}
                onHandleChangeTable={this.onChangeRowValue}
                onRemove={this.handleClickRemoveRecord}
              />
              <div className="col-md-12 mb-3 mt-3 pr-0">
                {modeModifyCollectionSheduleConfig === 2 && (
                  <button type="submit" className="button button-border x-small float-right">
                    {t(dataSelect && dataSelect.isNew ? 'label.create' : 'label.modify')}
                  </button>
                )}
                <button
                  type="button"
                  onClick={this.onToggleModal}
                  className="button button-border black x-small float-right"
                >
                  {t('label.cancel')}
                </button>
              </div>
            </div>
          </form>
        </ModalWithItem>
        <ModalSearchCollectionProfile
          isOpen={isOpenModalCollectionProfile}
          onSelect={this.onSelectCollectionProfile}
          onCancel={this.onToggleModalCollectionProfile}
          selectedParentId={collectionProfileSelected || null}
          selectFieldName="collectionProfile"
        />
        <ModalWithItem
          isOpen={isOpenAuditModal}
          onToggle={this.onToggleModalAudit}
          wrapperClass="modal-custom modal-70 bd-example-modal-lg modal-selector"
          modalTitle={t('label.auditHistory')}
        >
          <div className="col-md-12">
            <DataTable
              columns={[
                ...auditHistoryColumns,
                {
                  name: 'view',
                  label: 'common:label.view',
                  render: (colName, item) => (
                    <button
                      type="button"
                      className="btn btn-outline-success btn-sm"
                      onClick={() => this.onToggleModalDetails({ item })}
                    >
                      {t('label.view')}
                    </button>
                  ),
                },
              ]}
              data={dataAuditHistory && dataAuditHistory.length ? dataAuditHistory : []}
            />
          </div>
          <div className="col-md-12">
            <button
              onClick={this.onToggleModalAudit}
              type="button"
              className="button button-border black x-small mr-2 mb-2 float-right"
            >
              {t('label.cancel')}
            </button>
          </div>
        </ModalWithItem>
        <ModalWithItem
          isOpen={isOpenAuditScheduleDetails}
          onToggle={this.onToggleModalDetails}
          wrapperClass="modal-custom modal-70 bd-example-modal-lg modal-selector"
          modalTitle={t('label.auditHistoryDetails')}
        >
          <div className="col-md-12 row mb-3">
            <GenericInput
              name="id"
              label="label.id"
              value={itemDetails ? itemDetails.id : null}
              wrapperClass="col-md-4"
              onChange={this.onChange}
              readOnly
              disabled
            />
            <GenericInput
              name="collectionProfile"
              label="label.collectionProfile"
              value={itemDetails ? itemDetails.collectionProfile : null}
              wrapperClass="col-md-4"
              onClick={() =>
                this.onToggleModalCollectionProfile({
                  itemSelected: itemDetails ? itemDetails.collectionProfile || null : null,
                })
              }
              readOnly
              disabled
            />
          </div>
          <div className="col-md-12">
            <DataTable
              columns={tableColumnsDetails}
              data={itemDetails && itemDetails.collectionSchedule ? itemDetails.collectionSchedule : []}
            />
          </div>
          <div className="col-md-12">
            <button
              onClick={this.onToggleModalDetails}
              type="button"
              className="button button-border black x-small mr-2 mb-2 float-right"
            >
              {t('label.cancel')}
            </button>
          </div>
        </ModalWithItem>
      </div>
    );
  }
}

CollectionSchedule.propTypes = {
  getCollectionScheduleByType: PropTypes.func.isRequired,
  createCollectionSchedule: PropTypes.func.isRequired,
  modifyCollectionSchedule: PropTypes.func.isRequired,
  permissionsCollections: PropTypes.objectOf(PropTypes.any),
};

CollectionSchedule.defaultProps = {
  permissionsCollections: {},
};

const mapStateToProps = createStructuredSelector({
  permissionsCollections: makeGetPermissionsCollectionManagement() || {},
  listOptionActions: makeGetListCollectionActions() || [],
});

export default withTranslation('common')(
  connect(mapStateToProps, {
    getCollectionScheduleByType,
    createCollectionSchedule,
    modifyCollectionSchedule,
    searchCollectionSchedules,
    getCollectionScheduleAudit,
    convertJsonToConfigCollectionSchedule,
  })(CollectionSchedule)
);
