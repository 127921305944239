import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { cloneDeep } from 'lodash';
import { withRouter } from 'react-router-dom';
import SideBar from '../../components/common/SideBar';
import RouteNames from '../App/RouteNames';
import { checkPermissionGetJobSchedule, checkPermissionFindJobConfig } from './CheckPermission';
import { isHasPermissionWithMenu } from '../../utils/utils';
import { permissionList } from '../../utils/constants';

class Sidebar extends Component {
  state = {};

  render() {
    const {
      isExpand = true,
      location: { pathname },
      permissionsJob,
      dailyList,
      scheduleList,
    } = this.props;
    if (!isHasPermissionWithMenu({ permissions: permissionsJob, listKeys: permissionList.jobsManagement })) return null;

    const scheduleRoutes = [];
    let scheduleItem = [];

    if (dailyList && dailyList.length) {
      dailyList.forEach(item => {
        scheduleRoutes.push({
          icon: 'ti-flag-alt',
          label: item.name,
          href: { pathname: RouteNames.prettifyPath(RouteNames.jobsManagmentJobSchedule.path, item.id) },
          isNoTranslation: true,
        });
      });
    }
    if (scheduleList && scheduleList.length) {
      scheduleList.forEach(item => {
        scheduleRoutes.push({
          icon: 'ti-flag-alt',
          label: item.name,
          href: { pathname: RouteNames.prettifyPath(RouteNames.jobsManagmentJobSchedule.path, item.id) },
          isNoTranslation: true,
        });
      });
    }

    if (scheduleRoutes && scheduleRoutes.length) {
      scheduleItem = [
        {
          isHaveExpand: true,
          mainTilte: 'navbar:operationsHub.subMain.jobsManagement',
          childs: [...scheduleRoutes],        },
      ];
    }

    const sideProps = {
      isExpand,
      items: [
        {
          isHaveExpand: false,
          mainTilte: 'navbar:operationsHub.main',
          href: RouteNames.jobsManagmentJobConfig.path,
          childs: [],
        },
        {
          isHaveExpand: true,
          mainTilte: 'jobManagementPage:sidebar.config',
          // isExpandChild: true,
          childs: [
            {
              icon: 'ti-settings',
              label: 'jobManagementPage:sidebar.jobScheduleConfig',
              href: RouteNames.jobsManagmentJobConfig.path,
            },
          ],
        },
        ...scheduleItem,
      ],
    };

    const newSideProps = cloneDeep(sideProps);
    let modeGetJobChedule = 0;
    let modeFindJobConfig = 0;
    const indexJobSchedule = newSideProps.items.findIndex(
      child => child.mainTilte === 'navbar:operationsHub.subMain.jobsManagement'
    );
    if (permissionsJob && permissionsJob.jobsModulePermissions) {
      const listPermission = permissionsJob.jobsModulePermissions;
      modeGetJobChedule = checkPermissionGetJobSchedule({ listPermission });
      modeFindJobConfig = checkPermissionFindJobConfig({ listPermission });
    }

    if (modeGetJobChedule === 0 && indexJobSchedule > -1) {
      newSideProps.items[indexJobSchedule].childs = newSideProps.items[indexJobSchedule].childs.filter(
        item => item.label !== 'jobManagementPage:sidebar.jobScheduleConfig'
      );
    }

    if (modeFindJobConfig === 0 && indexJobSchedule > -1) {
      newSideProps.items[indexJobSchedule].childs = newSideProps.items[indexJobSchedule].childs.filter(
        item => item.label !== 'jobManagementPage:sidebar.jobSchedule'
      );
    }

    return <SideBar {...newSideProps} defaultMaximize={scheduleRoutes && scheduleRoutes.length ? 2 : null} />;
  }
}

// export default Sidebar;
const mapStateToProps = store => {
  const layoutReducer = store.get('layout');
  const jobManagementReducer = store.get('jobManagementReducer');
  const { sidebarExpanded } = layoutReducer ? layoutReducer.toJS() : { sidebarExpanded: true };
  const permissionsJob = store.get('auth').toJS().permissionsApp.opsHubModules;
  return {
    isExpand: sidebarExpanded,
    permissionsJob: permissionsJob.length ? permissionsJob.filter(a => a.moduleName === 'JOBS_MANAGEMENT')[0] : {},
    dailyList: jobManagementReducer ? jobManagementReducer.toJS().dailyList : [],
    scheduleList: jobManagementReducer ? jobManagementReducer.toJS().scheduleList : [],
  };
};

Sidebar.propTypes = {
  isExpand: PropTypes.bool,
  permissionsJob: PropTypes.objectOf(PropTypes.any),
};

Sidebar.defaultProps = {
  isExpand: false,
  permissionsJob: {},
};

const mapDispatchToProps = dispatch => ({
  dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Sidebar));
