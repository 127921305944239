import React from 'react';
import PropTypes from 'prop-types';
import { cloneDeep } from 'lodash';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import TitleFrom from '../../../../components/common/TitleFrom';
import { GenericInput } from '../../../../components/common';
import { getTenantMerchantDataConfig } from '../../actions';
import { makeTenantMerchantDataConfig } from '../../selectors';

class GatewayAttributesForm extends React.PureComponent {
  state = { tenantData: [] };

  componentDidMount() {
    const { data } = this.props;
    this.doGetTenantMerchantDataConfig();
    this.setState({ tenantData: data?.tenantMerchantAccounts || [], idState: data?.id || null });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.data) {
      this.setState({
        tenantData: nextProps?.data?.tenantMerchantAccounts || [],
        idState: nextProps?.data?.id || null,
      });
    }
  }

  doGetTenantMerchantDataConfig = () => {
    const { getTenantMerchantDataConfig, getTenantConfig, gatewayType, merchantName } = this.props;
    if (getTenantConfig) {
      getTenantConfig({ gatewayName: gatewayType || null, merchantName: merchantName || null });
    } else getTenantMerchantDataConfig({ gatewayName: gatewayType || null, merchantName: merchantName || null });
  };

  onChange = ({ value, name, indexAttr, indexData }) => {
    try {
      const { tenantData } = this.state;
      const { gatewayKey } = this.props;
      const newData = cloneDeep(tenantData);
      newData[indexData][gatewayKey][indexAttr][name] = value;
      this.setState({ tenantData: newData });
    } catch (error) {
      console.log(error);
    }
  };

  onAddNewMapping = ({ indexData }) => {
    try {
      const { tenantData } = this.state;
      const { gatewayKey, isProvisioningGateway } = this.props;
      const newData = cloneDeep(tenantData);
      let lastIndex = 0;
      if (!newData[indexData] || !newData[indexData][gatewayKey]) newData[indexData][gatewayKey] = [];
      newData[indexData][gatewayKey].forEach(elm => {
        if (lastIndex < elm.index) lastIndex = elm.index;
      });
      newData[indexData][gatewayKey].push({
        url: null,
        type: '',
        index: lastIndex + 1,
        apiProtocol: isProvisioningGateway ? 'REST' : null,
      });
      this.setState({ tenantData: newData });
    } catch (error) {
      console.log(error);
    }
  };

  onSubmit = evt => {
    evt.preventDefault();
    const { modifyTenant, id, gatewayType, isProvisioningGateway } = this.props;
    const { tenantData, idState } = this.state;
    const dataSelect = tenantData.filter(val => val.type === gatewayType);
    if (isProvisioningGateway && dataSelect && dataSelect.length) {
      const newData = dataSelect.map(val => {
        const { provisionGatewayAttributes, ...rest } = val;
        let newProvisionGatewayAttributes = null;
        if (provisionGatewayAttributes && provisionGatewayAttributes.length) {
          newProvisionGatewayAttributes = provisionGatewayAttributes.map(item => {
            const { skipAuthorization, ...rest } = item;
            return { ...rest, skipAuthorization: skipAuthorization || 'false' };
          });
        }
        return { ...rest, provisionGatewayAttributes: newProvisionGatewayAttributes };
      });
      modifyTenant({ id: id || idState, tenantMerchantAccounts: newData }, () => this.doGetTenantMerchantDataConfig());
    } else {
      modifyTenant({ id: id || idState, tenantMerchantAccounts: dataSelect }, () =>
        this.doGetTenantMerchantDataConfig()
      );
    }
  };

  render() {
    const { tenantData } = this.state;
    const {
      modeModifyTaxGatewayMerchant,
      isRequireField,
      t,
      getTenantConfig,
      gatewayType,
      gatewayKey,
      tOptionsConfig,
      titleForm,
      modeModify,
      isProvisioningGateway,
    } = this.props;
    const data = tenantData.find(val => val.type === gatewayType);
    const indexData = tenantData.findIndex(val => val.type === gatewayType);
    return (
      <form className="col-md-12 card card-statistics" onSubmit={this.onSubmit}>
        <div className="d-block d-md-flex">
          <div className="d-block d-md-flex clearfix sm-mt-20 ">
            <TitleFrom title={titleForm || t('label.taxGatewayURLMapping')} />
          </div>
        </div>
        {data &&
          data[gatewayKey] &&
          data[gatewayKey].map((item, index) => (
            <div className="group-flex pl-2" key={index}>
              <GenericInput
                label="label.endPointType"
                type="select"
                value={item.type || null}
                // disabled={modeModifyTaxGatewayMerchant === 1}
                wrapperClass="col-md-2"
                required={isRequireField}
                tOptions={tOptionsConfig || 'selections:taxGetWayType'}
                onChange={({ name, value }) => this.onChange({ value, name, indexAttr: index, indexData })}
                name="type"
              />
              <GenericInput
                label="label.endPointURL"
                value={item.url}
                required={isRequireField}
                type="text"
                // disabled={modeModifyTaxGatewayMerchant === 1}
                onChange={({ name, value }) => this.onChange({ value, name, indexAttr: index, indexData })}
                name="url"
                wrapperClass="col-md-2"
              />
              <GenericInput
                label="label.apiType"
                type="select"
                value={item.apiType || null}
                wrapperClass="col-md-2"
                required={isRequireField}
                tOptions="selections:apiType"
                onChange={({ name, value }) => this.onChange({ value, name, indexAttr: index, indexData })}
                name="apiType"
              />
              <GenericInput
                label="label.apiProtocol"
                type="select"
                value={item.apiProtocol || null}
                // disabled={modeModifyTaxGatewayMerchant === 1}
                wrapperClass="col-md-2"
                required={isRequireField}
                tOptions="selections:apiProtocol"
                onChange={({ name, value }) => this.onChange({ value, name, indexAttr: index, indexData })}
                name="apiProtocol"
              />
              <GenericInput
                label="label.payloadMapOnly"
                value={item.payloadMapOnly || null}
                wrapperClass="mt-4 pt-2"
                required={isRequireField}
                onChange={({ name, value }) => this.onChange({ value, name, indexAttr: index, indexData })}
                name="payloadMapOnly"
                type="checkbox"
              />
              {isProvisioningGateway && (
                <GenericInput
                  label="label.skipAuthorization"
                  value={item.skipAuthorization || false}
                  wrapperClass="mt-4 pt-2"
                  required={isRequireField}
                  onChange={({ name, value }) => this.onChange({ value, name, indexAttr: index, indexData })}
                  name="skipAuthorization"
                  type="checkbox"
                  tooltipDetails="common:message.skipAuthorizationInfo"
                />
              )}
            </div>
          ))}
        {modeModify === 2 && (
          <div className="col-md-12 text-center mt-3">
            <button
              type="button"
              className="button button-border gray x-small"
              onClick={() => this.onAddNewMapping({ indexData })}
            >
              {t('label.addNewMapping')}
            </button>
          </div>
        )}
        <div className="mt-3 mb-3 col-md-12">
          {modeModify === 2 && (
            <button type="submit" className="button button-border ml-2 x-small float-right">
              {t('label.saveConfig')}
            </button>
          )}
          <button
            type="button"
            onClick={() => this.doGetTenantMerchantDataConfig()}
            className="button button-border black x-small float-right"
          >
            {t('label.cancel')}
          </button>
        </div>
      </form>
    );
  }
}

GatewayAttributesForm.propTypes = {
  data: PropTypes.arrayOf(PropTypes.any),
  onHandleChange: PropTypes.func,
  index: PropTypes.number.isRequired,
  modeModifyTaxGatewayMerchant: PropTypes.number,
  isRequireField: PropTypes.bool,
  isProvisioningGateway: PropTypes.bool,
};

GatewayAttributesForm.defaultProps = {
  data: [],
  onHandleChange: () => {},
  modeModifyTaxGatewayMerchant: 2,
  isRequireField: false,
  isProvisioningGateway: false,
};

const mapStateToProps = createStructuredSelector({
  data: makeTenantMerchantDataConfig() || {},
  // paymentMethodUrls: makeGetPaymentMethodUrls() || {},
});

export default withTranslation('common')(
  connect(mapStateToProps, { getTenantMerchantDataConfig })(GatewayAttributesForm)
);
