import gql from 'graphql-tag';
import { convertFilterRequestUrl } from '../../utils/utils';

export default ({ page, size, filter, sort }) => {
  const sortStr = sort ? `, sort: ${sort}` : '';
  const filterStr = filter ? `, filter: ${convertFilterRequestUrl(filter, ['switchName'])}` : '';
  const searchMediationFilesStats = `searchMediationFilesStats(page: ${page}, size: ${size}${sortStr}${filterStr})`;
  return gql`
    query {
      ${searchMediationFilesStats} {
        fileId
        fileName
        sourcePlatform
        startDate
        totalTime
        TotalInputRecords
        TotalImportedRecords
        TotalErrorRecords
        status
        statusMessage
      }
    }
  `;
};
