import { call, put, takeLatest, takeEvery } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import {
  searchAccountsCollection,
  searchCollectionHistory,
  getCollectionActionsByType,
  getCollectionScheduleByType,
  getCollectionAgentByType,
  createCollectionAction,
  modifyCollectionAction,
  createCollectionAgent,
  modifyCollectionAgent,
  createCollectionSchedule,
  modifyCollectionSchedule,
  searchCollectionAgentActivity,
  reassignCollectionAgent,
  getCollectionUnitsById,
  searchInvoiceUnitsInCollection,
  createCollectionUnit,
  modifyCollectionUnit,
  searchCollectionSchedules,
  searchCollectionProfiles,
  createCollectionProfiles,
  modifyCollectionProfiles,
  getCollectionScheduleAudit,
  convertJsonToConfigCollectionSchedule,
} from '../../api';
import { queryRequest, mutationRequest } from '../../utils/request';
import { collectionsEnum } from '../../constantsApp';
import { isConnecting, isEndConnected } from '../Loader/actions';
import * as types from './types';
import * as actions from './actions';
import { getTableFromResponeCollections } from '../../utils/utils';
import i18n from '../../i18n';

// ------ search account collection
export function* searchAccountsInCollectionSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const { page, size, filter, sort } = payload;
    const response = yield call(queryRequest, searchAccountsCollection({ page, size, filter, sort }));
    if (response.searchAccountsInCollection) {
      yield put(actions.searchAccountsCollectionSuccess(response.searchAccountsInCollection));
    }
    yield put(isEndConnected());
    if (cb) cb();
  } catch (err) {
    yield put(isEndConnected());
    if (cb) cb();
  }
}

// ------ search collection history
export function* searchListHistorySaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const { page, size, filter, sort } = payload;
    const response = yield call(queryRequest, searchCollectionHistory({ page, size, filter, sort }));
    if (response.searchCollectionHistory) {
      yield put(actions.searchCollectionHistorySuccess(response.searchCollectionHistory));
    }
    yield put(isEndConnected());
    if (cb) cb();
  } catch (err) {
    yield put(isEndConnected());
    if (cb) cb();
  }
}

export function* getCollectionActionsByTypeSaga({ payload, cb }) {
  yield put(isConnecting());

  try {
    const response = yield call(queryRequest, getCollectionActionsByType(payload));
    if (response && response.getCollectionActionsByType) {
      yield put(
        actions.getCollectionActionsByTypeSuccess(
          getTableFromResponeCollections(response.getCollectionActionsByType, 'actionList')
        )
      );
    }
    if (cb) yield cb(response);
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    if (cb) cb([]);
  }
}

export function* getCollectionScheduleByTypeSaga({ payload, cb }) {
  yield put(isConnecting());

  try {
    const response = yield call(queryRequest, getCollectionScheduleByType(payload));
    yield cb(response);
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    if (cb) cb();
  }
}

export function* getCollectionAgentByTypeSaga({ payload, cb }) {
  yield put(isConnecting());

  try {
    const response = yield call(queryRequest, getCollectionAgentByType(payload));
    yield cb(response);
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    if (cb) cb();
  }
}

//
export function* createCollectionActionSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const dataProcess = payload;
    const response = yield call(mutationRequest, createCollectionAction(dataProcess));
    if (response) yield put(actions.getCollectionActionsByType({ type: collectionsEnum.type.collectionAction }));
    if (cb) cb(response);
    yield put(isEndConnected());
    toast.success(i18n.t('common:apiMessage.createCollectionActionSuccessfully'));
  } catch (err) {
    yield put(isEndConnected());
  }
}

export function* modifyCollectionActionSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const dataProcess = payload;
    const response = yield call(mutationRequest, modifyCollectionAction(dataProcess));
    if (response) yield put(actions.getCollectionActionsByType({ type: collectionsEnum.type.collectionAction }));
    if (cb) cb(response);
    yield put(isEndConnected());
    toast.success(i18n.t('common:apiMessage.modifyCollectionActionSuccessfully'));
  } catch (err) {
    yield put(isEndConnected());
  }
}

export function* createCollectionAgentSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const dataProcess = payload;
    const response = yield call(mutationRequest, createCollectionAgent(dataProcess));
    if (cb) cb(response);
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
  }
}

export function* modifyCollectionAgentSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const dataProcess = payload;
    const response = yield call(mutationRequest, modifyCollectionAgent(dataProcess));

    if (cb) cb(response || {});
    yield put(isEndConnected());
    toast.success(i18n.t('common:apiMessage.modifyCollectionAgentSuccessfully'));
  } catch (err) {
    yield put(isEndConnected());
  }
}

//
export function* createCollectionScheduleSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const dataProcess = payload;
    const response = yield call(mutationRequest, createCollectionSchedule(dataProcess));
    if (cb) cb(response);
    yield put(isEndConnected());
    toast.success(i18n.t('common:apiMessage.createCollectionScheduleSuccessfully'));
  } catch (err) {
    yield put(isEndConnected());
  }
}

export function* modifyCollectionScheduleSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const dataProcess = payload;
    const response = yield call(mutationRequest, modifyCollectionSchedule(dataProcess));

    if (cb) cb(response || {});
    yield put(isEndConnected());
    toast.success(i18n.t('common:apiMessage.modifyCollectionScheduleSuccessfully'));
  } catch (err) {
    yield put(isEndConnected());
  }
}

// ------ search collection agent activity
export function* searchCollectionAgentActivitySaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const { page, size } = payload;
    const response = yield call(queryRequest, searchCollectionAgentActivity({ page, size }));
    if (response.searchCollectionAgentActivity) {
      yield put(actions.searchCollectionAgentActivitySuccess(response.searchCollectionAgentActivity));
    } else {
      yield put(
        actions.searchCollectionAgentActivityFailure(
          'Failed to fetch collection agent activity. Please try again or check your network!'
        )
      );
    }
    yield put(isEndConnected());
    if (cb) cb();
  } catch (err) {
    yield put(isEndConnected());
    yield put(
      actions.searchCollectionAgentActivityFailure(
        'Failed to fetch acccollection agent activityounts. Please try again or check your network!'
      )
    );
    if (cb) cb();
  }
}

export function* reassignCollectionAgentSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const dataProcess = payload;
    yield call(mutationRequest, reassignCollectionAgent(dataProcess));
    if (cb) cb({ success: true });
    yield put(isEndConnected());
    toast.success(i18n.t('common:apiMessage.reassignCollectionAgentSuccessfully'));
  } catch (err) {
    if (cb) cb({ success: false });
    yield put(isEndConnected());
  }
}

// getCollectionUnitsById
export function* getCollectionUnitsSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getCollectionUnitsById(payload));

    if (response.getCollectionUnitsById) {
      yield put(actions.getCollectionUnitsByIdSuccess(response.getCollectionUnitsById));
      if (cb) cb({ success: true, data: response.getCollectionUnitsById });
    } else {
      yield put(actions.getCollectionUnitsByIdFailed(`Can not get collection history detail for ${payload}`));
      if (cb) cb({ success: false, data: null });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    if (cb) cb({ success: false, data: null });
  }
}

// ------ search invoice units in collection
export function* searchInvoiceInCollectionSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const { page, size, filter, sort } = payload;
    const response = yield call(queryRequest, searchInvoiceUnitsInCollection({ page, size, filter, sort }));
    if (response.searchInvoiceUnitsInCollection) {
      yield put(actions.searchInvoiceInCollectionSuccess(response.searchInvoiceUnitsInCollection));
    } else {
      yield put(
        actions.searchInvoiceInCollectionFailed(
          'Failed to fetch invoice units. Please try again or check your network!'
        )
      );
    }
    yield put(isEndConnected());
    if (cb) cb();
  } catch (err) {
    yield put(isEndConnected());
    if (cb) cb();
  }
}

export function* createCollectionUnitSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const dataProcess = payload;
    const response = yield call(mutationRequest, createCollectionUnit(dataProcess));
    if (response.createCollectionUnit) {
      if (cb) cb({ success: true });
    }
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false });
    yield put(isEndConnected());
  }
}

export function* modifyCollectionUnitSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const dataProcess = payload;
    yield call(mutationRequest, modifyCollectionUnit(dataProcess));
    if (cb) cb({ success: true });
    yield put(isEndConnected());
    toast.success(i18n.t('common:apiMessage.modifyCollectionUnitSuccessfully'));
  } catch (err) {
    cb({ success: false });
    yield put(isEndConnected());
  }
}

// export function* searchSchedulePatternSaga({ payload, cb }) {
//   yield put(isConnecting());
//   try {
//     const { page, size, filter, sort } = payload;
//     const response = yield call(queryRequest, searchSchedulePattern({ page, size, filter, sort }));
//     if (response.searchSchedulePattern) {
//       if (cb) cb({ success: true, data: response.searchSchedulePattern });
//     } else if (cb) cb({ success: false, data: null });
//     yield put(isEndConnected());
//   } catch (err) {
//     yield put(isEndConnected());
//     if (cb) cb({ success: false, data: null });
//   }
// }

// export function* getSchedulePatternByIdSaga({ payload, cb }) {
//   yield put(isConnecting());
//   try {
//     const response = yield call(queryRequest, getSchedulePatternById(payload));
//     if (response.getSchedulePatternById) {
//       if (cb) cb({ success: true, data: response.getSchedulePatternById });
//     } else if (cb) cb({ success: false, data: null });
//     yield put(isEndConnected());
//   } catch (err) {
//     yield put(isEndConnected());
//     if (cb) cb({ success: false, data: null });
//   }
// }

// export function* createSchedulePatternSaga({ payload, cb }) {
//   yield put(isConnecting());
//   try {
//     const response = yield call(mutationRequest, createSchedulePattern(payload));
//     if (response.createSchedulePattern) {
//       toast.success(i18n.t('common:apiMessage.createSchedulePatternSuccessfully'));
//       if (cb) yield cb({ success: true, data: response.createSchedulePattern });
//     } else if (cb) yield cb({ success: false });
//     yield put(isEndConnected());
//   } catch (err) {
//     yield put(isEndConnected());
//     if (cb) yield cb({ success: false });
//   }
// }

// export function* modifySchedulePatternSaga({ payload, cb }) {
//   yield put(isConnecting());
//   try {
//     const response = yield call(mutationRequest, modifySchedulePattern(payload));
//     if (response.modifySchedulePattern) {
//       toast.success(i18n.t('common:apiMessage.modifySchedulePatternSuccessfully'));
//       if (cb) yield cb({ success: true, data: response.modifySchedulePattern });
//     } else if (cb) yield cb({ success: false });
//     yield put(isEndConnected());
//   } catch (err) {
//     yield put(isEndConnected());
//     if (cb) yield cb({ success: false });
//   }
// }

export function* searchCollectionSchedulesSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, searchCollectionSchedules(payload));
    if (response.searchCollectionSchedules) {
      if (cb) cb({ success: true, data: response.searchCollectionSchedules });
    } else if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  }
}

export function* searchCollectionProfilesSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, searchCollectionProfiles(payload));
    if (response.searchCollectionProfiles) {
      if (cb) cb({ success: true, data: response.searchCollectionProfiles });
    } else if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  }
}

export function* createCollectionProfilesSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, createCollectionProfiles(payload));
    if (response.createCollectionProfiles) {
      if (cb) cb({ success: true, data: response.createCollectionProfiles });
      toast.success(i18n.t('common:apiMessage.createCollectionProfilesSuccessfully'));
    } else if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  }
}

export function* modifyCollectionProfilesSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, modifyCollectionProfiles(payload));
    if (response.modifyCollectionProfiles) {
      if (cb) cb({ success: true, data: response.modifyCollectionProfiles });
      toast.success(i18n.t('common:apiMessage.modifyCollectionProfilesSuccessfully'));
    } else if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  }
}

export function* getCollectionScheduleAuditSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getCollectionScheduleAudit(payload));
    if (response.getCollectionScheduleAudit) {
      if (cb) cb({ success: true, data: response.getCollectionScheduleAudit });
    } else if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  }
}

export function* convertJsonToConfigCollectionScheduleSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, convertJsonToConfigCollectionSchedule(payload));
    if (response.convertJsonToConfigCollectionSchedule) {
      if (cb) cb({ success: true, data: response.convertJsonToConfigCollectionSchedule });
    } else if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export default function* collectionsSaga() {
  yield takeLatest(types.SEARCH_ACCOUNTS_COLLECTION, searchAccountsInCollectionSaga);
  yield takeLatest(types.SEARCH_COLLECTION_HISTORY, searchListHistorySaga);
  yield takeEvery(types.GET_COLLECTION_BY_TYPE, getCollectionActionsByTypeSaga);
  yield takeLatest(types.GET_COLLECTION_SCHEDULE_BY_TYPE, getCollectionScheduleByTypeSaga);
  yield takeLatest(types.GET_COLLECTION_AGENT_BY_TYPE, getCollectionAgentByTypeSaga);
  yield takeLatest(types.CREATE_COLLECTION_ACTION, createCollectionActionSaga);
  yield takeLatest(types.MODIFY_COLLECTION_ACTION, modifyCollectionActionSaga);
  yield takeLatest(types.CREATE_COLLECTION_AGENT, createCollectionAgentSaga);
  yield takeLatest(types.MODIFY_COLLECTION_AGENT, modifyCollectionAgentSaga);
  yield takeLatest(types.CREATE_COLLECTION_SCHEDULE, createCollectionScheduleSaga);
  yield takeLatest(types.MODIFY_COLLECTION_SCHEDULE, modifyCollectionScheduleSaga);
  yield takeLatest(types.SEARCH_COLLECTION_AGENT_ACTIVITY, searchCollectionAgentActivitySaga);
  yield takeLatest(types.REASSIGN_COLLECTION_AGENT, reassignCollectionAgentSaga);
  yield takeLatest(types.GET_COLLECTION_UNITS_BY_ID, getCollectionUnitsSaga);
  yield takeLatest(types.SEARCH_INVOICE_IN_COLLECTION, searchInvoiceInCollectionSaga);
  yield takeLatest(types.CREATE_COLLECTION_UNIT, createCollectionUnitSaga);
  yield takeLatest(types.MODIFY_COLLECTION_UNIT, modifyCollectionUnitSaga);
  yield takeLatest(types.SEARCH_COLLECTION_SCHEDULES, searchCollectionSchedulesSaga);
  yield takeLatest(types.CREATE_COLLECTION_PROFILE, createCollectionProfilesSaga);
  yield takeLatest(types.MODIFY_COLLECTION_PROFILE, modifyCollectionProfilesSaga);
  yield takeLatest(types.SEARCH_COLLECTION_PROFILE, searchCollectionProfilesSaga);
  yield takeLatest(types.GET_COLLECTION_SCHEDULE_AUDIT, getCollectionScheduleAuditSaga);
  yield takeLatest(types.CONVERT_JSON_TO_CONFIG_COLLECTION_SCHEDULE, convertJsonToConfigCollectionScheduleSaga);
}
