import React from 'react';
import { withTranslation, useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { cloneDeep, size } from 'lodash';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import arrayMove from 'array-move';

const Handle = SortableHandle(() => (
  <button type="button" className="sortable-handle no-border">
    <i className="fa fa-bars" />
  </button>
));
const SortableItem = SortableElement(({ job, onRemove, sortingItemKey, indexItem }) => {
  const { t } = useTranslation('common');
  const slt = t ? t('selections:jobTypes')().find(val => val.value === job.jobNames) : '';
  return (
    <tr className={`job-config-row ${sortingItemKey === job ? 'dragging' : ''}`}>
      <td>
        <Handle />
      </td>
      <td className="job-name-col">{slt ? slt.label : ''}</td>
      <td className="actions">
        <button type="button" className="no-border" onClick={e => onRemove({ index: indexItem, e, item: job })}>
          <i className="fa fa-trash" />
        </button>
      </td>
    </tr>
  );
});

const SortableList = SortableContainer(({ items, isSorting, sortingItemKey, onRemove }) => {
  return (
    <tbody>
      {items.map((job, index) => {
        if (size(job) < 2) return null;
        return (
          <SortableItem
            key={`item-${index}`}
            isSorting={isSorting}
            sortingItemKey={sortingItemKey}
            index={index}
            onRemove={onRemove}
            job={job}
            indexItem={index}
          />
        );
      })}
    </tbody>
  );
});

export class JobConfigForm extends React.PureComponent {
  constructor() {
    super();
    this.state = { configJobList: [] };
  }

  static getDerivedStateFromProps(props, state) {
    if (state.oldValue === props.jobConfig) return null;
    return {
      configJobList: props.jobConfig,
      oldValue: props.jobConfig,
    };
  }

  onChangeJobType = e => {
    this.setState({ newJobType: e });
  };

  addJobType = e => {
    const { configJobList = [], newJobType } = this.state;
    if (!newJobType) return false;
    configJobList.push({
      index: configJobList.length,
      jobNames: newJobType.value,
      isNew: true,
    });
    this.setState({ configJobList, newJobType: null }, () => this.onUpdateData());
  };

  removeJobType = ({ index, e, item }) => {
    e.preventDefault();
    const { configJobList = [] } = this.state;
    const newListJobConfig = cloneDeep(configJobList);
    if (item && item.isNew) {
      newListJobConfig.splice(index, 1);
      return this.setState({ configJobList: newListJobConfig }, () => this.onUpdateData());
    }
    newListJobConfig[index] = { index: item.index };
    return this.setState({ configJobList: newListJobConfig }, () => this.onUpdateData());
  };

  handleUpdateBeforeSortStart = ({ index }) => {
    return new Promise(resolve =>
      this.setState(
        ({ configJobList }) => ({
          sortingItemKey: configJobList[index],
          isSorting: true,
        }),
        resolve
      )
    );
  };

  onSortEnd = ({ oldIndex, newIndex }) => {
    const { configJobList = [] } = this.state;
    let newData = cloneDeep(configJobList);
    const tempOldIdx = newData[oldIndex].index;
    newData[oldIndex].index = newData[newIndex].index;
    newData[newIndex].index = tempOldIdx;
    newData = arrayMove(newData, oldIndex, newIndex);
    this.setState(
      {
        configJobList: newData,
        isSorting: false,
        sortingItemKey: null,
      },
      () => this.onUpdateData()
    );
  };

  onUpdateData = () => {
    const { configJobList } = this.state;
    const { onChangeJobList } = this.props;
    if (onChangeJobList) onChangeJobList(configJobList);
  };

  render() {
    const { configJobList } = this.state;
    const jobNames = configJobList.map(job => job.jobNames);
    const atStyle = { width: '100px' };
    const { modeCreateJobConfig, modeModifyJobConfig, t } = this.props;
    const availJobTypes = t('selections:jobTypes')().filter(jobType => jobNames.indexOf(jobType.value) < 0);
    return (
      <div className="form mt-3 mx-3 ">
        <div className="row">
          <div className="col-md-6 offset-md-3">
            <div className="table-responsive sticky-header">
              <table className="table center-aligned-table mb-0">
                <thead>
                  <tr>
                    <th />
                    <th>{t('common:label.jobType')}</th>
                    <th style={atStyle}>{t('common:label.remove')}</th>
                  </tr>
                </thead>
                {!configJobList ||
                  (configJobList.length === 0 && (
                    <tbody>
                      <tr>
                        <td colSpan="3">{t('common:label.noJobTypes')}</td>
                      </tr>
                    </tbody>
                  ))}
                <SortableList
                  items={configJobList}
                  useDragHandle
                  onSortStart={this.onSortStart}
                  onSortEnd={this.onSortEnd}
                  onRemove={this.removeJobType}
                  isSorting={this.state.isSorting}
                  sortingItemKey={this.state.sortingItemKey}
                  updateBeforeSortStart={this.handleUpdateBeforeSortStart}
                />
                <tfoot>
                  {modeCreateJobConfig === 2 && (
                    <tr>
                      <td colSpan="2" style={{ minWidth: 200 }}>
                        <Select
                          name="jobType"
                          options={availJobTypes}
                          placeholder="Job Types"
                          className="react-select"
                          classNamePrefix="custom-react-select"
                          value={this.state.newJobType}
                          onChange={this.onChangeJobType}
                          required
                          isClearable
                          menuPortalTarget={document.querySelector('body')}
                        />
                      </td>
                      <td style={atStyle}>
                        <button type="button" className="btn btn-success" onClick={this.addJobType}>
                          {t('common:label.add')}
                        </button>
                      </td>
                    </tr>
                  )}
                </tfoot>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

JobConfigForm.propTypes = {
  jobConfig: PropTypes.object,
  onChangeValueForm: PropTypes.func,
  modeCreateJobConfig: PropTypes.number,
  modeModifyJobConfig: PropTypes.number,
};

JobConfigForm.defaultProps = {
  jobConfig: {},
  onChangeValueForm: () => {},
  modeCreateJobConfig: 0,
  modeModifyJobConfig: 0,
};
export default withTranslation('common')(JobConfigForm);
