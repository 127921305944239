import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty, cloneDeep } from 'lodash';
import { createStructuredSelector } from 'reselect';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import { DataTable, TablePagination, TitleFrom, CheckGroup, GenericInput } from '../../../components/common';
import PageTitle from '../../../components/PageTitle';
import RouteNames from '../../App/RouteNames';
import { searchReRatingBatches, initiateReratingProcess } from '../actions';
import { getPageTotalCount } from '../../../utils/utils';
import { ReratingSearchForm } from '../../../components/UsageHub';
import { sortRerating } from '../constants';
import { makeGetReratingBatchesList } from '../selectors';
import { getCcpDateTimeConfig } from '../../App/actions';
import { makeGetPermissionsRating } from '../../App/selectors';

class Rerating extends React.PureComponent {
  state = {
    data: [],
    page: 0,
    size: 20,
    filter: {},
    sort: '',
    sorted: {},
    totalCount: null,
    isSearching: false,
    ccpTime: null,
    isCallConfig: false,
    idSelected: [],
  };

  static getDerivedStateFromProps(props, state) {
    return {
      data: props.reratingBatchesList,
      totalCount: getPageTotalCount({ ...state, items: props.reratingBatchesList }),
    };
  }

  componentDidMount() {
    const {
      getCcpDateTimeConfig,
      permissions: { modeGetReratingBatch },
    } = this.props;
    if (modeGetReratingBatch) {
      getCcpDateTimeConfig('', ({ success, data }) => {
        if (success && data.ccpTime) {
          const { filter } = this.state;
          filter.startDate = moment(data.ccpTime, 'YYYY-MM-DD')
            .startOf('month')
            .format('YYYY-MM-DD');
          filter.endDate = moment(data.ccpTime, 'YYYY-MM-DD')
            .endOf('month')
            .format('YYYY-MM-DD');

          this.setState(
            {
              ccpTime: data.ccpTime,
              filter,
            },
            () => {
              this.doSearch();
            }
          );
        } else {
          this.doSearch();
        }
        this.setState({ isCallConfig: true });
      });
    }
  }

  doSearch = () => {
    const { location } = this.props;
    if (location && location.state && location.state.filter) {
      this.setState({ filter: location.state.filter }, () => {
        this.dosearchReRatingBatches();
      });
    } else {
      this.dosearchReRatingBatches();
    }
  };

  dosearchReRatingBatches = () => {
    const { searchReRatingBatches } = this.props;
    const { filter, page, size, sorted } = this.state;
    if (!filter.status) delete filter.status;
    const payload = {
      page: page + 1,
      size,
      filter,
      sort: !isEmpty(sorted) ? sortRerating[sorted.sortCol][sorted.sortDir] : null,
    };
    this.setState({ isSearching: true });
    searchReRatingBatches(payload, () => {
      this.setState({ isSearching: false });
    });
  };

  onSortColumn = (sortCol, sortDir) => {
    this.setState({ sorted: { sortCol, sortDir } }, () => this.dosearchReRatingBatches());
  };

  onHandleSubmit = filter => {
    this.setState({ filter, page: 0 }, () => this.dosearchReRatingBatches());
  };

  onPageChange = pageChange => {
    const { page } = this.state;
    if (page === pageChange) return null;
    return this.setState({ page: pageChange }, () => this.dosearchReRatingBatches());
  };

  onSizeChange = size => {
    this.setState({ size, page: 0 }, () => this.dosearchReRatingBatches());
  };

  onChangeCheckBox = ({ item }) => {
    let { idSelected } = this.state;
    if (idSelected && idSelected.indexOf(item.id) !== -1) {
      idSelected = idSelected.filter(val => val !== item.id);
    } else {
      idSelected = [...idSelected, item.id];
    }
    this.setState({ idSelected: [...idSelected] });
  };

  onRerate = () => {
    const { idSelected } = this.state;
    const { initiateReratingProcess } = this.props;
    const payload = {
      embrixData: idSelected.map(val => ({
        id: val,
      })),
    };
    initiateReratingProcess(payload, success => {
      if (success) {
        this.dosearchReRatingBatches();
        this.setState({ idSelected: [] });
      }
    });
  };

  onSelectAllItem = ({ value }) => {
    try {
      const { data } = this.state;
      const newData = cloneDeep(data);
      if (value) {
        const idSelected = newData
          .filter(val => val.status === 'PENDING')
          .map(val => {
            return val.id;
          });
        this.setState({ idSelected: [...idSelected] });
      } else {
        this.setState({ idSelected: [] });
      }
    } catch (error) {
      console.log(error);
    }
  };

  render() {
    const { t, permissions, location } = this.props;
    const { modeGetReratingBatch, modeCreateReratingBatch, modeModifyReratingBatch } = permissions;
    if (!modeGetReratingBatch) return '';
    const { data, totalCount, sorted, isSearching, page, size, filter, isCallConfig, idSelected } = this.state;

    let isSelectAll = false;

    if (data && data.length && idSelected && idSelected.length) {
      isSelectAll = data.filter(val => val.status === 'PENDING').length === idSelected.length;
    }

    const tableColumns = [
      {
        name: 'select',
        label: 'label.select',
        render: (colName, item) => {
          return (
            <CheckGroup
              onChange={() => this.onChangeCheckBox({ item })}
              value={idSelected && idSelected.indexOf(item.id) !== -1}
              name="idSelected"
              checked={idSelected && idSelected.indexOf(item.id) !== -1}
              disabled={item.status !== 'PENDING'}
            />
          );
        },
      },
      {
        name: 'view',
        label: t('label.view'),
        render: (colName, item) =>
          modeModifyReratingBatch ? (
            <Link
              className="btn btn-outline-success btn-sm"
              to={{
                pathname: RouteNames.prettifyPath(RouteNames.reratingModify.path, item.id),
                state: { ...item, filter },
              }}
            >
              {t('common:label.view')}
            </Link>
          ) : (
            ''
          ),
      },
      {
        name: 'id',
        label: 'label.batchId',
        sortable: true,
        render: (colName, item) =>
          modeModifyReratingBatch ? (
            <Link
              className="text-success"
              to={{
                pathname: RouteNames.prettifyPath(RouteNames.reratingModify.path, item.id),
                state: { ...item, filter },
              }}
            >
              {item.id}
            </Link>
          ) : (
            <span>{item.id}</span>
          ),
      },
      {
        name: 'batchDate',
        label: 'label.date',
        sortable: true,
      },
      {
        name: 'status',
        label: 'label.status',
        sortable: true,
        isRenderT: true,
        render: (colName, item, t) => {
          const slt = t ? t('selections:reratingBatchStatus')().find(val => val.value === item.status) : '';
          return <span>{slt ? slt.label : ''}</span>;
        },
      },
      {
        name: 'userId',
        label: 'label.userId',
      },
      {
        name: 'notes',
        label: 'label.notes',
        style: { minWidth: '200px' },
        render: (colName, item) => (
          <GenericInput
            value={item.notes}
            name="comment"
            onChange={() => {}}
            wrapperClass="col-md-12"
            type="textarea"
            readOnly
          />
        ),
      },
    ];

    return (
      <div>
        <PageTitle
          linkTo={
            modeCreateReratingBatch === 2
              ? { pathname: RouteNames.reratingCreate.path, state: { filter } }
              : RouteNames.invoiceUsage.path
          }
          titleBtn={modeCreateReratingBatch === 2 ? t('label.createNewBatch') : t('label.back')}
          titleRight={t('label.reratingBatches')}
        />
        <br />
        <div className="col-md-12 mb-30">
          <div className="card card-statistics h-100">
            <div className="card-body">
              <TitleFrom title={t('label.reratingBatches')} wrapperClass="pl-0 pt-0" />
              <div className="repeater-file">
                <div>
                  {isCallConfig && (
                    <ReratingSearchForm
                      onSubmit={this.onHandleSubmit}
                      defaultFilter={
                        location && location.state && location.state.filter
                          ? location.state.filter
                          : {
                              startDate: filter.startDate || null,
                              endDate: filter.endDate || null,
                            }
                      }
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-sm-12 mb-30">
          <div className="card card-statistics h-100">
            <div className="pt-3 pb-3 pr-3">
              <button
                disabled={!idSelected || !idSelected.length}
                onClick={this.onRerate}
                type="button"
                className="button x-small mt-2 ml-3 float-right"
              >
                {t('label.rerate')}
              </button>
              <GenericInput
                value={isSelectAll}
                name="isSelectAll"
                type="checkbox"
                wrapperClass="col-md-2 mr-2 mt-3"
                onChange={({ value }) => this.onSelectAllItem({ value })}
                label={t('label.selectAll')}
                disabled={!data || !data.length || !data.filter(val => val.status === 'PENDING').length}
              />
              <br />
            </div>
            <div className="card-body">
              <DataTable
                columns={tableColumns}
                sorted={sorted}
                data={data}
                onSort={this.onSortColumn}
                isLoading={isSearching}
              />
            </div>
          </div>
        </div>

        <div className="mb-30">
          <TablePagination
            pageNumber={page}
            pageSize={size}
            totalCount={totalCount}
            onPageChange={this.onPageChange}
            onSizeChange={this.onSizeChange}
          />
        </div>
      </div>
    );
  }
}

Rerating.propTypes = {
  searchReRatingBatches: PropTypes.func,
};

Rerating.defaultProps = {
  searchReRatingBatches: () => {},
};

const mapStateToProps = createStructuredSelector({
  reratingBatchesList: makeGetReratingBatchesList() || [],
  permissionsRating: makeGetPermissionsRating() || {},
});

export default withTranslation('common')(
  connect(mapStateToProps, {
    searchReRatingBatches,
    getCcpDateTimeConfig,
    initiateReratingProcess,
  })(withRouter(Rerating))
);
