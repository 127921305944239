export default {
    collapsibleMenu: [
      // {
      //   title: 'Merchant Account',
      //   state: 'Merchant Account',
      // },
      {
        title: 'label.paymentMethods',
        state: 'Payment Types',
      },
      {
        title: 'paymentPage:sidebar.paymentTerms',
        state: 'Payment Terms',
      },
     
    ],
    paymentTypesColumns :[ 
      {
          name: 'method',
          label: 'Method',
      },
      {
        name: 'Description',
        label: 'Description',
      }
    ],
    paymentTermsColumns :[ 
      {
          name: 'paymentTerm',
          label: 'Payment Term',
      },
      {
        name: 'Description',
        label: 'Description',
      }, 
      {
        name: 'offset',
        label: 'Invoice Date Offset',
      },
      {
        name: 'paymentWorkingDay',
        label: 'Working Day',
      },
      {
        name: 'days',
        label: 'Days',
      },
    ]
};
