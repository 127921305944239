import gql from 'graphql-tag';
import { parseToMutationRequest } from '../../utils/utils';

export default payload => {
  let newPayload = '{dummy: ""}';
  if (payload) newPayload = parseToMutationRequest(payload, ['merchantName', 'gatewayName']);

  const getTenantConfig = `getTenantConfig(input: ${newPayload})`;
  return gql`
    query {
      ${getTenantConfig} {
        id
        tenantMerchantAccounts {
          index
          type
          provisionGatewayAttributes {
            index
            type
            url
            apiType
            apiProtocol
            payloadMapOnly
            skipAuthorization
          }
        }
      }
    }
  `;
};
