import React from 'react';
import PropTypes from 'prop-types';
import { cloneDeep } from 'lodash';
import { createStructuredSelector } from 'reselect';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { GenericInput, CollapsibleTable, TitleFrom, DataTable } from '../../../components/common';
import PageTitle from '../../../components/PageTitle';
import RouteNames from '../../App/RouteNames';
import { makeGetPermissionsOrderManagement } from '../../App/selectors';
import { getFlexAttributesMapping, modifyFlexAttributeMapping, createFlexAttributeMapping } from '../actions';
import {
  checkPermissionGetFlexAttributeConfig,
  checkPermissionModifyFlexAttributeConfig,
  checkPermissionCreateFlexAttributeConfig,
} from '../CheckPermission';
import { typeFileds } from '../constants';

class ProvisioningMapping extends React.PureComponent {
  state = {
    data: {},
    wasValidated: false,
    isModify: false,
    formValid: true,
    activeTab: -1,
    dataSubmit: {},
    indexItem: -1,
    itemGroupSelect: {},
    indexGroupSelect: -1,
  };

  formRef = React.createRef();

  componentDidMount() {
    this.doGetFlexAttributesMapping();
  }

  validate = (out = false) => {
    const { t } = this.props;
    const formValid = this.formRef && this.formRef.current.checkValidity();
    this.setState({ formValid });
    const { elements } = this.formRef.current;
    for (let i = 0; i < elements.length; i++) {
      if (!elements[i].validity.valid) {
        console.log(elements[i].name, 'invalid');
      }
    }
    if (!formValid && out) {
      toast.error(t('message.mandatory'));
    }
    return formValid;
  };

  doGetFlexAttributesMapping = () => {
    const { getFlexAttributesMapping } = this.props;
    getFlexAttributesMapping({ objectType: 'SERVICE' }, ({ data }) => {
      this.setState({
        data: data || {},
        isModify: !!data,
        dataSubmit: [],
      });
    });
  };

  onAddServiceProvisioningMapping = () => {
    try {
      const { data } = this.state;
      let lastIndex = 0;
      const newData = cloneDeep(data);
      if (!newData.types) newData.types = [];
      newData.types.forEach(val => {
        if (val.index > lastIndex) lastIndex = val.index;
      });
      const payload = {
        index: lastIndex + 1,
        provAttributeType: '',
        serviceType: '',
        status: 'ACTIVE',
        mapping: [],
      };
      newData.types = [payload, ...newData.types];
      this.setState({ data: newData });
    } catch (error) {
      console.log(error);
    }
  };

  onHandleSave = evt => {
    evt.preventDefault();
    const { data, activeTab } = this.state;

    this.setState({ wasValidated: true });
    if (activeTab !== -1 && !this.validate(true)) {
      return false;
    }
    const { createFlexAttributeMapping, modifyFlexAttributeMapping } = this.props;
    if (data.id) {
      const newData = cloneDeep(data);
      newData.types = newData.types.map(val => {
        const { ...rest } = val;
        return rest;
      });
      return modifyFlexAttributeMapping({ ...newData }, ({ success }) => {
        if (success) this.doGetFlexAttributesMapping();
      });
    }
    return createFlexAttributeMapping({ ...data, objectType: 'SERVICE' }, ({ success }) => {
      if (success) this.doGetFlexAttributesMapping();
    });
  };

  onToggleTab = (evt, item, index, indexItem) => {
    const { activeTab } = this.state;
    if (activeTab === index) {
      return this.setState({
        activeTab: -1,
        itemGroupSelect: null,
        indexGroupSelect: -1,
      });
    }
    return this.setState({
      activeTab: index,
      itemGroupSelect: item,
      indexGroupSelect: indexItem,
    });
  };

  onHandleChange = ({ name, value }) => {
    const { data, dataSubmit } = this.state;
    const newData = cloneDeep(data);
    newData[name] = value;
    dataSubmit[name] = value;
    this.setState({ data: newData, dataSubmit });
  };

  onChangeTable = ({ name, value, index }) => {
    try {
      const { data } = this.state;
      const newData = cloneDeep(data);
      newData.types[index][name] = value;
      this.setState({ data: newData });
    } catch (error) {
      console.log(error);
    }
  };

  onChangeChildTable = ({ name, value, index, indexParent }) => {
    try {
      const { data } = this.state;
      const { t } = this.props;
      const newData = cloneDeep(data);
      if (name === 'flexAttribute' && newData.types[indexParent].mapping.find(val => val.flexAttribute === value)) {
        return toast.error(t('message.messageDuplicateAttribute'));
      }
      newData.types[indexParent].mapping[index][name] = value;
      this.setState({ data: newData });
    } catch (error) {
      console.log(error);
    }
  };

  onRemoveItemTable = ({ index, indexParent }) => {
    try {
      const { data } = this.state;
      const newData = cloneDeep(data);
      newData[indexParent].tasks[index] = { index: newData[indexParent].tasks[index].index };
      this.setState({ data: newData });
    } catch (error) {
      console.log(error);
    }
  };

  onRemoveOrderType = ({ index, item }) => {
    const { modifyOms } = this.props;
    if (item && item.id) {
      modifyOms({ orderType: item.orderType }, () => {
        this.doGetOmsConfig();
      });
    } else {
      const { data } = this.state;
      const newData = cloneDeep(data);
      newData.splice(index, 1);
      this.setState({ data: newData });
    }
  };

  onHandleAddAttributeMap = ({ indexParent }) => {
    try {
      const { data } = this.state;
      let lastIndex = 0;
      const newData = cloneDeep(data);
      if (!newData.types) newData.types = [];
      if (!newData.types[indexParent] || !newData.types[indexParent].mapping) newData.types[indexParent].mapping = [];
      newData.types[indexParent].mapping.forEach(val => {
        if (val.index > lastIndex) lastIndex = val.index;
      });
      newData.types[indexParent].mapping.push({
        index: lastIndex + 1,
        flexAttribute: '',
        tag: '',
        status: 'ACTIVE',
        mandatoryFlag: '',
      });
      this.setState({ data: newData });
    } catch (error) {
      console.log(error);
    }
  };

  render() {
    const { t, permissionOrder } = this.props;
    let modeCreateFlexAttributeConfig = 0;
    let modeGetFlexAttributeConfig = 0;
    let modeModifyFlexAttributeConfig = 0;
    if (permissionOrder && permissionOrder.orderModulePermissions) {
      const listPermission = permissionOrder.orderModulePermissions;
      modeCreateFlexAttributeConfig = checkPermissionCreateFlexAttributeConfig({ listPermission });
      modeGetFlexAttributeConfig = checkPermissionGetFlexAttributeConfig({ listPermission });
      modeModifyFlexAttributeConfig = checkPermissionModifyFlexAttributeConfig({ listPermission });
    }
    if (!modeGetFlexAttributeConfig) return '';
    const { data, activeTab, wasValidated } = this.state;
    const headerTable = [
      {
        name: 'provAttributeType ',
        label: 'label.dataSetType',
        render: (colName, item, idx) => (
          <GenericInput
            value={item.provAttributeType}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onChangeTable({ name, value, index: idx })}
            name="provAttributeType"
            required
            readOnly
          />
        ),
      },
      {
        name: 'serviceType',
        label: 'label.serviceType',
        render: (colName, item, idx) => (
          <GenericInput
            value={item.serviceType}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onChangeTable({ name, value, index: idx })}
            name="serviceType"
            type="select"
            tOptions="selections:serviceType"
            menuPortalTarget
            readOnly
            disabled
          />
        ),
      },
      {
        name: 'status',
        label: 'label.status',
        render: (colName, item, idx) => (
          <GenericInput
            value={item.status}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onChangeTable({ name, value, index: idx })}
            name="status"
            type="select"
            tOptions="selections:objectStatus"
            menuPortalTarget
            readOnly
            disabled
          />
        ),
      },
      {
        name: '',
        label: '',
        style: { width: 100 },
        render: (colName, item, idx, indexParent, activeTab) => (
          <button type="button" className="btn-expand-table mr-3">
            <i className={`fa ${activeTab ? 'fa-minus' : 'fa-plus'}`} />
          </button>
        ),
      },
    ];

    const headerTableMapping = [
      {
        name: 'tag',
        label: 'label.attributeTag',
        required: true,
        render: (colName, item, idx, indexParent) => (
          <GenericInput
            value={item.tag}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onChangeChildTable({ name, value, index: idx, indexParent })}
            name="tag"
            required
          />
        ),
      },
      {
        name: 'flexAttribute',
        label: 'label.flexAttribute',
        required: true,
        render: (colName, item, idx, indexParent) => (
          <GenericInput
            value={item.flexAttribute}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onChangeChildTable({ name, value, index: idx, indexParent })}
            name="flexAttribute"
            required
            type="select"
            tOptions="selections:flexAttribute"
            menuPortalTarget
          />
        ),
      },
      {
        name: 'mandatoryFlag',
        label: 'label.mandatoryFlag',
        required: true,
        render: (colName, item, idx, indexParent) => (
          <GenericInput
            value={item.mandatoryFlag}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onChangeChildTable({ name, value, index: idx, indexParent })}
            name="mandatoryFlag"
            type="select"
            tOptions="selections:mandatoryFlag"
            menuPortalTarget
            required
          />
        ),
      },
      {
        name: 'status',
        label: 'label.status',
        render: (colName, item, idx, indexParent) => (
          <GenericInput
            value={item.status}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onChangeChildTable({ name, value, index: idx, indexParent })}
            name="status"
            type="select"
            tOptions="selections:objectStatus"
            menuPortalTarget
          />
        ),
      },
    ];

    return (
      <div>
        <PageTitle
          linkTo={RouteNames.searchAndList.path}
          titleBtn={t('label.back')}
          titleRight={t('label.provisionDataSetAttributes')}
        />
        <div className="col-md-12 mb-30">
          <form
            noValidate
            ref={this.formRef}
            onSubmit={modeModifyFlexAttributeConfig === 2 ? this.onHandleSave : () => {}}
            className={`needs-validation ${wasValidated ? 'was-validated' : ''}`}
          >
            <div className="card card-statistics mt-4">
              <TitleFrom title={t('label.provisionDataSetAttributes')} />
              <div className="form-group col-md-12 buttons-attibute pt-4 mb-4">
                {modeCreateFlexAttributeConfig === 2 && (
                  <button
                    type="button"
                    className="button button-border black x-small"
                    onClick={this.onAddServiceProvisioningMapping}
                  >
                    +
                    {t('label.addDataSet')}
                  </button>
                )}
                {modeModifyFlexAttributeConfig === 2 && (
                  <button
                    disabled={modeModifyFlexAttributeConfig === 1}
                    type="submit"
                    className="button button-border x-small float-right"
                  >
                    {data && data.id ? t('label.saveConfig') : t('label.createConfig')}
                  </button>
                )}
                <button
                  type="button"
                  onClick={this.doGetFlexAttributesMapping}
                  className="button mr-2 button-border black x-small float-right"
                >
                  {t('label.cancel')}
                </button>
              </div>

              <CollapsibleTable
                columns={headerTable}
                data={data && data.types && data.types.length ? data.types : []}
                indexViewer={activeTab}
                onClickRow={this.onToggleTab}
              >
                <br />
                <div className="col-md-12 row">
                  {typeFileds.map(val => {
                    return (
                      <GenericInput
                        value={
                          data.types && data.types[activeTab] && data.types[activeTab][val.name]
                            ? data.types[activeTab][val.name]
                            : ''
                        }
                        wrapperClass="col-md-4"
                        onChange={({ name, value }) => this.onChangeTable({ name, value, index: activeTab })}
                        {...val}
                      />
                    );
                  })}
                </div>

                <br />
                <div className="col-md-12">
                  <DataTable
                    columns={headerTableMapping}
                    isSupportRemoveIndex
                    indexParent={activeTab}
                    // isNoNeedFixedHeader
                    data={data && data.types && data.types[activeTab] ? data.types[activeTab].mapping : []}
                  />
                </div>
                <div className="form-group col-md-12 buttons-attibute">
                  <button
                    type="button"
                    className="button button-border black x-small"
                    // disabled={
                    //   data.types &&
                    //   data.types[activeTab] &&
                    //   data.types[activeTab].mapping &&
                    //   data.types[activeTab].mapping.length > 26
                    // }
                    onClick={() => this.onHandleAddAttributeMap({ indexParent: activeTab })}
                  >
                    +
                    {t('label.addAttribute')}
                  </button>
                </div>
              </CollapsibleTable>

              <br />
            </div>
          </form>
        </div>
      </div>
    );
  }
}

ProvisioningMapping.propTypes = {
  searchOms: PropTypes.func,
  modifyOms: PropTypes.func,
  createOms: PropTypes.func,
};

ProvisioningMapping.defaultProps = {
  searchOms: () => {},
  modifyOms: () => {},
  createOms: () => {},
};

const mapStateToProps = createStructuredSelector({
  permissionOrder: makeGetPermissionsOrderManagement() || {},
});

export default withTranslation('common')(
  connect(mapStateToProps, {
    getFlexAttributesMapping,
    modifyFlexAttributeMapping,
    createFlexAttributeMapping,
  })(withRouter(ProvisioningMapping))
);
