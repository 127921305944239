import gql from 'graphql-tag';
import { convertFilterRequestUrl } from '../../utils/utils';

export default ({ filter, page, size, sort }) => {
  const sortStr = sort ? `, sort: ${sort}` : '';
  const filterStr = filter ? `, filter: ${convertFilterRequestUrl(filter, [])}` : '';
  const searchSubscriptionTopUp = `searchSubscriptionTopUp(page: ${page}, size: ${size}${sortStr}${filterStr})`;
  return gql`
    query {
      ${searchSubscriptionTopUp} {
        id
        accountId
        subscriptionId
        userId
        createdDate
        paymentSource
        paymentRefId
        paymentSourceId
        paymentDescription
        amount
        serviceType
        validityTerm
        validityTermUnit
        applyPayment
      }
    }
  `;
};
