import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import SearchComponent from '../../PricingHub/SearchComponent';

class EnergyTopupSearchForm extends SearchComponent {
  searchInputs = [
    {
      label: 'label.startDateWithDetails',
      name: 'startDateTime',
      type: 'date',
      required: true,
      defaultValue: moment()
        .startOf('month')
        .format('YYYY-MM-DD'),
      dateFormat: 'YYYY-MM-DD',
    },
    {
      label: 'label.endDateWithDetails',
      name: 'endDateTime',
      type: 'date',

      dateFormat: 'YYYY-MM-DD',
    },
    {
      label: 'label.contractAccountId',
      name: 'accountId',
    },
  ];

  btnSpan = 'col-md-3';
}

EnergyTopupSearchForm.propTypes = {
  onSubmit: PropTypes.func,
  isSubmitting: PropTypes.bool,
};

EnergyTopupSearchForm.defaultProps = {
  isSubmitting: false,
};

export default withTranslation('common')(EnergyTopupSearchForm);
