import React, { PureComponent } from 'react';
import { compose } from 'redux';
import { Switch, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import injectReducer from '../../utils/injectReducer';
import injectSaga from '../../utils/injectSaga';
import reducer from './reducer';
import saga from './saga';
import RouteNames from '../App/RouteNames';
import Footer from '../../components/Footer';
import Sidebar from './Sidebar';
import { NewCustomer, AccountClosure, AccountReport, TaxExemption } from './CustomerReports';
import {
  Invoicing,
  Billing,
  ProductSummary,
  TransactionSummary,
  TaxSummary,
  TaxDetail,
  BillableServiceForecast,
  ObjectFilesSummary,
  BillingTracker,
  CommitmentData,
  FonatelReport,
  PHCReport,
  ServiceInvoiceSummary,
  ItemSummary,
} from './BillingReports';
import { Payment, FailedPayment, PaymentSuspense, TopUp } from './PaymentReports';
import {
  AgeingSummary,
  Collection030,
  Collection3060,
  Collection6090,
  CollectionMoreThan90,
  AgeingPayment,
} from './CollectionReports';
import { OrderReport, OrderDetailsReport } from './OrderReports';
import { JobSchedule, JobFailed, JobDetail } from './JobReports';
import {
  TrialSubscription,
  SubscriptionExpiry,
  ActiveSubscription,
  SubscriptionContractExpiry,
  TrialToPaidSubscription,
  ItemServices,
  ServicesForcecast,
  ProvisioningData,
  ServiceReconReport,
  EnergyTopup,
} from './SubscriptionReports';
import { AROpsDetails, OpenDispute, OpenWriteOff, CreditNotes } from './AROpsReports';
import { UnbilledTransactions, BillingInvoicing, BillingAR, AccountingReconciliation } from './ReconciliationReports';
import {
  UsageSummary,
  UsageReconciliation,
  VoiceCustomerCallTypeSummary,
  VoiceOperatorCallTypeSummary,
  VoiceExcessTrafficReport,
  VoiceTrafficByLdDistination,
  OnDemand,
  NewSmsReportData,
  VoiceITXRawReport,
  ITXSMS,
  VoiceICallTypeSummary,
  InternetOperatorDataCost,
  TecoSummary,
  RawFile,
  UsageTransactionDetail,
  RawCdrData,
  RawSmsData,
  RawNonBroadsoft,
  BroadsoftZeroDurationCdrs,
  NonBroadsoftZeroDurationCDR,
  SuspendedFailedRecords,
  UsagePrepaidConsumption,
  UsagePrepaidAverageConsumption,
} from './UsageReports';
import {
  RevenueRecognition,
  RevenueSummary,
  RevenueForecast,
  MRRForecast,
  EmployeeCostingPNL,
  EmployeeCostingYearlyPL,
} from './RevenueReports';
import { CommissionsCancellation, KPI, ExtractBI } from './ExtractsReports';
import {
  R00105,
  R00106,
  R00501,
  R00502,
  R00503,
  R00504,
  R00505,
  R00506,
  R00401,
  R00405,
  CreditBureau,
} from './RegulatoryReports';
import { makeGetPermissionsReports } from '../App/selectors';
import { checkPermissionForAllPages } from './CheckPermission';
import { isHasPermissionWithMenu } from '../../utils/utils';
import { permissionList } from '../../utils/constants';

class ReportManagement extends PureComponent {
  render() {
    const { permissionsReports } = this.props;
    let permissions = {};
    if (!isHasPermissionWithMenu({ permissions: permissionsReports, listKeys: permissionList.reports })) return null;
    if (permissionsReports && permissionsReports.reportModulePermissions) {
      const listPermission = permissionsReports.reportModulePermissions;
      permissions = checkPermissionForAllPages({ listPermission });
    }
    return (
      <div className="container-fluid">
        <div className="row">
          <Sidebar />
          <div className="content-wrapper">
            <Switch>
              <Route
                exact
                path={RouteNames.newCustomerReport.path}
                component={() => <NewCustomer permissions={permissions} />}
              />
              <Route
                exact
                path={RouteNames.accountClosure.path}
                component={() => <AccountClosure permissions={permissions} />}
              />

              <Route
                exact
                path={RouteNames.orderReport.path}
                component={() => <OrderReport permissions={permissions} />}
              />
              <Route
                exact
                path={RouteNames.taxExemptionReport.path}
                component={() => <TaxExemption permissions={permissions} />}
              />
              <Route
                exact
                path={RouteNames.productSummary.path}
                component={() => <ProductSummary permissions={permissions} />}
              />
              <Route
                exact
                path={RouteNames.transactionSummary.path}
                component={() => <TransactionSummary permissions={permissions} />}
              />
              <Route
                exact
                path={RouteNames.taxSummary.path}
                component={() => <TaxSummary permissions={permissions} />}
              />
              <Route exact path={RouteNames.taxDetail.path} component={() => <TaxDetail permissions={permissions} />} />
              <Route
                exact
                path={RouteNames.trialExpiry.path}
                component={() => <TrialSubscription permissions={permissions} />}
              />
              <Route
                exact
                path={RouteNames.subscriptionExpiry.path}
                component={() => <SubscriptionExpiry permissions={permissions} />}
              />

              <Route
                exact
                path={RouteNames.activeSubscriptions.path}
                component={() => <ActiveSubscription permissions={permissions} />}
              />

              <Route
                exact
                path={RouteNames.subscriptionContractExpiry.path}
                component={() => <SubscriptionContractExpiry permissions={permissions} />}
              />

              <Route
                exact
                path={RouteNames.trialToPaidSubscription.path}
                component={() => <TrialToPaidSubscription permissions={permissions} />}
              />

              <Route
                exact
                path={RouteNames.jobScheduleSummary.path}
                component={() => <JobSchedule permissions={permissions} />}
              />
              <Route
                exact
                path={RouteNames.jobFailureActivity.path}
                component={() => <JobFailed permissions={permissions} />}
              />

              <Route exact path={RouteNames.jobDetail.path} component={() => <JobDetail permissions={permissions} />} />

              <Route
                exact
                path={RouteNames.invoicingReport.path}
                component={props => <Invoicing permissions={permissions} {...props} />}
              />
              <Route
                exact
                path={RouteNames.paymentReport.path}
                component={() => <Payment permissions={permissions} />}
              />
              <Route
                exact
                path={RouteNames.failPayment.path}
                component={() => <FailedPayment permissions={permissions} />}
              />
              <Route
                exact
                path={RouteNames.paymentSuspenseReport.path}
                component={() => <PaymentSuspense permissions={permissions} />}
              />
              <Route
                exact
                path={RouteNames.billingReport.path}
                component={() => <Billing permissions={permissions} />}
              />
              <Route
                exact
                path={RouteNames.collectionAgeingSummaryReport.path}
                component={() => <AgeingSummary permissions={permissions} />}
              />
              <Route
                exact
                path={RouteNames.collection030DaysReport.path}
                component={() => <Collection030 permissions={permissions} />}
              />
              <Route
                exact
                path={RouteNames.collection3060DaysReport.path}
                component={() => <Collection3060 permissions={permissions} />}
              />
              <Route
                exact
                path={RouteNames.collection6090DaysReport.path}
                component={() => <Collection6090 permissions={permissions} />}
              />
              <Route
                exact
                path={RouteNames.collectionOver90DaysReport.path}
                component={() => <CollectionMoreThan90 permissions={permissions} />}
              />

              <Route
                exact
                path={RouteNames.arOpsDetails.path}
                component={() => <AROpsDetails permissions={permissions} />}
              />
              <Route
                exact
                path={RouteNames.openDispute.path}
                component={() => <OpenDispute permissions={permissions} />}
              />
              <Route
                exact
                path={RouteNames.openWriteOff.path}
                component={() => <OpenWriteOff permissions={permissions} />}
              />
              <Route
                exact
                path={RouteNames.unbilledTransactions.path}
                component={() => <UnbilledTransactions permissions={permissions} />}
              />
              <Route
                exact
                path={RouteNames.billingInvoicing.path}
                component={() => <BillingInvoicing permissions={permissions} />}
              />
              <Route exact path={RouteNames.billingAR.path} component={() => <BillingAR permissions={permissions} />} />

              {/* Usage */}
              <Route
                exact
                path={RouteNames.usageSummary.path}
                component={() => <UsageSummary permissions={permissions} />}
              />

              {/* <Route
                exact
                path={RouteNames.usageDetail.path}
                component={() => <UsageDetail permissions={permissions} />}
              /> */}
              <Route
                exact
                path={RouteNames.usageDetail.path}
                component={() => <UsageTransactionDetail permissions={permissions} />}
              />
              <Route
                exact
                path={RouteNames.CDRReconciliationSummary.path}
                component={() => <UsageReconciliation permissions={permissions} />}
              />

              <Route
                exact
                path={RouteNames.revenueSummary.path}
                component={() => <RevenueSummary permissions={permissions} />}
              />
              <Route
                exact
                path={RouteNames.revenueRecognition.path}
                component={() => <RevenueRecognition permissions={permissions} />}
              />
              <Route
                exact
                path={RouteNames.revenueForecast.path}
                component={() => <RevenueForecast permissions={permissions} />}
              />

              <Route
                exact
                path={RouteNames.revenueMMRForecast.path}
                component={() => <MRRForecast permissions={permissions} />}
              />

              <Route
                exact
                path={RouteNames.voiceCustomerCallTypeSummary.path}
                component={() => <VoiceCustomerCallTypeSummary permissions={permissions} />}
              />
              <Route
                exact
                path={RouteNames.voiceOperatorCallTypeSummary.path}
                component={() => <VoiceOperatorCallTypeSummary permissions={permissions} />}
              />
              <Route
                exact
                path={RouteNames.voiceExcessTrafficReport.path}
                component={() => <VoiceExcessTrafficReport permissions={permissions} />}
              />

              <Route
                exact
                path={RouteNames.voiceTrafficByLdDistination.path}
                component={() => <VoiceTrafficByLdDistination permissions={permissions} />}
              />

              <Route exact path={RouteNames.onDemand.path} component={() => <OnDemand permissions={permissions} />} />

              <Route
                exact
                path={RouteNames.newSmsReportData.path}
                component={() => <NewSmsReportData permissions={permissions} />}
              />

              <Route
                exact
                path={RouteNames.interconnectVoiceReport.path}
                component={() => <VoiceITXRawReport permissions={permissions} />}
              />

              <Route
                exact
                path={RouteNames.interconnectSmsReport.path}
                component={() => <ITXSMS permissions={permissions} />}
              />
              <Route
                exact
                path={RouteNames.creditNotesReport.path}
                component={props => <CreditNotes {...props} permissions={permissions} />}
              />
              <Route
                exact
                path={RouteNames.voiceCallTypeSummaryReport.path}
                component={() => <VoiceICallTypeSummary permissions={permissions} />}
              />
              <Route
                exact
                path={RouteNames.internetOperatorDataCost.path}
                component={() => <InternetOperatorDataCost permissions={permissions} />}
              />
              <Route
                exact
                path={RouteNames.telcoSummary.path}
                component={() => <TecoSummary permissions={permissions} />}
              />
              <Route
                exact
                path={RouteNames.searchAccount.path}
                component={() => <AccountReport permissions={permissions} />}
              />
              <Route
                exact
                path={RouteNames.rawUsageFile.path}
                component={() => <RawFile permissions={permissions} />}
              />
              <Route
                exact
                path={RouteNames.subscriptionBillableServices.path}
                component={() => <ItemServices permissions={permissions} />}
              />
              <Route
                exact
                path={RouteNames.accountingReconciliation.path}
                component={() => <AccountingReconciliation permissions={permissions} />}
              />
              <Route exact path={RouteNames.rawCdr.path} component={() => <RawCdrData permissions={permissions} />} />
              <Route
                exact
                path={RouteNames.rawSMSFiles.path}
                component={() => <RawSmsData permissions={permissions} />}
              />
              <Route
                exact
                path={RouteNames.nonBroadSoftRawCdrData.path}
                component={() => <RawNonBroadsoft permissions={permissions} />}
              />
              <Route
                exact
                path={RouteNames.servicesForcecast.path}
                component={() => <ServicesForcecast permissions={permissions} />}
              />
              <Route
                exact
                path={RouteNames.employeeCostingPNL.path}
                component={() => <EmployeeCostingPNL permissions={permissions} />}
              />
              <Route
                exact
                path={RouteNames.billableServicesForecast.path}
                component={() => <BillableServiceForecast permissions={permissions} />}
              />
              <Route
                exact
                path={RouteNames.folioCount.path}
                component={() => <ObjectFilesSummary permissions={permissions} />}
              />
              <Route
                exact
                path={RouteNames.provisioningData.path}
                component={() => <ProvisioningData permissions={permissions} />}
              />
              <Route
                exact
                path={RouteNames.billingTracker.path}
                component={() => <BillingTracker permissions={permissions} />}
              />
              <Route
                exact
                path={RouteNames.employeeCostingYearlyPL.path}
                component={() => <EmployeeCostingYearlyPL permissions={permissions} />}
              />
              <Route
                exact
                path={RouteNames.commitmentData.path}
                component={() => <CommitmentData permissions={permissions} />}
              />
              <Route
                exact
                path={RouteNames.ageingPayment.path}
                component={() => <AgeingPayment permissions={permissions} />}
              />
              <Route
                exact
                path={RouteNames.commissionsCancellations.path}
                component={() => <CommissionsCancellation permissions={permissions} />}
              />
              <Route exact path={RouteNames.kpi.path} component={() => <KPI permissions={permissions} />} />
              <Route
                exact
                path={RouteNames.broadsoftZeroDurationCdrs.path}
                component={() => <BroadsoftZeroDurationCdrs permissions={permissions} />}
              />
              <Route
                exact
                path={RouteNames.nonBroadsoftZeroDurationCdrs.path}
                component={() => <NonBroadsoftZeroDurationCDR permissions={permissions} />}
              />
              <Route
                exact
                path={RouteNames.suspendedFailedRecords.path}
                component={() => <SuspendedFailedRecords permissions={permissions} />}
              />
              <Route exact path={RouteNames.extractBI.path} component={() => <ExtractBI permissions={permissions} />} />
              <Route exact path={RouteNames.r00105.path} component={() => <R00105 permissions={permissions} />} />
              <Route exact path={RouteNames.r00106.path} component={() => <R00106 permissions={permissions} />} />
              <Route exact path={RouteNames.r00501.path} component={() => <R00501 permissions={permissions} />} />
              <Route exact path={RouteNames.r00502.path} component={() => <R00502 permissions={permissions} />} />
              <Route exact path={RouteNames.r00503.path} component={() => <R00503 permissions={permissions} />} />
              <Route exact path={RouteNames.r00504.path} component={() => <R00504 permissions={permissions} />} />
              <Route exact path={RouteNames.r00505.path} component={() => <R00505 permissions={permissions} />} />
              <Route exact path={RouteNames.r00506.path} component={() => <R00506 permissions={permissions} />} />
              <Route exact path={RouteNames.r00401.path} component={() => <R00401 permissions={permissions} />} />
              <Route exact path={RouteNames.r00405.path} component={() => <R00405 permissions={permissions} />} />
              <Route
                exact
                path={RouteNames.creditBureau.path}
                component={() => <CreditBureau permissions={permissions} />}
              />
              <Route
                exact
                path={RouteNames.fonatelReport.path}
                component={() => <FonatelReport permissions={permissions} />}
              />
              <Route exact path={RouteNames.phcReport.path} component={() => <PHCReport permissions={permissions} />} />
              <Route
                exact
                path={RouteNames.contractServiceReconciliation.path}
                component={() => <ServiceReconReport permissions={permissions} />}
              />
              <Route
                exact
                path={RouteNames.prepaidConsumption.path}
                component={() => <UsagePrepaidConsumption permissions={permissions} />}
              />
              <Route
                exact
                path={RouteNames.prepaidAverageConsumption.path}
                component={() => <UsagePrepaidAverageConsumption permissions={permissions} />}
              />
              <Route
                exact
                path={RouteNames.energyTopup.path}
                component={() => <EnergyTopup permissions={permissions} />}
              />
              <Route exact path={RouteNames.topUpReport.path} component={() => <TopUp permissions={permissions} />} />
              <Route
                exact
                path={RouteNames.orderDetailReport.path}
                component={() => <OrderDetailsReport permissions={permissions} />}
              />
              <Route
                exact
                path={RouteNames.serviceInvoiceSummary.path}
                component={() => <ServiceInvoiceSummary permissions={permissions} />}
              />
              <Route
                exact
                path={RouteNames.itemSummary.path}
                component={() => <ItemSummary permissions={permissions} />}
              />
            </Switch>
            <Footer className="footer-bottom" />
          </div>
        </div>
      </div>
    );
  }
}

const withReducer = injectReducer({ key: 'reportReducer', reducer });
const withSaga = injectSaga({ key: 'reportSaga', saga });

const mapStateToProps = createStructuredSelector({
  permissionsReports: makeGetPermissionsReports() || {},
});

const withConnect = connect(mapStateToProps, null);

export default compose(withReducer, withSaga, withConnect)(ReportManagement);
