import shortid from 'shortid';
import moment from 'moment';
import { orderEnum } from 'constantsApp';
import { cloneDeep } from 'lodash';
import constants from './constants';

export function addServicesPackage(packageInfo) {
  const servicePackage = [];
  packageInfo.components.forEach(el => {
    servicePackage.push({
      id: shortid(),
      action: orderEnum.action.add,
      status: orderEnum.status.create,
      packageId: packageInfo.id,
      packageName: packageInfo.name,
      bundleId: el.bundleId,
      serviceType: el.serviceType,
      lines: [],
    });
  });

  return servicePackage;
}

export function addLinesPackage(bundleComponent, id, servicePackage) {
  const service = servicePackage.find(el => el.id === id);
  const lines = [];
  bundleComponent.forEach(el => {
    lines.push({
      id: shortid(),
      action: orderEnum.action.add,
      status: orderEnum.status.create,
      priceOfferId: el.priceOfferId,
    });
  });
  service.lines = lines;
  return servicePackage;
}

export function parseLines(lines) {
  const dataLines = lines.map((item, index) => ({
    index: index + 1,
    action: item.action,
    status: item.status,
    reason: item.reason || null,
    quantity: item.quantity || null,
    priceOverride: item.priceOverride || null,
    priceOffset: item.priceOffset || null,
    discountPercent: item.discountPercent || null,
    startDate: item.startDate ? moment(item.startDate).format('YYYY-MM-DD') : null,
    endDate: item.endDate ? moment(item.endDate).format('YYYY-MM-DD') : null,
    relativeStart: item.relativeStart || null,
    relativeEnd: item.relativeEnd || null,
    relativeStartUnit: item.relativeStartUnit || null,
    relativeEndUnit: item.relativeEndUnit || null,
    priceOfferId: item.priceOfferId,
    discountOfferId: item.discountOfferId || null,
  }));

  return dataLines;
}

export function createServicePriceOffer(poSelected) {
  const serviceAlaCarte = [];
  poSelected.forEach(el => {
    const service = serviceAlaCarte.find(subEl => subEl.serviceType === el.serviceType);

    if (service) {
      service.lines.push({
        id: el.id,
        action: orderEnum.action.add,
        status: orderEnum.status.create,
        priceOfferId: el.id,
      });
    } else {
      serviceAlaCarte.push({
        id: el.serviceType,
        action: orderEnum.action.add,
        status: orderEnum.status.create,
        serviceType: el.serviceType,
        lines: [
          {
            id: el.id,
            action: orderEnum.action.add,
            status: orderEnum.status.create,
            priceOfferId: el.id,
          },
        ],
      });
    }
  });

  return serviceAlaCarte;
}

export const convertbuildOrderProvisioningAttributesNewOrder = data => {
  if (!data || !data.length) return null;
  const newPayload = [];
  data.forEach(serviceAttributes => {
    const { configProvisioningAttributes, serviceType, priceOfferId } = serviceAttributes || {};
    if (configProvisioningAttributes && configProvisioningAttributes.length) {
      configProvisioningAttributes.forEach(provisioningAttributes => {
        const { attributesList, name } = provisioningAttributes || {};

        const payloadProvisioningAttribute = {
          serviceType: serviceType || null,
          provisioningName: name || null,
          priceOfferId: priceOfferId || null,
          provisioningAttributesList: null,
        };
        if (attributesList && attributesList.length) {
          attributesList.forEach(att => {
            const { name, attributeValue } = att;
            const payloadAttribute = { name: name || null, value: attributeValue || null };
            if (!payloadProvisioningAttribute || !payloadProvisioningAttribute.provisioningAttributesList) {
              payloadProvisioningAttribute.provisioningAttributesList = [];
            }
            payloadProvisioningAttribute.provisioningAttributesList.push(payloadAttribute);
          });
        }
        newPayload.push(payloadProvisioningAttribute);
      });
    }
  });
  return newPayload;
};

export function getMessageModal(mode, detail, t) {
  switch (mode) {
    case constants.ModalMode.purchasePackage:
      return `Do you want to remove package and services? Package cannot be partially removed. ${detail || ''}`;
    case constants.ModalMode.purchaseBundle:
      return `Do you want to remove services and lines? ${detail || ''}`;
    case constants.ModalMode.purchaseAlaCarte:
      return `${
        t ? t('message.messageRemoveLinePurchaseAlaCarte') : 'Do you want to cancel all lines under this service?'
      } ${detail || ''}`;
    default:
      return `Do you want to remove package and services? Package cannot be partially removed. ${detail || ''}`;
  }
}

export const convertbuildOrderProvisioningAttributesPayload = (orderDetail, serviceStatus) => {
  return {
    type: orderDetail.type,
    status: serviceStatus || orderDetail.status,
    accountId: orderDetail.accountId,
    userId: orderDetail.userId,
    initialTerm: orderDetail.initialTerm,
    initialTermUnit: orderDetail.initialTermUnit,
    renewalTerm: orderDetail.renewalTerm,
    renewalTermUnit: orderDetail.renewalTermUnit,
    trialTerm: orderDetail.trialTerm,
    trialTermUnit: orderDetail.trialTermUnit,
    services: orderDetail.services.map(service => {
      const { index, serviceType, action, status, bundleId, packageId } = service;
      return {
        index,
        serviceType,
        action,
        bundleId,
        status: serviceStatus || status,
        packageId,
        provisioningId: 'Dummy',
      };
    }),
  };
};

const convertOrderProvisioningAttributes = provisioningAttributes => {
  let listAttribute = [];
  if (provisioningAttributes && provisioningAttributes.length) {
    provisioningAttributes.forEach(pr => {
      const { provisioningAttributesList, ...rest } = pr;
      if (provisioningAttributesList && provisioningAttributesList.length) {
        listAttribute = [...listAttribute, ...provisioningAttributesList.map(att => ({ ...att, ...rest }))];
      }
    });
  }

  return listAttribute;
};

export const convertDataProvisioningAttributes = (provisioningAttributesList, orderProvisioningAttribute) => {
  let data = null;
  const newOrderAttributes = convertOrderProvisioningAttributes(orderProvisioningAttribute);
  if (provisioningAttributesList && provisioningAttributesList.length) {
    data = provisioningAttributesList.map(pr => {
      const { serviceType, priceOfferId, configProvisioningAttributes, ...rest } = pr;
      let newConfigProvisioningAttributes = configProvisioningAttributes;
      if (newConfigProvisioningAttributes && newConfigProvisioningAttributes.length) {
        newConfigProvisioningAttributes = newConfigProvisioningAttributes.map(config => {
          const { attributesList, ...rest } = config;
          const newAttributesList = attributesList.map(attItem => {
            const itemSelect = newOrderAttributes.find(
              or => or.serviceType === serviceType && or.priceOfferId === priceOfferId && or.name === attItem.name
            );
            return { ...attItem, attributeValue: itemSelect ? itemSelect.value : null };
          });
          return { ...rest, attributesList: newAttributesList };
        });
      }

      return { serviceType, priceOfferId, configProvisioningAttributes: newConfigProvisioningAttributes, ...rest };
    });
  }
  return data;
};

export const convertSubscriptionData = (subscriptionsData, orderDetalis) => {
  return subscriptionsData.map((val, index) => {
    return {
      index: index + 1,
      initialTerm: orderDetalis?.initialTerm || orderDetalis?.initialTerm === 0 ? orderDetalis?.initialTerm : null,
      initialTermUnit: orderDetalis?.initialTermUnit || null,
      renewalTerm: orderDetalis?.renewalTerm || orderDetalis?.renewalTerm === 0 ? orderDetalis?.renewalTerm : null,
      renewalTermUnit: orderDetalis?.renewalTermUnit || null,
      trialTerm: orderDetalis?.trialTerm || orderDetalis?.trialTerm === 0 ? orderDetalis?.trialTerm : null,
      trialTermUnit: orderDetalis?.trialTermUnit || null,
      subscriptionName: val?.subscriptionName || null,
      subscriptionCategory: val?.subscriptionCategory || null,
    };
  });
};

export const convertMultiSubscriptionData = subscriptionsData => {
  return subscriptionsData.map((val, index) => {
    return {
      index: index + 1,
      initialTerm:
        val?.orderDetail?.initialTerm || val?.orderDetail?.initialTerm === 0 ? val?.orderDetail?.initialTerm : null,
      initialTermUnit: val?.orderDetail?.initialTermUnit || null,
      renewalTerm:
        val?.orderDetail?.renewalTerm || val?.orderDetail?.renewalTerm === 0 ? val?.orderDetail?.renewalTerm : null,
      renewalTermUnit: val?.orderDetail?.renewalTermUnit || null,
      trialToPaidNow: val?.orderDetail?.trialToPaidNow || null,
      trialTerm: val?.orderDetail?.trialTerm || val?.orderDetail?.trialTerm === 0 ? val?.orderDetail?.trialTerm : null,
      trialTermUnit: val?.orderDetail?.trialTermUnit || null,
      trialType: val?.orderDetail?.trialType || null,
      subscriptionName: val?.subscriptionName || null,
      creditProfileName: val?.creditProfileName || null,
      subscriptionCategory: val?.subscriptionCategory || null,
    };
  });
};

export const convertbuildOrderProvisioningAttributesWithDefaultValue = data => {
  let newData = cloneDeep(data);
  if (data && data.length) {
    newData = newData.map(val => {
      const newVal = val;
      const { configProvisioningAttributes } = val;
      if (configProvisioningAttributes && configProvisioningAttributes.length) {
        newVal.configProvisioningAttributes = configProvisioningAttributes.map(pr => {
          const newPr = pr;
          const { attributesList } = pr;
          if (attributesList && attributesList.length) {
            newPr.attributesList = attributesList.map(attr => {
              const newAttr = attr;
              const { defaultValue } = attr;
              if (defaultValue) {
                newAttr.attributeValue = defaultValue;
              }
              return { ...newAttr };
            });
          }
          return { ...newPr };
        });
      }
      return { ...newVal };
    });
  }
  return newData;
};

export const convertSubscriptionDataEdit = orderDetalis => {
  return {
    initialTerm: orderDetalis?.initialTerm || orderDetalis?.initialTerm === 0 ? orderDetalis?.initialTerm : null,
    initialTermUnit: orderDetalis?.initialTermUnit || null,
    renewalTerm: orderDetalis?.renewalTerm || orderDetalis?.renewalTerm === 0 ? orderDetalis?.renewalTerm : null,
    renewalTermUnit: orderDetalis?.renewalTermUnit || null,
    trialTerm: orderDetalis?.trialTerm || orderDetalis?.trialTerm === 0 ? orderDetalis?.trialTerm : null,
    trialTermUnit: orderDetalis?.trialTermUnit || null,
  };
};

export const convertLineFromDiscountOffers = (list, lastIndex) => {
  if (list && list.length) {
    return list.map((item, index) => ({
      index: lastIndex + index,
      action: 'ADD',
      status: 'CREATED',
      reason: null,
      quantity: null,
      priceOverride: null,
      priceOffset: null,
      discountPercent: null,
      startDate: item.startDate || null,
      endDate: item.endDate || null,
      relativeStart: null,
      relativeEnd: null,
      relativeStartUnit: null,
      relativeEndUnit: null,
      priceOfferId: item.priceOfferId,
      discountOfferId: item.id || null,
      discountOfferName: item.name,
    }));
  }
  return [];
};

export const initServiceWithSelectPackage = ({
  index,
  bundleId,
  packageId,
  serviceType,
  packageName,
  bundleName,
  discountOfferId,
}) => {
  return {
    index,
    serviceType,
    action: 'ADD',
    status: orderEnum.status.create,
    bundleId,
    packageId,
    packageName,
    bundleName,
    lines: [],
    discountOfferId,
    isPackage: true,
  };
};

const initLineFromBundle = (bundle, component) => {
  return {
    index: component.index,
    action: 'ADD',
    status: 'CREATED',
    reason: null,
    quantity: null,
    priceOverride: null,
    priceOffset: null,
    discountPercent: null,
    startDate: bundle.startDate || null,
    endDate: bundle.endDate || null,
    relativeStart: null,
    relativeEnd: null,
    relativeStartUnit: null,
    relativeEndUnit: null,
    priceOfferId: component.priceOfferId,
    discountOfferId: component.discountOfferId || null,
    discountOfferName: component.discountOfferName,
    priceOfferName: component.priceOfferName,
  };
};

export const initServiceWithSelectBundle = (bundle, index) => {
  return {
    index: index + 1,
    serviceType: bundle && bundle.components && bundle.components.length > 0 ? bundle.components[0].serviceType : '',
    action: 'ADD',
    reason: null,
    status: 'CREATED',
    bundleId: bundle.id,
    bundleName: bundle.name,
    packageId: null,
    discountOfferId: bundle.discountOfferId || null,
    discountOfferName: bundle.discountOfferName || null,
    isBundle: true,
    lines:
      bundle && bundle.components
        ? bundle.components.map(component => {
            return initLineFromBundle(bundle, component);
          })
        : [],
  };
};

const initLineFromPriceOffer = (priceOffer, index) => {
  return {
    index,
    action: 'ADD',
    status: 'CREATED',
    reason: null,
    quantity: null,
    priceOverride: null,
    priceOffset: null,
    discountPercent: null,
    startDate: priceOffer.startDate || null,
    endDate: priceOffer.endDate || null,
    relativeStart: null,
    relativeEnd: null,
    relativeStartUnit: null,
    relativeEndUnit: null,
    priceOfferId: priceOffer.id,
    priceOfferName: priceOffer.name,
    discountOfferId: priceOffer.discountOfferId || null,
  };
};

export const initServiceFromPriceOffers = (priceOffers, serviceIndex) => {
  return {
    index: serviceIndex,
    serviceType: priceOffers[0].serviceType || null,
    provisioningId: null,
    action: 'ADD',
    reason: null,
    status: 'CREATED',
    bundleId: null,
    packageId: null,
    isPriceOffer: true,
    lines: priceOffers.map((item, i) => {
      return initLineFromPriceOffer(item, i);
    }),
  };
};

export const findIndexService = ({ itemProvisioningSelect, services }) => {
  if (itemProvisioningSelect && itemProvisioningSelect.isPackage) {
    return services.findIndex(
      item =>
        item.isPackage &&
        item.index === itemProvisioningSelect.index &&
        item.packageId === itemProvisioningSelect.packageId
    );
  }
  if (itemProvisioningSelect && itemProvisioningSelect.isBundle) {
    return services.findIndex(
      item =>
        item.isBundle &&
        item.index === itemProvisioningSelect.index &&
        item.bundleId === itemProvisioningSelect.bundleId
    );
  }
  if (itemProvisioningSelect && itemProvisioningSelect.isPriceOffer) {
    return services.findIndex(item => item.isPriceOffer && item.index === itemProvisioningSelect.index);
  }
  if (itemProvisioningSelect && itemProvisioningSelect.isDiscount) {
    return services.findIndex(item => item.isDiscount && item.index === itemProvisioningSelect.index);
  }

  if (itemProvisioningSelect && itemProvisioningSelect.correlationId) {
    return services.findIndex(
      item => item.correlationId === itemProvisioningSelect.correlationId && item.index === itemProvisioningSelect.index
    );
  }

  return -1;
};
