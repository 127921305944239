import gql from 'graphql-tag';

export default id => gql`
  query {
    getOrderProvisioningOutboundTasks(id: "${id}") {
      id
      restartApiOnError
      apiName
      status
      index
      serviceType
      provisioningSequence
      retriedCount
      retryCountOnError
      retryCountOnTimeOut
    }
  }
`;
