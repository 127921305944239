import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Select, { components } from 'react-select';
import { useTranslation } from 'react-i18next';
import Tooltip from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap_white.css';

const DropdownIndicator = props => (
  <components.DropdownIndicator {...props}>
    <div
      className="ico-indicator"
      style={{
        marginRight: 2,
      }}
    >
      <svg xmlns="http://www.w3.org/2000/svg" width="10" height="6" viewBox="0 0 10 6">
        <path
          fill="#8891A5"
          fillRule="nonzero"
          d="M5 3.586L8.293.293a1 1 0 0 1 1.414 1.414l-4 4a1 1 0 0 1-1.414 0l-4-4A1 1 0 0 1 1.707.293L5 3.586z"
        />
      </svg>
    </div>
  </components.DropdownIndicator>
);
const SelectContainer = ({ children, className, ...props }) => {
  return (
    <components.SelectContainer className={`${className} ${props.isFocused ? 'select-focused' : ''}`} {...props}>
      {children}
    </components.SelectContainer>
  );
};
const SelectGroup = ({
  label,
  wrapperClass,
  value,
  required,
  menuPortalTarget,
  tooltipDetails,
  readOnly,
  subLabel,
  isNoClearable,
  isSupportDefaultValue,
  ...props
}) => {
  const [defaultValue, setDefaultValue] = useState(null);

  useEffect(() => {
    setDefaultValue(value);
  }, [value]);

  let valueForChecking = value;
  if (isSupportDefaultValue) {
    valueForChecking = defaultValue;
  }
  const { t } = useTranslation(['common', 'selections']);
  return (
    <div className={`form-group select-group ${wrapperClass} ${required && !value ? 'has-error' : ''}`}>
      {label && (
        <span>
          {t(label)}
          {label && required && <i className="fa fa-star is-required" />}
          {subLabel && <span className="sub-label">{subLabel}</span>}
          &nbsp;
          {tooltipDetails && (
            <Tooltip
              trigger={['hover']}
              placement="bottom"
              overlay={tooltipDetails || ''}
              arrowContent={<div className="rc-tooltip-arrow-inner" />}
              overlayClassName="tooltip-details"
            >
              <i className="ti-info-alt ico-info-tooltip" aria-hidden="true" />
            </Tooltip>
          )}
        </span>
      )}
      <Select
        className={`react-select ${readOnly ? 'read-only' : ''}`}
        components={{ DropdownIndicator, SelectContainer }}
        classNamePrefix="custom-react-select"
        menuPortalTarget={menuPortalTarget ? document.querySelector('body') : ''}
        styles={{
          control: props => ({
            ...props,
            backgroundColor: '#f6f7f8',
            border: '0px solid transparent',
            borderRadius: 0,
            minHeight: '27px',
          }),
          indicatorContainer: props => ({
            ...props,
            padding: '4px 8px',
          }),
          indicatorSeparator: props => ({
            ...props,
            marginTop: '7px',
            marginBottom: '7px',
          }),
        }}
        {...props}
        value={value || null}
        isClearable={!isNoClearable}
      />
      {!props.isDisabled && required && (
        <input
          type="text"
          name={props.name}
          tabIndex={-1}
          autoComplete="off"
          className="placeholder-selector asdasdasd"
          style={{ opacity: 0, height: 0 }}
          defaultValue={valueForChecking || null}
          required={required}
        />
      )}
    </div>
  );
};

SelectGroup.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.any), PropTypes.objectOf(PropTypes.any)]),
  required: PropTypes.bool,
  wrapperClass: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.object),
};

SelectGroup.defaultProps = {
  label: '',
  wrapperClass: 'col-md-3',
  options: [],
  required: false,
  value: null,
};

export default SelectGroup;
