import gql from 'graphql-tag';

export default () => gql`
  query {
    searchConfigMultiRating(dummy: "") {
      id
      serviceType
      subscriptionCategory
    }
  }
`;
