import { call, put, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { toast } from 'react-toastify';
import { queryRequest, mutationRequest } from '../../utils/request';
import {
  searchItems,
  getItemDetail,
  createItem,
  searchPriceOffers,
  getPriceOfferDetail,
  createPriceOffer,
  modifyItem,
  updateItemStatus,
  modifyPriceOffer,
  updatePriceOfferStatus,
  getUsageContainerSpec,
  searchDiscountOffers,
  createDiscountOffer,
  getDiscountOfferById,
  modifyDiscountOffer,
  updateDiscountOfferStatus,
  getProductFamilyConfig,
  modifyProductFamilyConfig,
  createProductFamilyConfig,
  searchItemServices,
  createItemServices,
  modifyItemServices,
  searchItemServicesExport,
  searchChartOfAccount,
  getItemServiceProcessStats,
  getItemServiceFileUploadErrors,
  searchDiscountTriggers,
  getDiscountTriggerByName,
  createDiscountTrigger,
  modifyDiscountTrigger,
  getPriceOfferAudit,
  getDiscountOfferAudit,
  convertJsonToPriceOffer,
  convertJsonToDiscountOffer,
  searchProductFamilyConfig,
} from '../../api';
import RouteNames from '../App/RouteNames';

import { isConnecting, isEndConnected } from '../Loader/actions';
import * as types from './types';
import * as actions from './actions';
import i18n from '../../i18n';

// ------ Items
export function* getChartOfAccountSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, searchChartOfAccount(payload));
    if (response.searchChartOfAccount) {
      if (cb) cb({ success: true, data: response.searchChartOfAccount });
      yield put(isEndConnected());
    } else if (cb) cb({ success: true, data: null });
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    if (cb) yield cb({ success: false });
  }
}
// ------ Items
export function* searchListItems({ payload, cb }) {
  yield put(isConnecting());
  try {
    const { page, size, filter, sort } = payload;
    const response = yield call(queryRequest, searchItems({ page, size, filter, sort }));
    if (response.searchItems) {
      yield put(actions.searchItemsSuccess(response.searchItems));
    }
    yield put(isEndConnected());
    if (cb) yield cb({ success: true });
  } catch (err) {
    yield put(isEndConnected());
    if (cb) yield cb({ success: false });
  }
}

export function* getItemSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getItemDetail(payload));
    if (response.getItemById) {
      yield put(actions.getItemDetailSuccess(response.getItemById));
      if (cb) yield cb({ success: true, data: response.getItemById });
    } else {
      yield cb({ success: false, data: null });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield cb({ success: false, data: null });
    yield put(isEndConnected());
  }
}

export function* createItemSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const dataCreate = payload;
    const response = yield call(mutationRequest, createItem(dataCreate));
    yield put(actions.createItemSuccess());
    if (cb) yield cb({ success: true });
    if (response.createItem && response.createItem.id) {
      yield put(push(RouteNames.prettifyPath(RouteNames.pricingItemEdit.path, response.createItem.id)));
      toast.success(i18n.t('common:apiMessage.createItemSuccessfully'));
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    if (cb) yield cb({ success: false });
  }
}

export function* modifyItemSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const dataCreate = payload;
    const response = yield call(mutationRequest, modifyItem(dataCreate));
    yield put(actions.modifyItemSuccess(response.modifyItem));
    yield cb({ success: true });
    yield put(isEndConnected());
    toast.success(i18n.t('common:apiMessage.modifyItemSuccessfully'));
  } catch (err) {
    yield put(isEndConnected());
    cb({ success: false });
  }
}

export function* updateItemStatusSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, updateItemStatus(payload));
    yield put(actions.updateItemStatusSuccess(response.updateItemStatus));
    yield cb({ success: true });
    yield put(isEndConnected());
    toast.success(i18n.t('common:apiMessage.updateItemStatusSuccessfully'));
  } catch (err) {
    yield put(isEndConnected());
    cb({ success: false });
  }
}

// -------Price Offers
export function* searchListPriceOffers({ payload, cb }) {
  yield put(isConnecting());
  try {
    const { page, size, filter, sort } = payload;
    const response = yield call(queryRequest, searchPriceOffers({ page, size, filter, sort }));
    if (response.searchPriceOffers) {
      yield put(actions.searchPriceOffersSuccess(response.searchPriceOffers));
    }
    yield put(isEndConnected());
    if (cb) yield cb({ success: true });
  } catch (err) {
    yield put(isEndConnected());
    if (cb) yield cb({ success: false });
  }
}

export function* getPriceOfferSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getPriceOfferDetail(payload));
    if (response.getPriceOfferById) {
      yield put(actions.getPriceOfferDetailSuccess(response.getPriceOfferById));
      if (cb) yield cb({ success: true, data: response.getPriceOfferById });
    } else {
      yield cb({ success: false, data: null });
    }
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false });
    yield put(isEndConnected());
  }
}

export function* createPriceOfferSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const dataCreate = payload;
    const response = yield call(mutationRequest, createPriceOffer(dataCreate));
    yield put(actions.createPriceOfferSuccess());
    if (response.createPriceOffer && response.createPriceOffer.id) {
      toast.success(i18n.t('common:apiMessage.createPriceOfferSuccessfully'));
      if (cb) yield cb({ success: true, payload: response.createPriceOffer.id });
      yield put(push(RouteNames.prettifyPath(RouteNames.pricingPriceOfferEdit.path, response.createPriceOffer.id)));
    } else {
      yield cb({ success: true, payload: response.createPriceOffer.id });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    if (cb) cb({ success: false });
  }
}

export function* modifyPriceOfferSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const dataCreate = payload;
    const response = yield call(mutationRequest, modifyPriceOffer(dataCreate));
    yield put(actions.modifyPriceOfferSuccess(response.modifyPriceOffer));
    if (cb) yield cb({ success: true });
    yield put(isEndConnected());
    toast.success(i18n.t('common:apiMessage.modifyPriceOfferSuccessfully'));
  } catch (err) {
    yield put(isEndConnected());
    if (cb) cb({ success: false });
  }
}

export function* updatePriceOfferStatusSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, updatePriceOfferStatus(payload));
    yield put(actions.updatePriceOfferStatusSuccess(response.updatePriceOfferStatus));
    yield cb({ success: true });
    yield put(isEndConnected());
    toast.success(i18n.t('common:apiMessage.updatePriceOfferStatusSuccessfully'));
  } catch (err) {
    yield put(isEndConnected());
    cb({ success: false });
  }
}

// get Usage Container Spec
export function* getUsageContainerSpecSaga({ cb, payload }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getUsageContainerSpec(payload));
    yield put(isEndConnected());
    if (response.getUsageContainerSpec && response.getUsageContainerSpec.id) {
      if (cb) yield cb({ success: true, data: response.getUsageContainerSpec.usageContainerRecord });
    } else if (cb) yield cb({ success: false, data: null });
  } catch (err) {
    yield put(isEndConnected());
    if (cb) yield cb({ success: false, data: null });
  }
}

// -------Discount Offers
export function* searchListDiscountOffersSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const { page, size, filter, sort } = payload;
    const response = yield call(queryRequest, searchDiscountOffers({ page, size, filter, sort }));
    if (response.searchDiscountOffers) {
      yield put(actions.searchDiscountOffersSuccess(response.searchDiscountOffers));
    }
    yield put(isEndConnected());
    if (cb) yield cb({ success: true });
  } catch (err) {
    yield put(isEndConnected());
    if (cb) yield cb({ success: false });
  }
}

export function* createDiscountOfferSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const dataCreate = payload;
    const response = yield call(mutationRequest, createDiscountOffer(dataCreate));
    yield put(actions.createPriceOfferSuccess());
    if (response.createDiscountOffer && response.createDiscountOffer.id) {
      toast.success(i18n.t('common:apiMessage.createDiscountOfferSuccessfully'));
      if (cb) yield cb({ success: true, payload: response.createDiscountOffer.id });
      yield put(push(RouteNames.prettifyPath(RouteNames.pricingDiscountEdit.path, response.createPriceOffer.id)));
    } else {
      yield cb({ success: true, payload: response.createPriceOffer.id });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    if (cb) cb({ success: false });
  }
}

export function* getDiscountOfferSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getDiscountOfferById(payload));
    if (response.getDiscountOfferById) {
      yield put(actions.getDiscountOfferDetailSuccess(response.getDiscountOfferById));
      if (cb) yield cb({ success: true, data: response.getDiscountOfferById });
    } else {
      yield cb({ success: false, data: null });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield cb({ success: false, data: null });
    yield put(isEndConnected());
  }
}

export function* modifyDiscountOfferSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, modifyDiscountOffer(payload));
    if (response.modifyDiscountOffer) {
      if (cb) yield cb({ success: true, data: response.modifyDiscountOffer });
      toast.success(i18n.t('common:apiMessage.modifyDiscountOfferSuccessfully'));
    } else if (cb) cb({ success: false, data: null });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: null });
    yield put(isEndConnected());
  }
}

export function* updateDiscountOfferStatusSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, updateDiscountOfferStatus(payload));
    if (response.updateDiscountOfferStatus) {
      if (cb) yield cb({ success: true, data: response.updateDiscountOfferStatus });
      toast.success(i18n.t('common:apiMessage.updateDiscountOfferStatusSuccessfully'));
    } else if (cb) yield cb({ success: true, data: null });

    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    if (cb) yield cb({ success: true, data: null });
    cb({ success: false });
  }
}

export function* getProductFamilyConfigSaga({ cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getProductFamilyConfig());
    yield put(isEndConnected());
    if (response.getProductFamilyConfig && response.getProductFamilyConfig.id) {
      yield put(actions.getProductFamilyConfigSuccess(response.getProductFamilyConfig));
      if (cb) yield cb({ success: true, data: response.getProductFamilyConfig });
    } else if (cb) yield cb({ success: false });
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getProductFamilyConfigSuccess([]));
    if (cb) yield cb({ success: false });
  }
}

export function* modifyProductFamilyConfigSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, modifyProductFamilyConfig(payload));
    yield put(actions.getProductFamilyConfig());
    if (response.modifyProductFamilyConfig) {
      if (cb) cb({ success: true, data: response.modifyProductFamilyConfig });
      toast.success(i18n.t('common:apiMessage.modifyProductFamilySuccessfully'));
    }
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  }
}

export function* createProductFamilyConfigSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, createProductFamilyConfig(payload));
    if (response.createProductFamilyConfig) {
      if (cb) cb({ success: true, data: response.createProductFamilyConfig });
      toast.success(i18n.t('common:apiMessage.createProductFamilySuccessfully'));
    }
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  }
}

// Item Services
export function* searchItemServicesSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const { page, size, filter, sort } = payload;
    const response = yield call(queryRequest, searchItemServices({ page, size, filter, sort }));
    if (response.searchItemServices) {
      yield put(actions.searchItemServicesSuccess(response.searchItemServices));
      if (cb) yield cb({ success: true, data: response.searchItemServices });
    } else {
      yield put(actions.searchItemServicesSuccess([]));
      if (cb) yield cb({ success: true, data: [] });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.searchItemServicesSuccess([]));
    if (cb) yield cb({ success: false });
  }
}

// Item Services
export function* searchAllItemServicesSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const { page, size, filter, sort } = payload;
    const response = yield call(queryRequest, searchItemServicesExport({ page, size, filter, sort }));
    if (response.searchItemServices) {
      yield put(actions.searchAllItemServicesSuccess(response.searchItemServices));
      if (cb) yield cb({ success: true, data: response.searchItemServices });
    } else {
      yield put(actions.searchAllItemServicesSuccess([]));
      if (cb) yield cb({ success: true, data: [] });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.searchAllItemServicesSuccess([]));
    if (cb) yield cb({ success: false });
  }
}

// Item Services
export function* getItemServiceSelectSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const { page, size, filter, sort } = payload;
    const response = yield call(queryRequest, searchItemServices({ page, size, filter, sort }));
    if (response.searchItemServices) {
      if (cb) yield cb({ success: true, data: response.searchItemServices });
    } else if (cb) yield cb({ success: true, data: [] });
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    if (cb) yield cb({ success: false });
  }
}

export function* createItemServicesSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const dataCreate = payload;
    const response = yield call(mutationRequest, createItemServices(dataCreate));
    yield put(actions.createPriceOfferSuccess());
    if (response.createItemServices && response.createItemServices.id) {
      toast.success(i18n.t('common:apiMessage.createItemServiceSuccessfully'));
      if (cb) yield cb({ success: true, data: response.createItemServices.id });
    } else {
      yield cb({ success: true, data: null });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    if (cb) cb({ success: false });
  }
}

export function* modifyItemServicesSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, modifyItemServices(payload));
    if (response.modifyItemServices) {
      if (cb) yield cb({ success: true, data: response.modifyItemServices });
      toast.success(i18n.t('common:apiMessage.modifyItemServiceSuccessfully'));
    } else if (cb) cb({ success: false, data: null });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: null });
    yield put(isEndConnected());
  }
}

export function* getItemServiceProcessStatsSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getItemServiceProcessStats(payload));
    if (response.getItemServiceProcessStats) {
      if (cb) yield cb({ success: true, data: response.getItemServiceProcessStats });
    } else if (cb) cb({ success: false, data: null });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: null });
    yield put(isEndConnected());
  }
}

export function* getItemServiceFileUploadErrorsSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getItemServiceFileUploadErrors(payload));
    if (response.getItemServiceFileUploadErrors) {
      if (cb) yield cb({ success: true, data: response.getItemServiceFileUploadErrors });
    } else if (cb) cb({ success: false, data: null });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: null });
    yield put(isEndConnected());
  }
}

export function* searchDiscountTriggersSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const { page, size, filter, sort } = payload;
    const response = yield call(queryRequest, searchDiscountTriggers({ page, size, filter, sort }));
    if (response.searchDiscountTriggers) {
      yield put(actions.searchDiscountTriggersSuccess(response.searchDiscountTriggers));
    } else {
      yield put(actions.searchDiscountTriggersSuccess([]));
    }
    yield put(isEndConnected());
    if (cb) yield cb({ success: true });
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.searchDiscountTriggersSuccess([]));
    if (cb) yield cb({ success: false });
  }
}

export function* getDiscountTriggerByNameSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getDiscountTriggerByName(payload));
    if (response.getDiscountTriggerByName) {
      if (cb) yield cb({ success: true, data: response.getDiscountTriggerByName });
    } else if (cb) yield cb({ success: false, data: null });
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    if (cb) yield cb({ success: false });
  }
}

export function* modifyDiscountTriggerSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, modifyDiscountTrigger(payload));
    if (response.modifyDiscountTrigger) {
      if (cb) cb({ success: true, data: response.modifyDiscountTrigger });
      toast.success(i18n.t('common:apiMessage.modifyDiscountTriggeruccessfully'));
    } else if (cb) cb({ success: false, data: null });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: null });
    yield put(isEndConnected());
  }
}

export function* createDiscountTriggerSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, createDiscountTrigger(payload));
    if (response.createDiscountTrigger) {
      if (cb) cb({ success: true, data: response.createDiscountTrigger });
      yield put(
        push(RouteNames.prettifyPath(RouteNames.discountTriggerEdit.path, response.createDiscountTrigger.name))
      );

      toast.success(i18n.t('common:apiMessage.createDiscountTriggeruccessfully'));
    } else if (cb) cb({ success: false, data: null });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: null });
    yield put(isEndConnected());
  }
}

export function* getPriceOfferAuditSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getPriceOfferAudit(payload));
    if (response.getPriceOfferAudit) {
      if (cb) yield cb({ success: true, data: response.getPriceOfferAudit });
    } else if (cb) yield cb({ success: false, data: null });
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    if (cb) yield cb({ success: false });
  }
}

export function* getDiscountOfferAuditSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getDiscountOfferAudit(payload));
    if (response.getDiscountOfferAudit) {
      if (cb) yield cb({ success: true, data: response.getDiscountOfferAudit });
    } else if (cb) yield cb({ success: false, data: null });
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    if (cb) yield cb({ success: false });
  }
}

export function* convertJsonToPriceOfferSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, convertJsonToPriceOffer(payload));
    if (response.convertJsonToPriceOffer) {
      if (cb) yield cb({ success: true, data: response.convertJsonToPriceOffer });
    } else if (cb) yield cb({ success: false, data: null });
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    if (cb) yield cb({ success: false });
  }
}

export function* convertJsonToDiscountOfferSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, convertJsonToDiscountOffer(payload));
    if (response.convertJsonToDiscountOffer) {
      if (cb) yield cb({ success: true, data: response.convertJsonToDiscountOffer });
    } else if (cb) yield cb({ success: false, data: null });
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    if (cb) yield cb({ success: false });
  }
}

export function* searchProductFamilyConfigSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const { page, size, filter, sort } = payload;
    const response = yield call(queryRequest, searchProductFamilyConfig({ page, size, filter, sort }));
    if (response.searchProductFamilyConfig) {
      yield put(actions.searchProductFamilyConfigSuccess(response.searchProductFamilyConfig));
      if (cb) yield cb({ success: true, data: response.searchProductFamilyConfig });
    } else {
      if (cb) yield cb({ success: false, data: null });
      yield put(actions.searchProductFamilyConfigSuccess(null));
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.searchProductFamilyConfigSuccess(null));
    if (cb) yield cb({ success: false });
  }
}

export default function* pricingSaga() {
  yield takeLatest(types.GET_CHART_OF_ACCOUNT, getChartOfAccountSaga);
  yield takeLatest(types.SEARCH_ITEMS, searchListItems);
  yield takeLatest(types.GET_ITEM_DETAIL, getItemSaga);
  yield takeLatest(types.CREATE_ITEM, createItemSaga);
  yield takeLatest(types.MODIFY_ITEM, modifyItemSaga);
  yield takeLatest(types.UPDATE_ITEM_STATUS, updateItemStatusSaga);
  yield takeLatest(types.SEARCH_PRICE_OFFERS, searchListPriceOffers);
  yield takeLatest(types.GET_PRICE_OFFER_DETAIL, getPriceOfferSaga);
  yield takeLatest(types.CREATE_PRICE_OFFER, createPriceOfferSaga);
  yield takeLatest(types.MODIFY_PRICE_OFFER, modifyPriceOfferSaga);
  yield takeLatest(types.UPDATE_PRICE_OFFER_STATUS, updatePriceOfferStatusSaga);
  yield takeLatest(types.GET_USAGE_CONTAINER_SPEC, getUsageContainerSpecSaga);
  yield takeLatest(types.SEARCH_DISCOUNT_OFFERS, searchListDiscountOffersSaga);
  yield takeLatest(types.CREATE_DISCOUNT_OFFER, createDiscountOfferSaga);
  yield takeLatest(types.GET_DISCOUNT_OFFER_DETAIL, getDiscountOfferSaga);
  yield takeLatest(types.MODIFY_DISCOUNT_OFFER, modifyDiscountOfferSaga);
  yield takeLatest(types.UPDATE_DISCOUNT_OFFER_STATUS, updateDiscountOfferStatusSaga);
  yield takeLatest(types.GET_PRODUCT_FAMILY_CONFIG, getProductFamilyConfigSaga);
  yield takeLatest(types.MODIFY_PRODUCT_FAMILY_CONFIG, modifyProductFamilyConfigSaga);
  yield takeLatest(types.CREATE_PRODUCT_FAMILY_CONFIG, createProductFamilyConfigSaga);
  yield takeLatest(types.SEARCH_ITEM_SERVICE, searchItemServicesSaga);
  yield takeLatest(types.MODIFY_ITEM_SERVICE, modifyItemServicesSaga);
  yield takeLatest(types.CREATE_ITEM_SERVICE, createItemServicesSaga);
  yield takeLatest(types.SEARCH_ITEM_SERVICE_SELECT, getItemServiceSelectSaga);
  yield takeLatest(types.SEARCH_ALL_ITEM_SERVICE, searchAllItemServicesSaga);
  yield takeLatest(types.GET_ITEM_SERVICE_PROCESS_STATS, getItemServiceProcessStatsSaga);
  yield takeLatest(types.GET_IETM_SERVICE_FILE_UPLOAD_ERRORS, getItemServiceFileUploadErrorsSaga);
  yield takeLatest(types.SEARCH_DISCOUNT_TRIGGER, searchDiscountTriggersSaga);
  yield takeLatest(types.GET_DISCOUNT_TRIGGER_BY_NAME, getDiscountTriggerByNameSaga);
  yield takeLatest(types.CREATE_DISCOUNT_TRIGGER, createDiscountTriggerSaga);
  yield takeLatest(types.MODIFY_DISCOUNT_TRIGGER, modifyDiscountTriggerSaga);
  yield takeLatest(types.GET_PRICE_OFFER_AUDIT, getPriceOfferAuditSaga);
  yield takeLatest(types.GET_DISCOUNT_OFFER_AUDIT, getDiscountOfferAuditSaga);
  yield takeLatest(types.CONVERT_JSON_TO_PRICE_OFFER, convertJsonToPriceOfferSaga);
  yield takeLatest(types.CONVERT_JSON_TO_DISCOUNT_OFFER, convertJsonToDiscountOfferSaga);
  yield takeLatest(types.SEARCH_PRODUCT_FAMILY_CONFIG, searchProductFamilyConfigSaga);
}
