import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import SearchComponent from '../../PricingHub/SearchComponent';

class UsagePrepaidConsumptionSearchForm extends SearchComponent {
  searchInputs = [
    {
      label: 'common:label.periodStart',
      name: 'startPeriod',
      type: 'monthYear',
      isClearable: true,
      required: true,
      defaultValue: moment()
        .startOf('month')
        .format('YYYY-MM'),
      dateFormat: 'YYYY-MM',
    },
    {
      label: 'common:label.periodEnd',
      name: 'endPeriod',
      type: 'monthYear',
      isClearable: true,
      dateFormat: 'YYYY-MM',
    },
    {
      label: 'label.contractAccountId',
      name: 'accountId',
    },
  ];

  btnSpan = 'col-md-3';
}

UsagePrepaidConsumptionSearchForm.propTypes = {
  onSubmit: PropTypes.func,
  isSubmitting: PropTypes.bool,
};

UsagePrepaidConsumptionSearchForm.defaultProps = {
  isSubmitting: false,
};

export default withTranslation('common')(UsagePrepaidConsumptionSearchForm);
