export default {
  sidebar: {
    report: 'Reporte',
    config: 'Configuración',
    styleSheet: 'Hoja-de-Estilo',
    templates: 'Plantillas',
    template: 'Plantilla',
    Reports: 'Reportes',
    customerReports: 'Reportes De Clientes',
    newCustomer: 'Nuevo Cliente',
    accountClosure: 'Cierre De Cuenta',
    subscriptionReports: 'Reportes de suscripción',
    subscriptionExpiry: 'Vencimiento De La Suscripción',
    trialExpiry: 'Expiración De Prueba',
    trialSubscription: 'Suscripción de Prueba',
    orderReports: 'Reportes de Ordenes',
    order: 'Ordene',
    billingReports: 'Reportes De Facturación',
    // billingReports: 'Billing Reports',
    billing: 'Facturación',
    productSummary: 'Resumen De Productos',
    invoicing: 'Emisión Facturas',
    transactionSummary: 'Resumen De Transacciones',
    paymentReports: 'Reportes de Pagos',
    payment: 'Pago',
    failedPayments: 'Pagos Fallidos',
    paymentSuspense: 'Suspensión De Pago',
    collectionReports: 'Reportes de Cobranza',
    '030days': '0-30 Días',
    '3060days': '30-60 Días',
    '6090days': '60-90 Días',
    over90days: '> 90 Días',
    ageingSummary: 'Resumen de Antigüedad',
    AROpsReports: 'Reportes de Operaciones CxC',
    AROpsDetails: 'Detalles de Operaciones CxC',
    openDispute: 'Abrir Disputa',
    openWriteOff: 'Abrir Cancelación',
    revenueReports: 'Reportes de Ingresos',
    revenueSummary: 'Resumen De Ingresos',
    jobReports: 'Reportes De Trabajo',
    scheduleSummary: 'Resumen del Calendario',
    jobFailureActivity: 'Actividad de Trabajo Fallido',
    reconciliationReports: 'Reportes De Conciliación',
    unbilledTransactions: 'Transacciones No Facturadas',
    billingInvoicing: 'Facturación',
    billingAR: 'Facturación - CxC',
    usageSummary: 'Resumen de Uso',
    usageDetail: 'Detalles de Uso',
    usageReconciliationSummary: 'Resumen de Conciliación de Uso',
    jobsDetailReport: 'Detalles de los Trabajo',
    usage: 'Reporte de Uso',
    revenueRecognition: 'Reconocimiento de Ingresos',
    activeSubscriptions: 'Suscripciones Activas',
    taxSummary: 'Resumen de Impuestos',
    taxDetail: 'Detalles de Impuestos',
    subscriptionContractExpiry: 'Vencimiento del Contrato de Suscripción',
    trialToPaidSubscription: 'Suscripción de Prueba para Pago',
    revenueForecast: 'Pronóstico de ingresos',
    MRRForecast: 'Ingreso Mensual Recurrente',
    voiceCustomerCallTypeSummary: 'Resumen de Tipo de Llamadas del Cliente de Voz',
    voiceOperatorCallTypeSummary: 'Resumen de Tipo de Llamadas del Operador de Voz',
    voiceExcessTrafficReport: 'Reporte de Exceso de Tráfico de Voz',
    voiceTrafficByLdDistination: 'Destino LDI de Voz',
    onDemand: 'Cliente de Datos',
    newSmsReportData: 'Cliente de SMS',
    interconnectVoiceReport: 'Voz ITX Sin Procesar',
    interconnectSmsReport: 'Operador de SMS',
    creditNotes: 'Notas de Crédito',
    voiceCallTypeSummaryReport: 'Reporte Resumido de Tipo de Llamada de Voz',
    internetOperatorDataCost: 'Costo del Operador de Datos',
    telcoSummary: 'Resumen de Telco',
    accountReport: 'Cuentas',
    // rawFiles: 'Archivos Crudos',
    rawFiles: 'Maestro de Archivos sin Procesar',
    subscriptionBillableServices: 'Servicios Facturables',
    accountingReconciliationReport: 'Conciliación Contable',
    rawCdrData: 'Archivo de Datos Sin Procesar',
    rawSMSFiles: 'Archivo de SMS Sin Procesar',
    rawNonBroadsoft: 'Crudos No Broadsoft',
    servicesForcecast: 'Forcecast de Servicios (Pronóstico)',
    timeAndExpensePL: 'Tiempo y Gastos P&G',
    billableServicesForecast: 'Pronóstico de Servicios Facturables',
    objectFilesSummary: 'Resumen de Archivos de Objeto',
    provisioningData: 'Datos de Aprovisionamiento',
    timeAndExpense: 'Tiempo y Gastos',
    billingTracker: 'Seguimiento de Facturación',
    timeAndExpenseYearlyPL: 'P&G anual de Tiempo y Gastos',
    timeAndExpenseYearly: 'Tiempo y Gasto Anual',
    commitmentData: 'Datos del Compromiso',
    ageingInvoice: 'Antigüedad de Factura',
    ageingPayment: 'Antigüedad de Pago',
    CDRReconciliationSummary: 'Resumen de Conciliación de CDRs',
    extracts: 'Extractos',
    commissionsCancellations: 'Cancelaciones de Comisiones',
    KPI: 'KPI',
    rawBroadsoft: 'Registros Crudos Broadsoft',
    rawNonBroadsoftZeroDurationCDR: 'CDR Crudo No-BroadSoft con Duración Cero',
    rawBroadsoftZeroDurationCDR: 'CDRs Crudos de Broadsoft con Duración Cero',
    suspendedFailedRecords: 'Registros Suspendidos Fallidos',
    CDRReports: 'Reportes de CDRs',
    extractBI: 'Extraer BI',
    metaswitch: 'Metaswitch',
    metaswitchZero: 'Metaswitch Zero',
    regulatoryReports: 'Reportes Regulatorios',
    R00105: 'R001-05',
    R00106: 'R001-06',
    R00401: 'R004-01',
    R00405: 'R004-05',
    R00501: 'R005-01',
    R00502: 'R005-02',
    R00503: 'R005-03',
    R00504: 'R005-04',
    R00505: 'R005-05',
    R00506: 'R005-06',
    creditBureau: 'Buró de Crédito',
    taxExemption: 'Exenciones Fiscales',
    fonatelReport: 'Reporte Fonatel',
    PHCReport: 'Reporte PHC',
    contractServiceReconciliation: 'Conciliación de Servicio de Contrato',
    folioCount: 'Número de Folios',
    prepaidConsumption: 'Consumo Prepago',
    prepaidAverageConsumption: 'Consumo Histórico Promedio Prepago',
    energyTopup: 'Reporte de Recargas',
    topUp: 'Top Up',
    orderDetail: 'Order Detail',
    serviceInvoiceSummary: 'Service Invoice Summary',
    itemSummary: 'Item Summary',
  },
};
