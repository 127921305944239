import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import Tooltip from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap_white.css';
import { validateNewLine } from '../../../utils/utils';
import './styles.scss';

const TextInput = ({ type, onChange, value, className, ...props }) => {
  const { t } = useTranslation('common');
  // const defaultValue = value || '';
  const [isValidNewLine, setIsValidNewLine] = useState(false);
  if (type === 'textarea') {
    return (
      <textarea
        value={value || ''}
        className={`${className || ''} ${isValidNewLine && props.isNoEnter ? 'valid-new-line' : ''}`}
        onChange={evt => {
          if (props.isNoEnter && evt?.target?.value && validateNewLine(evt?.target?.value)) {
            setIsValidNewLine(true);
            if (props.message) toast.error(t(props.message));
            return '';
          }
          setIsValidNewLine(false);
          return onChange(evt);
        }}
        {...props}
      />
    );
  }
  return <input type={type} value={value || ''} onChange={onChange} {...props} className={className} />;
};

TextInput.propTypes = {
  type: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  isNoEnter: PropTypes.bool,
  message: PropTypes.string,
  className: PropTypes.string,
};

TextInput.defaultProps = {
  type: 'text',
  value: '',
  onChange: () => {},
  isNoEnter: false,
  message: 'text',
  className: '',
};

const InputGroup = ({
  label,
  wrapperClass,
  fa,
  isPopupInfo,
  labelPopupInfo,
  onOpenPopupInfo,
  isSupportTooltip,
  subLabel,
  tooltipDetails,
  rightLabel,
  isBtnRight,
  onClickRightButton,
  labelRightButton,
  isDisableRightButton,
  ...props
}) => {
  const { t } = useTranslation('common');
  const { required, name, value } = props;
  return (
    <div className={`form-group ${wrapperClass}`}>
      {label && (
        <span>
          {t(label)}
          {label && required && <i className="fa fa-star is-required" />}
          {subLabel && <span className="sub-label">{subLabel}</span>}
          &nbsp;
          {tooltipDetails && (
            <Tooltip
              trigger={['hover']}
              placement="bottom"
              overlay={tooltipDetails ? t(tooltipDetails) : ''}
              arrowContent={<div className="rc-tooltip-arrow-inner" />}
              overlayClassName="tooltip-details"
            >
              <i className="ti-info-alt ico-info-tooltip" aria-hidden="true" />
            </Tooltip>
          )}
        </span>
      )}
      <div className={`wr-input ${isPopupInfo || isBtnRight ? 'd-flex' : ''}`} id={`${name}`}>
        {isSupportTooltip && value ? (
          <Tooltip placement="bottom" overlay={value || ''} arrowContent={<div className="rc-tooltip-arrow-inner" />}>
            <TextInput className="form-control input-group" {...props} />
          </Tooltip>
        ) : (
          <TextInput className={`form-control input-group ${fa ? 'pr-4' : ''}`} {...props} />
        )}
        {fa && (
          <div className="wr-input-icon">
            <i className={`fa ${fa}`} />
          </div>
        )}
        {isPopupInfo && (
          <button type="button" className="button x-small btn-popup" onClick={onOpenPopupInfo}>
            {t(labelPopupInfo)}
          </button>
        )}
        {isBtnRight && (
          <button
            type="button"
            className="button x-small btn-popup"
            disabled={isDisableRightButton}
            onClick={onClickRightButton}
          >
            {t(labelRightButton)}
          </button>
        )}

        {rightLabel && <span>{t(rightLabel)}</span>}
      </div>
    </div>
  );
};

InputGroup.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string,
  wrapperClass: PropTypes.string,
  onChange: PropTypes.func,
  onOpenPopupInfo: PropTypes.func,
  required: PropTypes.bool,
  isNoEnter: PropTypes.bool,
  name: PropTypes.string,
  value: PropTypes.string,
  fa: PropTypes.string,
  isPopupInfo: PropTypes.bool,
  labelPopupInfo: PropTypes.string,
  isSupportTooltip: PropTypes.bool,
  subLabel: PropTypes.string,
  tooltipDetails: PropTypes.string,
  rightLabel: PropTypes.string,
  isBtnRight: PropTypes.bool,
  onClickRightButton: PropTypes.func,
  labelRightButton: PropTypes.string,
  isDisableRightButton: PropTypes.bool,
};

InputGroup.defaultProps = {
  label: '',
  onChange: () => {},
  onOpenPopupInfo: () => {},
  type: 'text',
  wrapperClass: 'col-md-3',
  required: false,
  isNoEnter: false,
  name: '',
  value: '',
  fa: '',
  isPopupInfo: false,
  labelPopupInfo: '',
  isSupportTooltip: false,
  subLabel: '',
  tooltipDetails: '',
  rightLabel: '',
  isBtnRight: false,
  onClickRightButton: () => {},
  labelRightButton: '',
  isDisableRightButton: false,
};

export default InputGroup;
