import gql from 'graphql-tag';

export default ({ date, scheduleFrequency, scheduleName }) => {
  const getJobScheduleByDateAndFrequencyAndName = `getJobScheduleByDateAndFrequencyAndName(input: {
    scheduleDate: "${date}"
    scheduleFrequency: ${scheduleFrequency}
    scheduleName: "${scheduleName}"
  })`;
  return gql`
    query {
      ${getJobScheduleByDateAndFrequencyAndName} {
        id
        status
        scheduleDate
        userId
        resetAllowed
        scheduleName
        scheduleFrequency
        jobScheduleList {
          index
          jobId
          predJobId
          nextJobId
          name
          notes
          userId
          scheduleTime
          type
          scheduleType
          status
          count
          countSuccess
          countError
        }       
      }
    }
  `;
};
