import { cloneDeep } from 'lodash';
import pdfConfig from './helper';
import { getLabelOptions, formatMoneyValueToExport } from '../utils';
import { convertBoolLabel } from '../exportHelper';

const HeaderTable = ({ t, columnsTable }) => columnsTable.map(val => t(val.label));

const convertJson2Pdf = ({ data, t, title, fileName, columnsTable, ...rest }) => {
  const Header = cloneDeep(HeaderTable({ t, columnsTable }));
  const monthYearT = t('selections:monthOfYear')();
  const newListData = data.map(item => {
    const newItem = [];
    if (typeof item === 'object') {
      columnsTable.forEach(val => {
        if (val.name === 'accountingPeriod') {
          if (item.accountingPeriod) {
            let newItemSelect = item.accountingPeriod;
            const itemTranslation = monthYearT.find(it => newItemSelect.indexOf(it.value) > -1);
            if (itemTranslation) {
              newItemSelect = `${itemTranslation.label}${newItemSelect.substring(3, newItemSelect.length)}`;
            }
            newItem.push({ content: newItemSelect || '' });
          } else {
            newItem.push({ content: item.accountingPeriod ? item.accountingPeriod : '' });
          }
        }
        if (val.name !== 'accountingPeriod') {
          if ((item[val.name] || item[val.name] === 0) && typeof item[val.name] === 'number') {
            newItem.push({ content: formatMoneyValueToExport(item[val.name]) });
          } else if (typeof item[val.name] === 'boolean') {
            newItem.push({ content: convertBoolLabel({ t, value: item[val.name] }) });
          } else if (val.isRenderT) {
            newItem.push({
              content: getLabelOptions({
                item,
                t,
                fieldOption: val.fieldOption || null,
                fieldName: val.name,
              }),
            });
          } else {
            newItem.push({ content: item[val.name] ? item[val.name] : '' });
          }
        }
      });
    }
    return newItem;
  });
  return pdfConfig({ data: newListData, Header, title, fontSize: 2, cellPadding: 1, fileName, ...rest });
};

export default convertJson2Pdf;
