import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Tooltip from 'rc-tooltip';
import isStringANumber from 'is-string-a-number';
import 'rc-tooltip/assets/bootstrap_white.css';
import './styles.scss';

const numberWithCommas = value => {
  let decimalValue = null;
  let newValue = value;
  if (value) {
    const indexOfDecimal = `${value}`.indexOf('.');
    if (indexOfDecimal !== -1) {
      decimalValue = `${value}`.slice(indexOfDecimal + 1, `${value}`.length);
      newValue = `${value}`.slice(0, indexOfDecimal);
      return `${newValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}.${decimalValue}`;
    }
  }
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

const TextInput = ({ type, onChange, value, onBlur, isNegativeValue, ...props }) => {
  const onChangeNumber = evt => {
    const newEvt = evt;
    newEvt.target.value = newEvt.target.value.replace(/,/g, '');
    if (newEvt.target.value === '' || newEvt.target.value === null) return onChange(newEvt);
    if (newEvt.target.value) {
      const indexOfDecimal = `${newEvt.target.value}`.indexOf('.');
      const decimalValue = `${newEvt.target.value}`.slice(indexOfDecimal + 1, `${newEvt.target.value}`.length);
      if (!decimalValue) {
        return onChange(newEvt);
      }
    }
    if (
      !isStringANumber(newEvt.target.value) &&
      `${newEvt.target.value}`.indexOf('-') === 0 &&
      `${newEvt.target.value}`.length === 1 &&
      isNegativeValue
    ) {
      return onChange(newEvt);
    }

    if (!isStringANumber(newEvt.target.value) && `${Number(newEvt.target.value)}` === 'NaN') return '';
    return onChange(newEvt);
  };

  const onBlurInput = evt => {
    const newEvt = evt;
    if (`${Number(newEvt.target.value)}` !== 'NaN' && `${newEvt.target.value}`.length) {
      newEvt.target.value = `${Number(newEvt.target.value)}`;
      onChange(newEvt);
    }
    if (!isStringANumber(evt.target.value) && `${Number(Number.parseFloat(newEvt.target.value || 0))}` === 'NaN') {
      newEvt.target.value = null;
      onChange(newEvt);
    }
    if (onBlur) onBlur();
  };

  return (
    <input
      type="text"
      value={type === 'number' ? numberWithCommas(value) : value}
      onChange={type === 'number' ? onChangeNumber : onChange}
      onBlur={isNegativeValue ? onBlurInput : onBlur}
      {...props}
    />
  );
};

TextInput.propTypes = {
  type: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

TextInput.defaultProps = {
  type: 'text',
  value: '',
  onChange: () => {},
};

const NumberInputGroupHelper = ({
  label,
  wrapperClass,
  fa,
  isPopupInfo,
  labelPopupInfo,
  onOpenPopupInfo,
  isSupportTooltip,
  subLabel,
  tooltipDetails,
  isNoRenderCtrolButton,
  rightLabel,
  ...props
}) => {
  const { t } = useTranslation('common');
  return (
    <div className={`form-group ${wrapperClass}`}>
      {label && (
        <span className="left-label">
          {t(label)}
          {label && props.required && <i className="fa fa-star is-required" />}
          {subLabel && <span className="sub-label">{subLabel}</span>}
          &nbsp;
          {tooltipDetails && (
            <Tooltip
              trigger={['hover']}
              placement="bottom"
              overlay={tooltipDetails || ''}
              arrowContent={<div className="rc-tooltip-arrow-inner" />}
            >
              <i className="fa fa-question-circle-o ico-question-embrix" aria-hidden="true" />
            </Tooltip>
          )}
        </span>
      )}
      <div className="wr-input d-flex number-form" id={`${props.name}`}>
        {!isNoRenderCtrolButton && (
          <button
            type="button"
            className="minus-number btn-control"
            disabled={props.disabled || props.readOnly}
            onClick={() => {
              props.onChange({ target: { name: props.name, value: `${Number.parseFloat(props?.value || 0) - 1}` } });
            }}
          >
            <i className="ti-minus" />
          </button>
        )}
        <TextInput className="form-control input-group  form-control-number input-group-number" {...props} />
        {!isNoRenderCtrolButton && (
          <button
            type="button"
            className="plus-number btn-control"
            disabled={props.disabled || props.readOnly}
            onClick={() => {
              props.onChange({ target: { name: props.name, value: `${Number.parseFloat(props?.value || 0) + 1}` } });
            }}
          >
            <i className="ti-plus" />
          </button>
        )}
        {fa && (
          <div className="wr-input-icon">
            <i className={`fa ${fa}`} />
          </div>
        )}
        {isPopupInfo && (
          <button type="button" className="button x-small btn-popup" onClick={onOpenPopupInfo}>
            {t(labelPopupInfo)}
          </button>
        )}
        {rightLabel && <span className="right-label">{t(rightLabel)}</span>}
      </div>
    </div>
  );
};

NumberInputGroupHelper.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string,
  wrapperClass: PropTypes.string,
  onChange: PropTypes.func,
  onOpenPopupInfo: PropTypes.func,
  required: PropTypes.bool,
};

NumberInputGroupHelper.defaultProps = {
  label: '',
  onChange: () => {},
  onOpenPopupInfo: () => {},
  type: 'text',
  wrapperClass: 'col-md-3',
  required: false,
};

export default NumberInputGroupHelper;
