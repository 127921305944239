import gql from 'graphql-tag';
import { parseToMutationRequest } from 'utils/utils';

export default data => {
  const dataCreate = parseToMutationRequest(data, [
    'type',
    'index',
    'status',
    'templateType',
    'messageType',
    'accountCategory',
  ]);
  const createConfigCorrespondence = `createConfigCorrespondence(input: ${dataCreate})`;
  return gql`
  mutation {
    ${createConfigCorrespondence} {
      id
    }
  }
`;
};
