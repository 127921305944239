import gql from 'graphql-tag';
import { parseToMutationRequest } from '../../utils/utils';

export default data => {
  const dataFilter = parseToMutationRequest(data, ['type', 'scheduleFrequency']);
  const getJobErrors = `getJobErrors( input: ${dataFilter} )`;
  return gql`
    query {
      ${getJobErrors} {
        index
        jobId
        name
        userId
        type
        status
        countError
        jobErrorGroupList {
          reason
          count
        }
      }
    }
  `;
};
