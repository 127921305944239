import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import SearchComponent from '../../PricingHub/SearchComponent';

class ServiceInvoiceSummarySearchForm extends SearchComponent {
  searchInputs = [
    {
      label: 'label.accountId',
      name: 'accountId',
    },
    {
      label: 'label.startDate',
      name: 'startDate',
      type: 'date',
      defaultValue: moment()
        .startOf('month')
        .format('YYYY-MM-DD'),
      required: true,
    },
    {
      label: 'label.endDate',
      name: 'endDate',
      type: 'date',
      defaultValue: moment()
        .endOf('month')
        .format('YYYY-MM-DD'),
      required: true,
    },
    {
      label: 'label.billType',
      name: 'billType',
      tOptions: 'selections:billUnitType',
      isClearable: true,
      type: 'select',
    },
    {
      label: 'label.accountType',
      name: 'accountType',
      type: 'select',
      tOptions: 'selections:accountType',
      isClearable: true,
    },
    {
      label: 'label.status',
      name: 'status',
      tOptions: 'selections:invoiceUnitStatus',
      isClearable: true,
      type: 'select',
    },
  ];

  btnSpan = 'col-md-6';
}

ServiceInvoiceSummarySearchForm.propTypes = {
  onSubmit: PropTypes.func,
  isSubmitting: PropTypes.bool,
};

ServiceInvoiceSummarySearchForm.defaultProps = {
  isSubmitting: false,
};

export default withTranslation('common')(ServiceInvoiceSummarySearchForm);
