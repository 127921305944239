import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty, cloneDeep } from 'lodash';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { withTranslation } from 'react-i18next';
// import { NewPaymentGateway } from '../../../components/TenantHub';
import { getLastIndex, validate } from '../../../utils/utils';
import { GenericInput, FormCollapse } from '../../../components/common';
import { makeTenantMerchantDataConfig, makeTenantProfileConfig, makeGetTenantDataGateway } from '../selectors';
import { getTenantMerchantDataConfig } from '../actions';
import dataField from '../constant';
import {
  JsonWebTokenAttributeForm,
  OauthAttributesForm,
  HttpBasicAttributesForm,
  CustomerAttribute,
  GatewayAttributesForm,
  RequestResponseMapping,
  URLMappingPaymentGeteway,
  APIKeyAttributesForm,
} from '../TenantDetail/SubForm';

let isFirstCall = false;

const buttonsList = [
  {
    name: 'merchantData',
    label: 'tenantPage:tenantManagementPage.merchantData',
  },
  {
    name: 'customRecords',
    label: 'tenantPage:tenantManagementPage.customRecords',
  },
  {
    name: 'urlMapping',
    label: 'tenantPage:tenantManagementPage.urlMapping',
  },
  {
    name: 'apiMapping',
    label: 'label.apiMapping',
  },
];

class GatewayNewForm extends React.PureComponent {
  state = {
    tenantData: [],
    idState: null,
    activeTab: {},
    wasValidated: false,
  };

  formRef = React.createRef();

  componentDidMount() {
    const { profileData, tenantDataGateway } = this.props;
    let defaultData = null;
    if (profileData && profileData.tenantMerchantAccounts) defaultData = profileData;
    if (tenantDataGateway && tenantDataGateway.tenantMerchantAccounts) defaultData = tenantDataGateway;
    this.doGetDefaultData({
      defaultData,
    });
    // this.setState({
    //   tenantData: data && data?.tenantMerchantAccounts?.length ? data?.tenantMerchantAccounts : [{}, {}, {}, {}, {}],
    //   idState: data?.id || null,
    // });
  }

  doGetDefaultData = ({ defaultData }) => {
    const { getGatewayData, type } = this.props;
    const { activeTab } = this.state;
    if (defaultData) {
      return this.setState(
        {
          tenantData:
            defaultData && defaultData?.tenantMerchantAccounts?.length ? defaultData?.tenantMerchantAccounts : [],
          idState: defaultData?.id || null,
        },
        () => {
          const itemSelect =
            defaultData?.tenantMerchantAccounts && defaultData?.tenantMerchantAccounts?.length
              ? defaultData.tenantMerchantAccounts.find(val => val.type === type)
              : null;
          if (itemSelect && itemSelect.name && !activeTab.isActive) {
            const indexSelect =
              defaultData?.tenantMerchantAccounts && defaultData?.tenantMerchantAccounts?.length
                ? defaultData.tenantMerchantAccounts.findIndex(val => val.type === type)
                : -1;
            this.onToggleTab({
              name: itemSelect.index,
              merchantName: itemSelect.name,
              index: indexSelect,
              isSupportFitstCall: true,
            });
          }
        }
      );
    }
    if (getGatewayData) {
      return getGatewayData('', ({ success, data }) => {
        if (success) {
          this.setState(
            {
              tenantData: data && data?.tenantMerchantAccounts?.length ? data?.tenantMerchantAccounts : [],
              idState: data?.id || null,
            },
            () => {
              const itemSelect =
                data && data?.tenantMerchantAccounts?.length
                  ? data && data.tenantMerchantAccounts.find(val => val.type === type)
                  : null;

              if (itemSelect && itemSelect.name && !activeTab.isActive) {
                const indexSelect =
                  data?.tenantMerchantAccounts && data?.tenantMerchantAccounts?.length
                    ? data.tenantMerchantAccounts.findIndex(val => val.type === type)
                    : -1;
                this.onToggleTab({
                  name: itemSelect.index,
                  merchantName: itemSelect.name,
                  index: indexSelect,
                  isSupportFitstCall: true,
                });
              }
            }
          );
        }
      });
    }
  };

  doGetTenantMerchantDataConfig = ({ merchantName, index, isNoNeedGetData }) => {
    const { getTenantConfig, type } = this.props;
    if (getTenantConfig)
      return getTenantConfig({ merchantName, gatewayName: type || null }, ({ success, data }) => {
        if (success) {
          const { tenantData } = this.state;
          const newData = cloneDeep(tenantData);
          if (!isNoNeedGetData)
            newData[index] =
              data && data?.tenantMerchantAccounts?.length
                ? { ...data?.tenantMerchantAccounts[0], btnSelect: 'merchantData' }
                : newData[index];
          this.setState({
            tenantData: newData,
            idState: data?.id || null,
          });
        }
      });
    // return getTenantMerchantDataConfig();
  };

  onCancelData = ({ isNew }) => {
    const { getTenantConfig, type } = this.props;
    if (getTenantConfig)
      return getTenantConfig({ gatewayName: type || null }, ({ success, data }) => {
        if (success) {
          const newData = cloneDeep(data);
          if (newData?.tenantMerchantAccounts && newData?.tenantMerchantAccounts.length) {
            newData.tenantMerchantAccounts = newData?.tenantMerchantAccounts.map(val => ({
              ...val,
              btnSelect: 'merchantData',
            }));

            if (isNew) {
              this.setState({ activeTab: { name: newData?.tenantMerchantAccounts[0].index, isActive: true } });
            }
          }

          this.setState({
            tenantData: newData?.tenantMerchantAccounts ? newData?.tenantMerchantAccounts : [],
            idState: data?.id || null,
          });
        }
      });
    // return getTenantMerchantDataConfig();
  };

  onChange = ({ name, value, index }) => {
    const { onHandleChange, type } = this.props;
    const { tenantData } = this.state;
    const newData = cloneDeep(tenantData);
    if (!newData[index]) newData[index] = { type };
    newData[index][name] = value;
    this.setState({ tenantData: newData });
    // onHandleChange({ name, value, index });
  };

  onChangeAttributes = ({ name, value, index, indexAttr, fieldName = '' }) => {
    try {
      const { tenantData } = this.state;
      // const { onChangeAttributes } = this.props;
      if (!tenantData[index][fieldName]) tenantData[index][fieldName] = [];
      if (!tenantData[index][fieldName][indexAttr]) tenantData[index][fieldName][indexAttr] = {};
      tenantData[index][fieldName][indexAttr][name] = value;
      this.setState({ tenantData });
    } catch (error) {
      console.log(error);
    }
  };

  onSubmit = ({ evt, item, index }) => {
    evt.preventDefault();
    const { modifyTenant, id, type, t } = this.props;
    const { tenantData, idState } = this.state;
    this.setState({ wasValidated: true });
    if (!validate(true, this.formRef, t)) {
      return false;
    }
    this.setState({ wasValidated: false });
    let newData = cloneDeep(tenantData);
    let lastIndex = 0;
    if (newData && newData.length) {
      newData.forEach(val => {
        if (val.index > lastIndex) lastIndex = val.index;
      });
    }
    newData = newData.map(val => {
      const newVal = cloneDeep(val);
      if (!newVal.index) {
        newVal.index = lastIndex;
      }
      return newVal;
    });

    const { btnSelect, isNew, ...rest } = item;
    // const itemSelect = newData.filter(item => item.type === type);
    modifyTenant({ id: id || idState, tenantMerchantAccounts: rest }, ({ success }) => {
      if (success && isNew) this.onToggleTab({ name: item.name, merchantName: item.name, index });
    });
  };

  onToggleTab = ({ name, merchantName, index, isSupportFitstCall }) => {
    const { activeTab } = this.state;
    if (activeTab.name === name) {
      this.setState({
        activeTab: { name, isActive: !activeTab.isActive },
      });
    } else {
      if (merchantName) this.doGetTenantMerchantDataConfig({ merchantName, index });
      if (isSupportFitstCall) {
        if (!isFirstCall) this.doGetTenantMerchantDataConfig({ merchantName, index });
        isFirstCall = true;
      } else if (merchantName) this.doGetTenantMerchantDataConfig({ merchantName, index });
      this.setState({ activeTab: { name, isActive: true } });
    }
  };

  onChangeSelectData = ({ name, index }) => {
    const { tenantData } = this.state;
    const newData = cloneDeep(tenantData);
    newData[index].btnSelect = name;
    this.setState({ tenantData: newData });
  };

  onHandleAddNew = () => {
    const { tenantData } = this.state;
    const { type } = this.props;
    const newData = cloneDeep(tenantData);
    const item = { type, index: getLastIndex({ data: tenantData }), isNew: true };
    newData.push(item);
    this.onToggleTab({
      name: item.index,
      index: newData.length - 1,
      merchantName: item.name,
    });
    this.setState({ tenantData: newData });
  };

  render() {
    const { activeTab, tenantData, wasValidated } = this.state;
    const {
      t,
      type,
      isRequireField,
      modifyTenant,
      getTenantURLMapping,
      gatewayKey,
      tOptionsConfig,
      titleForm,
      title,
      isProvisioningGateway,
      modeModify,
    } = this.props;

    let dataHttpBasicAttributes = [];
    let dataJwtAttributes = [];
    let dataOauthAttributes = [];
    const indexDataHttpBasic = tenantData.findIndex(item => item.type === type && item.index === activeTab.name);
    if (indexDataHttpBasic > -1) dataHttpBasicAttributes = tenantData[indexDataHttpBasic].httpBasicAttributes;
    const indexDataJwtAttributes = tenantData.findIndex(item => item.type === type && item.index === activeTab.name);
    if (indexDataJwtAttributes > -1) dataJwtAttributes = tenantData[indexDataJwtAttributes].jwtAttributes;

    const indexDataOauthAttributes = tenantData.findIndex(item => item.type === type && item.index === activeTab.name);
    if (activeTab && activeTab.isActive) {
      dataOauthAttributes = tenantData[indexDataOauthAttributes].oauthAttributes;
    }

    return (
      <div className="card card-statistics">
        {modeModify === 2 && (
          <div className="mt-3 mb-3 col-md-12 ml-2">
            <button type="submit" className="button button-border ml-2 x-small" onClick={this.onHandleAddNew}>
              +
              {t('label.addMerchants')}
            </button>
          </div>
        )}
        <div className="gateway-form">
          <div className="col-md-12 p-2 card-body">
            {tenantData.map((item, index) => {
              if (item.type !== type) return null;
              return (
                <FormCollapse
                  key={item.index}
                  isActive={activeTab && item.index === activeTab.name && activeTab.isActive}
                  title={item.name || 'null'}
                  state={item.index}
                  onToggleTab={name => this.onToggleTab({ name, merchantName: item.name, index })}
                >
                  <div className="col-md-12 text-center mt-2 mb-4">
                    {buttonsList.map(val => (
                      <button
                        type="button"
                        className={`button button-border ml-2 x-small ${item.btnSelect === val.name ? 'active' : ''}`}
                        onClick={() => this.onChangeSelectData({ name: val.name, index })}
                        disabled={item.isNew}
                      >
                        {t(val.label)}
                      </button>
                    ))}
                  </div>
                  {(item.btnSelect === 'merchantData' || !item.btnSelect) &&
                    activeTab &&
                    item.index === activeTab.name &&
                    activeTab.isActive && (
                      <form
                        onSubmit={evt => (modeModify === 2 ? this.onSubmit({ evt, item, index }) : () => {})}
                        className={`col-md-12 group-flex mt-2 ${index > 0 ? 'geteway-group' : ''} needs-validation ${
                          wasValidated ? 'was-validated' : ''
                        }`}
                        noValidate
                        ref={this.formRef}
                        key={index}
                      >
                        {dataField.fieldGetwayForm.map(field => {
                          if (field && (!isEmpty(field.options) || field.tOptions)) {
                            return (
                              <GenericInput
                                key={field.name}
                                label={field.label || ''}
                                required={isRequireField || field.required}
                                type="select"
                                value={item[field.name] || null}
                                wrapperClass="col-md-4"
                                disabled={field.disabled}
                                onChange={({ name, value }) => this.onChange({ value, name, index, indexCreate: 1 })}
                                name={field.name}
                                {...field}
                              />
                            );
                          }

                          if (field && field.isFieldDate) {
                            return (
                              <GenericInput
                                key={field.name}
                                label={field.label || ''}
                                type="date"
                                value={item[field.name] || null}
                                required={isRequireField || field.required}
                                wrapperClass="col-md-4"
                                onChange={({ name, value }) => this.onChange({ value, name, index, indexCreate: 1 })}
                                name={field.name}
                              />
                            );
                          }

                          return (
                            <GenericInput
                              key={field.name}
                              label={field.label || ''}
                              value={item[field.name] || null}
                              required={isRequireField || field.required}
                              type={field.isNumber ? 'number' : 'text'}
                              onChange={({ name, value }) => this.onChange({ value, name, index })}
                              name={field.name}
                              wrapperClass="col-md-4"
                            />
                          );
                        })}
                        {!isEmpty(tenantData[index]) && item.authType === 'JSON_WEB_TOKEN' && (
                          <JsonWebTokenAttributeForm
                            onHandleChange={this.onChangeAttributes}
                            index={indexDataJwtAttributes}
                            isRequireField={isRequireField}
                            data={dataJwtAttributes || [{}]}
                          />
                        )}

                        {!isEmpty(tenantData[index]) && item.authType === 'OAUTH2' && (
                          <OauthAttributesForm
                            onHandleChange={this.onChangeAttributes}
                            index={indexDataOauthAttributes}
                            isRequireField={isRequireField}
                            data={dataOauthAttributes || [{}]}
                          />
                        )}
                        {!isEmpty(tenantData[index]) && item.authType === 'HTTP_BASIC' && (
                          <HttpBasicAttributesForm
                            onHandleChange={this.onChangeAttributes}
                            index={index}
                            isRequireField={isRequireField}
                            data={item.httpBasicAttributes || [{}]}
                          />
                        )}
                        {!isEmpty(tenantData[index]) && item.authType === 'API_KEY' && (
                          <APIKeyAttributesForm
                            onHandleChange={this.onChangeAttributes}
                            index={index}
                            isRequireField={isRequireField}
                            data={item.apiKeyAttributes || [{}]}
                          />
                        )}

                        <div className="mt-3 mb-3 col-md-12">
                          {modeModify === 2 && (
                            <button type="submit" className="button button-border ml-2 x-small float-right">
                              {t('label.saveConfig')}
                            </button>
                          )}
                          <button
                            type="button"
                            onClick={() =>
                              this.onCancelData({
                                index,
                                merchantName: item.name,
                                isNew: item.isNew,
                              })
                            }
                            className="button button-border black x-small float-right"
                          >
                            {t('label.cancel')}
                          </button>
                        </div>
                      </form>
                    )}
                  {item.btnSelect === 'customRecords' && <CustomerAttribute modeModify={modeModify} />}
                  {item.btnSelect === 'urlMapping' && (
                    <GatewayAttributesForm
                      // data={tenantData.tenantMerchantAccounts}
                      modifyTenant={modifyTenant}
                      getTenantConfig={getTenantURLMapping}
                      // id={id || ''}
                      gatewayKey={gatewayKey}
                      gatewayType={type}
                      tOptionsConfig={tOptionsConfig}
                      titleForm={titleForm}
                      merchantName={item.name || null}
                      isProvisioningGateway={isProvisioningGateway}
                      modeModify={modeModify}
                    />
                  )}
                  {item.btnSelect === 'apiMapping' && (
                    <RequestResponseMapping
                      gatewayName={type}
                      typeConfig="DOCUMENT"
                      title={title}
                      merchantName={item.name || null}
                      modeModify={modeModify}
                    />
                  )}
                </FormCollapse>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}

GatewayNewForm.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  onHandleChange: PropTypes.func,
  onChangeAttributes: PropTypes.func,
  modeSetPaymentGatewayMerchant: PropTypes.number,
  modeSetTaxGatewayMerchant: PropTypes.number,
  modeSetFinanceGatewayMerchant: PropTypes.number,
  modeModifyPaymentGatewayMerchant: PropTypes.number,
  modeModifyTaxGatewayMerchant: PropTypes.number,
  modeModifyFinanceGatewayMerchant: PropTypes.number,
  isRequireField: PropTypes.bool,
};

GatewayNewForm.defaultProps = {
  data: [{}, {}, {}],
  onHandleChange: () => {},
  onChangeAttributes: () => {},
  modeSetPaymentGatewayMerchant: 2,
  modeSetTaxGatewayMerchant: 2,
  modeSetFinanceGatewayMerchant: 2,
  modeModifyPaymentGatewayMerchant: 2,
  modeModifyTaxGatewayMerchant: 2,
  modeModifyFinanceGatewayMerchant: 2,
  isRequireField: false,
};

const mapStateToProps = createStructuredSelector({
  data: makeTenantMerchantDataConfig() || {},
  profileData: makeTenantProfileConfig() || {},
  tenantDataGateway: makeGetTenantDataGateway() || {},
  // paymentMethodUrls: makeGetPaymentMethodUrls() || {},
});

export default withTranslation('common')(connect(mapStateToProps, { getTenantMerchantDataConfig })(GatewayNewForm));
