export const GET_COLLECTION_BY_TYPE = 'app/arhub/getCollectionActionsByType';
export const GET_COLLECTION_ACTIONS_SUCCESS = 'app/arhub/getCollectionActionsSuccess';
export const GET_COLLECTION_BY_TYPE_FAILURE = 'app/arhub/getCollectionActionsByTypeFailure';

export const GET_COLLECTION_SCHEDULE_BY_TYPE = 'app/arhub/getCollectionScheduleByType';

export const GET_COLLECTION_AGENT_BY_TYPE = 'app/arhub/getCollectionAgentByType';

export const SEARCH_COLLECTION_UNITS = 'app/arhub/search_collection_unit';
export const SEARCH_COLLECTION_UNITS_SUCCESS = 'app/arhub/search_collection_unit_success';
export const SEARCH_COLLECTION_UNITS_FAILURE = 'app/arhub/search_collection_unit_failure';

export const SEARCH_ACCOUNTS_COLLECTION = 'app/arhub/search_accounts_collection';
export const SEARCH_ACCOUNTS_COLLECTION_SUCCESS = 'app/arhub/search_accounts_collection_success';
export const SEARCH_ACCOUNTS_COLLECTION_FAILURE = 'app/arhub/search_accounts_collection_failure';
export const SET_PARAMS_ACCOUNTS_COLLECTION = 'app/arhub/set_params_accounts_collection';

export const SEARCH_COLLECTION_HISTORY = 'app/arhub/search_collection_history';
export const SEARCH_COLLECTION_HISTORY_SUCCESS = 'app/arhub/search_collection_history_success';
export const SEARCH_COLLECTION_HISTORY_FAILURE = 'app/arhub/search_collection_history_failure';
export const SET_PARAMS_COLLECTION_HISTORY = 'app/arhub/set_params_collection_history';

export const CREATE_COLLECTION_ACTION = 'app/arhub/create_collection_action';
export const CREATE_COLLECTION_ACTION_SUCCESS = 'app/arhub/create_collection_action_success';
export const CREATE_COLLECTION_ACTION_FAILURE = 'app/arhub/create_collection_action_failure';

export const MODIFY_COLLECTION_ACTION = 'app/arhub/modify_collection_action';

export const CREATE_COLLECTION_AGENT = 'app/arhub/create_collection_agent';

export const MODIFY_COLLECTION_AGENT = 'app/arhub/modify_collection_agent';

export const CREATE_COLLECTION_SCHEDULE = 'app/arhub/create_collection_shedule';

export const MODIFY_COLLECTION_SCHEDULE = 'app/arhub/modify_collection_schedule';

export const SEARCH_COLLECTION_AGENT_ACTIVITY = 'app/arhub/search_collection_agent_activity';
export const SEARCH_COLLECTION_AGENT_ACTIVITY_SUCCESS = 'app/arhub/search_collection_agent_activity_success';
export const SEARCH_COLLECTION_AGENT_ACTIVITY_FAILURE = 'app/arhub/search_collection_agent_activity_failure';
export const SET_PARAMS_COLLECTION_AGENT_ACTIVITY = 'app/arhub/set_params_collection_agent_activity';

export const REASSIGN_COLLECTION_AGENT = 'app/arhub/reassign_collection_agent';

export const GET_COLLECTION_UNITS_BY_ID = 'app/arhub/get_collection_units_by_id';
export const GET_COLLECTION_UNITS_BY_ID_SUCCESS = 'app/arhub/get_collection_units_by_id_success';
export const GET_COLLECTION_UNITS_BY_ID_FAILED = 'app/arhub/get_collection_units_by_id_failed';

export const SEARCH_INVOICE_IN_COLLECTION = 'app/arhub/search_invoice_in_collection';
export const SEARCH_INVOICE_IN_COLLECTION_SUCCESS = 'app/arhub/search_invoice_in_collection_success';
export const SEARCH_INVOICE_IN_COLLECTION_FAILED = 'app/arhub/search_invoice_in_collection_failed';
export const SET_PARAMS_INVOICE_UNITS = 'app/arhub/set_params_invoice_units';

export const CREATE_COLLECTION_UNIT = 'app/arhub/create_collection_unit';
export const MODIFY_COLLECTION_UNIT = 'app/collection/modify_collection_unit';
export const SEARCH_COLLECTION_SCHEDULES = 'app/collection/SEARCH_COLLECTION_SCHEDULES';

// export const SEARCH_SCHEDULE_PATTERN = 'app/collection/SEARCH_SCHEDULE_PATTERN';
// export const CREATE_SCHEDULE_PATTERN = 'app/collection/CREATE_SCHEDULE_PATTERN';
// export const MODIFY_SCHEDULE_PATTERN = 'app/collection/MODIFY_SCHEDULE_PATTERN';
// export const GET_SCHEDULE_PATTERN_BY_ID = 'app/collection/GET_SCHEDULE_PATTERN_BY_ID';
export const CREATE_COLLECTION_PROFILE = 'CREATE_COLLECTION_PROFILE';
export const MODIFY_COLLECTION_PROFILE = 'MODIFY_COLLECTION_PROFILE';
export const SEARCH_COLLECTION_PROFILE = 'SEARCH_COLLECTION_PROFILE';
export const GET_COLLECTION_SCHEDULE_AUDIT = 'GET_COLLECTION_SCHEDULE_AUDIT';
export const CONVERT_JSON_TO_CONFIG_COLLECTION_SCHEDULE = 'CONVERT_JSON_TO_CONFIG_COLLECTION_SCHEDULE';