import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import SearchComponent from '../../PricingHub/SearchComponent';

class UsagePrepaidAverageConsumptionSearchForm extends SearchComponent {
  searchInputs = [
    {
      label: 'common:label.period',
      name: 'usagePeriod',
      type: 'monthYear',
      required: true,
      defaultValue: moment()
        .startOf('month')
        .format('YYYY-MM'),
      dateFormat: 'YYYY-MM',
    },
    {
      label: 'common:label.percentageVariation',
      name: 'percent',
      type: 'number',
      isInteger: true,
      defaultValue: '0',
      required: true,
    },
    {
      label: 'label.contractAccountId',
      name: 'accountId',
    },
  ];

  btnSpan = 'col-md-3';
}

UsagePrepaidAverageConsumptionSearchForm.propTypes = {
  onSubmit: PropTypes.func,
  isSubmitting: PropTypes.bool,
};

UsagePrepaidAverageConsumptionSearchForm.defaultProps = {
  isSubmitting: false,
};

export default withTranslation('common')(UsagePrepaidAverageConsumptionSearchForm);
