import gql from 'graphql-tag';
import { parseToTwoLvArrNSMutationRequest } from 'utils/utils';

export default data => {
  const dataCreate = parseToTwoLvArrNSMutationRequest(
    data,
    [],
    [
      'type',
      'name',
      'validity',
      'initialTerm',
      'renewalTerm',
      'billingDom',
      'passwordExpiryInMonths',
      'shortBillingCycle',
      'paymentNotification',
      'paymentAutoAllocation',
      'paymentFailureNotification',
      'invoiceDueNotification',
      'contactRole',
      'addressRole',
      'salesChannel',
      'marketSegment',
      'accountType',
      'accountSubType',
      'invoiceType',
      'invoiceDelivery',
      'index',
      'status',
      'paymentMethod',
      'paymentTerm',
      'initialTermUnit',
      'renewalTermUnit',
      'billingFrequency',
      'orderStatus',
      'authType',
      'phoneType',
      'number',
      'selfCareUserType',
      'selfCareUserCategory',
      'batchSizeBilling',
      'batchSizeInvoice',
      'batchSizePayment',
      'batchSizeCommon',
      'invoiceReminderThreshold',
      'useUsageStartTime',
      'apiType',
      'webApiType',
      'apiProtocol',
      'payloadMapOnly',
      'skipAuthorization',
      'addTo',
    ]
  );

  const modifyTenant = `modifyTenant(input: ${dataCreate})`;

  // console.log('modifyTenant', modifyTenant);
  // return modifyTenant;
  return gql`
    mutation {
      ${modifyTenant} {
        id
        name
        licenseKey
        vaultUri
        vaultPath
        tenantProfile {
          number
          street
          city
          country
          state
          postalCode
          enquiryEmail
          enquiryPhone
          companyTaxId
          companyTag
        }
        tenantMerchantAccounts {
          index
          type
          name
          startDate
          validity
          authType
          status
          paymentGatewayAttributes {
            index
            type
            url
          }
          taxGatewayAttributes {
            index
            type
            url
          }
          financeGatewayAttributes {
            index
            type
            url
          }
          httpBasicAttributes {
            clientId
            clientProfileId
            username
            password
          }
          jwtAttributes {
            authorizationKey
            merchantId
            publicKey
            privateKey
          }
          oauthAttributes {
            clientId
            clientSecret
            companyId
            refreshToken
            accessToken
          }
          apiKeyAttributes {
            clientId
            clientProfileId
            key
            value
            addTo
          }
        }

      }
    }
  `;
};

// tenantPropertyDefaults {
//   customerSegment
//   defaultCurrency
//   sellingCompany
//   lineOfBusiness
//   legalEntity
//   organizationUnit
//   setOfBooks
//   taxItemId
//   initialTerm
//   renewalTerm
//   billingDom
//   passwordExpiryInMonths
//   shortBillingCycle
//   paymentNotification
//   paymentAutoAllocation
//   contactRole
//   addressRole
//   salesChannel
//   marketSegment
//   accountType
//   accountSubType
//   invoiceType
//   invoiceDelivery
//   paymentMethod
//   paymentTerm
//   initialTermUnit
//   renewalTermUnit
//   billingFrequency
//   orderStatus
//   phoneType
//   selfCareUserType
//   selfCareUserCategory
//   defaultGLAccount
//   productCompany
//   productFamily
//   productLine
//   productType
//   productSubType
//   useUsageStartTime
//   batchSizeCommon
//   batchSizeBilling
//   batchSizeInvoice
//   batchSizePayment
//   invoiceReminderThreshold
// }
