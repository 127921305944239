import gql from 'graphql-tag';
import { convertFilterRequestUrl } from '../../utils/utils';

export default ({ filter, page, size, sort }) => {
  const sortStr = sort ? `, sort: ${sort}` : '';
  const filterStr = filter ? `, filter: ${convertFilterRequestUrl(filter, ['serviceType', 'status'])}` : '';
  const searchServiceProvisioningData = `searchServiceProvisioningData(page: ${page}, size: ${size}${sortStr}${filterStr})`;
  return gql`
    query {
      ${searchServiceProvisioningData} {
        provisioning {
          id
          provisioningId
          accountId
          serviceType
          effectiveDate
          endDate
          type
          instanceId
          flexAttr1
          flexAttr2
          flexAttr3
          flexAttr4
          flexAttr5
          flexAttr6
          flexAttr7
          flexAttr8
          flexAttr9
          flexAttr10
          flexAttr11
          flexAttr12
          flexAttr13
          flexAttr14
          flexAttr15
          flexAttr16
          flexAttr17
          flexAttr18
          flexAttr19
          flexAttr20
          flexAttr21
          flexAttr22
          flexAttr23
          flexAttr24
          flexAttr25
        }
        types {
          type
          serviceType
          flexAttrMapping {
            tag
            flexAttribute
            mandatoryFlag
          }
        }
      }
    }
  `;
};
