import { cloneDeep } from 'lodash';
import styleHeader from './styleHeader';
import { getLabelOptions } from '../utils';
import { columns } from './commonFields';
import { convertBoolLabel } from '../exportHelper';

const multiDataSetDefault = ({ t, title }) => [
  {
    xSteps: 3,
    columns: [],
    data: [[{ value: title || 'ITEM SERVICES', style: { font: { sz: '20', bold: true } } }]],
  },
  {
    columns: [
      columns.id,
      columns.common, // chargeType
      columns.name, // name
      columns.description, // description
      columns.serviceType,
      columns.common, // isChild
      columns.itemId,
      columns.common, // serviceProductCode
      columns.common, // uom
      columns.common, // uom name
      columns.common, // glAccount
      columns.common, // taxCode
      columns.status, // status
      columns.common, // createdDate
      columns.common, // isParent
    ],
    data: [
      [
        {
          value: t('label.id'),
          style: styleHeader,
        },
        {
          value: t('label.chargeType'),
          style: styleHeader,
        },
        {
          value: t('label.name'),
          style: styleHeader,
        },
        {
          value: t('label.description'),
          style: styleHeader,
        },
        {
          value: t('label.serviceType'),
          style: styleHeader,
        },
        {
          value: t('label.isChild'),
          style: styleHeader,
        },
        {
          value: t('label.itemId'),
          style: styleHeader,
        },
        {
          value: t('label.serviceProductCode'),
          style: styleHeader,
        },
        {
          value: t('label.uom'),
          style: styleHeader,
        },
        {
          value: t('label.uomName'),
          style: styleHeader,
        },
        {
          value: t('label.glAccount'),
          style: styleHeader,
        },
        {
          value: t('label.taxCode'),
          style: styleHeader,
        },
        {
          value: t('label.status'),
          style: styleHeader,
        },
        {
          value: t('label.createdDate'),
          style: styleHeader,
        },
        {
          value: t('label.isParent'),
          style: styleHeader,
        },
      ],
    ],
  },
];

const convertJson2Sheet = ({ data, t, uomList, title }) => {
  const newDataSheetSet = cloneDeep(multiDataSetDefault({ t, title }));
  const newListData = data.map(item => {
    const newItem = [];
    if (item && typeof item === 'object') {
      newItem.push({ value: item.id ? item.id : '' });
      newItem.push({ value: item.chargeType ? item.chargeType : '' });
      newItem.push({ value: item.name ? item.name : '' });
      newItem.push({ value: item.description ? item.description : '' });
      newItem.push({ value: item.serviceType ? item.serviceType : '' });
      newItem.push({ value: convertBoolLabel({ t, value: item.isChild }) });
      newItem.push({ value: item.itemId ? item.itemId : '' });
      newItem.push({ value: item.serviceProductCode ? item.serviceProductCode : '' });
      if (item.uom && uomList && uomList.length) {
        newItem.push({
          value: item.uom
            ? uomList.find(val => val.value === item.uom)
              ? uomList.find(val => val.value === item.uom).label
              : ''
            : '',
        });
      } else {
        newItem.push({ value: '' });
      }
      newItem.push({ value: item.uomName ? item.uomName : '' });
      newItem.push({ value: item.glAccount ? item.glAccount : '' });
      newItem.push({ value: item.taxCode ? item.taxCode : '' });
      newItem.push({ value: getLabelOptions({ item, t, fieldName: 'status', fieldOption: 'statusPricing' }) });
      newItem.push({ value: item.createdDate ? item.createdDate : '' });
      newItem.push({ value: convertBoolLabel({ t, value: item.isParent }) });
    }
    return newItem;
  });

  if (newListData.length) {
    newDataSheetSet[1].data = newDataSheetSet[1].data.concat(newListData);
  }
  return newDataSheetSet;
};

export default convertJson2Sheet;
