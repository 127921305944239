import { groupNamePermissions } from './constants';
import { getTypePermission } from '../../utils/utils';

const checkPermissionCreateReportStylesheet = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.createReportStylesheet });
};

const checkPermissionModifyReportStylesheet = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.modifyReportStylesheet });
};

const checkPermissionViewReportStylesheet = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.viewReportStylesheet });
};

const checkPermissionEditReportData = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.editReportData });
};

const checkPermissionViewReportData = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.viewReportData });
};

const checkPermissionGetNewCustomerReport = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.getNewCustomerReport });
};

const checkPermissionGetAccountClosureReport = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.getAccountClosureReport });
};

const checkPermissionGetOrderReport = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.getOrderReport });
};

const checkPermissionGetTrialSubscriptionReport = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.getTrialSubscriptionReport });
};

const checkPermissionGetPaidSubscriptionReport = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.getPaidSubscriptionReport });
};

const checkPermissionGetTrialToPaidSubscriptionReport = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.getTrialToPaidSubscriptionReport });
};

const checkPermissionGetUnbilledTransactionReport = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.getUnbilledTransactionReport });
};

const checkPermissionGetUsageSummaryReport = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.getUsageSummaryReport });
};

const checkPermissionGetUsageDetailReport = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.getUsageDetailReport });
};

const checkPermissionGetUsageReconciliationReport = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.getUsageReconciliationReport });
};

const checkPermissionGetSubscriptionReport = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.getSubscriptionReport });
};

const checkPermissionGetCustomerBillingReport = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.getCustomerBillingReport });
};

const checkPermissionGetBilledTransactionSummaryReport = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.getBilledTransactionSummaryReport });
};

const checkPermissionGetBillProductSummaryReport = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.getBillProductSummaryReport });
};

const checkPermissionGetInvoiceReport = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.getInvoiceReport });
};

const checkPermissionGetTaxReport = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.getTaxReport });
};

const checkPermissionGetServiceBillingReport = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.getServiceBillingReport });
};

const checkPermissionGetPaymentReport = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.getPaymentReport });
};

const checkPermissionGetFailedPaymentReport = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.getFailedPaymentReport });
};

const checkPermissionGetPaymentSuspenseReport = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.getPaymentSuspenseReport });
};

const checkPermissionGetFailedPaymentCustomerReport = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.getFailedPaymentCustomerReport });
};

const checkPermissionCollection030days = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.collection030days });
};

const checkPermissionCollection3060days = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.collection3060days });
};

const checkPermissionCollection6090days = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.collection6090days });
};

const checkPermissionGetAgeingReport = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.getAgeingReport });
};

const checkPermissionGetFailedJobsReport = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.getFailedJobsReport });
};

const checkPermissionGetBillingInvoicingReconReport = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.getBillingInvoicingReconReport });
};

const checkPermissionGetInvoicingPaymentReconReport = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.getInvoicingPaymentReconReport });
};

const checkPermissionGetJobScheduleReport = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.getJobScheduleReport });
};

const checkPermissionGetJobDetailReport = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.getJobDetailReport });
};

const checkPermissionGetInvoicingArReconReport = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.getInvoicingArReconReport });
};

const checkPermissionCollectionGT90days = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.collectionGT90days });
};

const checkPermissionGetArOPSReport = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.getArOPSReport });
};

const checkPermissionGetWriteOffReport = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.getWriteOffReport });
};

const checkPermissionGetDisputeReport = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.getDisputeReport });
};

const checkPermissionGetRevenueSummaryByItemReport = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.getRevenueSummaryByItemReport });
};

const checkPermissionGetRevenueRecognitionByItemReport = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.getRevenueRecognitionByItemReport });
};

const checkPermissionGetTaxSummaryReport = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.getTaxSummaryReport });
};

const checkPermissionGetTaxDetailReport = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.getTaxDetailReport });
};

const checkPermissionGetRevenueRecognitionReport = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.getRevenueRecognitionReport });
};

const checkPermissionGetRevenueSummaryReport = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.getRevenueSummaryReport });
};

const checkPermissionGetActiveSubscriptionReport = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.getActiveSubscriptionReport });
};

const checkPermissionDownloadReport = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.downloadReport });
};

const checkPermissionGetActiveTrialSubscriptionReport = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.getActiveTrialSubscriptionReport });
};

const checkPermissionMailReport = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.mailReport });
};

const checkPermissionGetSubscriptionContractExpiryReport = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.getSubscriptionContractExpiryReport });
};

const checkPermissionGetRevenueForecastReport = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.getRevenueForecastReport });
};

const checkPermissionGetMonthlyRecurringRevenueReport = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.getMonthlyRecurringRevenueReport });
};

const checkPermissionGetVoiceCustomerCallTypeSummaryReport = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.getVolumeAmountByUsageType });
};

const checkPermissionGetVoiceOperatorCallTypeSummaryReport = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.getVolumeAmountPerOperator });
};

const checkPermissionGetExcessVoiceTrafficReport = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.getExcessVoiceTrafficReport });
};

const checkPermissionGetVoiceTrafficByLDDestinationReport = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.getVoiceTrafficByLDDestinationReport });
};

const checkPermissionGetDataServiceReport = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.getDataServiceReport });
};

const checkPermissionGetNewSMSReport = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.getNewSMSReport });
};

const checkPermissionGetRawInterconnectVoiceReport = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.getRawInterconnectVoiceReport });
};

const checkPermissionGetRawInterconnectSMSReport = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.getRawInterconnectSMSReport });
};

const checkPermissionGetCreditNoteReport = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.getCreditNoteReport });
};

const checkPermissionGetVoiceCallTypeSummaryReport = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.getVoiceCallTypeSummaryReport });
};

const checkPermissionGetBillableServiceReport = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.getBillableServiceReport });
};

const checkPermissionGetInternetOperatorDataCostReport = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.getInternetOperatorDataCostReport });
};

const checkPermissionTelcoSummaryReport = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.telecomSummaryReport });
};

const checkPermissionSearchAccountReport = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.searchAccountReport });
};

const checkPermissionSearchMediationFileStatus = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.searchMediationFileStatus });
};

const checkPermissionGetAccountingReconciliationReport = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.getAccountingReconciliationReport });
};

const checkPermissionGetRawCdrReport = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.getRawCdrReport });
};

const checkPermissionGetRawSmsReport = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.getRawSmsReport });
};

// const checkPermissionGetBroadsoftMapReport = ({ listPermission = [] }) => {
//   return getTypePermission({ listPermission, type: groupNamePermissions.GET_BROADSOFT_RAW_CDR_REPORT });
// };

const checkPermissionGetNonBroadsoftMapReport = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.GET_NON_BROADSOFT_RAW_CDR_REPORT });
};

const checkPermissionGetServicesForecast = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.GET_ASSETS_FORECAST_REPORT });
};

const checkPermissionGetEmployeeCostingPnl = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.EMPLOYEE_COSTING_PNL_REPORT });
};

const checkPermissionGetBillableServicesForecast = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.BILLABLE_SERVICES_FORECAST_REPORT });
};

const checkPermissionGetObjectFiles = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.GET_OBJECT_FILES_REPORT });
};

const checkPermissionGetProvisioningData = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.GET_PROVISIONING_DATA_REPORT });
};

const checkPermissionGetbillingTracker = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.BILLING_TRACKER_REPORT });
};

const checkPermissionCommitmentReport = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.COMMITMENT_REPORT });
};

const checkPermissionGetAgeingPaymentReport = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.GET_PAYMENT_AGEING_REPORT });
};

const checkPermissionGetCancellationExtract = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.SEARCH_SERVICE_CANCELLATION_EXTRACT });
};

const checkPermissionSearchKPIExtract = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.SEARCH_KPI_EXTRACT });
};

const checkPermissionGetBroadsoftZeroDurationCdrs = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.GET_BROADSOFT_ZERODURATION_CDRS_REPORT });
};

const checkPermissionGetNonBroadsoftZeroDurationCdrs = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.GET_NONBROADSOFT_ZERODURATION_CDRS_REPORT });
};

const checkPermissionGetDetailRecords = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.GET_SUSPENDED_FAILED_RECORDS_REPORT });
};

const checkPermissionGetExtractBI = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.GET_BI_EXTRACT });
};

const checkPermissionGetRegR00105Report = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.GET_REG_R001_05_REPORT });
};

const checkPermissionGetRegR00106Report = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.GET_REG_R001_06_REPORT });
};

const checkPermissionGetRegR00401Report = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.GET_REG_R004_01_REPORT });
};

const checkPermissionGetRegR00405Report = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.GET_REG_R004_05_REPORT });
};

const checkPermissionGetRegR00501Report = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.GET_REG_R005_01_REPORT });
};

const checkPermissionGetRegR00502Report = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.GET_REG_R005_02_REPORT });
};

const checkPermissionGetRegR00503Report = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.GET_REG_R005_03_REPORT });
};

const checkPermissionGetRegR00504Report = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.GET_REG_R005_04_REPORT });
};

const checkPermissionGetRegR00505Report = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.GET_REG_R005_05_REPORT });
};

const checkPermissionGetRegR00506Report = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.GET_REG_R005_06_REPORT });
};

const checkPermissionGetCreditBureauReport = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.GET_CREDIT_BUREAU_REPORT });
};

const checkPermissionPushKPIExtract = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.PUSH_KPI_EXTRACT });
};

const checkPermissionTaxExemtionReport = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.TAX_EXEMPTION_REPORT });
};

const checkPermissionFonatelReport = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.FONATEL_REPORT });
};

const checkPermissionPhcAssetReport = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.PHC_ASSET_REPORT });
};
const checkPermissionServiceReconReport = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.SERVICE_RECON_REPORT });
};

const checkPermissionUsageConsumptionReport = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.USAGE_CONSUMPTION_REPORT });
};

const checkPermissionUsageAverageConsumptionReport = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.GET_USAGE_AVERAGE_CONSUMPTION_REPORT });
};

const checkPermissionGetEnergyTopupReport = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.ENERGY_TOPUP_REPORT });
};

const checkPermissionSubscriptionTopupReport = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.SUBSCRIPTION_TOPUP_REPORT });
};

const checkPermissionGetOrderDetailReport = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.GET_ORDER_DETAIL_REPORT });
};

const checkPermissionGetServiceInvoiceSummaryReport = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.GET_SERVICE_INVOICE_SUMMARY_REPORT });
};

const checkPermissionGetItemSummaryReport = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.GET_ITEM_SUMMARY_REPORT });
};

const checkPermissionForAllPages = ({ listPermission = [] }) => {
  let modeGetCustomerBillingReport = 0;
  let modeGetSubscriptionReport = 0;
  let modeGetBillingInvoicingReconReport = 0;
  let modeGetFailedPaymentCustomerReport = 0;
  let modeCollection030days = 0;
  let modeCollection3060days = 0;
  let modeCollection6090days = 0;
  let modeEditReportData = 0;
  let modeViewReportStylesheet = 0;
  let modeGetFailedPaymentReport = 0;
  let modeGetServiceBillingReport = 0;
  let modeGetPaymentReport = 0;
  let modeGetAgeingReport = 0;
  let modeGetTrialSubscriptionReport = 0;
  let modeViewReportData = 0;
  let modeGetFailedJobsReport = 0;
  let modeGetPaymentSuspenseReport = 0;
  let modeGetInvoicingPaymentReconReport = 0;
  let modeGetNewCustomerReport = 0;
  let modeGetAccountClosureReport = 0;
  let modeGetOrderReport = 0;
  let modeGetPaidSubscriptionReport = 0;
  let modeGetTrialToPaidSubscriptionReport = 0;
  let modeGetUnbilledTransactionReport = 0;
  let modeGetUsageSummaryReport = 0;
  let modeGetUsageDetailReport = 0;
  let modeGetUsageReconciliationReport = 0;
  let modeGetBilledTransactionSummaryReport = 0;
  let modeGetBillProductSummaryReport = 0;
  let modeGetInvoiceReport = 0;
  let modeGetTaxReport = 0;
  let modeGetWriteOffReport = 0;
  let modeGetDisputeReport = 0;
  let modeGetRevenueSummaryByItemReport = 0;
  let modeGetRevenueRecognitionByItemReport = 0;
  let modeGetTaxSummaryReport = 0;
  let modeGetTaxDetailReport = 0;
  let modeGetRevenueRecognitionReport = 0;
  let modeGetRevenueSummaryReport = 0;
  let modeGetArOPSReport = 0;
  let modeCollectionGT90days = 0;
  let modeGetInvoicingArReconReport = 0;
  let modeGetJobDetailReport = 0;
  let modeGetJobScheduleReport = 0;
  let modeGetActiveSubscriptionReport = 0;
  let modeDownloadReport = 0;
  let modeGetActiveTrialSubscriptionReport = 0;
  let modeGetMailReport = 0;
  let modeCreateReportStylesheet = 0;
  let modeModifyReportStylesheet = 0;
  let modeGetSubscriptionContractExpiryReport = 0;
  let modeGetRevenueForecastReport = 0;
  let modeGetMRRForecastReport = 0;
  let modeGetVoiceCustomerCallTypeSummaryReport = 0;
  let modeGetVoiceOperatorCallTypeSummaryReport = 0;
  let modeGetExcessVoiceTrafficReport = 0;
  let modeGetVoiceTrafficByLDDestinationReport = 0;
  let modeGetDataServiceReport = 0;
  let modeGetNewSMSReport = 0;
  let modeGetRawInterconnectVoiceReport = 0;
  let modeGetRawInterconnectSMSReport = 0;
  let modeGetCreditNoteReport = 0;
  let modeGetVoiceCallTypeSummaryReport = 0;
  let modeGetBillableService = 0;
  let modeGetInternetOperatorDataCost = 0;
  let modeGetTelcoSummary = 0;
  let modeSearchAccountReport = 0;
  let modeGetRawFileReport = 0;
  let modeGetAccountingReconciliationReport = 0;
  let modeGetRawCdrReport = 0;
  let modeGetRawSmsReport = 0;
  // let modeGetBroadsoftMapReport = 0;
  let modeGetNonBroadsoftMapReport = 0;
  let modeGetServicesForecastReport = 0;
  let modeGetEmployeeCostingPnl = 0;
  let modeGetBillableServicesForecast = 0;
  let modeGetObjectFiles = 0;
  let modeGetProvisioningData = 0;
  let modeGetbillingTracker = 0;
  let modeGetCommitmentReport = 0;
  let modeGetAgeingPaymentReport = 0;
  let modeGetCancellationExtract = 0;
  let modeGetSearchKPIExtract = 0;
  let modeGetBroadsoftZeroDurationCdrs = 0;
  let modeGetNonBroadsoftZeroDurationCdrs = 0;
  let modeGetDetailRecords = 0;
  let modeGetExtractBI = 0;

  let modeGetRegR00105Report = 0;
  let modeGetRegR00106Report = 0;
  let modeGetRegR00401Report = 0;
  let modeGetRegR00405Report = 0;
  let modeGetRegR00501Report = 0;
  let modeGetRegR00502Report = 0;
  let modeGetRegR00503Report = 0;
  let modeGetRegR00504Report = 0;
  let modeGetRegR00505Report = 0;
  let modeGetRegR00506Report = 0;
  let modeGetCreditBureauReport = 0;
  let modePushKPIExtract = 0;
  let modeExemtionReport = 0;
  let modeGetFonatelReport = 0;
  let modeGetPhcAssetReport = 0;
  let modeGetServiceReconReport = 0;
  let modeGetPrepaidConsumptionReport = 0;
  let modeGetUsageAverageConsumptionReport = 0;
  let modeGetEnergyTopupReport = 0;
  let modeGetSubscriptionTopupReport = 0;
  let modeGetOrderDetailReport = 0;
  let modeGetServiceInvoiceSummaryReport = 0;
  let modeGetItemSummaryReport = 0;

  modeGetCustomerBillingReport = checkPermissionGetCustomerBillingReport({ listPermission });
  modeGetSubscriptionReport = checkPermissionGetSubscriptionReport({ listPermission });
  modeGetBillingInvoicingReconReport = checkPermissionGetBillingInvoicingReconReport({ listPermission });
  modeGetFailedPaymentCustomerReport = checkPermissionGetFailedPaymentCustomerReport({ listPermission });
  modeCollection030days = checkPermissionCollection030days({ listPermission });
  modeCollection3060days = checkPermissionCollection3060days({ listPermission });
  modeCollection6090days = checkPermissionCollection6090days({ listPermission });
  modeEditReportData = checkPermissionEditReportData({ listPermission });
  modeViewReportStylesheet = checkPermissionViewReportStylesheet({ listPermission });
  modeGetFailedPaymentReport = checkPermissionGetFailedPaymentReport({ listPermission });
  modeGetServiceBillingReport = checkPermissionGetServiceBillingReport({ listPermission });
  modeGetPaymentReport = checkPermissionGetPaymentReport({ listPermission });
  modeGetAgeingReport = checkPermissionGetAgeingReport({ listPermission });
  modeGetTrialSubscriptionReport = checkPermissionGetTrialSubscriptionReport({ listPermission });
  modeViewReportData = checkPermissionViewReportData({ listPermission });
  modeGetNewCustomerReport = checkPermissionGetNewCustomerReport({ listPermission });
  modeGetAccountClosureReport = checkPermissionGetAccountClosureReport({ listPermission });
  modeGetOrderReport = checkPermissionGetOrderReport({ listPermission });
  modeGetPaidSubscriptionReport = checkPermissionGetPaidSubscriptionReport({ listPermission });
  modeGetTrialToPaidSubscriptionReport = checkPermissionGetTrialToPaidSubscriptionReport({ listPermission });
  modeGetUnbilledTransactionReport = checkPermissionGetUnbilledTransactionReport({ listPermission });
  modeGetUsageSummaryReport = checkPermissionGetUsageSummaryReport({ listPermission });
  modeGetUsageDetailReport = checkPermissionGetUsageDetailReport({ listPermission });
  modeGetFailedJobsReport = checkPermissionGetFailedJobsReport({ listPermission });
  modeGetPaymentSuspenseReport = checkPermissionGetPaymentSuspenseReport({ listPermission });
  modeGetInvoicingPaymentReconReport = checkPermissionGetInvoicingPaymentReconReport({ listPermission });
  modeGetUsageReconciliationReport = checkPermissionGetUsageReconciliationReport({ listPermission });
  modeGetBilledTransactionSummaryReport = checkPermissionGetBilledTransactionSummaryReport({ listPermission });
  modeGetBillProductSummaryReport = checkPermissionGetBillProductSummaryReport({ listPermission });
  modeGetInvoiceReport = checkPermissionGetInvoiceReport({ listPermission });
  modeGetJobScheduleReport = checkPermissionGetJobScheduleReport({ listPermission });
  modeGetJobDetailReport = checkPermissionGetJobDetailReport({ listPermission });
  modeGetInvoicingArReconReport = checkPermissionGetInvoicingArReconReport({ listPermission });
  modeCollectionGT90days = checkPermissionCollectionGT90days({ listPermission });
  modeGetArOPSReport = checkPermissionGetArOPSReport({ listPermission });
  modeGetWriteOffReport = checkPermissionGetWriteOffReport({ listPermission });
  modeGetDisputeReport = checkPermissionGetDisputeReport({ listPermission });
  modeGetRevenueSummaryByItemReport = checkPermissionGetRevenueSummaryByItemReport({ listPermission });
  modeGetRevenueRecognitionByItemReport = checkPermissionGetRevenueRecognitionByItemReport({ listPermission });
  modeGetTaxSummaryReport = checkPermissionGetTaxSummaryReport({ listPermission });
  modeGetTaxDetailReport = checkPermissionGetTaxDetailReport({ listPermission });
  modeGetRevenueRecognitionReport = checkPermissionGetRevenueRecognitionReport({ listPermission });
  modeGetRevenueSummaryReport = checkPermissionGetRevenueSummaryReport({ listPermission });
  modeGetActiveSubscriptionReport = checkPermissionGetActiveSubscriptionReport({ listPermission });
  modeDownloadReport = checkPermissionDownloadReport({ listPermission });
  modeGetActiveTrialSubscriptionReport = checkPermissionGetActiveTrialSubscriptionReport({ listPermission });
  modeGetMailReport = checkPermissionMailReport({ listPermission });
  modeGetTaxReport = checkPermissionGetTaxReport({ listPermission });
  modeCreateReportStylesheet = checkPermissionCreateReportStylesheet({ listPermission });
  modeModifyReportStylesheet = checkPermissionModifyReportStylesheet({ listPermission });
  modeGetSubscriptionContractExpiryReport = checkPermissionGetSubscriptionContractExpiryReport({ listPermission });
  modeGetRevenueForecastReport = checkPermissionGetRevenueForecastReport({ listPermission });
  modeGetMRRForecastReport = checkPermissionGetMonthlyRecurringRevenueReport({ listPermission });
  modeGetVoiceCustomerCallTypeSummaryReport = checkPermissionGetVoiceCustomerCallTypeSummaryReport({ listPermission });
  modeGetVoiceOperatorCallTypeSummaryReport = checkPermissionGetVoiceOperatorCallTypeSummaryReport({ listPermission });
  modeGetExcessVoiceTrafficReport = checkPermissionGetExcessVoiceTrafficReport({ listPermission });
  modeGetVoiceTrafficByLDDestinationReport = checkPermissionGetVoiceTrafficByLDDestinationReport({ listPermission });
  modeGetDataServiceReport = checkPermissionGetDataServiceReport({ listPermission });
  modeGetNewSMSReport = checkPermissionGetNewSMSReport({ listPermission });
  modeGetRawInterconnectVoiceReport = checkPermissionGetRawInterconnectVoiceReport({ listPermission });
  modeGetRawInterconnectSMSReport = checkPermissionGetRawInterconnectSMSReport({ listPermission });
  modeGetCreditNoteReport = checkPermissionGetCreditNoteReport({ listPermission });
  modeGetVoiceCallTypeSummaryReport = checkPermissionGetVoiceCallTypeSummaryReport({ listPermission });
  modeGetBillableService = checkPermissionGetBillableServiceReport({ listPermission });
  modeGetInternetOperatorDataCost = checkPermissionGetInternetOperatorDataCostReport({ listPermission });
  modeGetTelcoSummary = checkPermissionTelcoSummaryReport({ listPermission });
  modeSearchAccountReport = checkPermissionSearchAccountReport({ listPermission });
  modeGetRawFileReport = checkPermissionSearchMediationFileStatus({ listPermission });
  modeGetAccountingReconciliationReport = checkPermissionGetAccountingReconciliationReport({ listPermission });
  modeGetRawCdrReport = checkPermissionGetRawCdrReport({ listPermission });
  modeGetRawSmsReport = checkPermissionGetRawSmsReport({ listPermission });
  // modeGetBroadsoftMapReport = checkPermissionGetBroadsoftMapReport({ listPermission });
  modeGetNonBroadsoftMapReport = checkPermissionGetNonBroadsoftMapReport({ listPermission });
  modeGetServicesForecastReport = checkPermissionGetServicesForecast({ listPermission });
  modeGetEmployeeCostingPnl = checkPermissionGetEmployeeCostingPnl({ listPermission });
  modeGetBillableServicesForecast = checkPermissionGetBillableServicesForecast({ listPermission });
  modeGetObjectFiles = checkPermissionGetObjectFiles({ listPermission });
  modeGetProvisioningData = checkPermissionGetProvisioningData({ listPermission });
  modeGetbillingTracker = checkPermissionGetbillingTracker({ listPermission });
  modeGetCommitmentReport = checkPermissionCommitmentReport({ listPermission });
  modeGetAgeingPaymentReport = checkPermissionGetAgeingPaymentReport({ listPermission });
  modeGetCancellationExtract = checkPermissionGetCancellationExtract({ listPermission });
  modeGetSearchKPIExtract = checkPermissionSearchKPIExtract({ listPermission });
  modeGetBroadsoftZeroDurationCdrs = checkPermissionGetBroadsoftZeroDurationCdrs({ listPermission });
  modeGetNonBroadsoftZeroDurationCdrs = checkPermissionGetNonBroadsoftZeroDurationCdrs({ listPermission });
  modeGetDetailRecords = checkPermissionGetDetailRecords({ listPermission });
  modeGetExtractBI = checkPermissionGetExtractBI({ listPermission });
  modeGetRegR00105Report = checkPermissionGetRegR00105Report({ listPermission });
  modeGetRegR00106Report = checkPermissionGetRegR00106Report({ listPermission });
  modeGetRegR00401Report = checkPermissionGetRegR00401Report({ listPermission });
  modeGetRegR00405Report = checkPermissionGetRegR00405Report({ listPermission });
  modeGetRegR00501Report = checkPermissionGetRegR00501Report({ listPermission });
  modeGetRegR00502Report = checkPermissionGetRegR00502Report({ listPermission });
  modeGetRegR00503Report = checkPermissionGetRegR00503Report({ listPermission });
  modeGetRegR00504Report = checkPermissionGetRegR00504Report({ listPermission });
  modeGetRegR00505Report = checkPermissionGetRegR00505Report({ listPermission });
  modeGetRegR00506Report = checkPermissionGetRegR00506Report({ listPermission });
  modeGetCreditBureauReport = checkPermissionGetCreditBureauReport({ listPermission });
  modePushKPIExtract = checkPermissionPushKPIExtract({ listPermission });
  modeExemtionReport = checkPermissionTaxExemtionReport({ listPermission });
  modeGetFonatelReport = checkPermissionFonatelReport({ listPermission });
  modeGetPhcAssetReport = checkPermissionPhcAssetReport({ listPermission });
  modeGetServiceReconReport = checkPermissionServiceReconReport({ listPermission });
  modeGetPrepaidConsumptionReport = checkPermissionUsageConsumptionReport({ listPermission });
  modeGetUsageAverageConsumptionReport = checkPermissionUsageAverageConsumptionReport({ listPermission });
  modeGetEnergyTopupReport = checkPermissionGetEnergyTopupReport({ listPermission });
  modeGetSubscriptionTopupReport = checkPermissionSubscriptionTopupReport({ listPermission });

  modeGetOrderDetailReport = checkPermissionGetOrderDetailReport({ listPermission });
  modeGetServiceInvoiceSummaryReport = checkPermissionGetServiceInvoiceSummaryReport({ listPermission });
  modeGetItemSummaryReport = checkPermissionGetItemSummaryReport({ listPermission });

  return {
    modeGetCustomerBillingReport,
    modeGetSubscriptionReport,
    modeGetBillingInvoicingReconReport,
    modeGetFailedPaymentCustomerReport,
    modeCollection030days,
    modeCollection3060days,
    modeCollection6090days,
    modeEditReportData,
    modeViewReportStylesheet,
    modeGetFailedPaymentReport,
    modeGetServiceBillingReport,
    modeGetPaymentReport,
    modeGetAgeingReport,
    modeGetTrialSubscriptionReport,
    modeViewReportData,
    modeGetFailedJobsReport,
    modeGetPaymentSuspenseReport,
    modeGetInvoicingPaymentReconReport,
    modeGetNewCustomerReport,
    modeGetAccountClosureReport,
    modeGetOrderReport,
    modeGetPaidSubscriptionReport,
    modeGetTrialToPaidSubscriptionReport,
    modeGetUnbilledTransactionReport,
    modeGetUsageSummaryReport,
    modeGetUsageDetailReport,
    modeGetUsageReconciliationReport,
    modeGetBilledTransactionSummaryReport,
    modeGetBillProductSummaryReport,
    modeGetInvoiceReport,
    modeGetTaxReport,
    modeGetWriteOffReport,
    modeGetDisputeReport,
    modeGetRevenueSummaryByItemReport,
    modeGetRevenueRecognitionByItemReport,
    modeGetTaxSummaryReport,
    modeGetTaxDetailReport,
    modeGetRevenueRecognitionReport,
    modeGetRevenueSummaryReport,
    modeGetArOPSReport,
    modeCollectionGT90days,
    modeGetInvoicingArReconReport,
    modeGetJobDetailReport,
    modeGetJobScheduleReport,
    modeGetActiveSubscriptionReport,
    modeDownloadReport,
    modeGetActiveTrialSubscriptionReport,
    modeGetMailReport,
    modeCreateReportStylesheet,
    modeModifyReportStylesheet,
    modeGetSubscriptionContractExpiryReport,
    modeGetRevenueForecastReport,
    modeGetMRRForecastReport,
    modeGetVoiceCustomerCallTypeSummaryReport,
    modeGetVoiceOperatorCallTypeSummaryReport,
    modeGetExcessVoiceTrafficReport,
    modeGetVoiceTrafficByLDDestinationReport,
    modeGetDataServiceReport,
    modeGetNewSMSReport,
    modeGetRawInterconnectVoiceReport,
    modeGetRawInterconnectSMSReport,
    modeGetCreditNoteReport,
    modeGetVoiceCallTypeSummaryReport,
    modeGetBillableService,
    modeGetInternetOperatorDataCost,
    modeGetTelcoSummary,
    modeSearchAccountReport,
    modeGetRawFileReport,
    modeGetAccountingReconciliationReport,
    modeGetRawCdrReport,
    modeGetRawSmsReport,
    // modeGetBroadsoftMapReport,
    modeGetNonBroadsoftMapReport,
    modeGetServicesForecastReport,
    modeGetEmployeeCostingPnl,
    modeGetBillableServicesForecast,
    modeGetObjectFiles,
    modeGetProvisioningData,
    modeGetbillingTracker,
    modeGetCommitmentReport,
    modeGetAgeingPaymentReport,
    modeGetCancellationExtract,
    modeGetSearchKPIExtract,
    modeGetNonBroadsoftZeroDurationCdrs,
    modeGetBroadsoftZeroDurationCdrs,
    modeGetDetailRecords,
    modeGetExtractBI,
    modeGetRegR00105Report,
    modeGetRegR00106Report,
    modeGetRegR00401Report,
    modeGetRegR00405Report,
    modeGetRegR00501Report,
    modeGetRegR00502Report,
    modeGetRegR00503Report,
    modeGetRegR00504Report,
    modeGetRegR00505Report,
    modeGetRegR00506Report,
    modeGetCreditBureauReport,
    modePushKPIExtract,
    modeExemtionReport,
    modeGetFonatelReport,
    modeGetPhcAssetReport,
    modeGetServiceReconReport,
    modeGetPrepaidConsumptionReport,
    modeGetUsageAverageConsumptionReport,
    modeGetEnergyTopupReport,
    modeGetSubscriptionTopupReport,
    modeGetOrderDetailReport,
    modeGetServiceInvoiceSummaryReport,
    modeGetItemSummaryReport,
  };
};

export {
  checkPermissionForAllPages,
  checkPermissionGetCustomerBillingReport,
  checkPermissionGetSubscriptionReport,
  checkPermissionGetBillingInvoicingReconReport,
  checkPermissionGetFailedPaymentCustomerReport,
  checkPermissionCollection030days,
  checkPermissionCollection3060days,
  checkPermissionCollection6090days,
  checkPermissionEditReportData,
  checkPermissionViewReportStylesheet,
  checkPermissionGetFailedPaymentReport,
  checkPermissionGetServiceBillingReport,
  checkPermissionGetPaymentReport,
  checkPermissionGetAgeingReport,
  checkPermissionGetTrialSubscriptionReport,
  checkPermissionViewReportData,
  checkPermissionGetNewCustomerReport,
  checkPermissionGetAccountClosureReport,
  checkPermissionGetOrderReport,
  checkPermissionGetPaidSubscriptionReport,
  checkPermissionGetTrialToPaidSubscriptionReport,
  checkPermissionGetUnbilledTransactionReport,
  checkPermissionGetUsageSummaryReport,
  checkPermissionGetUsageDetailReport,
  checkPermissionGetFailedJobsReport,
  checkPermissionGetPaymentSuspenseReport,
  checkPermissionGetInvoicingPaymentReconReport,
  checkPermissionGetUsageReconciliationReport,
  checkPermissionGetBilledTransactionSummaryReport,
  checkPermissionGetBillProductSummaryReport,
  checkPermissionGetInvoiceReport,
  checkPermissionGetJobScheduleReport,
  checkPermissionGetJobDetailReport,
  checkPermissionGetInvoicingArReconReport,
  checkPermissionCollectionGT90days,
  checkPermissionGetArOPSReport,
  checkPermissionGetWriteOffReport,
  checkPermissionGetDisputeReport,
  checkPermissionGetRevenueSummaryByItemReport,
  checkPermissionGetRevenueRecognitionByItemReport,
  checkPermissionGetTaxSummaryReport,
  checkPermissionGetTaxDetailReport,
  checkPermissionGetRevenueRecognitionReport,
  checkPermissionGetRevenueSummaryReport,
  checkPermissionGetActiveSubscriptionReport,
  checkPermissionDownloadReport,
  checkPermissionGetActiveTrialSubscriptionReport,
  checkPermissionMailReport,
  checkPermissionGetTaxReport,
  checkPermissionCreateReportStylesheet,
  checkPermissionModifyReportStylesheet,
  checkPermissionGetSubscriptionContractExpiryReport,
  checkPermissionGetRevenueForecastReport,
  checkPermissionGetMonthlyRecurringRevenueReport,
  checkPermissionGetVoiceCustomerCallTypeSummaryReport,
  checkPermissionGetVoiceOperatorCallTypeSummaryReport,
  checkPermissionGetExcessVoiceTrafficReport,
  checkPermissionGetVoiceTrafficByLDDestinationReport,
  checkPermissionGetDataServiceReport,
  checkPermissionGetNewSMSReport,
  checkPermissionGetRawInterconnectVoiceReport,
  checkPermissionGetRawInterconnectSMSReport,
  checkPermissionGetCreditNoteReport,
  checkPermissionGetVoiceCallTypeSummaryReport,
  checkPermissionGetBillableServiceReport,
  checkPermissionGetInternetOperatorDataCostReport,
  checkPermissionTelcoSummaryReport,
  checkPermissionSearchAccountReport,
  checkPermissionSearchMediationFileStatus,
  checkPermissionGetAccountingReconciliationReport,
  checkPermissionGetRawCdrReport,
  checkPermissionGetRawSmsReport,
  // checkPermissionGetBroadsoftMapReport,
  checkPermissionGetNonBroadsoftMapReport,
  checkPermissionGetServicesForecast,
  checkPermissionGetEmployeeCostingPnl,
  checkPermissionGetBillableServicesForecast,
  checkPermissionGetObjectFiles,
  checkPermissionGetProvisioningData,
  checkPermissionGetbillingTracker,
  checkPermissionCommitmentReport,
  checkPermissionGetAgeingPaymentReport,
  checkPermissionGetCancellationExtract,
  checkPermissionSearchKPIExtract,
  checkPermissionGetBroadsoftZeroDurationCdrs,
  checkPermissionGetNonBroadsoftZeroDurationCdrs,
  checkPermissionGetDetailRecords,
  checkPermissionGetExtractBI,
  checkPermissionGetRegR00105Report,
  checkPermissionGetRegR00106Report,
  checkPermissionGetRegR00401Report,
  checkPermissionGetRegR00405Report,
  checkPermissionGetRegR00501Report,
  checkPermissionGetRegR00502Report,
  checkPermissionGetRegR00503Report,
  checkPermissionGetRegR00504Report,
  checkPermissionGetRegR00505Report,
  checkPermissionGetRegR00506Report,
  checkPermissionGetCreditBureauReport,
  checkPermissionPushKPIExtract,
  checkPermissionTaxExemtionReport,
  checkPermissionFonatelReport,
  checkPermissionPhcAssetReport,
  checkPermissionServiceReconReport,
  checkPermissionUsageConsumptionReport,
  checkPermissionUsageAverageConsumptionReport,
  checkPermissionGetEnergyTopupReport,
  checkPermissionSubscriptionTopupReport,
  checkPermissionGetOrderDetailReport,
  checkPermissionGetServiceInvoiceSummaryReport,
  checkPermissionGetItemSummaryReport,
};
