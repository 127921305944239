export default {
  // Self-care
  CREATE_USER: 'CREAR USUARIO',
  PROCESS_ACCOUNT: 'PROCESO_DE_CUENTA',
  SEARCH_OFFERS: 'BUSCAR_OFERTAS',
  ADD_CREDIT_CARD: 'AÑADIR_TARJETA_DE_CRÉDITO',
  MODIFY_CREDIT_CARD: 'MODIFICAR_TARJETA_DE_CRÉDITO',
  MODIFY_OFFERS: 'MODIFICAR_ OFERTAS',
  MODIFY_CONTACT: 'MODIFICAR_CONTACTO',
  VIEW_SUBSCRIPTION: 'VER_SUSCRIPCIÓN',
  VIEW_BALANCES: 'VER_SALDOS',
  VIEW_BILLS: 'VER_CUENTAS',
  VIEW_INVOICE: 'VER_FACTURA',
  VIEW_TRANSACTIONS: 'VER_TRANSACCIONES',
  APPLY_PAYMENT: 'APLICAR_PAGO',
  MODIFY_USER: 'MODIFICAR_USUARIO',
  // new Self-care permissions key
  CREATE_LAYOUT_PREFERENCE: 'CREAR_PREFERENCIA_DE_DISEÑO',
  MODIFY_LAYOUT_PREFERENCE: 'MODIFICAR_LA_PREFERENCIA_DE_DISEÑO',
  VIEW_LAYOUT_PREFERENCE: 'VER_PREFERENCIA_DE_DISEÑO',
  CREATE_ADS_DATA: 'CREAR_DATOS_DE_ANUNCIOS',
  MODIFY_ADS_DATA: 'MODIFICAR_DATOS_DE_ANUNCIOS',
  VIEW_ADS_DATA: 'VER_DATOS_DE_ANUNCIOS',
  UPGRADE_SUBSCRIPTION: 'ACTUALIZAR_SUSCRIPCIÓN_',
  DOWNGRADE_SUBSCRIPTION: 'DEGRADAR_SUSCRIPCIÓN',
  DOWNLOAD_INVOICE: 'DESCARGAR_FACTURA',
  VIEW_NOTES: 'VER_NOTAS',
  DOWNLOAD_NOTES: 'DESCARGAR_NOTAS',

  // Accounts
  GET_ACCOUNT: 'OBTENER_CUENTA',
  GET_ADDRESS: 'OBTENER_DIRECCIÓN',
  GET_CONTACT: 'OBTENER_CONTACTO',
  GET_BILLING_PROFILE: 'OBTENER_PERFIL_FACTURACIÓN',
  GET_PAYMENT_PROFILE: 'OBTENER_PERFIL_PAGOS',
  SEARCH_ACCOUNTS: 'BÚSQUEDA_CUENTAS',
  CREATE_CUSTOMER: 'CREAR_CLIENTE',
  SUSPEND_CUSTOMER: 'SUSPENDER_CLIENTE',
  RESUME_CUSTOMER: 'REANUDAR_CLIENTE',
  CLOSE_CUSTOMER: 'CERRAR_CLIENTE',
  MODIFY_CUSTOMER: 'MODIFICAR_CLIENTE',
  ADD_ADDRESS: 'AÑADIR_DIRECCIÓN',
  MODIFY_ADDRESS: 'MODIFICAR_DIRECCIÓN',
  DELETE_ADDRESS: 'ELIMINAR_DIRECCIÓN',
  ADD_CONTACT: 'AGREGAR_CONTACTO',
  DELETE_CONTACT: 'ELIMINAR_CONTACTO',
  ADD_PHONE: 'AÑADIR_TELÉFONO',
  MODIFY_PHONE: 'MODIFICAR_TELÉFONO',
  DELETE_PHONE: 'ELIMINAR_TELÉFONO',
  MODIFY_BILLING_PROFILE: 'MODIFICAR_PERFIL_FACTURACIÓN',
  MODIFY_PAYMENT_PROFILE: 'MODIFICAR_PERFIL_PAGO',
  DELETE_CREDIT_CARD: 'ELIMINAR_TARJETA_DE_CRÉDITO',
  // new permission account.
  CREATE_CUSTOM_ATTRIBUTE_CONFIG: 'CREAR_CONFIGURACIÓN_DE_ATRIBUTOS_PERSONALIZADOS',
  MODIFY_CUSTOM_ATTRIBUTE_CONFIG: 'MODIFICAR_LA_CONFIGURACIÓN_DE_ATRIBUTOS_PERSONALIZADOS',
  MODIFY_CUSTOM_ATTRIBUTES: 'MODIFICAR_ATRIBUTOS_PERSONALIZADOS',
  CREATE_CUSTOMER_HIERARCHY: 'CREAR_JERARQUÍA_DE_CLIENTES',
  GET_CUSTOM_ATTRIBUTE_CONFIG: 'OBTENER_CONFIGURACIÓN_DE_ATRIBUTOS_PERSONALIZADOS',
  GET_CUSTOMER_HIERARCHY: 'OBTENGA_LA_JERARQUÍA_DEL_CLIENTE',
  CREATE_EXTERNAL_PO: 'CREAR_ORDEN DE COMPRA_EXTERNA',
  MODIFY_EXTERNAL_PO: 'MODIFICAR_ORDEN DE COMPRA_EXTERNA',
  GET_EXTERNAL_PO: 'OBTENER_ORDEN DE COMPRA_EXTERNA',
  CREATE_CUSTOM_ATTRIBUTES: 'CREAR_ATRIBUTOS_PERSONALIZADOS',
  GET_CUSTOM_ATTRIBUTES: 'OBTENER_ATRIBUTOS_PERSONALIZADOS',

  // Order
  GET_ORDER: 'OBTENER_ORDEN',
  SEARCH_ORDER: 'BUSCAR_ORDEN',
  CREATE_ORDER: 'CREAR_ORDEN',
  UPDATE_ORDER: 'ACTUALIZAR_ORDEN',
  CANCEL_ORDER: 'CANCELAR_ORDEN',
  SUBMIT_ORDER: 'ENVIAR_ORDEN',
  NEW_ORDER_TYPE: 'NUEVO_TIPO_ORDEN',
  MODIFY_ORDER_TYPE: 'MODIFICAR_TIPO_ORDEN',
  SUSPEND_ORDER_TYPE: 'SUSPENDER_TIPO_ORDEN',
  RESUME_ORDER_TYPE: 'REANUDAR_TIPO_ORDEN',
  CANCEL_ORDER_TYPE: 'CANCELAR_TIPO_ORDEN',
  PACKAGE_PURCHASE: 'COMPRA_PAQUETE',
  BUNDLE_PURCHASE: 'COMPRA_BUNDLE',
  ALACARTE_PURCHASE: 'COMPRA_A_LA_CARTA',
  ADD_BUNDLE: 'AÑADIR_BUNDLE',
  ADD_PACKAGE: 'AÑADIR_PAQUETE',
  ADD_PRICE_OFFER: 'AÑADIR_OFERTA_PRECIOS',
  DELETE_BUNDLE: 'ELIMINAR_BUNDLE',
  DELETE_PACKAGE: 'ELIMINAR_PAQUETE',
  DELETE_PRICE_OFFER: 'ELIMINAR_OFERTA_PRECIOS',
  // new permission Order.
  NEW_OPT_IN_TRIAL_ORDER_TYPE: 'NUEVO_TIPO_DE_ORDEN_DE_PRUEBA_OPT_IN (Adhesión)',
  NEW_OPT_OUT_TRIAL_ORDER_TYPE: 'NUEVO_TIPO_DE_ORDEN_DE_PRUEBA_OPT_OUT_(Exclusión)',
  OPT_IN_ORDER_TYPE: 'TIPO_DE_ORDEN_OPT_IN (Adhesión)',
  OPT_OUT_ORDER_TYPE: 'TIPO_DE_ORDEN_OPT_OUT (Exclusión)',
  UPGRADE_ORDER_TYPE: 'ACTUALIZAR_TIPO_DE_ORDEN',
  DOWNGRADE_ORDER_TYPE: 'DEGRADAR_TIPO_DE_ORDEN',
  APPROVE_ORDER: 'APROBAR_ORDEN',

  // Activity
  GET_ACTIVITY: 'OBTENER_ACTIVIDAD',
  SEARCH_ACTIVITY: 'BUSCAR_ACTIVIDAD',
  // new permission Activity.
  VIEW_ACTIVITY_DETAILS: 'VER_DETALLES_DE_ACTIVIDAD',

  // subscription
  GET_SUBSCRIPTION: 'OBTENER_SUSCRIPCIÓN',
  GET_SERVICE_UNIT: 'OBTENER_UNIDAD_SERVICIO',
  GET_PRICE_UNIT: 'OBTENER_UNIDAD_PRECIO',
  // new permission subscription.
  GET_DISCOUNT_UNIT: 'OBTENER_UNIDAD_DE_DESCUENTO',

  // Items
  CREATE_ITEM: 'CREAR_ARTÍCULO',
  MODIFY_ITEM: 'MODIFICAR_ARTÍCULO',
  APPROVE_ITEM: 'APROBAR_ARTÍCULO',
  SELLABLE_ITEM: 'ARTÍCULO_VENDIBLE',
  NON_SELLABLE_ITEM: 'ARTÍCULO_NO_VENDIBLE',
  SUNSET_ITEM: 'ARTÍCULO_EXPIRADO',
  SEARCH_ITEMS: 'BUSCAR_ARTÍCULOS',
  GET_ITEM: 'OBTENER_ARTÍCULO',
  CREATE_PRICE_OFFER: 'CREAR_OFERTA_PRECIOS',
  MODIFY_PRICE_OFFER: 'MODIFICAR_OFERTA_PRECIOS',
  APPROVE_PRICE_OFFER: 'APROBAR_OFERTA_PRECIOS',
  SELLABLE_PRICE_OFFER: 'OFERTA_PRECIOS_VENDIBLE',
  NON_SELLABLE_PRICE_OFFER: 'OFERTA_PRECIOS_NO_VENDIBLE',
  SUNSET_PRICE_OFFER: 'EXPIRACIÓN_OFERTA_PRECIOS',
  SEARCH_PRICE_OFFERS: 'BUSCAR_OFERTA_PRECIOS',
  GET_PRICE_OFFER: 'OBTENER_OFERTA_PRECIOS',
  // new permission Items.
  CREATE_RATE_UNIT_CONFIG: 'CREAR_CONFIGURACIÓN_DE_UNIDAD_DE_TASACIÓN',
  MODIFY_RATE_UNIT_CONFIG: 'MODIFICAR_LA_CONFIGURACIÓN_DE_LA_UNIDAD_DE_TASACIÓN',
  VIEW_RATE_UNIT_CONFIG: 'VER_CONFIGURACIÓN_DE_LA_UNIDAD_DE_TASACIÓN',
  CREATE_TIME_UNIT_CONFIG: 'CREAR_CONFIGURACIÓN_DE_UNIDAD_DE_TIEMPO',
  MODIFY_TIME_UNIT_CONFIG: 'MODIFICAR_CONFIGURACIÓN_DE_UNIDAD_DE_TIEMPO',
  VIEW_TIME_UNIT_CONFIG: 'VER_CONFIGURACIÓN_DE_UNIDAD_DE_TIEMPO',
  CREATE_USAGE_MAPPING_CONFIG: 'CREAR_CONFIGURACIÓN_DE_MAPEO_DE_USO',
  MODIFY_USAGE_MAPPING_CONFIG: 'MODIFICAR_CONFIGURACIÓN_DE_MAPEO_DE_USO',
  VIEW_USAGE_MAPPING_CONFIG: 'VER_CONFIGURACIÓN_DE_MAPEO_DE_USO',
  ADD_USAGE_MAPPING_CUSTOM_ATTRIBUTES: 'AÑADIR_ATRIBUTOS_PERSONALIZADOS_DE_MAPEO_DE_USO',
  MODIFY_USAGE_MAPPING_CUSTOM_ATTRIBUTES: 'MODIFICAR_ATRIBUTOS_PERSONALIZADOS_DE_MAPEO_DE_USO',
  CREATE_USAGE_STREAM_CONFIG: 'CREAR_CONFIGURACIÓN_DE_FLUJO_DE_USO',
  MODIFY_USAGE_STREAM_CONFIG: 'MODIFICAR_LA_CONFIGURACIÓN_DEL_FLUJO_DE_USO_',
  VIEW_USAGE_STREAM_CONFIG: 'VER_CONFIGURACIÓN_DE_FLUJO_DE_USO',
  ADD_USAGE_CANONICAL_CUSTOM_ATTRIBUTES: 'AÑADIR_ATRIBUTOS_CANÓNICOS_PERSONALIZADOS_DE_USO',
  MODIFY_USAGE_CANONICAL_CUSTOM_ATTRIBUTES: 'MODIFICAR_EL_USO_DE_ATRIBUTOS_PERSONALIZADOS_CANÓNICOS',
  VIEW_USAGE_CANONICAL_MAPPING: 'VER_MAPEO_CANÓNICO_DE_USO',
  MODIFY_CURRENCY_CONFIG: 'MODIFICAR_LA_CONFIGURACIÓN_DE_MONEDA',
  VIEW_CURRENCY_CONFIG: 'VER_CONFIGURACIÓN_DE_MONEDA',
  CREATE_GRANTS_CONFIG: 'CREAR_CONFIGURACIÓN_DE_SUBVENCIONES',
  MODIFY_GRANTS_CONFIG: 'MODIFICAR_LA_CONFIGURACIÓN_DE_SUBVENCIONES',
  VIEW_GRANTS_CONFIG: 'VER_CONFIGURACIÓN_DE_SUBVENCIONES',
  CREATE_ACCUMULATOR_CONFIG: 'CREAR_CONFIGURACIÓN_DEL_ACUMULADOR',
  MODIFY_ACCUMULATOR_CONFIG: 'MODIFICAR_LA_CONFIGURACIÓN_DEL_ACUMULADOR',
  VIEW_ACCUMULATOR_CONFIG: 'VER_CONFIGURACIÓN_DEL_ACUMULADOR',
  CREATE_DISCOUNT_OFFER: 'CREAR_OFERTA_DE_DESCUENTO',
  MODIFY_DISCOUNT_OFFER: 'MODIFICAR_OFERTA_DE_DESCUENTO',
  APPROVE_DISCOUNT_OFFER: 'APROBAR_OFERTA_DE_DESCUENTO',
  SELLABLE_DISCOUNT_OFFER: 'OFERTA_DE_DESCUENTO_VENDIBLE',
  NON_SELLABLE_DISCOUNT_OFFER: 'OFERTA_DE_DESCUENTO_NO_VENDIBLE',
  SUNSET_DISCOUNT_OFFER: 'OFERTA_DESCUENTO_DE_FIN_DE_VIDA',
  SEARCH_DISCOUNT_OFFERS: 'BUSCAR_OFERTAS_DE_DESCUENTO',
  GET_DISCOUNT_OFFER: 'OBTENER_OFERTA_DE_DESCUENTO',
  CREATE_RATE_UNIT_MAP_CONFIG: 'CONFIGURACIÓN_CREAR_MAPA_DE_UNIDAD_DE_TASACIÓN',
  GET_RATE_UNIT_MAP_CONFIG: 'OBTENER_LA_CONFIGURACIÓN_DEL_MAPA_DE_LA_UNIDAD_DE_TASACIÓN',
  MODIFY_RATE_UNIT_MAP_CONFIG: 'MODIFICAR_LA_CONFIGURACIÓN_DEL_MAPA_DE_LA_UNIDAD_DE_TASACIÓN',
  INACTIVATE_FILE_MAPPING: 'INACTIVAR_EL_MAPEO_DE_ARCHIVOS',
  INACTIVATE_STREAM_MAPPING: 'INACTIVAR_MAPEO_DE_FLUJO',

  // New pricing
  CREATE_REGION_MAP_CONFIG: 'CREAR_CONFIGURACIÓN_MAPEO_REGIÓN',
  MODIFY_REGION_MAP_CONFIG: 'MODIFICAR_CONFIGURACIÓN_MAPEO_REGIÓN',
  GET_REGION_MAP_CONFIG: 'OBTENER_CONFIGURACIÓN_MAPEO_REGIÓN',
  CREATE_FLEX_USG_ATTRIBUTE_MAP_CONFIG: 'CREAR_CONFIG_MAPEO_ATRIBUTO_FLEX_USO',
  MODIFY_FLEX_USG_ATTRUBUTE_MAP_CONFIG: 'MODIFICAR_CONFIG_MAPEO_ATRIBUTO_FLEX_USO',
  GET_FLEX_USG_ATTRIBUTE_MAP_CONFIG: 'OBTENER_CONFIG_MAPEO_ATRIBUTO_FLEX_USO',
  CREATE_ZERO_RATE_CONFIG: 'CREAR_CONFIG_TASA_CERO',
  MODIFY_ZERO_RATE_CONFIG: 'MODIFICAR_CONFIG_TASA_CERO',
  GET_ZERO_RATE_CONFIG: 'OBTENER_CONFIG_TASA_CERO',
  CREATE_SPECIAL_PRICE_RATE: 'CREAR_TASA_PRECIO_ESPECIAL',
  MODIFY_SPECIAL_PRICE_RATE: 'MODIFICAR_TASA_PRECIO_ESPECIAL',
  GET_SPECIAL_PRICE_RATE: 'OBTENER_TASA_PRECIO_ESPECIAL',

  // bundle
  CREATE_BUNDLE: 'CREAR_BUNDLE',
  MODIFY_BUNDLE: 'MODIFICAR_BUNDLE',
  APPROVE_BUNDLE: 'APROBAR_BUNDLE',
  SELLABLE_BUNDLE: 'BUNDLE_VENDIBLE',
  NON_SELLABLE_BUNDLE: 'BUNDLE_NO_VENDIBLE',
  SUNSET_BUNDLE: 'EXPIRACIÓN_BUNDLE',
  SEARCH_BUNDLE: 'BUSCAR_BUNDLE',
  GET_BUNDLE: 'OBTENER_BUNDLE',
  CREATE_PACKAGE: 'CREAR_PAQUETE',
  MODIFY_PACKAGE: 'MODIFICAR_PAQUETE',
  APPROVE_PACKAGE: 'APROBAR_PAQUETE',
  SELLABLE_PACKAGE: 'PAQUETE_VENDIBLE',
  NON_SELLABLE_PACKAGE: 'PAQUETE_NO_VENDIBLE',
  SUNSET_PACKAGE: 'EXPIRACIÓN_PAQUETE',
  SEARCH_PACKAGE: 'BUSCAR_PAQUETE',
  GET_PACKAGE: 'OBTENER_PAQUETE',
  CREATE_DEPENDENCY: 'CREAR_DEPENDENCIA',
  MODIFY_DEPENDENCY: 'MODIFICAR_DEPENDENCIA',
  GET_BUNDLE_DEPENDENCY: 'OBTENER_DEPENDENCIA_BUNDLE',
  GET_PACKAGE_DEPENDENCY: 'OBTENER_DEPENDENCIA_PAQUETE',

  // Bills
  SEARCH_BILLS: 'BUSCAR_CUENTAS',
  GET_BILL: 'OBTENER_CUENTA',
  TRIGGER_PENDING_BILL: 'DISPARAR_FACTURACIÓN_PENDIENTE',
  TRIGGER_MANUAL_BILL: 'DISPARAR_FACTURACIÓNMANUAL',
  CREATE_DELAYED_BILLING_CONFIG: 'CREAR_CONFIGURACIÓN_FACTURACIÓN_ATRASADA',
  MODIFY_DELAYED_BILLING_CONFIG: 'MODIFICAR_CONFIGURACIÓN_FACTURACIÓN_RETRASADA',
  GET_DELAYED_BILLING_CONFIG: 'OBTENER_CONFIGURACIÓN_FACTURACIÓN_ATRASADA',

  // Invoice
  SEARCH_INVOICES: 'BUSCAR_FACTURAS',
  GET_INVOICE: 'OBTENER_FACTURA',
  // new permission Invoice.
  CREATE_INVOICE_TEMPLATE: 'CREAR_PLANTILLA_DE_FACTURA',
  MODIFY_INVOICE_TEMPLATE: 'MODIFICAR_PLANTILLA_DE_FACTURA',
  VIEW_INVOICE_TEMPLATE: 'VER_PLANTILLA_DE_FACTURA',
  REGENERATE_INVOICE: 'REGENERAR_FACTURA',
  RE_INVOICE: 'REFACTURAR',
  SEND_INVOICE: 'ENVIAR_FACTURA',
  APPROVE_INVOICE: 'APROBAR_FACTURA',
  RESYNC_INVOICE: 'RESINCRONIZACIÓN_FACTURA',
  UNDO_MANUAL_INVOICE: 'DESHACER_FACTURA_MANUAL',

  // Rating
  SEARCH_TRANSACTIONS: 'BUSCAR_TRANSACCIONES',
  GET_TRANSACTION: 'OBTENER_TRANSACCIÓN',
  GET_BALANCE: 'OBTENER_SALDO',
  SET_CREDIT_LIMIT_CONFIG: 'CONFIG_ESTABLECER_LIMITE_CRÉDITO',
  MODIFY_CREDIT_LIMIT_CONFIG: 'CONFIG_MODIFICAR_LÍMITE_CRÉDITO',
  // new permission Rating.
  VIEW_TRANSACTION_DETAILS: 'VER_DETALLES_DE_LA_TRANSACCIÓN',
  RE_RATING: 'RE_TASACIÓN',
  CREATE_RERATING_CONFIG: 'CREAR_CONFIGURACIÓN_DE_TASACIÓN',
  GET_RERATING_CONFIG: 'OBTENER_CONFIGURACIÓN_DE_TASACIÓN',
  MODIFY_RERATING_CONFIG: 'MODIFICAR_CONFIGURACIÓN_DE_RE_TASACIÓN',
  BACKOUT_RERATING: 'VOLVER_A_TASAR',
  GET_CREDIT_LIMIT_CONFIG: 'OBTENER_CONFIGURACIÓN_DE_LÍMITE_DE_CRÉDITO',

  // ar ops
  CREATE_REASON_CODE_CONFIG: 'CONFIG_CREAR_CÓDIGO_RAZÓN',
  MODIFY_REASON_CODE_CONFIG: 'CONFIG_MODIFICAR_CÓDIGO_RAZÓN',
  GET_REASON_CODE_CONFIG: 'CONFIG_OBTENER_CÓDIGO_RAZÓN',
  PROCESS_ADJUSTMENT: 'PROCESO_AJUSTE',
  PROCESS_TAX_ADJUSTMENT: 'PROCESO_AJUSTE_IMPUESTOS',
  SEARCH_ADJUSTMENTS: 'BUSQUEDA_AJUSTES',
  GET_ADJUSTMENT_DETAILS: 'OBTENER_DETALLES_AJUSTE',
  PROCESS_DISPUTE: 'PROCESO_DISPUTA',
  PROCESS_TAX_DISPUTE: 'PROCESO_DISPUTA_IMPUESTOS',
  SEARCH_DISPUTES: 'BUSQUEDA_DISPUTAS',
  GET_DISPUTE_DETAILS: 'OBTENER_DETALLES_DISPUTA',
  PROCESS_SETTLEMENT: 'PROCESO_LIQUIDACIÓN',
  PROCESS_TAX_SETTLEMENT: 'PROCESO_LIQUIDACIÓN_IMPUESTO',
  SEARCH_SETTLEMENT: 'BUSQUEDA_LIQUIDACIÓN',
  GET_SETTLEMENT_DETAILS: 'OBTENER_DETALLES_LIQUIDACIÓN',
  PROCESS_WRITEOFF: 'PROCESO_CANCELACIÓN',
  PROCESS_TAX_WRITEOFF: 'PROCESO_CANCELACIÓN_IMPUESTO',
  SEARCH_WRITEOFF: 'BUSQUEDA_CANCELACIÓN',
  GET_WRITEOFF_DETAILS: 'OBTENER_DETALLES_CANCELACIÓN',
  PROCESS_WRITEOFF_REVERSAL: 'PROCESO_REVERSIÓN_CANCELACIÓN',
  PROCESS_TAX_WRITEOFF_REVERSAL: 'PROCESO_REVERSIÓN_CANCELACIÓN_IMPUESTO',
  SEARCH_WRITEOFF_REVERSAL: 'BUSCAR_REVERSIÓN_CANCELACIÓN',
  GET_WRITEOFF_REVERSAL_DETAILS: 'OBTENER_DETALLES_REVERSIÓN_CANCELACIÓN',
  SEARCH_AROPS_UNIT: 'BUSCAR_UNIDAD_OPSCXC',
  CREATE_NOTES_TEMPLATE: 'CREAR_PLANTILLA_DE_NOTAS',
  MODIFY_NOTES_TEMPLATE: 'MODIFICAR_LA_PLANTILLA_DE_NOTAS',
  VIEW_NOTES_TEMPLATE: 'VER_PLANTILLA_DE_NOTAS',
  SEARCH_NOTES: 'BÚSQUEDA_DE_NOTAS',
  GET_NOTES: 'OBTENER_NOTAS',
  CREATE_CREDIT_NOTES: 'CREAR_NOTAS_DE_CRÉDITO',
  CREATE_DEBIT_NOTES: 'CREAR_NOTAS_DE_DÉBITO',
  AR_OPS_TAX_OPTIONS: 'OPS_DE_CxC_OPCIONES_IMPUESTOS',

  // Payment
  CREATE_PAYMENT_TERMS_CONFIG: 'CREAR_CONFIG_TÉRMINOS_DE_PAGO',
  MODIFY_PAYMENT_TERMS_CONFIG: 'MODIFICAR_CONFIG_TÉRMINOS_DE_PAGO',
  CREATE_PAYMENT_MERCHANT_CONFIG: 'CREAR_CONFIG_COMERCIANTE_DE_PAGOS',
  MODIFY_PAYMENT_MERCHANT_CONFIG: 'MODIFICAR_CONFIG_COMERCIANTE_DE_PAGOS',
  CREATE_PAYMENT_METHOD_CONFIG: 'CREAR_CONFIG_MÉTODO_DE_PAGO',
  MODIFY_PAYMENT_METHOD_CONFIG: 'MODIFICAR_CONFIG_MÉTODO_DE_PAGO',
  GET_PAYMENT_CONFIGURATION: 'OBTENER_CONFIGURACIÓN_PAGOS',
  PROCESS_PAYMENT: 'PROCESAR_PAGO',
  PROCESS_PAYMENT_REVERSAL: 'PROCESAR_REVERSIÓN_DE_PAGO',
  AUTO_PAYMENT_ALLOCATION: 'ASIGNACIÓN_DE_PAGO_AUTOMÁTICO',
  MANUAL_PAYMENT_ALLOCATION: 'ASIGNACIÓN_DE_PAGO_MANUAL',
  SEARCH_PAYMENTS: 'BÚSQUEDA_DE_PAGOS',
  SEARCH_PAYMENT_REVERSALS: 'BÚSQUEDA_DE_REVERSIÓN_DE_PAGOS',
  SEARCH_PAYMENT_SUSPENSE: 'BÚSQUEDA_DE_SUSPENSIÓN_DE_PAGOS',
  PROCESS_PAYMENT_SUSPENSE: 'PROCESAR_SUSPENSIÓN_DE_PAGOS',
  PROCESS_REFUND: 'PROCESO_DE_REEMBOLSO',
  PROCESS_CHARGEBACKS: 'PROCESO_DE_DEVOLUCIONES',
  APPLY_BATCH_PAYMENTS: 'APLICAR_PAGOS_POR_LOTES',
  APPLY_BATCH_PAYMENT_REVERSALS: 'APLICAR_REVERSIONES_DE_PAGO_POR_LOTES',
  APPLY_BATCH_REFUNDS: 'APLICAR_REEMBOLSOS_POR_LOTES',
  VIEW_PAYMENT_HISTORY: 'VER_HISTORIAL_DE_PAGOS',
  SEARCH_AR_ACTIVITY: 'BUSCAR_ACTIVIDAD_CxC',
  GET_AR_ACTIVITY: 'OBTENER_ACTIVIDAD_DE_CxC',
  VIEW_AR_ACTIVITY_DETAILS: 'VER_DETALLES_DE_ACTIVIDAD_DE_CxC',

  // Collection
  CREATE_COLLECTION_AGENT_CONFIG: 'CREAR_CONFIGURACIÓN_DE_AGENTES_DE_COBRANZA',
  GET_COLLECTION_AGENT_CONFIG: 'OBTENER_CONFIGURACIÓN_DE_AGENTES_DE_COBRANZA',
  MODIFY_COLLECTION_AGENT_CONFIG: 'MODIFICAR_CONFIGURACIÓN_DE_AGENTES_DE_COBRANZA',
  CREATE_COLLECTION_ACTION_CONFIG: 'CREAR_CONFIGURACIÓN_DE_ACCIÓN_DE_COBRANZA',
  GET_COLLECTION_ACTION_CONFIG: 'OBTENER_CONFIGURACIÓN_DE_ACCIÓN_DE_COBRANZA',
  MODIFY_COLLECTION_ACTION_CONFIG: 'MODIFICAR_CONFIGURACIÓN_DE_ACCIÓN_DE_COBRANZA',
  CREATE_COLLECTION_SCHEDULE_CONFIG: 'CREAR_CONFIGURACIÓN_CRONOGRAMA_DE_COBRANZA',
  GET_COLLECTION_SCHEDULE_CONFIG: 'OBTENER_CONFIGURACIÓN_CRONOGRAMA_DE_COBRANZA',
  MODIFY_COLLECTION_SCHEDULE_CONFIG: 'MODIFICAR_CONFIGURACIÓN_CRONOGRAMA_DE_COBRANZA',
  SEARCH_COLLECTION_AGENT_ACTIVITY: 'BUSCAR_ACTIVIDAD_DEL_AGENTE_DE_COBRANZA',
  REASSIGN_COLLECTION_AGENT: 'REASIGNAR_AGENTE_COBRANZA',
  SEARCH_ACCOUNTS_IN_COLLECTION: 'BUSCAR_CUENTAS_EN_COBRANZA',
  SEARCH_INVOICE_UNITS_IN_COLLECTION: 'BUSCAR_UNIDADES_DE_FACTURA_EN_COBRANZA',
  SEARCH_COLLECTION_UNITS: 'BUSCAR_UNIDADES_DE_COBRANZA',
  ADD_MANUAL_ACTIONS: 'AÑADIR_ACCIONES_MANUALES',
  VIEW_MANUAL_ACTIONS: 'VER_ACCIONES_MANUALES',

  // Role
  CREATE_ROLES: 'CREAR_ROLES',
  MODIFY_ROLES: 'MODIFICAR_ROLES',
  SEARCH_ROLES: 'BUSCAR_ROLES',
  DELETE_ROLES: 'ELIMINAR_ROLES',
  CREATE_ROLE_GROUPS: 'CREAR_GRUPOS_DE_ROLES',
  MODIFY_ROLE_GROUPS: 'MODIFICAR_GRUPOS_DE_ROLES',
  SEARCH_ROLE_GROUPS: 'BUSCAR_GRUPOS_DE_ROLES',
  DELETE_ROLE_GROUPS: 'ELIMINAR_GRUPOS_DE_ROLES',
  SEARCH_USER: 'BUSCAR_USUARIO',
  CHANGE_USER_STATUS: 'CAMBIAR_ESTATUS_DEL_USUARIO',
  CHANGE_ROLE_STATUS: 'CAMBIAR_ESTATUS_DEL_ROL',
  CHANGE_ROLE_GROUP_STATUS: 'CAMBIAR_ESTATUS_DEL_GRUPO_DE_ROLES',
  GET_ROLES: 'OBTENER_ROLES',
  GET_ROLES_GROUP: 'OBTENER_GRUPO_DE_ROLES',
  GET_USER: 'OBTENER_USUARIO',

  // Jobs
  CREATE_JOB_SCHEDULE: 'CREAR_PROGRAMA_DE_TRABAJO',
  MODIFY_JOB_SCHEDULE: 'MODIFICAR_PROGRAMA_DE_TRABAJO',
  GET_JOB_SCHEDULE: 'OBTENER_PROGRAMA_DE_TRABAJO',
  UPDATE_JOB_SCHEDULE_STATUS: 'ACTUALIZAR_ESTATUS_DE_PROGRAMA_DE_TRABAJO',
  PROCESS_JOB_SCHEDULE: 'PROCESAR_PROGRAMA_DE_TRABAJO',
  REPROCESS_JOB_SCHEDULE: 'REPROCESAR_PROGRAMA_DE_TRABAJO',
  CREATE_JOB_CONFIG: 'CREAR_CONFIGURACIÓN_DE_TRABAJO',
  MODIFY_JOB_CONFIG: 'MODIFICAR_CONFIGURACIÓN_DE_TRABAJO',
  FIND_JOB_CONFIG: 'ENCONTRAR_CONFIGURACIÓN_DE_TRABAJO',
  CREATE_JOB: 'CREAR_TRABAJO',
  MODIFY_JOB: 'MODIFICAR_TRABAJO',
  UPDATE_JOB_STATUS: 'ACTUALIZAR_ESTATUS_TRABAJO',
  PROCESS_JOB: 'PROCESAR_TRABAJO',
  REPROCESS_JOB: 'REPROCESAR_TRABAJO',
  GET_JOB_STATS: 'OBTENER_ESTADÍSTICAS_TRABAJO',
  MODIFY_JOB_TYPE: 'MODIFICAR_TIPO_DE_TRABAJO',

  // Tenant
  GET_TENANT_INFO: 'OBTENER_INFORMACIÓN_INSTANCIA',
  CREATE_TENANT_INFO: 'CREAR_INFORMACIÓN_INSTANCIA',
  SET_LICENSE_DATA: 'ESTABLECER_DATOS_LICENCIA',
  MODIFY_LICENSE_DATA: 'MODIFICAR_DATOS_LICENCIA',
  SET_COMPANY_PROFILE: 'ESTABLECER_PERFIL_DE_LA_COMPAÑÍA',
  MODIFY_COMPANY_PROFILE: 'MODIFICAR_PERFIL_DE_LA_COMPAÑÍA',
  SET_PROPERTY_DEFAULTS: 'ESTABLECER_VALORES_PREDETERMINADOS_PROPIEDAD',
  MODIFY_PROPERTY_DEFAULTS: 'MODIFICAR_VALORES_PREDETERMINADOS_PROPIEDAD',
  SET_PAYMENT_GATEWAY_MERCHANT: 'CONFIGURAR_GATEWAY_DE_PAGOS_DEL_COMERCIANTE',
  SET_TAX_GATEWAY_MERCHANT: 'CONFIGURAR_GATEWAY_DE_IMPUESTOS_DEL_COMERCIANTE',
  SET_FINANCE_GATEWAY_MERCHANT: 'CONFIGURAR_GATEWAY_DE_FINANZAS_DEL_COMERCIANTE',
  SET_CRM_GATEWAY_PROVIDER: 'CONFIGURAR_GATEWAY_DE_CRM_DEL_PROVEEDOR',
  SET_DATA_GATEWAY_PROVIDER: 'CONFIGURAR_GATEWAY_DE_DATOS_DEL_PROVEEDOR',
  SET_USAGE_GATEWAY_PROVIDER: 'CONFIGURAR_GATEWAY_DE_USO_DEL_PROVEEDOR',
  SET_PROVISIONING_GATEWAY_PROVIDER: 'CONFIGURAR_GATEWAY_DE_APROVISINOAMIENTO_DEL_PROVEEDOR',
  MODIFY_PAYMENT_GATEWAY_MERCHANT: 'MODIFICAR_GATEWAY_DE_PAGOS_DEL_COMERCIANTE',
  MODIFY_TAX_GATEWAY_MERCHANT: 'MODIFICAR_GATEWAY_DE_IMPUESTOS_DEL_COMERCIANTE',
  MODIFY_FINANCE_GATEWAY_MERCHANT: 'MODIFICAR_GATEWAY_DE_FINANZAS_DEL_COMERCIANTE',
  MODIFY_CRM_GATEWAY_PROVIDER: 'MODIFICAR_GATEWAY_DE_CRM_DEL_PROVEEDOR',
  MODIFY_DATA_GATEWAY_PROVIDER: 'MODIFICAR_GATEWAY_DE_DATOS_DEL_PROVEEDOR',
  MODIFY_USAGE_GATEWAY_PROVIDER: 'MODIFICAR_GATEWAY_DE_USO_DEL_PROVEEDOR',
  MODIFY_PROVISIONING_GATEWAY_PROVIDER: 'MODIFICAR_GATEWAY_DE_APROVISINOAMIENTO_DEL_PROVEEDOR',
  CREATE_GATEWAY_CANONICAL: 'CREAR_GATEWAY_CANÓNICO',
  MODIFY_GATEWAY_CANONICAL: 'MODIFICAR_GATEWAY_CANÓNICO',
  GET_GATEWAY_CANONICAL: 'OBTENER_GATEWAY_ CANÓNICO',
  CREATE_PAYMENT_METHOD_URL: 'CREAR_MÉTODO_DE_PAGO_URL',
  MODIFY_PAYMENT_METHOD_URL: 'MODIFICAR_MÉTODO_DE_PAGO_URL',
  GET_PAYMENT_METHOD_URL: 'OBTENER_MÉTODO_DE_PAGO_URL',
  GET_GATEWAY_CANONICAL_SPEC: 'OBTENER_ESPECIFICACIONES_DE_GATEWAY_CANÓNICO',

  // Report
  CREATE_REPORT_STYLESHEET: 'CREAR_HOJA_DE_ESTILO_DE_REPORTE',
  MODIFY_REPORT_STYLESHEET: 'MODIFICAR_HOJA_DE_ESTILO_DE_REPORTE',
  VIEW_REPORT_STYLESHEET: 'VER_HOJA_DE_ESTILO_DE_REPORTE',
  EDIT_REPORT_DATA: 'EDITAR_DATOS_DE_REPORTE',
  VIEW_REPORT_DATA: 'VER_DATOS_DEL_REPORTE',
  GET_NEW_CUSTOMER_REPORT: 'OBTENER_REPORTE_DE_CLIENTE_NUEVO',
  GET_ACCOUNT_CLOSURE_REPORT: 'OBTENER_REPORTE_DE_CIERRE_DE_CUENTA',
  GET_ORDER_REPORT: 'OBTENER_REPORTE_DE_ORDENES',
  GET_TRIAL_SUBSCRIPTION_REPORT: 'OBTENER_REPORTE_DE_SUSCRIPCIÓN_DE_PRUEBA',
  GET_PAID_SUBSCRIPTION_REPORT: 'OBTENER_REPORTE_DE_SUSCRIPCIÓN_PAGADA',
  GET_TRIAL_TO_PAID_SUBSCRIPTION_REPORT: 'OBTENER_REPORTE_DE_SUBSCRIPCIÓN_DE_PRUEBA_A_PAGADA',
  GET_UNBILLED_TRANSACTION_REPORT: 'OBTENER_REPORTE_DE_TRANSACCIÓN_NO_FACTURADA',
  GET_USAGE_SUMMARY_REPORT: 'OBTENER_REPORTE_RESUMIDO_DE_USOS',
  GET_USAGE_DETAIL_REPORT: 'OBTENER_REPORTE_DETALLADO_DE_USO',
  GET_USAGE_RECONCILIATION_REPORT: 'OBTENER_REPORTE_DE_RECONCILIACIÓN_DE_USO',
  GET_CUSTOMER_BILLING_REPORT: 'OBTENER_REPORTE_DE_FACTURACIÓN_DEL_CLIENTE',
  GET_BILLED_TRANSACTION_SUMMARY_REPORT: 'OBTENER_REPORTE_RESUMIDO_DE_TRANSACCIÓN',
  GET_BILL_PRODUCT_SUMMARY_REPORT: 'OBTENER_REPORTE_RESUMIDO_DE_PRODUCTOS',
  GET_INVOICE_REPORT: 'OBTENER_REPORTE_DE_FACTURA',
  GET_TAX_REPORT: 'OBTENER_REPORTE_DE_IMPUESTOS',
  GET_BILLING_INVOICING_RECON_REPORT: 'OBTENER_REPORTE_DE_CONCILIACIÓN_DE_FACTURACIÓN',
  GET_JOBSCHEDULE_REPORT: 'OBTENER_REPORTE_DE_TRABAJO_PROGRAMADO',
  GET_FAILED_JOBS_REPORT: 'OBTENER_REPORTE_DE_TRABAJOS_FALLIDOS',
  GET_JOB_DETAIL_REPORT: 'OBTENER_REPORTE_DETALLADO_DE_TRABAJO',
  GET_PAYMENT_REPORT: 'OBTENER_REPORTE_DE_PAGO',
  GET_FAILED_PAYMENT_REPORT: 'OBTENER_REPORTE_DE_PAGO_FALLIDO',
  GET_PAYMENT_SUSPENSE_REPORT: 'OBTENER_REPORTE_DE_SUSPENSIÓN_DE_PAGO',
  GET_FAILED_PAYMENT_CUSTOMER_REPORT: 'OBTENER_REPORTE_DEL_CLIENTE_DE_PAGO_FALLIDO',
  GET_INVOICING_AR_RECON_REPORT: 'OBTENER_REPORTE_DETALLADO_DE_TRABAJO',
  GET_COLLECTION_0_30_DAYS_REPORT: 'OBTENER_REPORTE_DE_COBRANZA_DE_0_30_DÍAS',
  GET_COLLECTION_30_60_DAYS_REPORT: 'OBTENER_REPORTE_DE_COBRANZA_DE_30_60_DÍAS',
  GET_COLLECTION_60_90_DAYS_REPORT: 'OBTENER_REPORTE_DE_COBRANZA_DE_60_90_DÍAS',
  GET_COLLECTION_GT_90_DAYS_REPORT: 'OBTENER_REPORTE_COBRANZA_MAYOR_90_DÍAS',
  GET_AGEING_REPORT: 'OBTENER_REPORTE_POR_ANTIGÜEDAD',
  GET_AROPS_REPORT: 'OBTENER_REPORTE_OPERACIONES_DE_CxC',
  GET_WRITEOFF_REPORT: 'OBTENER_REPORTE_CANCELACIÓN',
  GET_DISPUTE_REPORT: 'OBTENER_REPORTE_DE_DISPUTA',
  GET_REVENUE_SUMMARY_BY_ITEM_REPORT: 'OBTENER_REPORTE_RESUMIDO_DE_INGRESOS_POR_ARTÍCULO',
  GET_REVENUE_RECOGNITION_BY_ITEM_REPORT: 'OBTENGA_REPORTE_DE_RECONOCIMIENTO_DE_INGRESOS_POR_ARTÍCULO',
  GET_TAX_SUMMARY_REPORT: 'OBTENER_REPORTE DEL RESUMEN DE IMPUESTOS',
  GET_TAX_DETAIL_REPORT: 'OBTENER REPORTE DE DETALLES DE IMPUESTOS',
  GET_SUBSCRIPTION_REPORT: 'OBTENER_REPORTE_DE_SUBSCRIPCIÓN',
  GET_REVENUE_RECOGNITION_REPORT: 'OBTENER_REPORTE_DE_RECONOCIMIENTO_DE_INGRESOS',
  GET_REVENUE_SUMMARY_REPORT: 'OBTENER_REPORTE_DE_RESUMEN_DE_INGRESOS',
  GET_ACTIVE_SUBSCRIPTION_REPORT: 'OBTENER_REPORTE_DE_SUSCRIPCIONES_ACTIVAS',
  DOWNLOAD_REPORT_DATA: 'DESCARGAR_DATOS_DEL_REPORTE',
  GET_ACTIVE_TRIAL_SUBSCRIPTION_REPORT: 'OBTENER_REPORTE_DE_SUSCRIPCIÓN_DE_PRUEBA_ACTIVA',
  GET_SERVICE_BILLING_REPORT: 'OBTENER_REPORTE_DE_SERVICIO_DE_FACTURACIÓN',
  MAIL_REPORT_DATA: 'DATOS_DEL_REPORTE_POR_CORREO',
  GET_INVOICING_PAYMENT_RECON_REPORT: 'OBTENER_REPORTE_DE_RECONOCIMIENTO_DE_PAGO_DE_FACTURACIÓN',
  GET_SUBSCRIPTION_CONTRACT_EXPIRY_REPORT: 'OBTENER_REPORTE_CONTRATOS_POR_SUSCRIPCIÓN_EXPIRADOS',
  GET_REVENUE_FORECAST_REPORT: 'OBTENER_REPORTE_PRONÓSTICO_DE_INGRESOS',
  GET_MONTHLY_RECURRING_REVENUE: 'OBTENER_INGRESOS_RECURRENTES_MENSUALES',

  // Dashboard
  SAVE_USER_PREFERENCES: 'GUARDAR_PREFERENCIAS_DE_USUARIO',
  GET_USER_PREFERENCES: 'OBTENER_PREFERENCIAS_DE_USUARIO',
  CUSTOMER_ONBOARDING: 'INCORPORACIÓN_DEL_CLIENTE',
  CUSTOMER_CHURN: 'ROTACIÓN_DE_CLIENTES',
  TRIAL_TO_PAID_CONVERSION: 'CONVERSIÓN_DE_PRUEBA_A_SERVICIO_PAGADO',
  SUBSCRIPTION_CANCELLATIONS: 'CANCELACIÓN_DE_SUSCRIPCIONES',
  BILLED_REVENUE: 'INGRESOS_FACTURADOS',
  BILLED_CUSTOMER: 'CLIENTE_FACTURADO',
  PAYMENT_SUCCESS: 'PAGO EXITOSO',
  PAYMENT_SUSPENSE: 'SUSPENSIÓN_DE_PAGO',
  PAYMENT_FAILED: 'PAGO_FALLIDO',
  ACCOUNTS_WITH_FAILED_PAYMENT: 'CUENTAS_CON_PAGO_FALLIDO',
  COLLECTION_0_30_DAYS: 'COBRANZA_0_30_DÍAS',
  COLLECTION_30_60_DAYS: 'COBRANZA_30_60_DÍAS',
  COLLECTION_60_90_DAYS: 'COBRANZA_60_90_DIAS',
  COLLECTION_OVER_90_DAYS: 'COBRANZA_MAS_DE_90_DIAS',
  TOTAL_REVENUE: 'TOTAL_INGRESOS',
  FAILED_ORDER: 'ORDEN_FALLIDA',
  BILLS_FAILED: 'CUENTA_FALLIDA',
  INVOICES_FAILED: 'FACTURAS_FALLIDAS',
  TOTAL_CREDIT_ADJUSTMENTS: 'AJUSTES_TOTALES_DE_CREDITO',
  TOTAL_DISPUTES: 'DISPUTAS_TOTALES',
  TOTAL_SETTLEMENTS: 'LIQUIDACIONES_TOTALES',
  JOBS_FAILED: 'TRABAJOS_FALLIDOS',
  SUBMITTED_ORDER: 'ORDEN_ENVIADA',
  USAGE_TRANSACTION: 'TRANSACCION_DE_USO',
  RATED_USAGE_TRANSACTION: 'TRANSACCIÓN_DE_USO_TASADA',
  TOTAL_JOBS: 'TOTAL_DE_TRABAJOS',
  RECOGNIZED_REVENUE: 'INGRESOS_RECONOCIDOS',
  TOTAL_WRITEOFF: 'TOTAL_CANCELACIONES',
  ACTIVE_SUBSCRIPTIONS: 'SUSCRIPCIONES_ACTIVAS',
  TOTAL_DEBIT_ADJUSTMENTS: 'TOTAL_DE_AJUSTES_DÉBITO',
  REVENUE_BY_PRODUCT_COMPANY: 'INGRESOS_POR_PRODUCTO_COMPAÑÍA',
  REVENUE_BY_REVENUE_TYPE: 'INGRESOS_POR_TIPO_DE_INGRESOS',
  CUSTOMER_COUNT_BY_ACCOUNT_TYPE: 'RECUENTO_DE_CLIENTES_POR_TIPO_DE_CUENTA',
  CUSTOMER_COUNT_BY_SELLING_COMPANY: 'RECUENTO_DE_CLIENTES_POR_COMPAÑÍA_VENDEDORA',
  REVENUE_FORECAST: 'PRONÓSTICO_DE_INGRESOS',

  // Revenue
  CREATE_LOCATION_CONFIG: 'CREAR_CONFIG_UBICACIÓN',
  GET_LOCATION_CONFIG: 'OBTENER_CONFIG_UBICACIÓN',
  MODIFY_LOCATION_CONFIG: 'MODIFICAR_CONFIG_UBICACIÓN',
  CREATE_CURRENCY_EXCHANGE_CONFIG: 'CREAR_CONFIGURACIÓN_DE_CAMBIO_DE_DIVISAS',
  GET_CURRENCY_EXCHANGE_CONFIG: 'OBTENER_CONFIGURACIÓN_DE_CAMBIO_DE_DIVISAS',
  MODIFY_CURRENCY_EXCHANGE_CONFIG: 'MODIFICAR_LA_CONFIGURACIÓN_DE_CAMBIO_DE_DIVISAS',
  CREATE_MILESTONE_CONFIG: 'CREAR_CONFIGURACIÓN_DE_HITO',
  GET_MILESTONE_CONFIG: 'OBTENER_LA_CONFIGURACIÓN_DE_HITO',
  MODIFY_MILETSTONE_CONFIG: 'MODIFICAR_LA_CONFIGURACIÓN_DE_HITO',
  CREATE_GL_ACCOUNTS_CONFIG: 'CREAR_CONFIG_CUENTAS_LIBRO_MAYOR',
  GET_GL_ACCOUNTS_CONFIG: 'OBTENER_CONFIG_CUENTAS_LIBRO_MAYOR',
  MODIFY_GL_ACCOUNTS_CONFIG: 'MODIFICAR_CONFIG_CUENTAS_LIBRO_MAYOR',
  CREATE_LEDGER_MAP: 'CREAR_MAPA_DE_LIBRO_MAYOR',
  GET_LEDGER_MAP: 'OBTENER_MAPA_DE_LIBRO_CONTABLE',
  MODIFY_LEDGER_MAP: 'MODIFICAR_MAPA_DE_LIBRO_CONTABLE',
  CREATE_SUB_LEDGER_MAP: 'CREAR_MAPA_DE_SUB_LIBRO_CONTABLE',
  GET_SUB_LEDGER_MAP: 'OBTENER_SUB_MAPA_DE_LIBRO_CONTABLE',
  MODIFY_SUB_LEDGER_MAP: 'MODIFICAR_MAPA_SUB_LIBRO_CONTABLE',
  CREATE_MANUAL_RECOGNITION: 'CREAR_RECONOCIMIENTO_MANUAL',
  VIEW_LEDGER: 'VER_LIBRO_CONTABLE',
  VIEW_SUB_LEDGER: 'VER_SUB-LIBRO_CONTABLE',
  VIEW_BALANCE_SHEET: 'VER_BALANCE_GENERAL',
  VIEW_P_AND_L: 'VER_P_&_G',
  CREATE_ITEM_SPLIT_CONFIG: 'CREAR_CONFIGURACIÓN_DIVIDIDA_DE_ARTÍCULOS',
  MODIFY_ITEM_SPLIT_CONFIG: 'MODIFICAR_CONFIGURACIÓN_DIVISIÓN_DE_ARTÍCULOS_',
  GET_ITEM_SPLIT_CONFIG: 'OBTENER_CONFIGURACIÓN_DIVISIÓN_DE_ARTÍCULOS',
  CREATE_CALENDAR_CONFIG: 'CREAR_CONFIGURACIÓN_DE_CALENDARIO',
  GET_CALENDAR_CONFIG: 'OBTENER_CONFIGURACIÓN_DE_CALENDARIO',
  MODIFY_CALENDAR_CONFIG: 'MODIFICAR_LA_CONFIGURACIÓN_DEL_CALENDARIO',
  CREATE_ENTERPRISE_CONFIG: 'CREAR_CONFIGURACIÓN_DE_LA_EMPRESA',
  GET_ENTERPRISE_CONFIG: 'OBTENER_CONFIGURACIÓN_DE_LA_EMPRESA',
  MODIFY_ENTERPRISE_CONFIG: 'MODIFICAR_CONFIGURACIÓN_DE_LA_EMPRESA',
  CREATE_DIVISIONS_CONFIG: 'CREAR_CONFIGURARCIÓN_DIVISIONES',
  GET_DIVISIONS_CONFIG: 'OBTENER_CONFIGURACIÓN_DE_DIVISIONES',
  MODIFY_DIVISIONS_CONFIG: 'MODIFICAR_CONFIGURACIÓN_DE_DIVISIONES',
  CREATE_LEGAL_ENTITY_CONFIG: 'CREAR_CONFIGURACIÓN_DE_ENTIDAD_LEGAL',
  GET_LEGAL_ENTITY_CONFIG: 'OBTENER_CONFIGURACIÓN_DE_ENTIDAD_LEGAL',
  MODIFY_LEGAL_ENTITY_CONFIG: 'MODIFICAR_LA_CONFIGURACIÓN_DE_LA_ENTIDAD_LEGAL',
  CREATE_BUSINESS_UNIT_CONFIG: 'CREAR_CONFIGURACIÓN_DE_UNIDAD_DE_NEGOCIO',
  GET_BUSINESS_UNIT_CONFIG: 'OBTENER_LA_CONFIGURACIÓN_DE_LA_UNIDAD_DE_NEGOCIO',
  MODIFY_BUSINESS_UNIT_CONFIG: 'MODIFICAR_LA_CONFIGURACIÓN_DE_LA_UNIDAD_DE_NEGOCIO',
  CREATE_DEPARTMENT_CONFIG: 'CREAR_CONFIGURACIÓN_DE_DEPARTAMENTO',
  GET_DEPARTMENT_CONFIG: 'OBTENER_LA_CONFIGURACIÓN_DEL_DEPARTAMENTO',
  MODIFY_DEPARTMENT_CONFIG: 'MODIFICAR_LA_CONFIGURACIÓN_DEL_DEPARTAMENTO',
  CREATE_COST_CENTER_CONFIG: 'CREAR_LA_CONFIGURACIÓN_DEL_CENTRO_DE_COSTOS',
  GET_COST_CENTER_CONFIG: 'OBTENER_LA_CONFIGURACIÓN_DEL_CENTRO_DE_COSTOS',
  MODIFY_COST_CENTER_CONFIG: 'MODIFICAR_LA_CONFIGURACIÓN_DEL_CENTRO_DE_COSTOS',
  CREATE_COA_CONFIG: 'CREAR_CONFIGURACIÓN_DE_CATÁLOGO_DE_CUENTAS',
  GET_COA_CONFIG: 'OBTENER_CONFIG_CATÁLOGO_DE_CUENTAS',
  MODIFY_COA_CONFIG: 'MODIFICAR_CONFIGURACIÓN_DE_CATÁLOGO_DE_CUENTAS',

  // Correspondence
  CREATE_PAYMENT_FAILURE_TEMPLATE: 'CREAR_PLANTILLA_DE_FALLO_DE_PAGO',
  MODIFY_PAYMENT_FAILURE_TEMPLATE: 'MODIFICAR_PLANTILLA_DE_FALLO_DE_PAGO',
  VIEW_PAYMENT_FAILURE_TEMPLATE: 'VER_PLANTILLA_DE_FALLO_DE_PAGO',
  CREATE_FIRST_PAYMENT_REMINDER_TEMPLATE: 'CREAR_PLANTILLA_DE_RECORDATORIO_DE_PRIMER_PAGO',
  MODIFY_FIRST_PAYMENT_REMINDER_TEMPLATE: 'MODIFICAR_LA_PLANTILLA_DE_RECORDATORIO_DEL_PRIMER_PAGO',
  VIEW_FIRST_PAYMENT_REMINDER_TEMPLATE: 'VER_PLANTILLA_DE_RECORDATORIO_DE_PRIMER_PAGO',
  CREATE_SECOND_PAYMENT_REMINDER_TEMPLATE: 'CREAR_PLANTILLA_DE_RECORDATORIO_DE_SEGUNDO_PAGO',
  MODIFY_SECOND_PAYMENT_REMINDER_TEMPLATE: 'MODIFICAR_LA_PLANTILLA_DE_RECORDATORIO_DEL_SEGUNDO_PAGO',
  VIEW_SECOND_PAYMENT_REMINDER_TEMPLATE: 'VER_PLANTILLA_DE_RECORDATORIO_DEL_SEGUNDO_PAGO',
  CREATE_THIRD_PAYMENT_REMINDER_TEMPLATE: 'CREAR_PLANTILLA_DE_RECORDATORIO_DE_TERCER_PAGO',
  MODIFY_THIRD_PAYMENT_REMINDER_TEMPLATE: 'MODIFICAR_LA_PLANTILLA_DE_RECORDATORIO_DE_TERCER_PAGO',
  VIEW_THIRD_PAYMENT_REMINDER_TEMPLATE: 'VER_PLANTILLA_DE_RECORDATORIO_DE_TERCER_PAGO',
  CREATE_PAYMENT_SUCCESS_TEMPLATE: 'CREAR_PLANTILLA_DE_PAGO_EXITOSO',
  MODIFY_PAYMENT_SUCCESS_TEMPLATE: 'MODIFICAR_LA_PLANTILLA_DE_PAGO_EXITOSO',
  VIEW_PAYMENT_SUCCESS_TEMPLATE: 'VER_PLANTILLA_DE_PAGO_EXITOSO',
  CREATE_NEW_SUBSCRIPTION_TEMPLATE: 'CREAR_NUEVA_PLANTILLA_DE_SUSCRIPCIÓN',
  MODIFY_NEW_SUBSCRIPTION_TEMPLATE: 'MODIFICAR_NUEVA_PLANTILLA_DE_SUSCRIPCIÓN',
  VIEW_NEW_SUBSCRIPTION_TEMPLATE: 'VER_NUEVA_PLANTILLA_DE_SUSCRIPCIÓN',
  CREATE_CANCEL_SUBSCRIPTION_TEMPLATE: 'CREAR_PLANTILLA_DE_CANCELACIÓN_DE_SUSCRIPCIÓN',
  MODIFY_CANCEL_SUBSCRIPTION_TEMPLATE: 'MODIFICAR_PLANTILLA_DE__CANCELACIÓN_DE_SUSCRIPCIÓN',
  VIEW_CANCEL_SUBSCRIPTION_TEMPLATE: 'VER_PLANTILLA_DE_CANCELAR_SUSCRIPCIÓN',
  CREATE_RENEWAL_DUE_TEMPLATE: 'CREAR_PLANTILLA_DE_RENOVACIÓN_VENCIDA',
  MODIFY_RENEWAL_DUE_TEMPLATE: 'MODIFICAR_LA_PLANTILLA_DE_RENOVACIÓN_VENCIDA',
  VIEW_RENEWAL_DUE_TEMPLATE: 'VER_PLANTILLA_DE_RENOVACIÓN_VENCIDA',
  CREATE_INVOICE_READY_TEMPLATE: 'CREAR_PLANTILLA_FACTURA_LISTA',
  MODIFY_INVOICE_READY_TEMPLATE: 'MODIFICAR_PLANTILLA_FACTURA_LISTA',
  VIEW_INVOICE_READY_TEMPLATE: 'VER_PLANTILLA_DE_FACTURA_LISTA',
  CREATE_TRIAL_EXPIRY_TEMPLATE: 'CREAR_PLANTILLA_DE_CADUCIDAD_DE_PERIODO_DE_PRUEBA',
  MODIFY_TRIAL_EXPIRY_TEMPLATE: 'MODIFICAR_PLANTILLA_DE_CADUCIDAD_DE_PERIODO_DE_PRUEBA',
  VIEW_TRIAL_EXPIRY_TEMPLATE: 'VER_PLANTILLA_DE_CADUCIDAD_DE_PERIODO_DE_PRUEBA',
  CREATE_CREDIT_LIMIT_TEMPLATE: 'CREAR_PLANTILLA_DE_LÍMITE_DE_CRÉDITO',
  MODIFY_CREDIT_LIMIT_TEMPLATE: 'MODIFICAR_PLANTILLA_DE_LÍMITE_DE_CRÉDITO',
  VIEW_CREDIT_LIMIT_TEMPLATE: 'VER_PLANTILLA_DE_LÍMITE_DE_CRÉDITO',
  CREATE_CREDIT_THRESHOLD_TEMPLATE: 'CREAR_PLANTILLA_DE_UMBRAL_DE_CRÉDITO',
  MODIFY_CREDIT_THRESHOLD_TEMPLATE: 'MODIFICAR_PLANTILLA_DE_UMBRAL_DE_CRÉDITO',
  VIEW_CREDIT_THRESHOLD_TEMPLATE: 'VER_PLANTILLA_DE_UMBRAL_DE_CRÉDITO',
  CREATE_INVOICE_DUE_DATE_TEMPLATE: 'CREAR_PLANTILLA_DE_FECHA_DE_VENCIMIENTO_DE_FACTURA',
  MODIFY_INVOICE_DUE_DATE_TEMPLATE: 'MODIFICAR_FECHA_DE_VENCIMIENTO_DE_FACTURA_PLANTILLA',
  VIEW_INVOICE_DUE_DATE_TEMPLATE: 'VER_PLANTILLA_DE_FECHA_DE_VENCIMIENTO_DE_FACTURAS',
  CREATE_OPS_JOB_FAILURE_TEMPLATE: 'CREAR_PLANTILLA_DE_ERROR_EN_EL_TRABAJO_DE_OPERACIONES',
  MODIFY_OPS_JOB_FAILURE_TEMPLATE: 'MODIFICAR_LA_PLANTILLA_DE_ERROR_DE_TRABAJO_DE_OPERACIONES',
  VIEW_OPS_JOB_FAILURE_TEMPLATE: 'VER_PLANTILLA_DE_FALLA_DE_TRABAJO_DE_OPERACIONES',

  // Tax config
  CREATE_TAX_CONFIG: 'CREAR_CONFIG_IMPUESTOS',
  MODIFY_TAX_CONFIG: 'MODIFICAR_CONFIG_IMPUESTOS',
  GET_TAX_CONFIG: 'OBTENER_CONFIG_IMPUESTOS',

  // New tenant
  CREATE_QUEUE_CONFIG: 'CREAR_CONFIGURACIÓN_QUEUE',
  MODIFY_QUEUE_CONFIG: 'MODIFICAR_CONFIGURACIÓN_QUEUE',
  GET_QUEUE_CONFIG: 'OBTENER_CONFIGURACIÓN_QUEUE',
  CREATE_OMS_TASK_CONFIG: 'CREAR_CONFIG_TAREA_OMS',
  MODIFY_OMS_TASK_CONFIG: 'MODIFICAR_CONFIG_TAREA_OMS',
  GET_OMS_TASK_CONFIG: 'OBTENER_CONFIG_TAREA_OMS',
  CREATE_PROV_SEQUENCE_CONFIG: 'CREAR_CONFIG_SECUENCIA_APROVISIONAMIENTO',
  MODIFY_PROV_SEQUENCE_CONFIG: 'MODIFICAR_CONFIG_SECUENCIA_APROVISIONAMIENTO',
  GET_PROV_SEQUENCE_CONFIG: 'OBTENER_CONFIG_SECUENCIA_APROVISIONAMIENTO',

  // New Billing
  CREATE_IN_ADVANCE_BILLING_CONFIG: 'CREAR_CONFIG_FACTURACIÓN_ANTICIPADA',
  MODIFY_IN_ADVANCE_BILLING_CONFIG: 'MODIFICAR_CONFIG_FACTURACIÓN_ANTICIPADA',
  GET_IN_ADVANCE_BILLING_CONFIG: 'OBTENER_CONFIG_FACTURACIÓN_ANTICIPADA',

  // New permissions
  BATCH_PAYMENT_UPLOAD: 'CARGA_PAGO_BATCH',
  BATCH_PAYMENT_PROCESS: 'PROCESAR_PAGO_BATCH',
  VIEW_BANAMEX_PAYMENT: 'VER_PAGO_BANAMEX',
  VIEW_BANORTE_PAYMENT: 'VER_PAGO_BANORTE',
  VIEW_BANCOMER_PAYMENT: 'VER_PAGO_BANCOMER',
  VIEW_SANTANDER_PAYMENT: 'VER_PAGO_SANTANDER',
  CREATE_PAYMENT_SURCHARGE_CONFIG: 'CREAR_COFING_PAGO_RECARGO',
  MODIFY_PAYMENT_SURCHARGE_CONFIG: 'MODIFICAR_COFING_PAGO_RECARGO',
  GET_PAYMENT_SURCHARGE_CONFIG: 'OBTENER_COFING_PAGO_RECARGO',
  CREATE_PENDING_BILL_TRIGGER_CONFIG: 'CREAR_CONFIG_ACTIVADOR_FACTURACIÓN_PENDIENTE',
  MODIFY_PENDING_BILL_TRIGGER_CONFIG: 'MODIFICAR_CONFIG_ACTIVADOR_FACTURACIÓN_PENDIENTE',
  GET_PENDING_BILL_TRIGGER_CONFIG: 'OBTENER_CONFIG_ACTIVADOR_FACTURACIÓN_PENDIENTE',
  CREATE_OPERATOR_MAP_CONFIG: 'CREAR_COFING_MAPEO_OPERADOR',
  MODIFY_OPERATOR_MAP_CONFIG: 'MODIFICAR_COFING_MAPEO_OPERADOR',
  GET_OPERATOR_MAP_CONFIG: 'OBTENER_COFING_MAPEO_OPERADOR',
  CREATE_TRUNK_RULES_CONFIG: 'CREAR_CONFIG_REGLAS_TRONCALES',
  MODIFY_TRUNK_RULES_CONFIG: 'MODIFICAR_CONFIG_REGLAS_TRONCALES',
  GET_TRUNK_RULES_CONFIG: 'OBTENER_CONFIG_REGLAS_TRONCALES',
  CREATE_SELF_CARE_CONFIG: 'CREAR_CONFIG_AUTO_ATENCIÓN',
  MODIFY_SELF_CARE_CONFIG: 'MODIFICAR_CONFIG_AUTO_ATENCIÓN',
  GET_SELF_CARE_CONFIG: 'OBTENER_CONFIG_AUTO_ATENCIÓN',

  CREATE_CONFIG_AR_OPS_THRESHOLDS: 'CREAR_CONFIG_UMBRALES_OPS_CXC',
  MODIFY_CONFIG_AR_OPS_THRESHOLDS: 'MODIFICAR_CONFIG_UMBRALES_OPS_CXC',
  GET_CONFIG_AR_OPS_THRESHOLDS: 'OBTENER_CONFIG_UMBRALES_OPS_CXC',
  CREATE_CONFIG_GROUP_REASON_CODE: 'CREAR_CONFIG_GRUPO_CÓDIGOS_DE_MOTIVOS',
  MODIFY_CONFIG_GROUP_REASON_CODE: 'MODIFICAR_CONFIG_GRUPO_CÓDIGOS_DE_MOTIVOS',
  GET_CONFIG_GROUP_REASON_CODE: 'OBTENER_CONFIG_CÓDIGO_DE_GRUPO_DE_MOTIVOS',
  CREATE_CONFIG_APPROVALS: 'CREAR_CONFIG_APROBACIONES',
  MODIFY_CONFIG_APPROVALS: 'MODIFICAR_CONFIG_APROBACIONES',
  GET_CONFIG_APPROVALS: 'OBTENER_CONFIG_APROBACIONES',
  CREATE_OBJECT_APPROVALS: 'CREAR_APROBACIONES_DE_OBJETOS',
  MODIFY_OBJECT_APPROVALS: 'MODIFICAR_APROBACIONES_DE_OBJETOS',
  GET_OBJECT_APPROVALS: 'OBTENER_APROBACIONES_DE_OBJETOS',
  CREATE_CONFIG_USER_GROUPS: 'CREAR_CONFIG_GRUPOS_DE_USUARIOS',
  MODIFY_CONFIG_USER_GROUPS: 'MODIFICAR_CONFIG_GRUPOS_DE_USUARIOS',
  GET_CONFIG_USER_GROUPS: 'OBTENER_CONFIG_GRUPOS_USUARIOS',
  CREATE_NPA_SPECIAL_PRICE_RATE: 'CREAR_TARIFA_ESPECIAL_PRECIOS_NPA',
  MODIFY_NPA_SPECIAL_PRICE_RATE: 'MODIFICAR_TARIFA_ESPECIAL_PRECIOS_NPA',
  GET_NPA_SPECIAL_PRICE_RATE: 'OBTENER_TARIFA_ESPECIAL_PRECIOS_NPA',
  CREATE_UOM_CONFIG: 'CREAR_CONFIG_UNIDAD_DE_MEDIDA',
  MODIFY_UOM_CONFIG: 'MODIFICAR_CONFIG_UNIDAD_DE_MEDIDA',
  SEARCH_UOM_CONFIG: 'BUSCAR_CONFIG_UNIDAD_DE_MEDIDA',

  // New permissions
  CREATE_FLEX_ATTRIBUTE_CONFIG: 'CREAR_CONFIG_ATRIBUTOS_FLEX',
  MODIFY_FLEX_ATTRIBUTE_CONFIG: 'MODIFICAR_CONFIG_ATRIBUTOS_FLEX',
  GET_FLEX_ATTRIBUTES_CONFIG: 'OBTENER_CONFIG_ATRIBUTOS_FLEX',

  CREATE_CONFIG_ACCOUNTING_RULES: 'CREAR_CONFIG_REGLAS_CONTABLES',
  GET_CONFIG_ACCOUNTING_RULES: 'OBTENER_CONFIG_REGLAS_CONTABLES',
  MODIFY_CONFIG_ACCOUNTING_RULES: 'MODIFICAR_CONFIG_REGLAS_CONTABLES',

  GET_VOLUME_AMOUNT_BY_USAGE_TYPE: 'OBTENER_CANTIDAD_DE_VOLUMEN_POR_TIPO_DE_USO ',
  GET_VOLUME_AMOUNT_PER_OPERATOR: 'OBTENER_CANTIDAD_DE_VOLUMEN_POR_OPERADOR',
  EXCESS_VOICE_TRAFFIC_REPORT: 'REPORTE_DE_EXCESO_DE_TRÁFICO_DE_VOZ',
  VOICE_TRAFFIC_BY_LD_DESTINATION_REPORT: 'REPORTE_DE_TRÁFICO_DE_VOZ_POR_DESTINO_LD',
  DATA_SERVICE_REPORT: 'REPORTE_DE_SERVICIO_DE_DATOS',
  NEW_SMS_REPORT: 'REPORTE_NUEVOS_SMS',
  RAW_INTERCONNECT_VOICE_REPORT: 'REPORTE_DE_INTERCONEXIÓN_DE_VOZ_SIN_PROCESAR ',
  VOICE_CALL_TYPE_SUMMARY_REPORT: 'REPORTE_RESUMIDO_DEL_TIPO_DE_LLAMADA_DE_VOZ ',
  RAW_INTERCONNECT_SMS_REPORT: 'REPORTE_DE_SMS_DE_INTERCONEXIÓN_SIN_PROCESAR',
  GET_CREDIT_NOTE_REPORT: 'OBTENER_REPORTE_DE_NOTAS_DE_CRÉDITO',

  MONTHLY_RECURRING_REVENUE: 'INGRESO_MENSUAL_RECURRENTE',
  USAGE_DATA_BY_SERVICE_TYPE: 'DATOS_DE_USO_POR_TIPO_DE_SERVICIO',
  USAGE_DATA_BY_CALL_TYPE: 'DATOS_DE_USO_POR_TIPO_DE_LLAMADA',

  CREATE_ITEM_SERVICES: 'CREAR_SERVICIOS_DE_ARTÍCULO',
  MODIFY_ITEM_SERVICES: 'MODIFICAR_SERVICIOS_DE_ARTÍCULO',
  SEARCH_ITEM_SERVICES: 'BUSCAR_SERVICIOS_DE_ARTÍCULO',
  BILLABLE_SERVICES_REPORT: 'REPORTE_DE_SERVICIOS_FACTURABLES',
  INTERNET_OPERATOR_DATA_COST_REPORT: 'REPORTE_DE_COSTOS_DE_DATOS_DEL_OPERADOR_DE_INTERNET',

  TELECOM_SUMMARY_REPORT: 'REPORTE_RESUMIDO_DE_TELECOMUNICACIONES',
  CREATE_BILLING_ACCOUNT: 'CREAR_CUENTA_DE_FACTURACIÓN',
  PROCESS_SPLIT_ORDER: 'DESHACER_PROCESO_DIVISIÓN_DE_ORDEN',

  REFRESH_JOB_SCHEDULE: 'ACTUALIZAR_PROGRAMA_TRABAJOS',

  CREATE_PAYMENT_ITEM_MAP: 'CREAR_MAPA_DE_ARTÍCULOS_DE_PAGO',
  MODIFY_PAYMENT_ITEM_MAP: 'MODIFICAR_MAPA_DE_ARTÍCULOS_DE_PAGO',
  GET_PAYMENT_ITEM_MAP: 'OBTENER_MAPA_DE_ARTÍCULOS_DE_PAGO',
  CREATE_BATCH_PAYMENT_FILE_CONFIG: 'CREAR_CONFIG_DE_ARCHIVO_DE_PAGOS_BATCH',
  MODIFY_BATCH_PAYMENT_FILE_CONFIG: 'MODIFICAR_CONFIG_DE_ARCHIVO_DE_PAGOS_BATCH',
  GET_BATCH_PAYMENT_FILE_CONFIG: 'OBTENER_CONFIG_DE_ARCHIVO_DE_PAGOS_BATCH',

  CREATE_CONFIG_USAGE_TYPE: 'CREAR_CONFIG_DE_TIPO_DE_USO',
  MODIFY_CONFIG_USAGE_TYPE: 'MODIFICAR_CONFIG_DE_TIPO_DE_USO',
  GET_CONFIG_USAGE_TYPE: 'OBTENER_CONFIG_DE_TIPO_DE_USO',

  GET_ORDER_REASON_CODES_CONFIG: 'OBENER_CONFIG_DE_CÓDIGOS_DE_MOTIVO_DE_ORDEN',
  CREATE_ORDER_REASON_CODES_CONFIG: 'CREAR_CONFIG_DE_CÓDIGOS_DE_MOTIVO_DE_ORDE',
  MODIFY_ORDER_REASON_CODES_CONFIG: 'MODIFICAR_CONFIG_DE_CÓDIGOS_DE_MOTIVO_DE_ORDE',

  SEARCH_ACCOUNT_REPORT: 'BUSCAR_REPORTE_DE_CUENTAS',
  SEARCH_MEDIATION_FILE_STATUS: 'BUSCAR_ESTÁTUS_ARCHIVO_DE-MEDIACIÓN',

  EXTEND_INVOICE_DUE_DATE: 'EXTENDER_FECHA_DE_VENCIMIENTO_DE LA_FACTURA',
  CREATE_CONFIG_CORRESPONDENCE_TEMPLATE: 'CREAR_CONFIG_PLANTILLA_CORRESPONDENCIA',
  MODIFY_CONFIG_CORRESPONDENCE_TEMPLATE: 'MODIFICAR_CONFIG_PLANTILLA_CORRESPONDENCIA',
  VIEW_CONFIG_CORRESPONDENCE_TEMPLATE: 'VER_CONFIG_PLANTILLA_CORRESPONDENCIA',

  GET_ORDER_ACCOUNT_SUMMARY: 'OBTENER_RESUMEN_DE_LA_ORDEN_DE_LA_CUENTA',
  UPDATE_BILLING_ACCOUNT: 'ACTUALIZAR_CUENTA_DE_FACTURACIÓN',
  CREATE_CONFIG_COUNTRY_CODES: 'CREAR_CONFIG_DE_CÓDIGOS_DE_PAÍS',
  MODIFY_CONFIG_COUNTRY_CODES: 'MODIFICAR_CONFIG_DE_CÓDIGOS_DE_PAÍS',
  SEARCH_CONFIG_COUNTRY_CODES: 'BUSCAR_CONFIG_DE_CÓDIGOS_DE_PAÍS',
  CREATE_ACCOUNT_EXCHANGE_RATES: 'CREAR_TIPOS_DE_CAMBIO_DE_CUENTA',
  MODIFY_ACCOUNT_EXCHANGE_RATES: 'MODIFICAR_TIPOS_DE_CAMBIO_DE_CUENTA',
  GET_ACCOUNT_EXCHANGE_RATES: 'OBTENER_TIPOS_DE_CAMBIO_DE_CUENTA',

  ADD_VOICE_PLAN: 'AGREGAR_PLAN_DE_VOZ',
  MODIFY_VOICE_PLAN: 'MODIFICAR_PLAN_DE_VOZ',
  SEARCH_VOICE_PLAN: 'BUSCAR_PLAN_DE_VOZ',
  CREATE_SEG_MAPPING: 'CREAR_MAPEO_DE_SEGMENTOS',
  MODIFY_SEG_MAPPING: 'MODIFICAR_MAPEO_DE_SEGMENTOS',
  GET_SEG_MAPPING: 'OBTENER_MAPEO_DE_SEGMENTOS',

  CREATE_CUSTOM_DB_SEQUENCE_CONFIG: 'CREAR_CONFIGURACIÓN_DE_SECUENCIA_DE_BD_PERSONALIZADA',
  MODIFY_CUSTOM_DB_SEQUENCE_CONFIG: 'MODIFICAR_CONFIGURACIÓN_DE_SECUENCIA_DE_BD_PERSONALIZADA',
  GET_CUSTOM_DB_SEQUENCE_CONFIG: 'OBTENER_CONFIGURACIÓN_DE_SECUENCIA_DE_BD_PERSONALIZADA',

  GENERATE_INVOICE: 'GENERAR_FACTURA',

  CREATE_PAYMENT_INSTALLMENT: 'CREAR_PAGO_A_PLAZOS',
  MODIFY_PAYMENT_INSTALLMENT: 'MODIFICAR_PAGO_A_PLAZOS',
  GET_PAYMENT_INSTALLMENT: 'OBTENER_PAGO_A_PLAZOS',

  SEARCH_PROVISIONING_DATA: 'BUSCAR_DATOS_APROVISIONAMIENTO',
  GET_ACCOUNTING_RECONCILIATION_REPORT: 'OBTENER_REPORTE_CONCILIACIÓN_CONTABLE',
  GET_RAW_CDR_REPORT: 'OBTENER_REPORTE_CDR_SIN PROCESAR',
  GET_RAW_SMS_REPORT: 'OBTENER_REPORTE_SMS_SIN PROCESAR',

  SAVE_COST_CENTER_MAP_CONFIG: 'GUARDAR_CONFIG_DE_MAPA_DE_CENTRO_DE_COSTOS',
  GET_COST_CENTER_MAP_CONFIG: 'OBTENER_CONFIG_DE_MAPA_DE_CENTRO_DE_COSTOS',
  // GET_BROADSOFT_RAW_CDR_REPORT: 'OBTENER_REPORTE_CDR_CRUDOS_BROADSOFT',
  GET_NON_BROADSOFT_RAW_CDR_REPORT: 'OBTENER_REPORTE_CDR_CRUDOS_NO_BROADSOFT',
  THIRD_PARTY_SELF_CARE: 'AUTO_ATENCIÓN_TERCEROS',
  UNDO_PROCESS_SPLIT_ORDER: 'DESHACER_PROCESO_DIVISIÓN_DE_ORDEN',
  SEARCH_LADING_BILL: 'BUSCAR_CARTA_PORTE',
  GET_ASSETS_FORECAST_REPORT: 'OBTENER_REPORTE_FORECAST_ACTIVOS',

  EMPLOYEE_COSTING_PNL_REPORT: 'REPORTE_P&G_DE_COSTOS_DE_EMPLEADOS',
  RESEND_ERP_BATCH: 'REENVIAR_BATCH_DEL_ERP',
  BILLABLE_SERVICES_FORECAST_REPORT: 'REPORTE_DE_PRONÓSTICO (FORECAST)_DE_SERVICIOS_FACTURABLES',

  CREATE_RERATING_BATCH: 'CREAR_BATCH_DE_RETARIFICACIÓN',
  MODIFY_RERATING_BATCH: 'MODIFICAR_BATCH_DE_RETARIFICACIÓN',
  GET_RERATING_BATCH: 'OBTENER_BATCH_DE_RETARIFICACIÓN',
  CREATE_USAGE_PLATFORM_CONFIG: 'CREAR_CONFIG_PLATAFORMA_DE_USO',
  MODIFY_USAGE_PLATFORM_CONFIG: 'MODIFICAR_CONFIG_PLATAFORMA_DE_USO',
  GET_USAGE_PLATFORM_CONFIG: 'OBTENER_CONFIG_PLATAFORMA_DE_USO',
  GET_OBJECT_FILES_REPORT: 'OBTENER_REPORTE_DE_ARCHIVOS_DE_OBJETO',
  EDIT_SUSPENDED_FAILED_USAGE_RECORDS: 'EDITAR_REGISTROS_DE_USO_SUSPENDIDOS_FALLIDOS',
  SET_SYSTEM_TIME: 'ESTABLECER_HORA_DEL_SISTEMA',
  GET_PROVISIONING_DATA_REPORT: 'OBTENER_REPORTE_DE_APROVISIONAMIENTO_DE_DATOS',
  BILLING_TRACKER_REPORT: 'REPORTE_SEGUIMIENTO_FACTURACIÓN',
  COMMITMENT_REPORT: 'REPORTE_DE_COMPROMISOS',
  GET_PAYMENT_AGEING_REPORT: 'OBTENER_REPORTE_DE_ANTIGÜEDAD_DE_PAGOS',
  SEARCH_SERVICE_CANCELLATION_EXTRACT: 'BUSCAR_EXTRACTO_DE_CANCELACIÓN_DE_SERVICIOS',
  SEARCH_KPI_EXTRACT: 'BUSCAR_EXTRACTO_KPI',
  GET_NONBROADSOFT_ZERODURATION_CDRS_REPORT: 'OBTENER_REPORTE_NO-BROADSOFT_DE_CDRS_CON_DURACIÓN_CERO',
  GET_BROADSOFT_ZERODURATION_CDRS_REPORT: 'OBTENER_REPORTE_BROADSOFT_DE_CDRS_CON_DURACIÓN_CERO',
  GET_SUSPENDED_FAILED_RECORDS_REPORT: 'OBTENER_REPORTE_DE_REGISTROS_FALLIDOS_SUSPENDIDOS',
  GET_BI_EXTRACT: 'OBTENER_EXTRACTO_BI',
  GET_REG_R001_05_REPORT: 'OBTENER_REPORTE_REG_R001_05',
  GET_REG_R001_06_REPORT: 'OBTENER_REPORTE_REG_R001_06',
  GET_REG_R004_01_REPORT: 'OBTENER_REPORTE_REG_R004_01',
  GET_REG_R004_05_REPORT: 'OBTENER_REPORTE_REG_R004_05',
  GET_REG_R005_01_REPORT: 'OBTENER_REPORTE_REG_R005_01',
  GET_REG_R005_02_REPORT: 'OBTENER_REPORTE_REG_R005_02',
  GET_REG_R005_03_REPORT: 'OBTENER_REPORTE_REG_R005_03',
  GET_REG_R005_04_REPORT: 'OBTENER_REPORTE_REG_R005_04',
  GET_REG_R005_05_REPORT: 'OBTENER_REPORTE_REG_R005_05',
  GET_REG_R005_06_REPORT: 'OBTENER_REPORTE_REG_R005_06',
  GET_CREDIT_BUREAU_REPORT: 'OBTENER_REPORTE_BURO_DE_CRÉDITO',
  PUSH_KPI_EXTRACT: 'ENVIAR_EXTRACTO_KPI',
  CREATE_CREDIT_NOTE_READY_TEMPLATE: 'CREAR_PLANTILLA_NOTA_DE_CRÉDITO_LISTA',
  MODIFY_CREDIT_NOTE_READY_TEMPLATE: 'MODIFICAR_PLANTILLA_NOTA_DE_CRÉDITO_LISTA',
  VIEW_CREDIT_NOTE_READY_TEMPLATE: 'VER_PLANTILLA_NOTA_DE_CRÉDITO_LISTA',
  CREATE_PROV_ATTRIBUTE_CONFIG: 'CREAR_CONFIG_ATRIBUTO_APROVISIONAMIENTO',
  MODIFY_PROV_ATTRIBUTE_CONFIG: 'MODIFICAR_CONFIG_ATRIBUTO_APROVISIONAMIENTO',
  GET_PROV_ATTRIBUTE_CONFIG: 'OBTENER_CONFIG_ATRIBUTO_APROVISIONAMIENTO',
  FOLIO_SUMMARY: 'RESUMEN_DE_FOLIO',
  SAVE_QUOTE: 'GUARDAR_COTIZACIÓN',
  SEARCH_QUOTE: 'BUSCAR_COTIZACIÓN',
  CREATE_TASK: 'CREAR_TAREA',
  MODIFY_TASK: 'MODIFICAR_TAREA',
  SEARCH_TASKS: 'TAREAS_DE_BÚSQUEDA',
  CREATE_CHILD_TASK: 'CREAR_TAREA_SECUNDARIA',
  GET_CHILD_TASKS: 'OBTENER_TAREA_SECUNDARIA',
  SYNC_TASK_TO_EXTERNAL: 'SINCRONIZAR_TAREA_A_EXTERNA',
  SYNC_TASK_STATUS_FROM_EXTERNAL: 'SINCRONIZAR_ESTATUS_DE_TAREAS_DESDE_EXTERNO',

  CONVERT_QUOTE: 'CONVERTIR_COTIZACIÓN',
  CANCEL_QUOTE: 'CANCELAR_COTIZACIÓN',
  APPROVE_QUOTE: 'APROBAR_COTIZACIÓN',
  NEW_QUOTE_TYPE: 'NUEVO_TIPO_DE_COTIZACIÓN',
  MODIFY_QUOTE_TYPE: 'MODIFICAR_TIPO_DE_COTIZACIÓN',
  CANCEL_QUOTE_TYPE: 'CANCELAR_TIPO_DE_COTIZACIÓN',
  GET_QUOTE: 'OBTENER_COTIZACIÓN',
  UPGRADE_QUOTE_TYPE: 'SUBIR_DE_CATEGORÍA_TIPO_DE_COTIZACIÓN',
  DOWNGRADE_QUOTE_TYPE: 'BAJAR_DE_CATEGORÍA_TIPO_DE_COTIZACIÓN',
  OVERRIDE_QUOTE_LINES: 'ANULAR_LÍNEAS_DE_COTIZACIÓN',
  UPDATE_PROVISIONING_ATTRIBUTES: 'ACTUALIZAR_ATRIBUTOS_DE_APROVISIONAMIENTO',
  CREATE_SUSPEND_SUBSCRIPTION_TEMPLATE: 'CREAR_PLANTILLA_DE_SUSPENSIÓN_DE_SUSCRIPCIÓN',
  MODIFY_SUSPEND_SUBSCRIPTION_TEMPLATE: 'MODIFICAR_PLANTILLA_DE_SUSPENSIÓN_DE_SUSCRIPCIÓN',
  VIEW_SUSPEND_SUBSCRIPTION_TEMPLATE: 'VER_PLANTILLA_DE_SUSPENSIÓN_DE_SUSCRIPCIÓN',
  TASK_ADMIN: 'ADMIN_DE_TAREAS',
  BULK_ADJUSTMENT: 'AJUSTE_MASIVO',
  CREATE_TOPUP_SYSTEM_OFFER_CONFIG: 'CREAR_CONFIG_DE_OFERTAS_DEL_SISTEMA_DE_RECARGAS',
  MODIFY_TOPUP_SYSTEM_OFFER_CONFIG: 'MODIFICAR_CONFIG_DE_OFERTAS_DEL_SISTEMA_DE_RECARGAS',
  SEARCH_TOPUP_SYSTEM_OFFER_CONFIG: 'BUSCAR_CONFIG_DE_OFERTAS_DEL_SISTEMA_DE_RECARGAS',
  CREATE_COLLECTION_ENTRY_TEMPLATE: 'CREAR_PLANTILLA_DE_ENTRADA_DE_COBRANZA',
  MODIFY_COLLECTION_ENTRY_TEMPLATE: 'MODIFICAR_PLANTILLA_DE_ENTRADA_DE_COBRANZA',
  VIEW_COLLECTION_ENTRY_TEMPLATE: 'VER_PLANTILLA_DE_ENTRADA_DE_COBRANZA',

  CREATE_ZONE_UNIT_CONFIG: 'CREAR_CONFIGURACIÓN_DE_UNIDAD_DE_ZONA',
  MODIFY_ZONE_UNIT_CONFIG: 'MODIFICAR_CONFIGURACIÓN_DE_UNIDAD_DE_ZONA',
  VIEW_ZONE_UNIT_CONFIG: 'VER_CONFIGURACIÓN_DE_UNIDAD_DE_ZONA',

  GET_TAX_TYPE_CONFIG: 'OBTENER_CONFIG_TIPO_DE_IMPUESTO',
  MODIFY_TAX_TYPE_CONFIG: 'MODIFICAR_CONFIG_TIPO_DE_IMPUESTO',
  CREATE_TAX_TYPE_CONFIG: 'CREAR_CONFIG_TIPO_DE_IMPUESTO',

  CREATE_COLLECTION_PROFILE: 'CREAR_PERFIL_DE_COBRANZA',
  MODIFY_COLLECTION_PROFILE: 'MODIFICAR_PERFIL_DE_COBRANZA',
  SEARCH_COLLECTION_PROFILE: 'BUSCAR_PERFIL_DE_COBRANZA',

  GET_PAYMENT_ALLOCATION_CONFIG: 'OBTENER_CONFIGURACIÓN_DE_ASIGNACIÓN_DE_PAGO',
  MODIFY_PAYMENT_ALLOCATION_CONFIG: 'MODIFICAR_CONFIGURACIÓN_DE_ASIGNACIÓN_DE_PAGO',
  CREATE_PAYMENT_ALLOCATION_CONFIG: 'CREA_CONFIGURACION_DE_ALOCACION_DE_PAGO',
  REGENERATE_PAYMENT_FOLIO: 'REGENERAR_FOLIO_DE_PAGO',
  RESEND_TO_VENDOR: 'REENVIAR_AL_PROVEEDOR',
  GET_ACCOUNT_STATEMENT: 'OBTENER_ESTADO_DE_CUENTA',
  SEARCH_RATED_USAGE: 'BUSCAR_USO_CALIFICADO',
  UNDO_INVOICE_CANCEL: 'DESHACER_CANCELACIÓN_FACTURA',
  SEARCH_SAVED_MANUAL_INVOICE: 'BUSCAR_FACTURA_MANUAL_GUARDADA',
  CREDIT_NOTE_APPROVAL: 'APROBACIÓN_DE_NOTA_DE_CRÉDITO',
  TAX_EXEMPTION_REPORT: 'INFORME_DE_EXENCIÓN_DE_IMPUESTOS',
  FONATEL_REPORT: 'INFORME_FONATEL',
  PHC_ASSET_REPORT: 'INFORME_DE_ACTIVO_PHC',
  SERVICE_RECON_REPORT: 'INFORME_DE_RECON_DE_SERVICIO',
  CREATE_EXPENSES_CONFIG: 'CREAR_CONFIGURACIÓN_DE_GASTOS',
  GET_EXPENSES_CONFIG: 'OBTENER_CONFIGURACIÓN_DE_GASTOS',
  MODIFY_EXPENSES_CONFIG: 'MODIFICAR_CONFIGURACIÓN_DE_GASTOS',
  CREATE_EXPENSE_DATA: 'CREAR_DATOS_DE_GASTO',
  MODIFY_EXPENSE_DATA: 'MODIFICAR_DATOS_DE_GASTO',
  SEARCH_EXPENSE_DATA: 'BUSCAR_DATOS_DE_GASTO',
  CREATE_REGULATORY_PRODUCT_CODE: 'CREAR_CÓDIGO_DE_PRODUCTO_REGULATORIO',
  MODIFY_REGULATORY_PRODUCT_CODE: 'MODIFICAR_CÓDIGO_DE_PRODUCTO_REGULATORIO',
  VIEW_REGULATORY_PRODUCT_CODE: 'VER_CÓDIGO_DE_PRODUCTO_REGULATORIO',
  CREATE_CURRENCY_CONFIG: 'CREAR_CONFIGURACIÓN_DE_MONEDA',
  CREATE_PRODUCT_FAMILY: 'CREAR_FAMILIA_DE_PRODUCTO',
  MODIFY_PRODUCT_FAMILY: 'MODIFICAR_FAMILIA_DE_PRODUCTO',
  SEARCH_PRODUCT_FAMILY: 'BUSCAR_FAMILIA_DE_PRODUCTO',
  MOVE_PROVISIONING_ORDER_TYPE: 'MOVER_TIPO_DE_ORDEN_DE_APROVISIONAMIENTO',
  CREATE_EMPLOYEE_BONUS: 'CREATE_EMPLOYEE_BONUS',
  MODIFY_EMPLOYEE_BONUS: 'MODIFY_EMPLOYEE_BONUS',
  SEARCH_EMPLOYEE_BONUS: 'SEARCH_EMPLOYEE_BONUS',
  GET_FOLIO_RESPONSE: 'OBTENER_REPUESTA_FOLIO',
  CREATE_FOLIO_RESPONSE: 'CREAR_RESPUESTA_FOLIO',
  MODIFY_FOLIO_RESPONSE: 'MODIFICAR_RESPUESTA_FOLIO',
  GET_ORDER_DETAIL_REPORT: 'GET_ORDER_DETAIL_REPORT',
  GET_SERVICE_INVOICE_SUMMARY_REPORT: 'GET_SERVICE_INVOICE_SUMMARY_REPORT',
  GET_ITEM_SUMMARY_REPORT: 'GET_ITEM_SUMMARY_REPORT',
};
