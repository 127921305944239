export const GET_JOBCONFIG = 'app/job_management/get_jobconfig';
export const GET_JOBCONFIG_SUCCESS = 'app/job_management/get_jobconfig_success';
export const GET_JOBCONFIG_FAILURE = 'app/job_management/get_jobconfig_failure';

export const MODIFY_JOBCONFIG = 'app/job_management/modify_jobconfig';
export const MODIFY_JOBCONFIG_SUCCESS = 'app/job_management/modify_jobconfig_success';
export const MODIFY_JOBCONFIG_FAILURE = 'app/job_management/modify_jobconfig_failure';

export const CREATE_JOBCONFIG = 'app/job_management/create_jobconfig';

export const GET_JOBSCHEDULE = 'app/job_management/get_jobschedule';
export const GET_JOBSCHEDULE_SUCCESS = 'app/job_management/get_jobschedule_success';
export const GET_JOBSCHEDULE_FAILURE = 'app/job_management/get_jobschedule_failure';

export const CREATE_JOBSCHEDULE = 'app/job_management/create_tenant_schedule';
export const CREATE_JOBSCHEDULE_SUCCESS = 'app/job_management/create_tenant_schedule_success';
export const CREATE_JOBSCHEDULE_FAILURE = 'app/job_management/create_tenant_schedule_failure';
export const RECREATE_JOBSCHEDULE = 'app/job_management/RECREATE_JOBSCHEDULE';

export const MODIFY_JOBSCHEDULE = 'app/job_management/modify_job_schedule';
export const MODIFY_JOBSCHEDULE_SUCCESS = 'app/job_management/modify_job_schedule_success';
export const MODIFY_JOBSCHEDULE_FAILURE = 'app/job_management/modify_job_schedule_failure';

export const RETRY_JOBSCHEDULE = 'app/job_management/retry_job_schedule';
export const RETRY_JOBSCHEDULE_SUCCESS = 'app/job_management/retry_job_schedule_success';
export const RETRY_JOBSCHEDULE_FAILURE = 'app/job_management/retry_job_schedule_failure';

export const PROCESS_JOBSCHEDULE = 'app/job_management/process_job_schedule';
export const PROCESS_JOBSCHEDULE_SUCCESS = 'app/job_management/process_job_schedule_success';
export const PROCESS_JOBSCHEDULE_FAILURE = 'app/job_management/process_job_schedule_failure';

export const PROCESS_ALL_JOBSCHEDULE_SUCCESS = 'app/job_management/process_all_job_schedule_success';

export const UPDATE_STATUS_JOBSCHEDULE = 'app/job_management/update_status_job_schedule';
export const UPDATE_STATUS_JOBSCHEDULE_SUCCESS = 'app/job_management/update_status_job_schedule_success';
export const UPDATE_STATUS_JOBSCHEDULE_FAILURE = 'app/job_management/update_status_job_schedule_failure';

export const GET_JOB_CCP_DATETIME = 'app/job_management/get_job_ccp_datetime';
export const GET_JOB_CCP_DATETIME_SUCCESS = 'app/job_management/get_job_ccp_datetime_success';
export const GET_JOB_CCP_DATETIME_FAILURE = 'app/job_management/get_job_ccp_datetime_failure';
export const GET_JOB_ERRORS = 'app/job_management/get_job_errors';
export const GET_JOB_ERRORS_SUCCESS = 'app/job_management/get_job_errors_success';
export const GET_JOB_ERRORS_FAILURE = 'app/job_management/get_job_errors_failure';
export const GET_JOB_ERROR_DETAILS = 'app/job_management/get_job_errors_details';
export const GET_JOB_ERROR_DETAILS_SUCCESS = 'app/job_management/get_job_error_details_success';
export const GET_JOB_ERROR_DETAILS_FAILURE = 'app/job_management/get_job_error_details_failure';
export const RESET_NOTIFICATION = 'congerocloud/reset_notification';
export const SET_NOTIFICATION = 'congerocloud/set_notification';
export const RESET_SUBSEQUENT_JOBS_FROM_ERROR_JOB = 'app/job_management/RESET_SUBSEQUENT_JOBS_FROM_ERROR_JOB';
export const GET_JOB_SCHEDULE_CONFIG_BY_FREQUENCY = 'app/job_management/GET_JOB_SCHEDULE_CONFIG_BY_FREQUENCY';
export const GET_JOB_SCHEDULE_CONFIG_BY_FREQUENCY_DAILY_SUCCESS = 'app/job_management/GET_JOB_SCHEDULE_CONFIG_BY_FREQUENCY_DAILY_SUCCESS';
export const GET_JOB_SCHEDULE_CONFIG_BY_FREQUENCY_SCHEDULED_SUCCESS = 'app/job_management/GET_JOB_SCHEDULE_CONFIG_BY_FREQUENCY_SCHEDULED_SUCCESS';


