import gql from 'graphql-tag';
import { convertFilterRequestUrl } from '../../utils/utils';

export default ({ page, size, filter, sort }) => {
  const sortStr = sort ? `, sort: ${sort}` : '';
  const filterStr = filter
    ? `, filter: ${convertFilterRequestUrl(filter, ['billType', 'accountType', 'status'])}`
    : '';
  const getServiceInvoiceSummaryReport = `getServiceInvoiceSummaryReport(page: ${page}, size: ${size}${sortStr}${filterStr})`;
  return gql`
    query {
      ${getServiceInvoiceSummaryReport} {
        accountId
        invoiceDate
        startDate
        endDate
        dueDate
        lastArAction
        invoiceNotes
        identity
        identityDocument
        firstName
        lastName
        phoneNumber
        partyId
        customAccountType
        state
        district
        neighbourhood
        collectionProfile
        paymentDate
        cancellationDate
        isInternetService
        isIptvService
        isVoipService
        subsEffectiveDate
        invoicesCount
        billType
        accountType
        invoiceUnitStatus
        accountStatus
        subscriptionStatus
        total
        due
        installmentInvoice
        invoiceUnitId
      }
    }
  `;
};
