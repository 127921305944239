import React from 'react';

const adjustmentFieldDetails = [
  {
    label: 'common:label.adjustmentType',
    name: 'type',
    tOptions: 'selections:adjustmentARType',
    disabled: true,
  },
  {
    label: 'common:label.source',
    name: 'source',
  },
  {
    label: 'common:label.CRDRType',
    name: 'crDrType',
    tOptions: 'selections:arCrDrType',
    disabled: true,
    isClearable: true,
  },
  {
    label: 'common:label.taxRule',
    name: 'taxRule',
    tOptions: 'selections:taxRule',
    disabled: true,
    isClearable: true,
  },
  {
    label: 'common:label.accountId',
    name: 'accountId',
  },
  {
    label: 'common:label.userId',
    name: 'userId',
  },
  {
    label: 'common:label.invoiceId',
    name: 'invoiceId',
  },
  {
    label: 'common:label.itemId',
    name: 'itemId',
  },
  {
    label: 'common:label.customReasonCode',
    name: 'customReason',
  },
  {
    label: 'common:label.reason',
    name: 'reason',
  },
  {
    label: 'common:label.amount',
    name: 'amount',
    type: 'money',
  },
  {
    label: 'common:label.percent',
    name: 'percent',
  },
  {
    label: 'common:label.numberOfTransactions',
    name: 'numberOfTransactions',
  },
  {
    label: 'common:label.date',
    name: 'startDate',
  },
  {
    label: 'common:label.notes',
    name: 'notes',
    type: 'textarea',
    wrapperClass: 'col-md-12',
  },
];

const adjustmentFieldApply = (
  t,
  disabled,
  data,
  optionResonCodes,
  fixedTaxRuleForAROps,
  isDisablePercent,
  isAropsSupportTicket,
  isMandateSatCodeForAdjustments
) => {
  const additionalField = [];
  if (data?.customReason && data?.invoiceCancellation === 'TRUE') {
    additionalField.push({
      label: 'common:label.satCode',
      name: 'satCode',
      options: t('selections:satCode')(),
      isClearable: true,
      required: isMandateSatCodeForAdjustments && data?.invoiceCancellation === 'TRUE',
    });
  }
  return [
    {
      label: 'common:label.adjustmentType',
      name: 'type',
      options: t('selections:adjustmentARType')(),
      required: true,
    },
    {
      label: 'common:label.source',
      name: 'source',
      options: t('selections:source')(),
      disabled: true,
      required: true,
    },
    {
      label: 'common:label.cRDrType',
      name: 'arType',
      options: t('selections:CRDRType')(),
      required: true,
    },
    {
      label: 'common:label.taxRule',
      name: 'taxRule',
      options: t('selections:taxRule')(),
      disabled: (fixedTaxRuleForAROps && fixedTaxRuleForAROps.value) || data?.type === 'UNBILLED_LINE_ADJUSTMENT',
    },
    {
      label: 'common:label.customReasonCode',
      name: 'customReason',
      disabled: data && !!data.reason,
      options: optionResonCodes,
      isClearable: true,
    },
    {
      label: 'common:label.reason',
      name: 'reason',
      options: t('selections:reasonConfig')(),
      disabled: data && !!data.customReason,
      isClearable: true,
    },
    {
      label: 'common:label.accountId',
      name: 'accountId',
      isPopup: true,
    },
    {
      label: 'common:label.invoiceId',
      name: 'invoiceId',
      isPopup: true,
      disabled: data?.type === 'UNBILLED_LINE_ADJUSTMENT',
    },
    {
      label: 'common:label.invoiceTotal',
      name: 'total',
      readOnly: true,
      disabled: true,
      type: 'number',
    },
    {
      label: 'common:label.invoiceDue',
      name: 'due',
      readOnly: true,
      disabled: true,
      type: 'number',
    },
    {
      label: 'common:label.itemId',
      name: 'itemId',
      isPopup: true,
      disabled:
        disabled ||
        data?.invoiceCancellation === 'TRUE' ||
        !data?.accountId ||
        data?.type === 'UNBILLED_LINE_ADJUSTMENT',
    },
    { name: 'invoiceCancellation', label: 'label.invoiceCancellation', readOnly: true },
    ...additionalField,
    {
      label: 'common:label.amount',
      name: 'amount',
      type: 'money',
      disabled: disabled || data?.type === 'UNBILLED_LINE_ADJUSTMENT',
    },
    {
      label: 'common:label.percentWithNumberRange',
      name: 'percent',
      type: 'number',
      disabled: disabled || isDisablePercent || data?.type === 'UNBILLED_LINE_ADJUSTMENT',
      isNoRenderCtrolButton: data.type === 'BILL_ADJUSTMENT' || data.type === 'INVOICE_LINE_ADJUSTMENT',
    },
    {
      label: 'common:label.numberOfTransactions',
      name: 'numberOfTransactions',
      type: 'number',
      isPopupInfo: data && data.type === 'TRANSACTION_ADJUSTMENT',
      labelPopupInfo: data && data.type === 'TRANSACTION_ADJUSTMENT' ? 'label.viewTransactions' : null,
      disabled:
        disabled || (data && data.type !== 'TRANSACTION_ADJUSTMENT') || data?.type === 'UNBILLED_LINE_ADJUSTMENT',
    },
    {
      label: 'common:label.date',
      name: 'startDate',
      type: 'date',
    },
    {
      label: 'common:label.ticketNumber',
      name: 'ticketNumber',
      required: !!isAropsSupportTicket,
    },
    {
      label: 'common:label.notes',
      name: 'notes',
      type: 'textarea',
      wrapperClass: 'col-md-12',
    },
  ];
};

const disputeFieldDetails = t => [
  {
    label: 'common:label.disputeType',
    options: t('selections:arType')(),
    name: 'type',
    disabled: true,
  },
  {
    label: 'common:label.source',
    name: 'source',
    options: t('selections:source')(),
    disabled: true,
  },
  {
    label: 'common:label.taxRule',
    name: 'taxRule',
    options: t('selections:taxRule')(),
    disabled: true,
  },
  {
    label: 'common:label.accountId',
    name: 'accountId',
  },
  {
    label: 'common:label.userId',
    name: 'userId',
  },
  {
    label: 'common:label.invoiceId',
    name: 'invoiceId',
  },
  {
    label: 'common:label.itemId',
    name: 'itemId',
  },
  {
    label: 'common:label.customReasonCode',
    name: 'customReason',
  },
  {
    label: 'common:label.reason',
    name: 'reason',
    options: t('selections:reasonConfig')(),
    disabled: true,
  },
  {
    label: 'common:label.amount',
    name: 'amount',
  },

  {
    label: 'common:label.settledAmount',
    name: 'settledAmount',
  },

  {
    label: 'common:label.percent',
    name: 'percent',
  },
  {
    label: 'common:label.date',
    name: 'startDate',
  },
  {
    label: 'common:label.settledDate',
    name: 'settlementDate',
  },
  {
    label: 'common:label.status',
    name: 'status',
    options: t('selections:disputeStatus')(),
    disabled: true,
  },
  {
    label: 'common:label.notes',
    name: 'notes',
    type: 'textarea',
    wrapperClass: 'col-md-12',
  },
];

const disputeFieldApply = (t, data, optionResonCodes, fixedTaxRuleForAROps, isDisablePercent) => [
  {
    label: 'common:label.disputeType',
    name: 'type',
    options: t('selections:disputeARType')(),
  },
  {
    label: 'common:label.source',
    name: 'source',
    options: t('selections:source')(),
    disabled: true,
  },

  {
    label: 'common:label.taxRule',
    name: 'taxRule',
    options: t('selections:taxRule')(),
    disabled: fixedTaxRuleForAROps && fixedTaxRuleForAROps.value,
  },
  {
    label: 'common:label.accountId',
    name: 'accountId',
    isPopup: true,
  },
  {
    label: 'common:label.invoiceId',
    name: 'invoiceId',
    isPopup: true,
  },
  {
    label: 'common:label.itemId',
    name: 'itemId',
    isPopup: true,
  },
  {
    label: 'common:label.customReasonCode',
    name: 'customReason',
    disabled: data && !!data.reason,
    options: optionResonCodes,
    isClearable: true,
  },
  {
    label: 'common:label.reason',
    name: 'reason',
    options: t('selections:reasonConfig')(),
    disabled: data && !!data.customReason,
    isClearable: true,
  },
  { name: 'invoiceCancellation', label: 'label.invoiceCancellation', readOnly: true },
  {
    label: 'common:label.amount',
    name: 'amount',
    type: 'money',
  },
  {
    label: 'common:label.percent',
    name: 'percent',
    type: 'number',
    disabled: isDisablePercent,
  },
  {
    label: 'common:label.startDate',
    name: 'date',
    type: 'date',
  },
  {
    label: 'common:label.status',
    name: 'status',
    options: t('selections:disputeStatus')(),
  },
  {
    label: 'common:label.notes',
    name: 'notes',
    type: 'textarea',
    wrapperClass: 'col-md-12',
  },
];

const writeOffFieldDetails = [
  {
    label: 'common:label.writeOffType',
    name: 'type',
  },
  {
    label: 'common:label.source',
    name: 'source',
  },
  {
    label: 'common:label.taxRule',
    name: 'taxRule',
    tOptions: 'selections:taxRule',
    isClearable: true,
  },
  {
    label: 'common:label.accountId',
    name: 'accountId',
  },
  {
    label: 'common:label.userId',
    name: 'userId',
  },
  {
    label: 'common:label.invoiceId',
    name: 'invoiceId',
  },
  {
    label: 'common:label.itemId',
    name: 'itemId',
  },
  {
    label: 'common:label.customReasonCode',
    name: 'customReason',
  },
  {
    label: 'common:label.reason',
    name: 'reason',
  },
  {
    label: 'common:label.amount',
    name: 'amount',
  },
  {
    label: 'common:label.recoveredDebt',
    name: 'recoveryAmount',
  },

  {
    label: 'common:label.percent',
    name: 'percent',
  },
  {
    label: 'common:label.date',
    name: 'date',
  },
  {
    label: 'common:label.recoveredDate',
    name: 'recoveryDate',
  },
  {
    label: 'common:label.status',
    name: 'status',
  },
  {
    label: 'common:label.notes',
    name: 'notes',
    type: 'textarea',
    wrapperClass: 'col-md-12',
  },
];

const writeOffFieldApply = (data, optionResonCodes, fixedTaxRuleForAROps, isDisablePercent, isAccountWriteOff) => [
  {
    label: 'common:label.writeOffType',
    name: 'type',
    tOptions: 'selections:arType',
    disabled: true,
    required: true,
  },
  {
    label: 'common:label.source',
    name: 'source',
    disabled: true,
  },
  {
    label: 'common:label.taxRule',
    name: 'taxRule',
    tOptions: 'selections:taxRule',
    isClearable: true,
    disabled: fixedTaxRuleForAROps && fixedTaxRuleForAROps.value,
  },
  {
    label: 'common:label.accountId',
    name: 'accountId',
    fa: 'fa fa-external-link',
  },
  // {
  //   label: 'common:label.userId',
  //   name: 'userId',
  //   required: true,
  //   // disabled: true,
  // },
  {
    label: 'common:label.invoiceId',
    name: 'invoiceId',
    fa: 'fa fa-external-link',
    disabled: isAccountWriteOff,
  },
  {
    label: 'common:label.customReasonCode',
    name: 'customReason',
    disabled: data && !!data.reason,
    options: optionResonCodes,
    isClearable: true,
  },
  {
    label: 'common:label.reason',
    name: 'reason',
    // options: arOpsSelect.reason,
    tOptions: 'selections:reasonConfig',
    disabled: data && !!data.customReason,
    isClearable: true,
  },
  { name: 'invoiceCancellation', label: 'label.invoiceCancellation', readOnly: true },
  {
    label: 'common:label.writeOffAmount',
    name: 'amount',
    type: 'money',
  },
  {
    label: 'common:label.writeOffPercent',
    name: 'percent',
    type: 'number',
    disabled: isDisablePercent,
  },
  {
    label: 'common:label.notes',
    name: 'notes',
    type: 'textarea',
    wrapperClass: 'col-md-12',
  },
];

const settleADisputeFields = [
  {
    label: 'common:label.amount',
    name: 'amount',
    type: 'money',
    wrapperClass: 'col-md-6',
  },
  {
    label: 'common:label.disputeAmount',
    name: 'disputeAmount',
    disabled: true,
    wrapperClass: 'col-md-6',
  },
  {
    label: 'common:label.percent',
    name: 'percent',
    type: 'number',
    wrapperClass: 'col-md-6',
  },
  {
    label: 'common:label.disputePercent',
    name: 'disputePercent',
    disabled: true,
    wrapperClass: 'col-md-6',
  },
  {
    label: 'common:label.notes',
    name: 'notes',
    type: 'textarea',
    wrapperClass: 'col-md-12',
  },
];

export const messageNotes = t => (
  <div className="note-content">
    <div>
      <b>1. &nbsp;&nbsp;</b>
      {t('message.noteThreshold1')}
    </div>
    <div>
      <b>2. &nbsp;&nbsp;</b>
      {t('message.noteThreshold2')}
    </div>
    <div>
      <b>3. &nbsp;&nbsp;</b>
      {t('message.noteThreshold3')}
    </div>
    <div>
      <b>4. &nbsp;&nbsp;</b>
      {t('message.noteThreshold4')}
    </div>
    <div>
      <b>5. &nbsp;&nbsp;</b>
      {t('message.noteThreshold5')}
    </div>
  </div>
);

const groupNamePermissions = {
  createReasonCodeConfig: 'CREATE_REASON_CODE_CONFIG',
  modifyReasonCodeConfig: 'MODIFY_REASON_CODE_CONFIG',
  getReasonCodeConfig: 'GET_REASON_CODE_CONFIG',
  processAdjustment: 'PROCESS_ADJUSTMENT',
  processTaxAdjustment: 'PROCESS_TAX_ADJUSTMENT',
  searchAdjustment: 'SEARCH_ADJUSTMENTS',
  getAdjustmentDetails: 'GET_ADJUSTMENT_DETAILS',
  processDispute: 'PROCESS_DISPUTE',
  processTaxDispute: 'PROCESS_TAX_DISPUTE',
  searchDispute: 'SEARCH_DISPUTES',
  getDisputeDetails: 'GET_DISPUTE_DETAILS',
  processSettlement: 'PROCESS_SETTLEMENT',
  processTaxSettlement: 'PROCESS_TAX_SETTLEMENT',
  searchSettlement: 'SEARCH_SETTLEMENT',
  getSettlementDetails: 'GET_SETTLEMENT_DETAILS',
  processWriteoff: 'PROCESS_WRITEOFF',
  processTaxWriteoff: 'PROCESS_TAX_WRITEOFF',
  searchWriteoff: 'SEARCH_WRITEOFF',
  getWriteoffDetails: 'GET_WRITEOFF_DETAILS',
  processWriteoffReversal: 'PROCESS_WRITEOFF_REVERSAL',
  processTaxWriteoffReversal: 'PROCESS_TAX_WRITEOFF_REVERSAL',
  searchWriteoffReversal: 'SEARCH_WRITEOFF_REVERSAL',
  getWriteoffReversalDetails: 'GET_WRITEOFF_REVERSAL_DETAILS',
  searchArOpsUnit: 'SEARCH_AROPS_UNIT',
  getArOpsTaxOptions: 'AR_OPS_TAX_OPTIONS',
  createConfigArOpsThresholds: 'CREATE_CONFIG_AR_OPS_THRESHOLDS',
  modifyConfigArOpsThresholds: 'MODIFY_CONFIG_AR_OPS_THRESHOLDS',
  getConfigArOpsThresholds: 'GET_CONFIG_AR_OPS_THRESHOLDS',
  createConfigGroupReasonCode: 'CREATE_CONFIG_GROUP_REASON_CODE',
  modifyConfigGroupReasonCode: 'MODIFY_CONFIG_GROUP_REASON_CODE',
  getConfigGroupReasonCode: 'GET_CONFIG_GROUP_REASON_CODE',
  BULK_ADJUSTMENT: 'BULK_ADJUSTMENT',
};

export {
  adjustmentFieldDetails,
  adjustmentFieldApply,
  disputeFieldDetails,
  disputeFieldApply,
  writeOffFieldDetails,
  writeOffFieldApply,
  settleADisputeFields,
  groupNamePermissions,
};

export const invoiceLinesColumnsInit = data => [
  {
    name: 'name',
    label: 'common:label.name',
  },
  {
    name: 'transactionId',
    label: 'common:label.transactionId',
  },
  {
    name: 'percent',
    label: 'common:label.percent',
  },
  {
    name: 'amount',
    label: data.type === 'INVOICE_LINE_ADJUSTMENT' ? 'common:label.invoiceLineAmount' : 'common:label.lineAmount',
  },
];
