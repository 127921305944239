import { cloneDeep } from 'lodash';
import styleHeader from './styleHeader';
import { getLabelOptions, formatMoneyValueToExport, redFontExcelFile } from '../utils';
import { columns } from './commonFields';
import { convertBoolLabel } from '../exportHelper';

const multiDataSetDefault = ({ t, title, columnsTable }) => [
  {
    xSteps: columnsTable.length > 4 ? columnsTable.length / 2 - 2 : 1,
    columns: [],
    data: [[{ value: title || 'LIST PAYMENTS', style: { font: { sz: '20', bold: true } } }]],
  },
  {
    columns: columnsTable.map(val => columns[val.name] || columns.common),
    data: [
      columnsTable.map(val => ({
        value: t(val.exportLabel) || t(val.label),
        style: styleHeader,
      })),
    ],
  },
];

const convertJson2Sheet = ({ data, t, title, columnsTable }) => {
  const newDataSheetSet = cloneDeep(multiDataSetDefault({ t, title, columnsTable }));
  const newListData = data.map(item => {
    const newItem = [];
    if (item && typeof item === 'object') {
      columnsTable.forEach(val => {
        if ((item[val.name] || item[val.name] === 0) && typeof item[val.name] === 'number') {
          newItem.push({
            value: formatMoneyValueToExport(item[val.name]),
            style: val.isSupportRedFront && item[val.name] < 0 ? redFontExcelFile : null,
          });
        } else if (typeof item[val.name] === 'boolean') {
          newItem.push({ value: convertBoolLabel({ t, value: item[val.name] }) });
        } else if (val.isRenderT) {
          newItem.push({
            value: getLabelOptions({
              item,
              t,
              fieldOption: val.fieldOption || null,
              fieldName: val.name,
            }),
          });
        } else {
          newItem.push({ value: item[val.name] ? item[val.name] : '' });
        }
      });
    }
    return newItem;
  });

  if (newListData.length) {
    newDataSheetSet[1].data = newDataSheetSet[1].data.concat(newListData);
  }
  return newDataSheetSet;
};

export default convertJson2Sheet;
