import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { GenericInput } from '../common';

class ProvisioningDataSearchForm extends PureComponent {
  state = {
    modalName: '',
  };

  searchInputs = [
    {
      label: 'common:label.serviceType',
      name: 'serviceType',
      tOptions: 'selections:serviceType',
      type: 'select',
      defaultValue: 'VOICE',
    },
    {
      label: 'common:label.type',
      name: 'type',
      options: [],
      type: 'select',
      // defaultValue: 'TRUNK',
    },
    {
      label: 'common:label.status',
      name: 'status',
      type: 'select',
      tOptions: 'selections:statusProvisioning',
      isClearable: true,
    },
    {
      label: 'common:label.provisioningId',
      name: 'provisioningId',
    },
    {
      label: 'common:label.startingNumber',
      name: 'startRange',
      disabled: true,
    },
    {
      label: 'common:label.endingNumber',
      name: 'endRange',
      disabled: true,
    },
  ];

  btnSpan = 'col-md-6';

  submitText = 'Search';

  clearText = 'Clear';

  componentDidMount() {
    const { defaultFilter } = this.props;
    if (defaultFilter && Object.keys(defaultFilter).length > 0) {
      this.searchInputs.map(input => this.setState({ [input.name]: defaultFilter[input.name] || null }));
    } else {
      this.setDefaultInputs();
    }
  }

  setDefaultInputs = () => {
    this.searchInputs.map(input => this.setState({ [input.name]: null }));
  };

  handleInputChange = ({ name, value }) => {
    const { isSupportRemoveType } = this.props;
    if (name === 'serviceType' && isSupportRemoveType) {
      return this.setState({ [name]: value, type: null });
    }
    return this.setState({ [name]: value });
  };

  handleSubmit = e => {
    e.preventDefault();
    e.stopPropagation();
    const { onSubmit } = this.props;
    const data = {};
    this.searchInputs.map(input => (this.state[input.name] ? (data[input.name] = this.state[input.name]) : null));
    if (onSubmit) {
      onSubmit(data);
    }
  };

  handleReset = e => {
    e.preventDefault();
    e.stopPropagation();
    // const { onSubmit } = this.props;
    this.setDefaultInputs();
    // if (onSubmit) {
    //   onSubmit({});
    // }
  };

  onSelect = (value, row, childField) => {
    const { modalName } = this.state;
    if (childField) {
      return this.setState({ [childField]: value, modalName: '' });
    }
    return this.setState({ [modalName]: value, modalName: '' });
  };

  onSelectChild = (value, row, childField) => {
    return this.setState({ [childField]: value });
  };

  onOpenModal = ({ modalName }) => {
    this.setState({ modalName });
  };

  onToggleModal = () => {
    this.setState({ modalName: '' });
  };

  render() {
    const { isSubmitting, t, ModalSearch, keyOptionInject, optionsInject } = this.props;
    const { modalName } = this.state;
    if (keyOptionInject) {
      this.searchInputs.map(val => {
        const newVal = val;
        if (val.name === keyOptionInject) newVal.options = optionsInject;
        return newVal;
      });
    }
    if (this.state && this.state.type === 'DID') {
      this.searchInputs = this.searchInputs.map(val => {
        const { disabled, ...rest } = val;
        return { ...rest };
      });
    } else {
      this.searchInputs = this.searchInputs.map(val => {
        const { disabled, name, ...rest } = val;
        return { ...rest, name, disabled: !!(name === 'startRange' || name === 'endRange') };
      });
    }
    return (
      <form onSubmit={this.handleSubmit}>
        <div className="form-row">
          {this.searchInputs.map(item => (
            <GenericInput
              key={item.name}
              {...item}
              label={t && t(item.label) ? t(item.label) : item.label}
              value={this.state[item.name] || item.defaultValue}
              onChange={this.handleInputChange}
              onClick={item.fa ? () => this.onOpenModal({ modalName: item.name }) : () => {}}
            />
          ))}
          {ModalSearch && (
            <ModalSearch
              isOpen={modalName}
              onToggle={this.onToggleModal}
              onSelect={this.onSelect}
              onSelectChild={this.onSelectChild}
              data={this.state}
            />
          )}
          <div className={`form-group text-right btn-search-actions ${this.btnSpan}`}>
            <label htmlFor="validationCustom01se" />
            <br />
            <button
              type="button"
              className="button button-border black x-small"
              disabled={isSubmitting}
              onClick={this.handleReset}
            >
              {t ? t('label.clear') : this.clearText}
            </button>
            <button type="submit" className="button x-small" disabled={isSubmitting}>
              {t ? t('label.search') : this.submitText}
            </button>
          </div>
        </div>
      </form>
    );
  }
}

ProvisioningDataSearchForm.propTypes = {
  onSubmit: PropTypes.func,
  isSubmitting: PropTypes.bool,
};

ProvisioningDataSearchForm.defaultProps = {
  isSubmitting: false,
};

export default withTranslation(['common', 'selections'])(ProvisioningDataSearchForm);
