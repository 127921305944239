import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import SearchComponent from '../PricingHub/SearchComponent';

class RatedUsageSearchForm extends SearchComponent {
  searchInputs = [
    {
      label: 'common:label.startDate',
      name: 'startDate',
      type: 'date',
      isClearable: true,
    },
    {
      label: 'common:label.endDate',
      name: 'endDate',
      type: 'date',
      isClearable: true,
    },
    {
      label: 'common:label.serviceType',
      name: 'serviceType',
      type: 'select',
      tOptions: 'selections:serviceType',
      isClearable: true,
    },
    {
      label: 'common:label.sourceSystem',
      name: 'sourceSystem',
      type: 'select',
      options: [],
    },
    {
      label: 'common:label.usageType',
      name: 'usageType',
      type: 'select',
      options: [],
    },
    {
      label: 'common:label.billUnitId',
      name: 'billUnitId',
    },
    {
      label: 'common:label.batchId',
      name: 'batchId',
    },
    {
      label: 'common:label.fileId',
      name: 'fileId',
      type: 'number',
    },
    {
      label: 'common:label.provisioningId',
      name: 'provisioningId',
    },
    {
      label: 'common:label.itemId',
      name: 'itemId',
    },
    {
      label: 'common:label.ratingDescription',
      name: 'ratingDescr',
      type: 'select',
      tOptions: 'selections:ratingDescription',
      isClearable: true,
    },
    {
      label: 'common:label.usageClass',
      name: 'usageClass',
    },
    {
      label: 'common:label.source',
      name: 'source',
    },
    {
      label: 'common:label.destination',
      name: 'destination',
    },
    {
      label: 'common:label.routingNumber',
      name: 'routingNumber',
    },
    {
      label: 'common:label.direction',
      name: 'usageDirection',
      type: 'select',
      tOptions: 'selections:usageDirection',
      isClearable: true,
    },
  ];

  btnSpan = 'col-md-12';
}

RatedUsageSearchForm.propTypes = {
  onSubmit: PropTypes.func,
  isSubmitting: PropTypes.bool,
};

RatedUsageSearchForm.defaultProps = {
  isSubmitting: false,
};

export default withTranslation(['common', 'selections'])(RatedUsageSearchForm);
