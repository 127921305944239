import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { countryList } from '../../constantsApp/countryList';
import SearchComponent from './SearchComponent';

class ItemSearchForm extends SearchComponent {
  searchInputs = [
    {
      label: 'common:label.id',
      name: 'id',
    },
    {
      label: 'common:label.name',
      name: 'name',
    },
    {
      label: 'common:label.itemType',
      name: 'type',
      type: 'select',
      tOptions: 'selections:itemType',
      isClearable: true,
    },
    {
      label: 'common:label.company',
      name: 'company',
    },
    {
      label: 'common:label.productFamily',
      name: 'productFamily',
    },
    {
      label: 'common:label.productLine',
      name: 'productLine',
    },
    {
      label: 'common:label.productType',
      name: 'productType',
    },
    {
      label: 'common:label.productSubType',
      name: 'productSubType',
    },
    {
      label: 'common:label.revenueType',
      name: 'revenueType',
      type: 'select',
      tOptions: 'selections:revenueType',
      isClearable: true,
    },
    {
      label: 'common:label.revenueRecognitionType',
      name: 'revenueRecognitionType',
      type: 'select',
      tOptions: 'selections:revenueRecognitionType',
      isClearable: true,
    },
    {
      label: 'common:label.status',
      name: 'status',
      type: 'select',
      tOptions: 'selections:statusPricing',
      isClearable: true,
    },
    {
      name: 'country',
      label: 'common:label.country',
      wrapperClass: 'col-md-3',
      options: countryList,
      isClearable: true,
      type: 'select',
    },
    {
      label: 'common:label.taxCode',
      name: 'taxCode',
    },
    {
      label: 'common:label.glAccount',
      name: 'glAccount',
    },
  ];

  btnSpan = 'col-md-6';
}

ItemSearchForm.propTypes = {
  onSubmit: PropTypes.func,
  isSubmitting: PropTypes.bool,
};

ItemSearchForm.defaultProps = {
  isSubmitting: false,
};

export default withTranslation('common')(ItemSearchForm);
