import React from 'react';
import { isEmpty, cloneDeep } from 'lodash';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { getPageTotalCount } from '../../../utils/utils';
import { disputesSort } from '../../../constantsApp';
import { AROperationsSearchForm, AROperationsTable } from '../../../components/ArOperationsHub';
import RouteName from '../../App/RouteNames';
import { TablePagination, ModalWithItem, AccountHeaderFrom } from '../../../components/common';
import PageTitle from '../../../components/PageTitle';
import Footer from '../../../components/Footer';
import { makeGetListDisputes, makePageDisputeParams, makeErrorMessageDisputes } from '../selectors';
import { searchDisputes, searchArOpsRequests, getArOpsRequestById } from '../actions';
import { makeGetPermissionsArOpsManagement } from '../../App/selectors';
import {
  checkPermissionSearchDispute,
  checkPermissionGetDisputeDetails,
  checkPermissionProcessDispute,
  checkPermissionProcessSettlement,
} from '../CheckPermission';
import ArActivity from '../common/ArActivity';
import ArOperationsForm from '../common/ArOperationsForm';
import { disputeFieldDetails } from '../constant';

class Disputes extends React.PureComponent {
  constructor() {
    super();
    this.state = {
      filter: {},
      isSearching: false,
      sort: '',
      sorted: {},
      page: 0,
      size: 20,
      listData: [],
      itemDetailsSelect: null,
      detailsData: {},
      isOpenModalItem: false,
    };
  }

  static getDerivedStateFromProps(props, state) {
    return {
      totalCount: getPageTotalCount({ ...state, items: state.listData }),
    };
  }

  componentDidMount() {
    const { location } = this.props;
    if (location && location.state && location.state.filter) {
      this.setState({ filter: location.state.filter }, () => {
        this.doSearchDisputes();
      });
    } else {
      this.doSearchDisputes();
    }
  }

  doSearchDisputes = () => {
    const { filter, page, size, sorted } = this.state;
    const { searchArOpsRequests } = this.props;
    const newFilter = cloneDeep(filter);
    newFilter.action = 'DISPUTE';
    const payload = {
      page: page + 1,
      size,
      filter: newFilter,
      sort: !isEmpty(sorted) ? disputesSort[sorted.sortCol][sorted.sortDir] : null,
    };
    this.setState({ isSearching: true });
    searchArOpsRequests(payload, ({ data }) => this.setState({ isSearching: false, listData: data || [] }));
  };

  onHandleSearch = filter => {
    this.setState({ filter, page: 0 }, () => {
      this.doSearchDisputes();
    });
  };

  onSortColumn = (sortCol, sortDir) => {
    this.setState({ sorted: { sortCol, sortDir } }, () => this.doSearchDisputes());
  };

  onSizeChange = size => {
    this.setState({ size, page: 0 }, () => this.doSearchDisputes());
  };

  onPageChange = page => {
    this.setState({ page }, () => this.doSearchDisputes());
  };

  handleClickSettle = item => {
    const { history } = this.props;
    const { filter } = this.state;
    history.push({
      pathname: RouteName.arOperationsSettleDisputes.path,
      state: { disputeSelected: item, filter },
    });
  };

  onToggleModalDetails = () => {
    this.setState({ itemDetailsSelect: null });
  };

  onViewItemDetails = ({ item }) => {
    this.setState({ itemDetailsSelect: item || null });
  };

  onViewRequestDetails = ({ item }) => {
    const { getArOpsRequestById } = this.props;
    getArOpsRequestById(item.id, ({ success, data }) => {
      if (success) this.setState({ isOpenModalItem: true, detailsData: data || {} });
    });
  };

  onToggleModal = () => {
    const { isOpenModalItem } = this.state;
    this.setState({ isOpenModalItem: !isOpenModalItem });
  };

  render() {
    const { permissionsArOps, t, searchDisputes, location } = this.props;
    const {
      sorted,
      page,
      size,
      totalCount,
      isSearching,
      listData,
      itemDetailsSelect,
      detailsData,
      isOpenModalItem,
      filter,
    } = this.state;

    let modeSearchDispute = 0;
    let modeGetDisputeDetails = 0;
    let modeProcessDispute = 0;
    let modeProcessSettlement = 0;

    if (permissionsArOps && permissionsArOps.arOpsModulePermissions) {
      const listPermission = permissionsArOps.arOpsModulePermissions;
      modeSearchDispute = checkPermissionSearchDispute({ listPermission });
      modeGetDisputeDetails = checkPermissionGetDisputeDetails({ listPermission });
      modeProcessDispute = checkPermissionProcessDispute({ listPermission });
      modeProcessSettlement = checkPermissionProcessSettlement({ listPermission });
    }
    const lastColumns = [];

    const newColumns = [];
    if (modeProcessSettlement) {
      newColumns.push({
        name: 'Selected',
        label: 'common:label.selected',
        render: (colName, item) => {
          return ['SETTLED'].includes(item.status) || item.action === 'ADJUSTMENT' ? (
            <span />
          ) : (
            <button
              disabled={modeProcessSettlement === 1}
              type="button"
              onClick={() => this.handleClickSettle(item)}
              className="button x-small btn-select-next"
            >
              {t('label.settle')}
            </button>
          );
        },
      });
    }
    if (modeGetDisputeDetails) {
      newColumns.push({
        name: 'details',
        label: 'common:label.details',
        isRenderT: true,
        render: (colName, item, t) => (
          <button
            className="btn btn-outline-success btn-sm"
            type="button"
            onClick={() => this.onViewItemDetails({ item })}
          >
            {t ? t('common:label.view') : ''}
          </button>
        ),
      });

      newColumns.push({
        name: 'action',
        label: 'common:label.request',
        isRenderT: true,
        render: (colName, item, t) => (
          <button
            className="btn btn-outline-success btn-sm"
            type="button"
            onClick={() => this.onViewRequestDetails({ item })}
          >
            {t ? t('common:label.view') : ''}
          </button>
        ),
      });

      newColumns.push({
        name: 'parentId',
        label: 'common:label.requestId',
        render: (colName, item) => (
          <button className="text-success no-border" type="button" onClick={() => this.onViewItemDetails({ item })}>
            {item.id || ''}
          </button>
        ),
      });
    }
    return (
      <div className="content-wrapper">
        <PageTitle
          linkTo={
            modeSearchDispute === 0 || modeProcessDispute === 0
              ? '/'
              : { pathname: RouteName.arOperationsDisputesApply.path, state: { filter } }
          }
          titleBtn={
            modeSearchDispute === 0 || modeProcessDispute === 0 ? t('label.back') : `+ ${t('label.openADispute')}`
          }
          disabled={modeProcessDispute === 1}
          items={[{ name: t('arOperationPage:sidebar.disputes'), url: RouteName.arOperationsDisputes.path }]}
        />
        <br />
        {modeSearchDispute !== 0 && (
          <div>
            <div className="col-md-12 mb-30">
              <div className="card card-statistics h-100">
                <div className="card-body">
                  <div className="repeater-file">
                    <AROperationsSearchForm
                      onSubmit={this.onHandleSearch}
                      defaultFilter={location && location.state && location.state.filter ? location.state.filter : {}}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-12 mb-30">
              <div className="card card-statistics h-100">
                <div className="card-body">
                  <AROperationsTable
                    data={listData}
                    onClickRow={this.onSelectedDispute}
                    onSort={this.onSortColumn}
                    sorted={sorted}
                    modeProcessSettlement={modeProcessSettlement}
                    modeGetDisputeDetails={modeGetDisputeDetails}
                    labelButton={t('label.settle')}
                    handleClickButton={this.handleClickSettle}
                    linkDetail={RouteName.arOperationsDisputesDetail.path}
                    isLoading={isSearching}
                    defaultLabelType="label.disputeType"
                    newColumns={[
                      ...newColumns,
                      {
                        name: 'parentId',
                        label: 'common:label.disputeId',
                      },
                    ]}
                    newLastColumns={lastColumns}
                  />
                </div>
              </div>
            </div>
            <div className="mb-30">
              <TablePagination
                pageNumber={page}
                pageSize={size}
                totalCount={totalCount}
                onPageChange={this.onPageChange}
                onSizeChange={this.onSizeChange}
              />
            </div>
          </div>
        )}
        <Footer className="footer-bottom" />
        <ModalWithItem
          wrapperClass="modal-70 modal-custom"
          modalTitle={t('label.disputesRequestDetails')}
          isOpen={isOpenModalItem}
          onToggle={this.onToggleModal}
        >
          <AccountHeaderFrom accountNum={detailsData?.accountId || ''} />
          <div className="col-md-12 mb-30 mt-3">
            <ArOperationsForm
              listFields={disputeFieldDetails(t)}
              data={detailsData}
              isDisabledAllField
              onChange={() => {}}
              onCancel={this.onToggleModal}
            />
          </div>
        </ModalWithItem>
        <ModalWithItem
          wrapperClass="modal-70 modal-custom"
          modalTitle={t('label.disputeDetail')}
          isOpen={!!itemDetailsSelect}
          onToggle={this.onToggleModalDetails}
        >
          <AccountHeaderFrom accountNum={itemDetailsSelect?.id} />
          <div className="col-md-12 mb-30">
            <ArActivity
              id={itemDetailsSelect?.id}
              type={
                itemDetailsSelect?.type && itemDetailsSelect?.type === 'UNBILLED_LINE_ADJUSTMENT'
                  ? 'UNBILLED_LINE_ADJUSTMENT'
                  : null
              }
              onCancel={this.onToggleModalDetails}
              searchData={searchDisputes}
            />
          </div>
        </ModalWithItem>
      </div>
    );
  }
}

Disputes.propTypes = {
  history: PropTypes.objectOf(PropTypes.any),
  searchArOpsRequests: PropTypes.func.isRequired,
  getArOpsRequestById: PropTypes.func.isRequired,
  permissionsArOps: PropTypes.objectOf(PropTypes.any),
};

Disputes.defaultProps = {
  history: {},
  permissionsArOps: {},
};

const mapStateToProps = createStructuredSelector({
  listDisputes: makeGetListDisputes() || [],
  params: makePageDisputeParams() || {},
  errorMessage: makeErrorMessageDisputes() || '',
  permissionsArOps: makeGetPermissionsArOpsManagement() || {},
});

export default withTranslation('common')(
  connect(mapStateToProps, { searchDisputes, searchArOpsRequests, getArOpsRequestById })(Disputes)
);
