import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import SearchComponent from '../PricingHub/SearchComponent';

class AccountingLogSearchForm extends SearchComponent {
  searchInputs = [
    {
      label: 'common:label.startDateWithRank',
      name: 'startDate',
      type: 'date',
      isClearable: true,
    },
    {
      label: 'common:label.endDateWithRank',
      name: 'endDate',
      type: 'date',
      isClearable: true,
    },
    {
      label: 'common:label.accountingPeriod',
      name: 'accountingPeriod',
      type: 'monthYear',
      isClearable: true,
    },
    {
      label: 'common:label.objectType',
      name: 'objectType',
      type: 'select',
      tOptions: 'selections:accountingRulesObjectType',
      isClearable: true,
    },
    {
      label: 'common:label.drCurrency',
      name: 'drCurrency',
      options: [],
      isClearable: true,
      type: 'select',
    },
    {
      label: 'common:label.crCurrency',
      name: 'crCurrency',
      options: [],
      isClearable: true,
      type: 'select',
    },
    {
      label: 'common:label.status',
      name: 'journalStatus',
      type: 'select',
      tOptions: 'selections:revenueJournalStatus',
      isClearable: true,
    },
    {
      label: 'common:label.batchId',
      name: 'batchId',
    },
    {
      label: 'common:label.crGlAccount',
      name: 'crGlAccount',
    },
    {
      label: 'common:label.drGlAccount',
      name: 'drGlAccount',
    },
    {
      label: 'common:label.journalId',
      name: 'journalId',
    },
  ];

  btnSpan = 'col-md-3';
}

AccountingLogSearchForm.propTypes = {
  onSubmit: PropTypes.func,
  isSubmitting: PropTypes.bool,
};

AccountingLogSearchForm.defaultProps = {
  isSubmitting: false,
};

export default withTranslation('common')(AccountingLogSearchForm);
