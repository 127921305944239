import gql from 'graphql-tag';
import { parseToMutationRequest } from 'utils/utils';

export default data => {
  const dataModify = parseToMutationRequest(data, [
    'type',
    'index',
    'status',
    'templateType',
    'messageType',
    'accountCategory',
  ]);
  const modifyConfigCorrespondence = `modifyConfigCorrespondence(input: ${dataModify})`;
  return gql`
  mutation {
    ${modifyConfigCorrespondence} {
      id
    }
  }
`;
};
