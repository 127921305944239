import gql from 'graphql-tag';
import { parseToTwoLvArrNSMutationRequest } from 'utils/utils';

export default data => {
  const dataCreate = parseToTwoLvArrNSMutationRequest(
    data,
    [],
    [
      'type',
      'name',
      'validity',
      'initialTerm',
      'renewalTerm',
      'billingDom',
      'passwordExpiryInMonths',
      'shortBillingCycle',
      'paymentNotification',
      'paymentAutoAllocation',
      'paymentFailureNotification',
      'invoiceDueNotification',
      'contactRole',
      'addressRole',
      'salesChannel',
      'marketSegment',
      'accountType',
      'accountSubType',
      'invoiceType',
      'invoiceDelivery',
      'index',
      'status',
      'paymentMethod',
      'paymentTerm',
      'initialTermUnit',
      'renewalTermUnit',
      'billingFrequency',
      'orderStatus',
      'authType',
      'phoneType',
      'number',
      'selfCareUserType',
      'selfCareUserCategory',
      'batchSizeBilling',
      'batchSizeInvoice',
      'batchSizePayment',
      'batchSizeCommon',
      'invoiceReminderThreshold',
      'useUsageStartTime',
      'apiType',
      'webApiType',
      'apiProtocol',
      'payloadMapOnly',
      'skipAuthorization',
      'addTo',
    ]
  );

  const createTenant = `createTenant(input: ${dataCreate})`;
  return gql`
    mutation {
      ${createTenant} {
        id
      }
    }
  `;
};
