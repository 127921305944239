import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { cloneDeep } from 'lodash';
import RouteNames from '../../App/RouteNames';
import JobConfigForm from './JobConfigForm';
import { getJobConfig, modifyJobConfig, createJobConfig, getJobScheduleConfigByFrequency } from '../actions';
import { makeJobConfig, makeGetScheduleList, makeGetDailyList } from '../selectors';
import { Breadcrumb, FormCollapse, GenericInput } from '../../../components/common';
import { makeGetPermissionsJobsManagement } from '../../App/selectors';

import {
  checkPermissionCreateJobConfig,
  checkPermissionModifyJobConfig,
  checkPermissionFindJobConfig,
} from '../CheckPermission';
import { supportRemoveLastIndex, supportRemoveIndexWithSize, validate } from '../../../utils/utils';
import { getUserId } from '../../../localStorages';

const listInputFields = [
  {
    label: 'label.frequency',
    name: 'scheduleFrequency',
    tOptions: 'selections:scheduleFrequency',
    type: 'select',
    required: true,
    isSupportDefaultValue: true,
    isNoClearable: true,
  },
  {
    label: 'label.name',
    name: 'name',
    required: true,
  },
  {
    label: 'label.description',
    name: 'description',
    type: 'textarea',
  },
  {
    label: 'label.scheduleTime',
    name: 'jobScheduleTime',
    type: 'time',
    wrapperClass: 'col-md-3',
    isSupportDefaultTime: true,
  },
  {
    label: 'label.exceptionStartTime',
    name: 'exceptionStartTime',
    type: 'time',
    wrapperClass: 'col-md-3',
    isSupportDefaultTime: true,
  },
  {
    label: 'label.exceptionEndTime',
    name: 'exceptionEndTime',
    type: 'time',
    wrapperClass: 'col-md-3',
    isSupportDefaultTime: true,
  },
];

class JobConfig extends Component {
  formRef = React.createRef();

  state = {
    activeTab: {},
    jobConfig: [],
    wasValidated: false,
  };

  static getDerivedStateFromProps(props, state) {
    if (state.oldDailyValue === props.dailyList && state.oldScheduleValue === props.scheduleList) return null;
    let jobConfig = [];
    if (props.dailyList) jobConfig = [...jobConfig, ...props.dailyList];
    if (props.scheduleList) jobConfig = [...jobConfig, ...props.scheduleList];
    return {
      jobConfig: [...jobConfig],
      oldDailyValue: props.dailyList,
      oldScheduleValue: props.scheduleList,
    };
  }

  doGetJobConfigByType = type => {
    const { getJobScheduleConfigByFrequency } = this.props;
    getJobScheduleConfigByFrequency(type);
  };

  onSubmit = ({ e, jobList }) => {
    e.preventDefault();
    const { modifyJobConfig, createJobConfig, t } = this.props;
    this.setState({ wasValidated: true });
    if (!validate(true, this.formRef, t)) {
      return false;
    }

    this.setState({ wasValidated: false });
    const {
      jobConfig: { userId },
    } = this.props;

    if (jobList?.id) {
      const newJobs = jobList.configJobList ? supportRemoveLastIndex({ data: jobList.configJobList }) : null;
      const newConfig = { ...jobList, userId, configJobList: newJobs };
      return modifyJobConfig({ jobConfig: newConfig }, ({ success }) => {
        if (success) this.doGetJobConfigByType(jobList.scheduleFrequency);
      });
    }
    const newJobs = jobList.configJobList ? supportRemoveIndexWithSize({ data: jobList.configJobList }) : null;
    const newConfig = { ...jobList, userId, configJobList: newJobs };

    return createJobConfig({ jobConfig: newConfig }, ({ success, data }) => {
      if (success) {
        this.doGetJobConfigByType(jobList.scheduleFrequency);
        this.onToggleTab(data?.id || null);
      }
    });
  };

  onToggleTab = (activeTabName, isNeedExpandOne) => {
    const { activeTab } = this.state;
    if (activeTab.name === activeTabName && !isNeedExpandOne) {
      this.setState({
        activeTab: { name: activeTabName, isActive: !activeTab.isActive },
      });
    } else {
      this.setState({ activeTab: { name: activeTabName, isActive: true } });
    }
  };

  onChangeData = ({ name, value, index }) => {
    const { jobConfig } = this.state;
    try {
      jobConfig[index][name] = value;
      this.setState({ jobConfig: [...jobConfig] });
    } catch (error) {
      console.log(error);
    }
  };

  onAddNew = () => {
    const { jobConfig } = this.state;
    let newData = cloneDeep(jobConfig);
    newData = [
      {
        id: null,
        userId: getUserId() || null,
        name: null,
        scheduleFrequency: 'DAILY',
        exceptionStartTime: null,
        exceptionEndTime: null,
        description: null,
        jobScheduleTime: null,
        configJobList: [],
      },
      ...jobConfig,
    ];
    this.setState({ jobConfig: newData, activeTab: {} }, () => this.onToggleTab(null));
  };

  onChangeJobList = ({ index, data }) => {
    const { jobConfig } = this.state;
    jobConfig[index].configJobList = data;
    this.setState({ jobConfig: [...jobConfig] });
  };

  render() {
    const { permissionsJob, t } = this.props;
    const { activeTab, jobConfig, wasValidated } = this.state;

    let modeCreateJobConfig = 0;
    let modeModifyJobConfig = 0;
    let modeFindJobConfig = 0;

    if (permissionsJob && permissionsJob.jobsModulePermissions) {
      const listPermission = permissionsJob.jobsModulePermissions;
      modeCreateJobConfig = checkPermissionCreateJobConfig({ listPermission });
      modeModifyJobConfig = checkPermissionModifyJobConfig({ listPermission });
      modeFindJobConfig = checkPermissionFindJobConfig({ listPermission });
    }

    if (modeFindJobConfig === 0) return '';

    return (
      <div>
        <div className="page-title">
          <div className="row">
            <div className="col-sm-6">
              <Link to={RouteNames.jobsManagmentJobSchedule.path} className="button x-small">
                {t('common:label.back')}
              </Link>
            </div>

            <div className="col-sm-6">
              <Breadcrumb items={[{ name: t('jobManagementPage:sidebar.jobScheduleConfig') }]} />
            </div>
          </div>
        </div>

        <br />
        <div className="col-md-12 mb-30">
          <div className="card card-statistics h-100">
            <div className="card-body">
              <div className="d-block w-100">
                <h5 className="card-title">{t('jobManagementPage:sidebar.jobScheduleConfig')}</h5>
              </div>
              <button
                type="button"
                disabled={jobConfig && jobConfig.length && jobConfig.find(i => !i.id)}
                className="button x-small mb-2 ml-2"
                onClick={this.onAddNew}
              >
                {`+ ${t('common:label.add')}`}
              </button>
              <form className={`needs-validation ${wasValidated ? 'was-validated' : ''}`} ref={this.formRef} noValidate>
                {jobConfig &&
                  jobConfig.length > 0 &&
                  jobConfig.map((item, index) => {
                    let title = `${item.scheduleFrequency}:`;
                    if (item.name) {
                      title = `${title} ${item.name}`;
                    }
                    return (
                      <FormCollapse
                        key={item.id}
                        isActive={item.id === activeTab.name && activeTab.isActive}
                        title={title || 'null'}
                        state={item.id}
                        onToggleTab={this.onToggleTab}
                        isNoT
                      >
                        <div className="col-md-12 row m-2">
                          {listInputFields.map(val => (
                            <GenericInput
                              {...val}
                              value={item[val.name] || null}
                              onChange={e => this.onChangeData({ ...e, index })}
                            />
                          ))}
                        </div>
                        <JobConfigForm
                          jobConfig={item.configJobList || []}
                          modeCreateJobConfig={modeCreateJobConfig}
                          modeModifyJobConfig={modeModifyJobConfig}
                          onChangeJobList={data => this.onChangeJobList({ index, data })}
                        />
                        <div className="mt-3 mb-5 col-md-12">
                          <button
                            type="button"
                            onClick={e => this.onSubmit({ e, jobList: item })}
                            className="button button-border ml-2 x-small float-right"
                          >
                            {t('label.saveConfig')}
                          </button>
                          <button
                            type="button"
                            onClick={() => this.doGetJobConfigByType(item.scheduleFrequency)}
                            className="button button-border black x-small float-right"
                          >
                            {t('label.cancel')}
                          </button>
                        </div>
                      </FormCollapse>
                    );
                  })}
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

JobConfig.propTypes = {
  getJobConfig: PropTypes.func,
  jobConfig: PropTypes.any,
  modifyJobConfig: PropTypes.func,
  permissionsJob: PropTypes.objectOf(PropTypes.any),
};

JobConfig.defaultProps = {
  permissionsJob: {},
};

const mapStateToProps = createStructuredSelector({
  jobConfig: makeJobConfig() || {},
  permissionsJob: makeGetPermissionsJobsManagement() || {},
  scheduleList: makeGetScheduleList() || {},
  dailyList: makeGetDailyList() || {},
});
export default withTranslation('common')(
  connect(mapStateToProps, { getJobConfig, modifyJobConfig, createJobConfig, getJobScheduleConfigByFrequency })(
    JobConfig
  )
);
