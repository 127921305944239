import gql from 'graphql-tag';
import { parseToMutationRequest } from 'utils/utils';

export default data => {
  const dataCreate = parseToMutationRequest(data, [
    'salesChannel',
    'marketSegment',
    'accountType',
    'accountSubType',
    'creditLimit',
    'index',
    'action',
    'accountCategory',
  ]);
  let newData = dataCreate.replace('{embrixData: ', '');
  newData = `${newData.substring(0, newData.length - 1)}`;
  const modifyConfigCreditProfile = `modifyConfigCreditProfile(input: ${newData})`;

  return gql`
  mutation {
    ${modifyConfigCreditProfile} {
      id
    }
  }
`;
};
