import gql from 'graphql-tag';

export default () => {
  const getTenantConfig = 'getTenantConfig(input: {dummy: ""})';
  return gql`
    query {
      ${getTenantConfig} {
        id
        tenantMerchantAccounts {
          index
          type
          name
          startDate
          validity
          authType
          status
          paymentGatewayAttributes {
            index
            type
            url
          }
          taxGatewayAttributes {
            index
            type
            url
          }
          financeGatewayAttributes {
            index
            type
            url
          }
          provisionGatewayAttributes {
            index
            type
            url
          }
          projectGatewayAttributes {
            index
            type
            url
          }
          customerSupportGatewayAttributes {
            index
            type
            url
          }
          documentGatewayAttributes {
            index
            type
            url
          }
          operationGatewayAttributes {
            index
            type
            url
          }
          crmGatewayAttributes {
            index
            type
            url
          }
          httpBasicAttributes {
            clientId
            clientProfileId
            username
            password
          }
          jwtAttributes {
            authorizationKey
            merchantId
            publicKey
            privateKey
          }
          oauthAttributes {
            clientId
            clientSecret
            companyId
            refreshToken
            accessToken
          }
          apiKeyAttributes {
            clientId
            clientProfileId
            key
            value
            addTo
          }
        }
      }
    }
  `;
};
