import gql from 'graphql-tag';

export default payload => {
  const getJobScheduleConfigByFrequency = `getJobScheduleConfigByFrequency(scheduleFrequency: ${payload || ''})`;
  return gql`
    query {
      ${getJobScheduleConfigByFrequency} {
        id
        exceptionEndTime
        exceptionStartTime
        description
        jobScheduleTime
        scheduleFrequency
        name
        configJobList {
          index
          jobNames
        }
      }
    }
  `;
};
