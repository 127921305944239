export const GET_CUSTOMERS = 'app/customers/get_customers';
export const GET_CUSTOMERS_SUCCESS = 'app/customers/get_customers_success';
export const GET_CUSTOMERS_FAILURE = 'app/customers/get_customers_failure';

export const SET_PARAMS = 'app/customers/set_params';

export const CREATE_ACCOUNT = 'app/customers/create_account';
export const CREATE_ACCOUNT_SUCCESS = 'app/customers/create_account_success';
export const CREATE_ACCOUNT_FAILURE = 'app/customers/create_account_failure';

export const GET_ACCOUNT_DETAIL = 'app/customers/get_account_detail';
export const GET_ACCOUNT_DETAIL_SUCCESS = 'app/customers/get_account_detail_success';
export const GET_ACCOUNT_DETAIL_FAILURE = 'app/customers/get_account_detail_failure';

export const GET_ACCOUNT_INFORMATION = 'app/customers/get_account_information';
// export const GET_ACCOUNT_INFORMATION_SUCCESS = 'app/customers/get_account_information_success';

export const GET_PARENT_ACCOUNT_DETAIL = 'app/customers/get_parent_account_detail';
export const GET_PARENT_ACCOUNT_DETAIL_SUCCESS = 'app/customers/get_parent_account_detail_success';
export const GET_PARENT_ACCOUNT_DETAIL_FAILURE = 'app/customers/get_parent_account_detail_failure';

export const GET_CUSTOMERS_ID = 'app/customers/get_customers_id';

export const MODIFY_ACCOUNT = 'app/customers/modify_account';
export const MODIFY_ACCOUNT_SUCCESS = 'app/customers/modify_account_success';

export const UPDATE_ACCOUNT_STATUS = 'app/customers/update_account_status';
export const UPDATE_ACCOUNT_STATUS_SUCCESS = 'app/customers/update_account_status_success';

export const GET_PRICE_UNITS = 'app/customers/get_price-units';
export const GET_PRICE_UNITS_SUCCESS = 'app/customers/get_price-units_success';
export const GET_PRICE_UNITS_FAILURE = 'app/customers/get_price-units_failure';

export const GET_SERVICE_UNITS_AC = 'app/customers/get_service_units_ac';
export const GET_SERVICE_UNITS_AC_SUCCESS = 'app/customers/get_service_units_ac_success';
export const GET_SERVICE_UNITS_AC_FAILURE = 'app/customers/get_service_units_ac_failure';

export const GET_SUBSCRIPTION = 'app/customers/get_subscription';
export const GET_SUBSCRIPTION_SUCCESS = 'app/customers/get_subscription_success';
export const GET_SUBSCRIPTION_FAILURE = 'app/customers/get_subscription_failure';

export const GET_BALANCE_UNIT = 'app/customers/get_balance-unit';
export const GET_BALANCE_UNIT_SUCCESS = 'app/customers/get_balance-unit_success';
export const GET_BALANCE_UNIT_FAILURE = 'app/customers/get_balance-unit_failure';

export const SET_TRANSACTION_PARAMS = 'app/customers/set_transaction_params';
export const GET_TRANSACTION_UNIT = 'app/customers/get_transaction_unit';
export const GET_TRANSACTION_UNIT_SUCCESS = 'app/customers/get_transaction_unit_success';
export const GET_TRANSACTION_UNIT_FAILURE = 'app/customers/get_transaction_unit_failure';

export const SET_AR_ACTIVITY_PARAMS = 'app/customers/set_ar_activity_params';
export const GET_AR_ACTIVITY = 'app/customers/get_ar_activity';
export const GET_AR_ACTIVITY_SUCCESS = 'app/customers/get_ar_activity_success';
export const GET_AR_ACTIVITY_FAILURE = 'app/customers/get_ar_activity_failure';

export const SET_ACTIVITY_PARAMS = 'app/customers/set_activity_params';
export const GET_CUSTOMER_ACTIVITY = 'app/customers/get_customer_activity';
export const GET_CUSTOMER_ACTIVITY_SUCCESS = 'app/customers/get_customer_activity_success';
export const GET_CUSTOMER_ACTIVITY_FAILURE = 'app/customers/get_customer_activity_failure';

export const GET_PENDING_BILL = 'app/customers/get_pending_bill';
export const GET_PENDING_BILL_SUCCESS = 'app/customers/get_pending_bill_success';
export const GET_PENDING_BILL_FAILURE = 'app/customers/get_pending_bill_failure';

export const GET_OC_BILL = 'app/customers/get_oc_bill';
export const GET_OC_BILL_SUCCESS = 'app/customers/get_oc_bill_success';
export const GET_OC_BILL_FAILURE = 'app/customers/get_oc_bill_failure';

export const GET_CUSTOM_ATTRIBUTES = 'app/customers/getCustomAttributes';
export const GET_CUSTOM_ATTRIBUTES_ORDER_SUCCESS = 'app/customers/GET_CUSTOM_ATTRIBUTES_ORDER_SUCCESS';
export const MODIFY_CUSTOM_ATTRIBUTES = 'app/customers/modifyCustomAttributes';
export const CREATE_CUSTOM_ATTRIBUTES = 'app/customers/createCustomAttributes';
export const GET_CUSTOMERS_PARENT = 'app/customers/getCustomerParent';

export const GET_CONFIG_CREDIT_PROFILE = 'app/customers/get_config_credit_profile';
export const GET_CONFIG_CREDIT_PROFILE_SUCCESS = 'app/customers/get_Config_credit_profile_success';
export const MODIFY_CONFIG_CREDIT_PROFILE = 'app/customers/modify_config_credit_profile';
export const CREATE_CONFIG_CREDIT_PROFILE = 'app/customers/create_config_credit_profile';

export const GET_TRANSACTION_UNIT_BY_ID = 'app/customers/get_transaction_unit_by_id';
export const GET_ACTIVITY_BY_ID = 'app/customers/get_activity_by_id';
export const GET_AR_OPS_UNIT_BY_ID = 'app/customers/get_ar_ops_unit_by_id';

export const MANUAL_INVOICE_SERVICE = 'app/customers/MANUAL_INVOICE_SERVICE';
export const RUN_PENDING_BILL = 'app/customers/RUN_PENDING_BILL';

export const GET_FUTURE_CYCLE_CFG = 'app/customers/get_FUTURE_CYCLE_CFG';
export const GET_FUTURE_CYCLE_CFG_SUCCESS = 'app/customers/get_FUTURE_CYCLE_CFG_success';
export const MODIFY_FUTURE_CYCLE_CFG = 'app/customers/modify_FUTURE_CYCLE_CFG';
export const CREATE_FUTURE_CYCLE_CFG = 'app/customers/create_FUTURE_CYCLE_CFG';

export const SEND_INVOICE = 'app/customers/SEND_INVOICE';
export const REGENERATE_INVOICE = 'app/customers/REGENERATE_INVOICE';
export const MODIFY_INVOICE_STATUS = 'app/customers/MODIFY_INVOICE_STATUS';
export const GET_ACCOUNT_STATEMENT = 'app/customers/GET_ACCOUNT_STATEMENT';
export const MODIFY_INVOICE_DUE_DATE = 'app/customers/MODIFY_INVOICE_DUE_DATE';

export const GET_EXTERNAL_BY_ACCOUNT_ID = 'app/customers/getExternalPOByAccountId';
export const CREATE_EXTERNAL_BY_ACCOUNT_ID = 'app/customers/CREATE_EXTERNAL_BY_ACCOUNT_ID';
export const MODIFY_EXTERNAL_BY_ACCOUNT_ID = 'app/customers/MODIFY_EXTERNAL_BY_ACCOUNT_ID';

export const UNDO_MANUAL_INVOICE = 'app/customers/UNDO_MANUAL_INVOICE';
export const SEARCH_ORDER_IN_COMPLETE = 'app/customers/SEARCH_ORDER_IN_COMPLETE';
export const SEARCH_NOTE_UNITS = 'app/customers/SEARCH_NOTE_UNITS';
export const GET_ACCOUNT_ASSETS = 'app/customers/GET_ACCOUNT_ASSETS';

export const GET_SPECIAL_PRICE = 'app/customers/GET_SPECIAL_PRICE';
export const CREATE_SPECIAL_PRICE = 'app/customers/CREATE_SPECIAL_PRICE';
export const MODIFY_SPECIAL_PRICE = 'app/customers/MODIFY_SPECIAL_PRICE';
export const UPDATE_CUSTOMER_ORDER_STATUS = 'app/customers/UPDATE_CUSTOMER_ORDER_STATUS';

export const MODIFY_NOTES_UNIT = 'app/customers/MODIFY_NOTES_UNIT';
export const GET_ORDER_SUMMARY_BY_ACCOUNT_ID = 'app/customers/GET_ORDER_SUMMARY_BY_ACCOUNT_ID';
export const UPDATE_BILLING_ACCOUNT = 'app/customers/UPDATE_BILLING_ACCOUNT';

export const GET_EXCHANGE_RATE_BY_ACCOUNT_ID = 'app/customers/GET_EXCHANGE_RATE_BY_ACCOUNT_ID';
export const CREATE_EXCHANGE_RATE_BY_ACCOUNT_ID = 'app/customers/CREATE_EXCHANGE_RATE_BY_ACCOUNT_ID';
export const MODIFY_EXCHANGE_RATE_BY_ACCOUNT_ID = 'app/customers/MODIFY_EXCHANGE_RATE_BY_ACCOUNT_ID';

export const GET_PREVIOUS_ACCOUNT_MERGE = 'app/customers/GET_PREVIOUS_ACCOUNT_MERGE';
export const INVOICE_ACCOUNT = 'app/customers/INVOICE_ACCOUNT';
export const GET_PREVIOUS_ACCOUNT_MOVEMENT = 'app/customers/GET_PREVIOUS_ACCOUNT_MOVEMENT';

export const GET_PAYMENT_INSTALLMENT_BY_ACCOUNTID = 'app/customers/GET_PAYMENT_INSTALLMENT_BY_ACCOUNTID';
export const CREATE_PAYMENT_INSTALLMENT_BY_ACCOUNTID = 'app/customers/CREATE_PAYMENT_INSTALLMENT_BY_ACCOUNTID';
export const MODIFY_PAYMENT_INSTALLMENT_BY_ACCOUNTID = 'app/customers/MODIFY_PAYMENT_INSTALLMENT_BY_ACCOUNTID';
export const GET_INVOICE_INSTALLMENTS_BY_INVOICEID = 'app/customers/GET_INVOICE_INSTALLMENTS_BY_INVOICEID';
export const SEARCH_USAGE_TRANSACTION = 'app/customers/SEARCH_USAGE_TRANSACTION';
export const SEARCH_USAGE_TRANSACTION_SUCCESS = 'app/customers/SEARCH_USAGE_TRANSACTION_SUCCESS';
export const GET_TRANSACTION_UNIT_BY_ID_USAGE = 'app/customers/GET_TRANSACTION_UNIT_BY_ID_USAGE';
export const RESEND_INVOICE_VENDOR = 'app/customers/RESEND_INVOICE_VENDOR';
export const UNDO_INVOICE_CANCEL = 'app/customers/UNDO_INVOICE_CANCEL';
export const RE_PROCESS_INVOICE = 'app/customers/RE_PROCESS_INVOICE';
export const GET_ORDER_HISTORY = 'app/customers/GET_ORDER_HISTORY';
export const SEARCH_LADING_BILL = 'app/customers/SEARCH_LADING_BILL';
export const GET_PAC_INTERFACE_RECORD_BY_ID = 'GET_PAC_INTERFACE_RECORD_BY_ID';
export const SAVE_ACCOUNT_NON_BILLABLES = 'SAVE_ACCOUNT_NON_BILLABLES';
export const MOVE_ACCOUNT = 'MOVE_ACCOUNT';
export const GET_INVOICE_TRANSACTION_SUMMARY = 'GET_INVOICE_TRANSACTION_SUMMARY';
export const GET_PENDING_MANUAL_INVOICE = 'GET_PENDING_MANUAL_INVOICE';
export const DELETE_PENDING_MANUAL_INVOICE = 'DELETE_PENDING_MANUAL_INVOICE';
export const SAVE_MANUAL_INVOICE = 'SAVE_MANUAL_INVOICE';
export const GET_INVOICE_TAX_SUMMARY = 'GET_INVOICE_TAX_SUMMARY';
export const SYNC_PROJECT_APP = 'SYNC_PROJECT_APP';
export const SAVE_ACCOUNT_MOVEMENT_DATA = 'SAVE_ACCOUNT_MOVEMENT_DATA';
export const GET_NOTES_UNIT_BY_ID = 'GET_NOTES_UNIT_BY_ID';
export const RESEND_PAYMENT_VENDOR = 'RESEND_PAYMENT_VENDOR';
export const RESEND_NOTES_VENDOR = 'RESEND_NOTES_VENDOR';
export const GET_CLIENT_ACCOUNT_STATEMENT = 'GET_CLIENT_ACCOUNT_STATEMENT';
export const GET_USED_CFDI_BY_TAX_REGION = 'GET_USED_CFDI_BY_TAX_REGION';
export const GET_SPLIT_TAX_PER_LINE = 'GET_SPLIT_TAX_PER_LINE';
export const SEARCH_ACCOUNTS_ASSETS = 'SEARCH_ACCOUNTS_ASSETS';
export const SEARCH_SUBSCRIPTION_BY_ACCOUNT_ID = 'SEARCH_SUBSCRIPTION_BY_ACCOUNT_ID';
export const SUBSCRIPTION_TOP_UP = 'SUBSCRIPTION_TOP_UP';
export const SEARCH_BALANCE_UNIT_BY_ACCOUNT_ID = 'SEARCH_BALANCE_UNIT_BY_ACCOUNT_ID';
export const GET_PROVISIONING_BY_ACCOUNT_ID = 'GET_PROVISIONING_BY_ACCOUNT_ID';
export const GET_MULTI_INV_PYMT_INSTALLMENTS_BY_ACCOUNT_ID = 'GET_MULTI_INV_PYMT_INSTALLMENTS_BY_ACCOUNT_ID';
export const SETUP_MULTI_INVOICE_PAYMENT_INSTALLMENT = 'SETUP_MULTI_INVOICE_PAYMENT_INSTALLMENT';
export const GET_SHARING_GROUP = 'GET_SHARING_GROUP';
export const CREATE_SHARING_GROUP = 'CREATE_SHARING_GROUP';
export const MODIFY_SHARING_GROUP = 'MODIFY_SHARING_GROUP';
export const SEARCH_ALL_AR_OPS_REQUESTS = 'app/customers/SEARCH_ALL_AR_OPS_REQUESTS';
export const GET_AR_OPS_REQUEST_BY_ID = 'app/customers/GET_AR_OPS_REQUEST_BY_ID';
export const GENERATE_PAYMENT_INSTALLMENT_REPORT = 'app/customers/GENERATE_PAYMENT_INSTALLMENT_REPORT';
export const GET_ACCOUNT_DETAIL_FOR_SELECT_PARENT = 'app/customers/get_account_detail_FOR_SELECT_PARENT';
export const GET_UNALLOCATED_PAYMENT = 'app/customers/GET_UNALLOCATED_PAYMENT';
export const GET_CUSTOMERS_NO_PARENT_FILTER = 'app/customers/GET_CUSTOMERS_NO_PARENT_FILTER';
export const SEARCH_SUBSCRIPTION_TOP_UP = 'app/customers/SEARCH_SUBSCRIPTION_TOP_UP';
