import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import SearchComponent from '../../PricingHub/SearchComponent';

class ItemSummarySearchForm extends SearchComponent {
  searchInputs = [
    {
      label: 'label.accountId',
      name: 'accountId',
    },
    {
      label: 'label.billStartDate',
      name: 'billStartDate',
      type: 'date',
    },
    {
      label: 'label.billEndDate',
      name: 'billEndDate',
      type: 'date',
    },
    {
      label: 'label.invoiceId',
      name: 'invoiceId',
    },
  ];

  btnSpan = 'col-md-12';
}

ItemSummarySearchForm.propTypes = {
  onSubmit: PropTypes.func,
  isSubmitting: PropTypes.bool,
};

ItemSummarySearchForm.defaultProps = {
  isSubmitting: false,
};

export default withTranslation('common')(ItemSummarySearchForm);
