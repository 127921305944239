import { cloneDeep } from 'lodash';
import styleHeader from './styleHeader';
import { columns } from './commonFields';
import { getLabelOptions, formatMoneyValueToExport } from '../utils';
import { convertBoolLabel } from '../exportHelper';

const multiDataSetDefault = ({ t, title, colums }) => [
  {
    xSteps: 4,
    columns: [],
    data: [[{ value: title || 'CREDIT NOTES', style: { font: { sz: '20', bold: true } } }]],
  },
  {
    columns: colums.map(() => columns.common),
    data: [
      colums.map(val => ({
        value: t(val.label),
        style: styleHeader,
      })),
    ],
  },
];

const convertJson2Sheet = ({ data, t, title, colums }) => {
  const newDataSheetSet = cloneDeep(multiDataSetDefault({ t, title, colums }));
  const newListData = data.map(item => {
    const newItem = [];
    if (item && typeof item === 'object') {
      if (colums && colums.length) {
        colums.forEach(cl => {
          if (cl.isRenderT) {
            newItem.push({ value: getLabelOptions({ item, t, fieldName: cl.name, fieldOption: cl.keyOptions }) });
          }
          if (cl.isBool) {
            newItem.push({ value: convertBoolLabel({ t, value: item[cl.name] }) });
          }
          if (!cl.isBool && !cl.isRenderT) {
            if (item[cl.name] && typeof item[cl.name] === 'number') {
              newItem.push({ value: formatMoneyValueToExport(item[cl.name]) });
            } else newItem.push({ value: item[cl.name] ? item[cl.name] : '' });
          }
        });
      }
    }
    return newItem;
  });

  if (newListData.length) {
    newDataSheetSet[1].data = newDataSheetSet[1].data.concat(newListData);
  }
  return newDataSheetSet;
};

export default convertJson2Sheet;
