import React, { useEffect, useState, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { cloneDeep } from 'lodash';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { DataTable, FormWithTableItem, GenericInput, ModalWithItem } from '../../../components/common';
import PageTitle from '../../../components/PageTitle';
import {
  searchConfigMultiRating,
  createConfigMultiRating,
  modifyConfigMultiRating,
  getConfigMultiRatingById,
} from '../actions';
import RouteNames from '../../App/RouteNames';
import { getLastIndex, supportRemoveIndexWithSize, compareArrayIndexValueChange, validate } from '../../../utils/utils';
import { getConfigUsageTypeConfig } from '../../App/actions';

const MultiRatingConfig = ({
  searchConfigMultiRating,
  createConfigMultiRating,
  modifyConfigMultiRating,
  getConfigUsageTypeConfig,
  getConfigMultiRatingById,
}) => {
  const formRef = useRef();

  const [isLoading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [defaultData, setDefaultData] = useState(null);
  const [itemSelect, setItemSelect] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [wasValidated, setValidate] = useState(false);
  const [usageTypeOptions, setUsageTypeOptions] = useState({});

  const { t } = useTranslation('common');

  const doSearchConfigMultiRating = useCallback(() => {
    setLoading(true);
    searchConfigMultiRating('', ({ success, data }) => {
      setLoading(false);
      if (success && data) {
        setData(data);
        setItemSelect(null);
        setDefaultData(null);
        setValidate(false);
      } else {
        setData([]);
        setItemSelect(null);
        setDefaultData(null);
        setValidate(false);
      }
    });
  }, [searchConfigMultiRating]);

  const onChangeTable = ({ name, value, index, key }) => {
    const newData = cloneDeep(itemSelect);
    newData[key][index][name] = value;
    setItemSelect(newData);
  };

  const onChangeData = ({ name, value }) => {
    const newData = cloneDeep(itemSelect);
    newData[name] = value;
    setItemSelect(newData);
  };

  const onAddNewConfigMultiRatingUsageTypes = () => {
    const newData = cloneDeep(itemSelect);
    if (!newData || !newData.configMultiRatingUsageTypes) newData.configMultiRatingUsageTypes = [];
    newData.configMultiRatingUsageTypes.push({
      index: getLastIndex({ data: newData.configMultiRatingUsageTypes }),
      usageType: null,
    });
    setItemSelect(newData);
  };

  const onRemoveItem = ({ index, item, key }) => {
    try {
      const newData = cloneDeep(itemSelect);
      if (item.isNew) {
        newData[key].splice(index, 1);
        return setItemSelect(newData);
      }
      newData[key][index] = { index: newData[key][index].index };
      return setItemSelect(newData);
    } catch (error) {
      return console.log(error);
    }
  };

  let listOptions = [];
  if (usageTypeOptions && usageTypeOptions.configUsageTypeService && itemSelect) {
    const itemsList = usageTypeOptions.configUsageTypeService.find(val => val.serviceType === itemSelect.serviceType);
    if (itemsList && itemsList.configUsageTypeList) {
      listOptions = itemsList.configUsageTypeList.map(val => ({ label: val.usagetype, value: val.usagetype }));
    }
  }

  const ratingUsageColumns = [
    {
      name: 'usageType',
      label: 'label.usageType',
      required: true,
      style: { width: '200px' },
      render: (colName, item, index) => {
        return (
          <GenericInput
            value={item.usageType}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => onChangeTable({ name, value, index, key: 'configMultiRatingUsageTypes' })}
            name="usageType"
            required
            type="select"
            options={listOptions}
            menuPortalTarget
            isCreateOption
          />
        );
      },
    },
    {
      name: 'remove',
      label: t('label.remove'),
      render: (colName, item, index) => {
        return (
          <button
            type="button"
            className="no-border"
            onClick={() => onRemoveItem({ index, item, key: 'configMultiRatingUsageTypes' })}
          >
            <i className="fa fa-trash" />
          </button>
        );
      },
    },
  ];

  const onSubmit = () => {
    setValidate(true);
    if (!validate(true, formRef, t)) {
      return false;
    }
    setValidate(false);
    const payload = cloneDeep(itemSelect);
    const { configMultiRatingUsageTypes, id, serviceType, subscriptionCategory } = payload;

    if (isEdit) {
      return modifyConfigMultiRating(
        {
          id,
          serviceType: serviceType || null,
          subscriptionCategory: subscriptionCategory || null,
          configMultiRatingUsageTypes: configMultiRatingUsageTypes
            ? compareArrayIndexValueChange({
                defaultData: defaultData ? defaultData.configMultiRatingUsageTypes : null,
                newData: supportRemoveIndexWithSize({ data: configMultiRatingUsageTypes }),
              })
            : null,
        },
        ({ success }) => {
          if (success) doSearchConfigMultiRating();
        }
      );
    }

    return createConfigMultiRating(
      {
        serviceType: serviceType || null,
        subscriptionCategory: subscriptionCategory || null,
        id: id || null,
        configMultiRatingUsageTypes: configMultiRatingUsageTypes
          ? configMultiRatingUsageTypes.map(val => {
              const { index, isNew, ...rest } = val;
              return { ...rest };
            })
          : null,
      },
      ({ success }) => {
        if (success) doSearchConfigMultiRating();
      }
    );
  };

  const doGetConfigMultiRatingById = ({ item }) => {
    getConfigMultiRatingById(item.id, ({ success, data }) => {
      if (success) {
        setItemSelect(data);
        setDefaultData(data);
        setIsEdit(true);
      }
    });
  };

  const tableColumns = [
    {
      name: 'id',
      label: 'label.id',
      render: (colName, item) => (
        <button
          className="text-success no-border"
          type="button"
          onClick={() => {
            doGetConfigMultiRatingById({ item });
            if (!usageTypeOptions || !usageTypeOptions.id) doGetConfigUsageTypeConfig();
          }}
        >
          {item.id}
        </button>
      ),
    },
    {
      label: 'common:label.serviceType',
      name: 'serviceType',
      isRenderT: true,
      render: (colName, item, t) => {
        const slt = t ? t('selections:serviceType')().find(val => val.value === item.serviceType) : '';
        return <span>{slt ? slt.label : ''}</span>;
      },
    },
    {
      label: 'common:label.subscriptionCategory',
      name: 'subscriptionCategory',
      isRenderT: true,
      render: (colName, item, t) => {
        const slt = t
          ? t('selections:subscriptionCategory')().find(val => val.value === item.subscriptionCategory)
          : '';
        return <span>{slt ? slt.label : ''}</span>;
      },
    },
    {
      name: 'id',
      label: 'label.view',
      render: (colName, item) => (
        <button
          className="btn btn-outline-success btn-sm"
          type="button"
          onClick={() => {
            doGetConfigMultiRatingById({ item });
            if (!usageTypeOptions || !usageTypeOptions.id) doGetConfigUsageTypeConfig();
          }}
        >
          {t('label.view')}
        </button>
      ),
    },
  ];

  const mainFileds = [
    {
      name: 'id',
      label: 'label.id',
    },
    {
      name: 'serviceType',
      label: 'label.serviceType',
      type: 'select',
      tOptions: 'selections:serviceType',
      required: true,
    },
    {
      name: 'subscriptionCategory',
      label: 'label.subscriptionCategory',
      type: 'select',
      tOptions: 'selections:subscriptionCategory',
      required: true,
    },
  ];

  const onToggleModal = () => {
    setItemSelect(null);
  };

  const onAddNew = () => {
    setItemSelect({});
    if (!usageTypeOptions || !usageTypeOptions.id) doGetConfigUsageTypeConfig();
    setIsEdit(false);
  };

  const doGetConfigUsageTypeConfig = () => {
    getConfigUsageTypeConfig('', ({ success, data }) => {
      if (success) setUsageTypeOptions(data);
    });
  };

  useEffect(() => {
    doSearchConfigMultiRating();
  }, [doSearchConfigMultiRating]);

  return (
    <div className="col-md-12">
      <PageTitle
        linkTo={RouteNames.invoicingBilling.path}
        titleBtn={t('label.back')}
        items={[
          { name: t('navbar:billingHub.subMain.billing'), url: RouteNames.invoicingBilling.path },
          { name: t('baseConfigurationsPage:sidebar.multiRatingConfig') },
        ]}
      />
      <FormWithTableItem>
        <div className="col-md-12 ml-3 mb-3">
          <button type="button" className="button x-small" onClick={onAddNew}>
            {`+ ${t('label.add')}`}
          </button>
        </div>
        <DataTable isLoading={isLoading} columns={tableColumns} data={data || []} />
      </FormWithTableItem>

      <ModalWithItem
        isOpen={!!itemSelect}
        onToggle={onToggleModal}
        modalTitle={isEdit ? t('baseConfigurationsPage:sidebar.multiRatingConfig') : t('label.addNew')}
        wrapperClass="modal-custom modal-70 bd-example-modal-lg modal-selector"
      >
        <form ref={formRef} noValidate className={`needs-validation ${wasValidated ? 'was-validated' : ''}`}>
          <div className="mb-2">
            <div className="col-md-12 row">
              {mainFileds.map(val => (
                <GenericInput
                  readOnly={val.name === 'id' && isEdit}
                  {...val}
                  value={itemSelect ? itemSelect[val.name] : null}
                  onChange={onChangeData}
                />
              ))}
            </div>
            <div className="pt-2">
              <div className="row col-md-6 p-2">
                <DataTable
                  columns={ratingUsageColumns}
                  data={
                    itemSelect && itemSelect?.configMultiRatingUsageTypes ? itemSelect.configMultiRatingUsageTypes : []
                  }
                  isLoading={isLoading}
                  isSupportRemoveIndex
                />
                <button
                  type="button"
                  className="button button-border black x-small"
                  onClick={onAddNewConfigMultiRatingUsageTypes}
                >
                  {`+ ${t('label.add')}`}
                </button>
              </div>
            </div>
            <div className="col-md-12 mt-3">
              <button type="button" onClick={onSubmit} className="button button-border x-small float-right">
                {t('label.saveConfig')}
              </button>
              <button
                type="button"
                onClick={onToggleModal}
                className="button button-border black x-small float-right mr-2"
              >
                {t('label.cancel')}
              </button>
            </div>
          </div>
        </form>
      </ModalWithItem>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({});

MultiRatingConfig.propTypes = {
  searchConfigMultiRating: PropTypes.func.isRequired,
  createConfigMultiRating: PropTypes.func.isRequired,
  modifyConfigMultiRating: PropTypes.func.isRequired,
  getConfigUsageTypeConfig: PropTypes.func.isRequired,
  getConfigMultiRatingById: PropTypes.func.isRequired,
};

MultiRatingConfig.defaultProps = {};

export default connect(mapStateToProps, {
  searchConfigMultiRating,
  createConfigMultiRating,
  modifyConfigMultiRating,
  getConfigUsageTypeConfig,
  getConfigMultiRatingById,
})(MultiRatingConfig);
