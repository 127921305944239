import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { SidebarSupportSubItem } from '../../components/common';
import RouteNames from '../App/RouteNames';
import {
  checkPermissionForAllPages,
  checkPermissionGetUsagePlatforms,
  checkPermissionGetZeroPriceUsageConfig,
  checkPermissionGetVoicePlan,
  checkPermissionGetRegionMapConfig,
  checkPermissionGetTrunkRuleConfig,
  checkPermissionGetOperatorMapConfig,
} from './CheckPermission';
import { isHasPermissionWithMenu } from '../../utils/utils';
import { permissionList } from '../../utils/constants';
import { checkPermissionForAllPages as checkPermissionForAllPagesReport } from '../ReportManagement/CheckPermission';

class Sidebar extends Component {
  state = {};

  render() {
    const {
      isExpand = true,
      permissionsRating,
      permissionsMediation,
      permissionsPricing,
      permissionsReports,
    } = this.props;
    const childConfig = [];
    const usageRating = [];
    const operatorMap = [];
    if (!isHasPermissionWithMenu({ permissions: permissionsRating, listKeys: permissionList.usage })) return null;
    let permissions = {};
    let modeGetUsagePlatforms = 0;
    let modeGetZeroRateConfig = 0;
    let modeGetVoicePlan = 0;
    let modeGetRegionMapConfig = 0;
    let modeGetTrunkRuleConfig = 0;
    let modeGetOperatorMapConfig = 0;

    if (permissionsRating && permissionsRating.ratingModulePermissions) {
      const listPermission = permissionsRating.ratingModulePermissions;
      permissions = checkPermissionForAllPages({ listPermission });
    }
    if (permissionsMediation && permissionsMediation.mediationModulePermissions) {
      const listPermission = permissionsMediation.mediationModulePermissions;
      modeGetUsagePlatforms = checkPermissionGetUsagePlatforms({ listPermission });
    }

    if (permissionsPricing && permissionsPricing.pricingModulePermissions) {
      const listPermissionPricing = permissionsPricing.pricingModulePermissions;
      modeGetZeroRateConfig = checkPermissionGetZeroPriceUsageConfig({ listPermission: listPermissionPricing });
      modeGetVoicePlan = checkPermissionGetVoicePlan({ listPermission: listPermissionPricing });
      modeGetRegionMapConfig = checkPermissionGetRegionMapConfig({ listPermission: listPermissionPricing });
      modeGetTrunkRuleConfig = checkPermissionGetTrunkRuleConfig({ listPermission: listPermissionPricing });
      modeGetOperatorMapConfig = checkPermissionGetOperatorMapConfig({ listPermission: listPermissionPricing });
    }

    let permissionsReport = {};
    if (permissionsReports && permissionsReports.reportModulePermissions) {
      const listPermission = permissionsReports.reportModulePermissions;
      permissionsReport = checkPermissionForAllPagesReport({ listPermission });
    }

    const { modeGetReratingBatch, modeTestUsage } = permissions || {};

    if (modeGetZeroRateConfig) {
      usageRating.push({
        icon: 'ti-time',
        label: 'baseConfigurationsPage:sidebar.zeroPriceUsage',
        href: RouteNames.zeroPriceUsage.path,
      });
    }

    if (modeGetVoicePlan) {
      usageRating.push({
        icon: 'ti-map-alt',
        label: 'baseConfigurationsPage:sidebar.voicePlans',
        href: RouteNames.voicePlans.path,
      });
    }

    if (modeGetRegionMapConfig) {
      usageRating.push({
        icon: 'ti-map-alt',
        label: 'baseConfigurationsPage:sidebar.regionMapping',
        href: RouteNames.regionMapping.path,
      });
    }

    if (modeGetTrunkRuleConfig) {
      usageRating.push({
        icon: 'ti-calendar',
        label: 'baseConfigurationsPage:sidebar.trunkRule',
        href: RouteNames.trunkRule.path,
      });
    }

    usageRating.push({
      icon: 'ti-comment',
      label: 'baseConfigurationsPage:sidebar.PNNList',
      href: RouteNames.PNNList.path,
    });

    usageRating.push({
      icon: 'ti-server',
      label: 'baseConfigurationsPage:sidebar.multiRatingConfig',
      href: RouteNames.multiRatingConfig.path,
    });

    if (modeGetOperatorMapConfig) {
      operatorMap.push({
        icon: 'ti-calendar',
        label: 'baseConfigurationsPage:sidebar.operatorMap',
        href: RouteNames.operatorMap.path,
      });
    }

    if (permissionsReport && permissionsReport.modeGetRawFileReport) {
      childConfig.push({
        icon: 'ti-bag',
        label: 'reportPage:sidebar.rawFiles',
        href: RouteNames.rawUsageFilesUsage.path,
      });
    }

    if (permissionsReport && permissionsReport.modeGetUsageReconciliationReport) {
      childConfig.push({
        icon: 'ti-medall',
        label: 'reportPage:sidebar.CDRReconciliationSummary',
        href: RouteNames.cdrReconciliationSummaryUsage.path,
      });
    }

    childConfig.push({
      icon: 'ti-control-pause',
      label: 'usagePage:sidebar.reprocessingSuspendedFailedRecords',
      href: RouteNames.reprocessingSuspendedFailedRecords.path,
    });

    if (modeGetReratingBatch) {
      childConfig.push({
        icon: 'ti-layout-width-default',
        label: 'usagePage:sidebar.rerating',
        href: RouteNames.rerating.path,
      });
    }

    if (modeTestUsage) {
      childConfig.push({
        icon: 'ti-server',
        label: 'usagePage:sidebar.testUsage',
        href: RouteNames.testUsage.path,
      });
    }

    const sideProps = {
      isExpand,
      items: [
        {
          isHaveExpand: true,
          isExpandChild: true,
          mainTilte: 'usagePage:sidebar.mediationSetup',
          href: '',

          childs: [
            {
              icon: 'ti-bag',
              label: 'usagePage:sidebar.platforms',
              href: RouteNames.platformsUsage.path,
            },
            {
              icon: 'ti-map-alt',
              label: 'baseConfigurationsPage:sidebar.customRecords',
              href: RouteNames.customRecords.path,
            },
            {
              icon: 'ti-map',
              label: 'baseConfigurationsPage:sidebar.fileMapping',
              href: RouteNames.fileMapping.path,
            },
          ],
        },
        {
          isHaveExpand: true,
          isExpandChild: true,
          mainTilte: 'usagePage:sidebar.usageConfig',
          href: '',
          childs: [
            {
              icon: 'ti-layout-media-center-alt',
              label: 'baseConfigurationsPage:sidebar.processingStreams',
              href: RouteNames.processingStreams.path,
            },
            ...usageRating,
          ],
        },
        {
          isHaveExpand: true,
          isExpandChild: true,
          mainTilte: 'usagePage:sidebar.operatorProcess',
          href: '',
          childs: [
            ...operatorMap,
            {
              icon: 'ti-check-box',
              label: 'usagePage:sidebar.invoice',
              href: RouteNames.invoiceUsage.path,
            },

            {
              icon: 'ti-flag-alt',
              label: 'usagePage:sidebar.dispute',
              href: RouteNames.disputeUsage.path,
            },
            {
              icon: 'ti-comment',
              label: 'usagePage:sidebar.recordLog',
              href: RouteNames.recordLogUsage.path,
            },
            {
              icon: 'ti-shield',
              label: 'usagePage:sidebar.tenantDispute',
              href: RouteNames.tenantDispute.path,
            },
            {
              icon: 'ti-video-camera',
              label: 'usagePage:sidebar.tenantRecordLog',
              href: RouteNames.tenantRecordLog.path,
            },
          ],
        },
        {
          isHaveExpand: true,
          isExpandChild: true,
          mainTilte: 'usagePage:sidebar.usageOps',
          href: '',
          childs: [...childConfig],
        },
      ],
    };
    return <SidebarSupportSubItem {...sideProps} />;
  }
}

const mapStateToProps = store => {
  const layoutReducer = store.get('layout');
  const { sidebarExpanded } = layoutReducer ? layoutReducer.toJS() : { sidebarExpanded: true };
  const permissionsRating = store.get('auth').toJS().permissionsApp.billingHubModules;
  const permissionsMediation = store.get('auth').toJS().permissionsApp.mediationHubModules;
  const permissionsPricing = store.get('auth').toJS().permissionsApp.pricingHubModules;
  const permissionsReports = store.get('auth').toJS().permissionsApp.opsHubModules;

  return {
    isExpand: sidebarExpanded,
    permissionsRating:
      permissionsRating && permissionsRating.length ? permissionsRating.filter(a => a.moduleName === 'RATING')[0] : {},
    permissionsMediation:
      permissionsMediation && permissionsMediation.length
        ? permissionsMediation.filter(a => a.moduleName === 'MEDIATION')[0]
        : {},
    permissionsPricing: permissionsPricing.length
      ? permissionsPricing.filter(a => a.moduleName === 'PRICE_MANAGEMENT')[0]
      : {},
    permissionsReports: permissionsReports.length ? permissionsReports.filter(a => a.moduleName === 'REPORTS')[0] : {},
  };
};
const mapDispatchToProps = dispatch => ({
  dispatch,
});

Sidebar.propTypes = {
  isExpand: PropTypes.bool,
  permissionsPayment: PropTypes.objectOf(PropTypes.any),
};

Sidebar.defaultProps = {
  isExpand: false,
  permissionsPayment: {},
};

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
