import { cloneDeep } from 'lodash';
import pdfConfig from './helper';
import { formatMoneyValueToExport, getLabelOptions } from '../utils';
import { convertBoolLabel } from '../exportHelper';

const HeaderTable = ({ t, columnsTable }) => columnsTable.map(val => t(val.label));

const convertJson2Pdf = ({ data, t, title, fileName, columnsTable, ...rest }) => {
  const Header = cloneDeep(HeaderTable({ t, columnsTable }));
  const newListData = data.map(item => {
    const newItem = [];
    if (item && typeof item === 'object') {
      columnsTable.forEach(val => {
        if (val.name === 'cutOffDOM') {
          if (item.cutOffDOM === -2) {
            const cutOffDOMOptions = t('selections:cutOffDOM')();
            const lastItem = cutOffDOMOptions[0];
            newItem.push({ content: lastItem.label || '' });
          } else newItem.push({ content: `${item.cutOffDOM || ''}` });
        }
        if (val.name !== 'cutOffDOM') {
          if ((item[val.name] || item[val.name] === 0) && typeof item[val.name] === 'number') {
            newItem.push({ content: formatMoneyValueToExport(item[val.name]) });
          } else if (typeof item[val.name] === 'boolean') {
            newItem.push({ content: convertBoolLabel({ t, value: item[val.name] }) });
          } else if (val.isRenderT) {
            newItem.push({
              content: getLabelOptions({
                item,
                t,
                fieldOption: val.fieldOption || null,
                fieldName: val.name,
              }),
            });
          } else {
            newItem.push({ content: item[val.name] ? item[val.name] : '' });
          }
        }
      });
    }
    return newItem;
  });
  return pdfConfig({ data: newListData, Header, title, fileName, fontSize: 2, cellPadding: 1, ...rest });
};

export default convertJson2Pdf;
