import gql from 'graphql-tag';
import { convertFilterRequestUrl } from '../../utils/utils';

export default ({ page, size, filter, sort }) => {
  const sortStr = sort ? `, sort: ${sort}` : '';
  const filterStr = filter
    ? `, filter: ${convertFilterRequestUrl(filter, ['orderType', 'accountType', 'status', 'groupBy'])}`
    : '';
  const getOrderReport = `getOrderReport(page: ${page}, size: ${size}${sortStr}${filterStr})`;
  return gql`
    query {
      ${getOrderReport} {
        id
        accountId
        company
        firstName
        lastName
        accountType
        orderType
        effectiveDate
        subscriptionReason
        orderSubType
        orderLevel
        reason
        status
        crmId
        provisioningId
        orderSource
        userId
        submittedDate
        reason
        provisioningStartDate
        serviceProvisionedDate
        serviceType
        lineOfBusiness
      }
    }
  `;
};
