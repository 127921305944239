import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Tooltip from 'rc-tooltip';
import moment from 'moment';
import 'rc-tooltip/assets/bootstrap_white.css';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import './styles.scss';

const TextInput = ({ value, onChange, isSupportDefaultTime, readOnly, disabled }) => {
  let newValue = null;
  if (moment(value, 'HH:mm:ss').isValid()) {
    newValue = moment(value, 'HH:mm:ss');
  }
  const onChangeValue = date => {
    if (date) {
      onChange({ value: moment(date).format('HH:mm:ss') });
    }
    if (date == null) onChange({ value: null });
  };
  return (
    <TimePicker
      defaultOpenValue={isSupportDefaultTime ? moment(`${moment().hour()}:00:00`, 'HH:mm:ss') : moment()}
      value={newValue}
      onChange={onChangeValue}
      readOnly={readOnly}
      disabled={disabled}
    />
  );
};

TextInput.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

TextInput.defaultProps = {
  value: '',
  onChange: () => {},
};

const TimeGroup = ({
  label,
  wrapperClass,
  fa,
  isPopupInfo,
  labelPopupInfo,
  onOpenPopupInfo,
  isSupportTooltip,
  subLabel,
  tooltipDetails,
  rightLabel,
  isBtnRight,
  onClickRightButton,
  labelRightButton,
  isDisableRightButton,
  ...props
}) => {
  const { t } = useTranslation('common');
  const { required, name, value } = props;
  return (
    <div className={`form-group ${wrapperClass}`}>
      {label && (
        <span>
          {t(label)}
          {label && required && <i className="fa fa-star is-required" />}
          {subLabel && <span className="sub-label">{subLabel}</span>}
          &nbsp;
          {tooltipDetails && (
            <Tooltip
              trigger={['hover']}
              placement="bottom"
              overlay={tooltipDetails ? t(tooltipDetails) : ''}
              arrowContent={<div className="rc-tooltip-arrow-inner" />}
              overlayClassName="tooltip-details"
            >
              <i className="ti-info-alt ico-info-tooltip" aria-hidden="true" />
            </Tooltip>
          )}
        </span>
      )}
      <div className={`wr-input ${isPopupInfo || isBtnRight ? 'd-flex' : ''}`} id={`${name}`}>
        {isSupportTooltip && value ? (
          <Tooltip placement="bottom" overlay={value || ''} arrowContent={<div className="rc-tooltip-arrow-inner" />}>
            <TextInput className="form-control input-group" {...props} />
          </Tooltip>
        ) : (
          <TextInput className={`form-control input-group ${fa ? 'pr-4' : ''}`} {...props} />
        )}
        {fa && (
          <div className="wr-input-icon">
            <i className={`fa ${fa}`} />
          </div>
        )}
        {isPopupInfo && (
          <button type="button" className="button x-small btn-popup" onClick={onOpenPopupInfo}>
            {t(labelPopupInfo)}
          </button>
        )}
        {isBtnRight && (
          <button
            type="button"
            className="button x-small btn-popup"
            disabled={isDisableRightButton}
            onClick={onClickRightButton}
          >
            {t(labelRightButton)}
          </button>
        )}

        {rightLabel && <span>{t(rightLabel)}</span>}
      </div>
    </div>
  );
};

TimeGroup.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string,
  wrapperClass: PropTypes.string,
  onChange: PropTypes.func,
  onOpenPopupInfo: PropTypes.func,
  required: PropTypes.bool,
  isNoEnter: PropTypes.bool,
  name: PropTypes.string,
  value: PropTypes.string,
  fa: PropTypes.string,
  isPopupInfo: PropTypes.bool,
  labelPopupInfo: PropTypes.string,
  isSupportTooltip: PropTypes.bool,
  subLabel: PropTypes.string,
  tooltipDetails: PropTypes.string,
  rightLabel: PropTypes.string,
  isBtnRight: PropTypes.bool,
  onClickRightButton: PropTypes.func,
  labelRightButton: PropTypes.string,
  isDisableRightButton: PropTypes.bool,
};

TimeGroup.defaultProps = {
  label: '',
  onChange: () => {},
  onOpenPopupInfo: () => {},
  type: 'time',
  wrapperClass: 'col-md-3',
  required: false,
  isNoEnter: false,
  name: '',
  value: '',
  fa: '',
  isPopupInfo: false,
  labelPopupInfo: '',
  isSupportTooltip: false,
  subLabel: '',
  tooltipDetails: '',
  rightLabel: '',
  isBtnRight: false,
  onClickRightButton: () => {},
  labelRightButton: '',
  isDisableRightButton: false,
};

export default TimeGroup;
