import { cloneDeep } from 'lodash';
import styleHeader from './styleHeader';
import { getLabelOptions, formatMoneyValueToExport } from '../utils';
import { columns } from './commonFields';
import { convertBoolLabel } from '../exportHelper';

const multiDataSetDefault = ({ t, title, columnsTable }) => [
  {
    xSteps: columnsTable.length > 4 ? columnsTable.length / 2 - 2 : 1,
    columns: [],
    data: [[{ value: title || 'LIST JOURNALS', style: { font: { sz: '20', bold: true } } }]],
  },
  {
    columns: columnsTable.map(val => columns[val.name] || columns.common),
    // [
    //   columns.common,
    //   columns.startDate,
    //   columns.endDate,
    //   columns.common,
    //   columns.common,
    //   columns.glAccount,
    //   columns.common,
    //   columns.common,
    //   columns.common,
    //   columns.accountId,
    //   columns.accountType,
    //   columns.common,
    //   columns.amount,
    //   columns.common,
    //   columns.revenueType,
    //   columns.common,
    //   columns.common,
    //   columns.common,
    //   columns.status,
    //   columns.common,
    //   columns.transactionId,
    //   columns.common,
    //   columns.itemId,
    //   columns.billUnitId,
    //   columns.invoiceId,
    //   columns.invoiceDate,
    //   columns.common,
    //   columns.country,
    //   columns.productLine,
    //   columns.common, // reportObjectType
    //   columns.common, // notesId
    //   columns.common, // paymentId
    //   columns.common, // subAccount
    //   columns.common, // glOffset
    //   columns.salesChannel,
    //   columns.marketSegment,
    //   columns.accountSubType,
    //   columns.common, // exchangeRate
    //   columns.common,
    //   columns.sellingCompany,
    //   columns.common,
    //   columns.common,
    //   columns.productCompany,
    // ],
    // data: [
    //   [
    //     {
    //       value: t('label.id'),
    //       style: styleHeader,
    //     },
    //     {
    //       value: t('label.startDate'),
    //       style: styleHeader,
    //     },
    //     {
    //       value: t('label.endDate'),
    //       style: styleHeader,
    //     },
    //     {
    //       value: t('label.accountingPeriod'),
    //       style: styleHeader,
    //     },
    //     {
    //       value: t('label.batchId'),
    //       style: styleHeader,
    //     },
    //     {
    //       value: t('label.glAccount'),
    //       style: styleHeader,
    //     },
    //     {
    //       value: t('label.costCenter'),
    //       style: styleHeader,
    //     },
    //     {
    //       value: t('label.interCompany'),
    //       style: styleHeader,
    //     },
    //     {
    //       value: t('label.currency'),
    //       style: styleHeader,
    //     },
    //     {
    //       value: t('label.accountId'),
    //       style: styleHeader,
    //     },
    //     {
    //       value: t('label.accountType'),
    //       style: styleHeader,
    //     },
    //     {
    //       value: t('label.itemName'),
    //       style: styleHeader,
    //     },
    //     {
    //       value: t('label.amount'),
    //       style: styleHeader,
    //     },
    //     {
    //       value: t('label.amountRecognized'),
    //       style: styleHeader,
    //     },
    //     {
    //       value: t('label.revenueType'),
    //       style: styleHeader,
    //     },
    //     {
    //       value: t('label.revenueRecognitionType'),
    //       style: styleHeader,
    //     },
    //     {
    //       value: t('label.revenueActivityType'),
    //       style: styleHeader,
    //     },
    //     {
    //       value: t('label.transactionType'),
    //       style: styleHeader,
    //     },
    //     {
    //       value: t('label.status'),
    //       style: styleHeader,
    //     },
    //     {
    //       value: t('label.billed'),
    //       style: styleHeader,
    //     },
    //     {
    //       value: t('label.transactionId'),
    //       style: styleHeader,
    //     },
    //     {
    //       value: t('label.arOpsId'),
    //       style: styleHeader,
    //     },
    //     {
    //       value: t('label.itemId'),
    //       style: styleHeader,
    //     },
    //     {
    //       value: t('label.billUnitId'),
    //       style: styleHeader,
    //     },
    //     {
    //       value: t('label.invoiceId'),
    //       style: styleHeader,
    //     },
    //     {
    //       value: t('label.invoiceDate'),
    //       style: styleHeader,
    //     },
    //     {
    //       value: t('label.country'),
    //       style: styleHeader,
    //     },
    //     {
    //       value: t('label.productLine'),
    //       style: styleHeader,
    //     },
    //     {
    //       value: t('label.objectType'),
    //       style: styleHeader,
    //     },
    //     {
    //       value: t('label.notesId'),
    //       style: styleHeader,
    //     },
    //     {
    //       value: t('label.paymentId'),
    //       style: styleHeader,
    //     },
    //     {
    //       value: t('label.subAccount'),
    //       style: styleHeader,
    //     },
    //     {
    //       value: t('label.glOffset'),
    //       style: styleHeader,
    //     },
    //     {
    //       value: t('label.salesChannel'),
    //       style: styleHeader,
    //     },
    //     {
    //       value: t('label.marketSegment'),
    //       style: styleHeader,
    //     },
    //     {
    //       value: t('label.accountSubType'),
    //       style: styleHeader,
    //     },
    //     {
    //       value: t('label.exchangeRate'),
    //       style: styleHeader,
    //     },
    //     {
    //       value: t('label.itemSplitId'),
    //       style: styleHeader,
    //     },
    //     {
    //       value: t('label.sellingCompany'),
    //       style: styleHeader,
    //     },
    //     {
    //       value: t('label.legalEntity'),
    //       style: styleHeader,
    //     },
    //     {
    //       value: t('label.setOfBooks'),
    //       style: styleHeader,
    //     },
    //     {
    //       value: t('label.productCompany'),
    //       style: styleHeader,
    //     },
    //   ],
    // ],
    data: [
      columnsTable.map(val => ({
        value: t(val.label),
        style: styleHeader,
      })),
    ],
  },
];

const convertJson2Sheet = ({ data, t, title, columnsTable }) => {
  const newDataSheetSet = cloneDeep(multiDataSetDefault({ t, title, columnsTable }));
  const monthYearT = t('selections:monthOfYear')();
  const newListData = data.map(item => {
    const newItem = [];
    if (item && typeof item === 'object') {
      columnsTable.forEach(val => {
        if (val.name === 'accountingPeriod') {
          if (item.accountingPeriod) {
            let newItemSelect = item.accountingPeriod;
            const itemTranslation = monthYearT.find(it => newItemSelect.indexOf(it.value) > -1);
            if (itemTranslation) {
              newItemSelect = `${itemTranslation.label}${newItemSelect.substring(3, newItemSelect.length)}`;
            }
            newItem.push({ value: newItemSelect || '' });
          } else {
            newItem.push({ value: item.accountingPeriod ? item.accountingPeriod : '' });
          }
        }
        if (val.name !== 'accountingPeriod') {
          if ((item[val.name] || item[val.name] === 0) && typeof item[val.name] === 'number') {
            newItem.push({ value: formatMoneyValueToExport(item[val.name]) });
          } else if (typeof item[val.name] === 'boolean') {
            newItem.push({ value: convertBoolLabel({ t, value: item[val.name] }) });
          } else if (val.isRenderT) {
            newItem.push({
              value: getLabelOptions({
                item,
                t,
                fieldOption: val.fieldOption || null,
                fieldName: val.name,
              }),
            });
          } else {
            newItem.push({ value: item[val.name] ? item[val.name] : '' });
          }
        }
      });
    }
    return newItem;
  });

  if (newListData.length) {
    newDataSheetSet[1].data = newDataSheetSet[1].data.concat(newListData);
  }
  return newDataSheetSet;
};

export default convertJson2Sheet;
