/* eslint-disable import/no-cycle */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { searchServiceProvisioningData } from '../../../containers/App/actions';
import ModalSelector from '../../Modals/ModalSelector';
import ItemSearchForm from './ProvisioningSearchForm';

const listFlexAttributes = [
  {
    name: 'flexAttr1',
    label: 'FLEXATTR1',
  },
  {
    name: 'flexAttr2',
    label: 'FLEXATTR2',
  },
  {
    name: 'flexAttr3',
    label: 'FLEXATTR3',
  },
  {
    name: 'flexAttr4',
    label: 'FLEXATTR4',
  },
  {
    name: 'flexAttr5',
    label: 'FLEXATTR5',
  },
  {
    name: 'flexAttr6',
    label: 'FLEXATTR6',
  },
  {
    name: 'flexAttr7',
    label: 'FLEXATTR7',
  },
  {
    name: 'flexAttr8',
    label: 'FLEXATTR8',
  },
  {
    name: 'flexAttr9',
    label: 'FLEXATTR9',
  },
  {
    name: 'flexAttr10',
    label: 'FLEXATTR10',
  },
  {
    name: 'flexAttr11',
    label: 'FLEXATTR11',
  },
  {
    name: 'flexAttr12',
    label: 'FLEXATTR12',
  },
  {
    name: 'flexAttr13',
    label: 'FLEXATTR13',
  },
  {
    name: 'flexAttr14',
    label: 'FLEXATTR14',
  },
  {
    name: 'flexAttr15',
    label: 'FLEXATTR15',
  },
  {
    name: 'flexAttr16',
    label: 'FLEXATTR16',
  },
  {
    name: 'flexAttr17',
    label: 'FLEXATTR17',
  },
  {
    name: 'flexAttr18',
    label: 'FLEXATTR18',
  },
  {
    name: 'flexAttr19',
    label: 'FLEXATTR19',
  },
  {
    name: 'flexAttr20',
    label: 'FLEXATTR20',
  },
  {
    name: 'flexAttr21',
    label: 'FLEXATTR21',
  },
  {
    name: 'flexAttr22',
    label: 'FLEXATTR22',
  },
  {
    name: 'flexAttr23',
    label: 'FLEXATTR23',
  },
  {
    name: 'flexAttr24',
    label: 'FLEXATTR24',
  },
  {
    name: 'flexAttr25',
    label: 'FLEXATTR25',
  },
];

class ModalProvisioningData extends PureComponent {
  state = {
    dataResponse: null,
  };

  searchHanldeProvisioningData = (payload, cb) => {
    const { searchServiceProvisioningData } = this.props;
    searchServiceProvisioningData(payload, resp => {
      const newResp = resp;
      const flexAttrsList = {};
      if (
        newResp.success &&
        newResp.data &&
        newResp.data.types &&
        newResp.data.types.length &&
        newResp.data.types[0].flexAttrMapping
      ) {
        newResp.data.types[0].flexAttrMapping.forEach(val => {
          flexAttrsList[val.flexAttribute] = val.tag;
        });
      }
      if (newResp.data && newResp.data.provisioning)
        newResp.data.provisioning[0] = { ...newResp.data.provisioning[0], ...flexAttrsList };
      this.setState({ dataResponse: newResp.data.provisioning[0] });
      cb({
        success: newResp.success,
        data: newResp && newResp.data && newResp.data.provisioning ? newResp.data.provisioning : [],
      });
    });
  };

  render() {
    const { dataResponse } = this.state;
    const columns = [
      {
        name: 'serviceType',
        label: 'common:label.serviceType',
        isRenderT: true,
        render: (colName, item, t) => {
          const slt = t ? t('selections:serviceType')().find(val => val.value === item.serviceType) : '';
          return <span>{slt ? slt.label : ''}</span>;
        },
      },
      {
        name: 'provisioningId',
        label: 'common:label.provisioningId',
      },
      {
        name: 'type',
        label: 'common:label.type',
      },
      {
        name: 'effectiveDate',
        label: 'common:label.effectiveDate',
        // sortable: true,
      },
      {
        name: 'endDate',
        label: 'common:label.endDate',
      },
    ];
    if (dataResponse) {
      listFlexAttributes.forEach(att => {
        if (dataResponse[att.name]) {
          columns.push({
            name: att.name,
            label: dataResponse[att.label],
          });
        }
      });
    }

    const { isOpen, onSelect, onCancel, selectedParentId, t, optionsSelectAttr, defaultSearchParams } = this.props;
    return (
      <ModalSelector
        modalTitle={t('label.serviceProvisioningData')}
        isOpen={isOpen}
        SearchForm={props => <ItemSearchForm optionsSelectAttr={optionsSelectAttr} {...props} />}
        searchItems={this.searchHanldeProvisioningData}
        tableColumns={columns}
        onSelect={onSelect}
        onCancel={onCancel}
        toggleModal={onCancel}
        selectedParentId={selectedParentId}
        selectFieldName="itemId"
        isNeedFetch={isOpen}
        defaultSearchParams={defaultSearchParams}
        isNoNeedSelector
        isBack
      />
    );
  }
}

ModalProvisioningData.propTypes = {
  accountId: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  selectedParentId: PropTypes.string,
  getAccountItems: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
};

ModalProvisioningData.defaultProps = {
  accountId: '',
  isOpen: false,
  selectedParentId: '',
};

export default withTranslation('common')(connect(null, { searchServiceProvisioningData })(ModalProvisioningData));
