import gql from 'graphql-tag';
import { parseToMutationRequest } from '../../../utils/utils';

export default data => {
  const dataCreate = parseToMutationRequest(data, ['subscriptionCategory', 'index']);
  const createConfigMultiRating = `createConfigMultiRating(input: ${dataCreate})`;
  return gql`
  mutation {
    ${createConfigMultiRating} {
      id
    }
  }
`;
};
