import gql from 'graphql-tag';

export default id => gql`
  query {
    getConfigMultiRatingById(id: "${id}") {
      id
      serviceType
      subscriptionCategory
      configMultiRatingUsageTypes {
        usageType
        index
      }
    }
  }
`;
