import React, { PureComponent } from 'react';
import { compose } from 'redux';
import { Switch, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import injectReducer from '../../utils/injectReducer';
import injectSaga from '../../utils/injectSaga';
import reducer from './reducer';
import reportReducer from '../ReportManagement/reducer';
import saga from './saga';
import reportSaga from '../ReportManagement/saga';
import RouteNames from '../App/RouteNames';
import Footer from '../../components/Footer';
import Dispute from './Dispute';
import Invoice from './Invoice';
import RecordLog from './RecordLog';
import TenantDispute from './TenantDispute';
import TenantRecordLog from './TenantRecordLog';
import Rerating from './Rerating';
import Platforms from './UsageMapping/Platforms';
import ReprocessingSuspendedFailedRecords from './ReprocessingSuspendedFailedRecords';
import TestUsage from './TestUsage';
import ZeroPriceUsage from './ZeroPriceUsage';
import VoicePlans from './VoicePlans';
import RegionMapping from './RegionMapping';
import TrunkRules from './TrunkRules';
import PNNList from './PNNList';
import OperatorMap from './OperatorMap';
import CustomRecords from './UsageMapping/CustomRecords';
import FileMapping from './UsageMapping/NewFileMapping';
import ProcessingStreams from './UsageMapping/ProcessingStreams';
import CreateRerating from './Rerating/CreateRerating';
import ModifyRerating from './Rerating/ModifyRerating';
import { makeGetPermissionsRating, makeGetPermissionsMediation, makeGetPermissionsReports } from '../App/selectors';
import Sidebar from './Sidebar';
import {
  checkPermissionForAllPages,
  checkPermissionGetUsagePlatforms,
  checkPermissionModifyUsagePlatforms,
  checkPermissionCreateUsagePlatforms,
} from './CheckPermission';
import { isHasPermissionWithMenu } from '../../utils/utils';
import { permissionList } from '../../utils/constants';
import { UsageReconciliation, RawFile } from '../ReportManagement/UsageReports';
import { checkPermissionForAllPages as checkPermissionForAllPagesReport } from '../ReportManagement/CheckPermission';
import MultiRatingConfig from './MultiRatingConfig';

class UsageManagement extends PureComponent {
  render() {
    const { permissionsRating, permissionsMediation, permissionsReports } = this.props;
    if (!isHasPermissionWithMenu({ permissions: permissionsRating, listKeys: permissionList.usage })) return null;

    let permissions = {};
    let modeGetUsagePlatforms = 0;
    let modeModifyUsagePlatforms = 0;
    let modeCreateUsagePlatforms = 0;

    if (permissionsRating && permissionsRating.ratingModulePermissions) {
      const listPermission = permissionsRating.ratingModulePermissions;
      permissions = checkPermissionForAllPages({ listPermission });
    }

    if (permissionsMediation && permissionsMediation.mediationModulePermissions) {
      const listPermission = permissionsMediation.mediationModulePermissions;
      modeGetUsagePlatforms = checkPermissionGetUsagePlatforms({ listPermission });
      modeModifyUsagePlatforms = checkPermissionModifyUsagePlatforms({ listPermission });
      modeCreateUsagePlatforms = checkPermissionCreateUsagePlatforms({ listPermission });
    }

    let permissionsReport = {};
    if (permissionsReports && permissionsReports.reportModulePermissions) {
      const listPermission = permissionsReports.reportModulePermissions;
      permissionsReport = checkPermissionForAllPagesReport({ listPermission });
    }

    return (
      <div className="container-fluid">
        <div className="row">
          <Sidebar />
          <div className="content-wrapper">
            <Switch>
              <Route exact path={RouteNames.zeroPriceUsage.path} component={ZeroPriceUsage} />
              <Route exact path={RouteNames.voicePlans.path} component={VoicePlans} />
              <Route exact path={RouteNames.regionMapping.path} component={RegionMapping} />
              <Route exact path={RouteNames.trunkRule.path} component={TrunkRules} />
              <Route exact path={RouteNames.PNNList.path} component={PNNList} />
              <Route exact path={RouteNames.operatorMap.path} component={OperatorMap} />
              <Route exact path={RouteNames.multiRatingConfig.path} component={MultiRatingConfig} />

              <Route exact path={RouteNames.disputeUsage.path} component={() => <Dispute permissions={{}} />} />
              <Route exact path={RouteNames.invoiceUsage.path} component={() => <Invoice permissions={{}} />} />
              <Route exact path={RouteNames.recordLogUsage.path} component={() => <RecordLog permissions={{}} />} />
              <Route exact path={RouteNames.tenantDispute.path} component={() => <TenantDispute permissions={{}} />} />
              <Route
                exact
                path={RouteNames.tenantRecordLog.path}
                component={() => <TenantRecordLog permissions={{}} />}
              />
              <Route exact path={RouteNames.rerating.path} component={() => <Rerating permissions={permissions} />} />
              <Route
                exact
                path={RouteNames.reratingCreate.path}
                component={() => <CreateRerating permissions={permissions} />}
              />
              <Route
                exact
                path={RouteNames.reratingModify.path}
                component={() => <ModifyRerating permissions={permissions} />}
              />
              <Route
                exact
                path={RouteNames.platformsUsage.path}
                component={() => (
                  <Platforms
                    permissions={{ modeGetUsagePlatforms, modeModifyUsagePlatforms, modeCreateUsagePlatforms }}
                  />
                )}
              />
              <Route exact path={RouteNames.customRecords.path} component={CustomRecords} />
              <Route exact path={RouteNames.fileMapping.path} component={FileMapping} />
              <Route exact path={RouteNames.processingStreams.path} component={ProcessingStreams} />
              <Route
                exact
                path={RouteNames.reprocessingSuspendedFailedRecords.path}
                component={() => <ReprocessingSuspendedFailedRecords permissions={permissions} />}
              />
              <Route exact path={RouteNames.testUsage.path} component={() => <TestUsage permissions={permissions} />} />
              <Route
                exact
                path={RouteNames.cdrReconciliationSummaryUsage.path}
                component={props => <UsageReconciliation {...props} permissions={permissionsReport} isBilling />}
              />
              <Route
                exact
                path={RouteNames.rawUsageFilesUsage.path}
                component={props => <RawFile {...props} permissions={permissionsReport} isBilling />}
              />
            </Switch>
            <br />
            <Footer className="footer-bottom" />
          </div>
        </div>
      </div>
    );
  }
}

const withReducer = injectReducer({ key: 'usageReducer', reducer });
const withSaga = injectSaga({ key: 'usageSaga', saga });

const withReducerReport = injectReducer({ key: 'reportReducer', reducer: reportReducer });
const withSagaReport = injectSaga({ key: 'reportSaga', saga: reportSaga });

const mapStateToProps = createStructuredSelector({
  permissionsRating: makeGetPermissionsRating() || {},
  permissionsMediation: makeGetPermissionsMediation() || {},
  permissionsReports: makeGetPermissionsReports() || {},
});

const withConnect = connect(mapStateToProps, null);

export default compose(withReducer, withSaga, withReducerReport, withSagaReport, withConnect)(UsageManagement);
