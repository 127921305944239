import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { cloneDeep, isNumber } from 'lodash';
import { FormWithTableItem, CollapsibleTable, DataTable, GenericInput, ModalUploadFile } from '../../common';
import { getLastIndex, validate, getBase64 } from '../../../utils/utils';

const newRow = ({ data, ccpTime }) => ({
  isNew: true,
  index: getLastIndex({ data }),
  taxCodes: null,
  serviceTypes: null,
  productCodes: null,
  exemptionLevel: 'COUNTY',
  percent: '100',
  lowerLimit: '0',
  startDate: ccpTime || null,
});

const TaxExemptions = ({
  id,
  currencyOptions,
  customerInfo,
  data,
  // getTaxCodeConfigSelect,
  getTaxTypeConfigSelect,
  readFileFromS3,
  s3Config,
  modifyAccount,
  uploadMultiPartFiles,
  getAccountDetail,
  getPACProductCodesConfig,
  ccpTime,
  getCcpDateTimeConfig,
  ccpPropertyList,
}) => {
  const formRef = useRef();
  const [wasValidated, setValidate] = useState(false);
  const [activeTab, setActiveTab] = useState({});
  const [submitData, setSubmitData] = useState(data || []);
  const [taxCodeOptions, setTaxCodeOptions] = useState([]);
  const [taxTypesOptions, setTaxTypesOptions] = useState([]);
  const [base64FileSelect, setBase64FileSelect] = useState(null);
  const [fileSelect, setFileSelect] = useState(null);
  const [isOpenModalUploadFile, setIsOpenModalUploadFile] = useState(false);
  const [itemSelect, setItemSelect] = useState(null);
  const [indexSelect, setIndexSelect] = useState(null);

  const { t } = useTranslation('common');

  const isTaxExemptionOnTaxableAmountProperty =
    ccpPropertyList &&
    ccpPropertyList.length &&
    ccpPropertyList.find(ccp => ccp.property === 'taxExemptionOnTaxableAmount') &&
    ccpPropertyList.find(ccp => ccp.property === 'taxExemptionOnTaxableAmount').value === 'false';

  const onChangeTable = ({ name, value, index }) => {
    const newData = cloneDeep(submitData);
    try {
      if (name === 'percent') {
        newData[index].taxPercentOffset = null;
      }
      if (name === 'taxPercentOffset') {
        newData[index].percent = null;
      }
      newData[index][name] = value;
      setSubmitData(newData);
    } catch (error) {
      console.log(error);
    }
  };

  const onChangeTaxCertificate = ({ name, value, index }) => {
    const newData = cloneDeep(submitData);
    try {
      if (!newData[index].taxCertificate) newData[index].taxCertificate = {};
      newData[index].taxCertificate[name] = value || null;
      setSubmitData(newData);
    } catch (error) {
      console.log(error);
    }
  };

  const onChangeSubTable = ({ name, value, index }) => {
    const newData = cloneDeep(submitData);
    try {
      newData[activeTab.index][activeTab.key][index][name] = value;
      setSubmitData(newData);
    } catch (error) {
      console.log(error);
    }
  };

  const onRemoveSubItem = ({ index }) => {
    const newData = cloneDeep(submitData);

    try {
      if (newData[activeTab.index][activeTab.key][index].isNew) {
        newData[activeTab.index][activeTab.key].splice(index, 1);
      } else {
        newData[activeTab.index][activeTab.key][index] = {
          index: newData[activeTab.index][activeTab.key][index].index,
        };
      }
      setSubmitData(newData);
    } catch (error) {
      console.log(error);
    }
  };

  const onToggleSubTab = ({ index, key }) => {
    if (activeTab.index === index && activeTab.key === key) return setActiveTab({});
    if (activeTab.index === index && activeTab.key !== key) {
      setActiveTab({});
      return setTimeout(() => {
        setActiveTab({ key, index });
      }, 200);
    }
    return setActiveTab({ key, index });
  };

  const onAddNew = () => {
    const newData = cloneDeep(submitData);
    newData.push(newRow({ data: newData, ccpTime }));
    setSubmitData(newData);
    setActiveTab({});
  };

  const onAddNewSubData = () => {
    try {
      const newData = cloneDeep(submitData);
      if (!newData[activeTab.index][activeTab.key]) newData[activeTab.index][activeTab.key] = [];
      const dataNew = { isNew: true, index: getLastIndex({ data: newData[activeTab.index][activeTab.key] }) };
      if (activeTab.key === 'serviceTypes') {
        dataNew.serviceType = null;
      }
      if (activeTab.key === 'taxCodes') {
        dataNew.name = null;
      }
      if (activeTab.key === 'productCodes') {
        dataNew.productCode = null;
      }
      newData[activeTab.index][activeTab.key].push(dataNew);
      setSubmitData(newData);
    } catch (error) {
      console.log(error);
    }
  };

  const onToggleModalUpload = ({ itemSelect, index }) => {
    if (itemSelect.path) {
      readFileFromS3({ ...s3Config, url: itemSelect.path }, ({ success, data }) => {
        if (success) {
          getBase64(data, result => {
            setBase64FileSelect(result);
            setIsOpenModalUploadFile(true);
          });
          setFileSelect(data);
          setItemSelect(itemSelect);
          setIndexSelect(index);
        }
      });
    } else {
      setIsOpenModalUploadFile(true);
      setItemSelect(itemSelect);
      setIndexSelect(index);
    }
  };

  const tableTaxExemptionsColumns = [
    {
      name: 'exemptionLevel',
      label: 'label.level',
      style: { minWidth: '200px' },

      render: (colName, item, index) => (
        <GenericInput
          value={item.exemptionLevel}
          wrapperClass="col-md-12"
          onChange={({ name, value }) => onChangeTable({ name, value, index })}
          name="exemptionLevel"
          type="select"
          menuPortalTarget
          tOptions="selections:exemptionLevel"
        />
      ),
    },
    {
      name: 'startDate',
      label: 'label.startDate',
      style: { minWidth: '200px' },
      render: (colName, item, index) => (
        <GenericInput
          value={item.startDate}
          wrapperClass="col-md-12 inner-popover"
          onChange={({ name, value }) => onChangeTable({ name, value, index })}
          name="startDate"
          type="date"
        />
      ),
    },
    {
      name: 'endDate',
      label: 'label.endDate',
      style: { minWidth: '200px' },
      render: (colName, item, index) => (
        <GenericInput
          value={item.endDate}
          wrapperClass="col-md-12 inner-popover"
          onChange={({ name, value }) => onChangeTable({ name, value, index })}
          name="endDate"
          type="date"
        />
      ),
    },
    {
      name: 'exemptOnTaxAmount',
      label: 'label.exemptOnTaxAmount',
      tooltipLabel: 'message.exemptOnTaxAmountInfo',
      style: { minWidth: '120px' },
      render: (colName, item, index) => (
        <GenericInput
          value={item.exemptOnTaxAmount}
          wrapperClass="col-md-12"
          onChange={({ name, value }) => onChangeTable({ name, value, index })}
          name="exemptOnTaxAmount"
          type="checkbox"
        />
      ),
    },
    {
      name: 'percent',
      label: 'label.percentWithNumberRange',
      style: { minWidth: '120px' },
      render: (colName, item, index) => (
        <GenericInput
          value={item.percent}
          wrapperClass="col-md-12"
          onChange={({ name, value }) => onChangeTable({ name, value, index })}
          name="percent"
          type="number"
          minValue={1}
          maxValue={100}
          disabled={item && item.taxPercentOffset}
        />
      ),
    },
    {
      name: 'taxPercentOffset',
      label: 'label.taxPercentOffset',
      tooltipLabel: 'message.taxPercentOffsetInfo',
      style: { minWidth: '120px' },
      render: (colName, item, index) => (
        <GenericInput
          value={item.taxPercentOffset}
          wrapperClass="col-md-12"
          onChange={({ name, value }) => onChangeTable({ name, value, index })}
          name="taxPercentOffset"
          type="number"
          disabled={item && item.percent}
        />
      ),
    },
    {
      name: 'lowerLimit',
      label: 'label.lowerLimit',
      style: { minWidth: '120px' },
      render: (colName, item, index) => (
        <GenericInput
          value={item.lowerLimit}
          wrapperClass="col-md-12"
          onChange={({ name, value }) => onChangeTable({ name, value, index })}
          name="lowerLimit"
          type="number"
        />
      ),
    },
    {
      name: 'upperLimit',
      label: 'label.upperLimit',
      style: { minWidth: '120px' },
      render: (colName, item, index) => (
        <GenericInput
          value={item.upperLimit}
          wrapperClass="col-md-12"
          onChange={({ name, value }) => onChangeTable({ name, value, index })}
          name="upperLimit"
          type="number"
        />
      ),
    },
    {
      name: 'serviceType',
      label: 'label.serviceType',
      render: (colName, item, index) => {
        return (
          <button
            type="button"
            className="btn-expand-table mr-3"
            onClick={() => onToggleSubTab({ index, item, key: 'serviceTypes' })}
          >
            <i
              className={`fa ${
                activeTab && activeTab.key === 'serviceTypes' && activeTab.index === index ? 'fa-minus' : 'fa-plus'
              }`}
            />
          </button>
        );
      },
    },
    {
      name: 'taxCodes',
      label: 'label.taxType',
      render: (colName, item, index) => {
        return (
          <button
            type="button"
            className="btn-expand-table mr-3"
            onClick={() => onToggleSubTab({ index, item, key: 'taxCodes' })}
          >
            <i
              className={`fa ${
                activeTab && activeTab.key === 'taxCodes' && activeTab.index === index ? 'fa-minus' : 'fa-plus'
              }`}
            />
          </button>
        );
      },
    },
    {
      name: 'productCodes',
      label: 'label.productCodes',
      render: (colName, item, index) => {
        return (
          <button
            type="button"
            className="btn-expand-table mr-3"
            onClick={() => onToggleSubTab({ index, item, key: 'productCodes' })}
          >
            <i
              className={`fa ${
                activeTab && activeTab.key === 'productCodes' && activeTab.index === index ? 'fa-minus' : 'fa-plus'
              }`}
            />
          </button>
        );
      },
    },
    {
      name: 'certificateNumber',
      label: 'label.certificateId',
      style: { minWidth: '200px' },
      render: (colName, item, index) => (
        <GenericInput
          value={item?.taxCertificate?.certificateNumber ? item.taxCertificate.certificateNumber : null}
          wrapperClass="col-md-12"
          onChange={({ name, value }) => onChangeTaxCertificate({ name, value, index })}
          name="certificateNumber"
        />
      ),
    },
    {
      name: 'uploadCertificate',
      label: 'label.uploadCertificate',
      style: { minWidth: '300px' },
      render: (colName, item, index) => {
        return (
          <GenericInput
            value={item?.taxCertificate?.filename ? item.taxCertificate.filename : ''}
            wrapperClass="col-md-12"
            onChange={() => {}}
            name="uploadCertificate"
            fa="fa fa-external-link"
            onClick={() => onToggleModalUpload({ itemSelect: item?.taxCertificate || {}, index })}
            readOnly
          />
        );
      },
    },
    // {
    //   name: 'remove',
    //   label: 'label.remove',
    //   render: (colName, item, index) => {
    //     return (
    //       <div className="form-group col-md-12">
    //         <button type="button" className="btn-phone" onClick={() => onRemoveItem({ index })}>
    //           <i className="fa fa-trash" />
    //         </button>
    //       </div>
    //     );
    //   },
    // },
  ];

  const serviceTypeColumn = listExistData => [
    {
      name: 'serviceType',
      label: 'label.serviceType',
      style: { minWidth: '130px' },
      required: true,
      render: (colName, item, index) => {
        const listOptions = t('selections:serviceType')().filter(
          val =>
            listExistData &&
            listExistData.length &&
            !listExistData.find(i => i.serviceType === val.value && i.serviceType !== item.serviceType)
        );
        return (
          <GenericInput
            value={item.serviceType}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => onChangeSubTable({ name, value, index })}
            name="serviceType"
            type="select"
            menuPortalTarget
            options={listOptions || []}
            required
          />
        );
      },
    },
    {
      name: 'remove',
      label: 'label.remove',
      render: (colName, item, index) => {
        return (
          <div className="form-group col-md-12">
            <button type="button" className="btn-phone" onClick={() => onRemoveSubItem({ index })}>
              <i className="fa fa-trash" />
            </button>
          </div>
        );
      },
    },
  ];

  const taxTypeColumn = listExistData => [
    {
      name: 'name',
      label: 'label.taxType',
      style: { minWidth: '130px' },
      required: true,
      render: (colName, item, index) => {
        const listOptions = taxTypesOptions.filter(
          val =>
            listExistData &&
            listExistData.length &&
            !listExistData.find(i => i.name === val.value && i.name !== item.name)
        );
        return (
          <GenericInput
            value={item.name}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => onChangeSubTable({ name, value, index })}
            name="name"
            type="select"
            menuPortalTarget
            options={listOptions}
            required
          />
        );
      },
    },
    {
      name: 'remove',
      label: 'label.remove',
      render: (colName, item, index) => {
        return (
          <div className="form-group col-md-12">
            <button type="button" className="btn-phone" onClick={() => onRemoveSubItem({ index })}>
              <i className="fa fa-trash" />
            </button>
          </div>
        );
      },
    },
  ];

  const productCodeColumn = listExistData => [
    {
      name: 'productCode',
      label: 'label.productCode',
      style: { minWidth: '130px' },
      required: true,
      render: (colName, item, index) => {
        const listOptions = taxCodeOptions.filter(
          val =>
            listExistData &&
            listExistData.length &&
            !listExistData.find(i => i.productCode === val.value && i.productCode !== item.productCode)
        );
        return (
          <GenericInput
            value={item.productCode}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => onChangeSubTable({ name, value, index })}
            name="productCode"
            type="select"
            menuPortalTarget
            options={listOptions}
            required
          />
        );
      },
    },
    {
      name: 'remove',
      label: 'label.remove',
      render: (colName, item, index) => {
        return (
          <div className="form-group col-md-12">
            <button type="button" className="btn-phone" onClick={() => onRemoveSubItem({ index })}>
              <i className="fa fa-trash" />
            </button>
          </div>
        );
      },
    },
  ];

  let subData = [];
  let subColumns = [];
  if (isNumber(activeTab.index) && submitData[activeTab.index]) {
    subData = submitData[activeTab.index][activeTab.key];
    if (activeTab.key === 'serviceTypes') {
      subColumns = serviceTypeColumn(subData);
    }
    if (activeTab.key === 'taxCodes') {
      subColumns = taxTypeColumn(subData);
    }
    if (activeTab.key === 'productCodes') {
      subColumns = productCodeColumn(subData);
    }
  }

  let currencyLabel = '';
  if (currencyOptions && currencyOptions.length && customerInfo?.currency) {
    const currencySelect = currencyOptions.find(val => val.value === customerInfo?.currency);
    currencyLabel = currencySelect ? currencySelect.normalLabel : '';
  }

  const onSubmit = evt => {
    evt.preventDefault();
    setValidate(true);
    if (!validate(true, formRef, t)) {
      return false;
    }
    setValidate(false);
    let payload = cloneDeep(submitData);
    if (payload && payload.length) {
      const existData = payload.filter(val => !val.isNew);
      const newData = payload
        .filter(val => val.isNew)
        .map((item, index) => {
          const { isNew, ...rest } = item;
          return { ...rest, index: getLastIndex({ data: existData }) + index };
        });
      payload = [...existData, ...newData];
    }

    payload = payload.map(val => {
      const { taxCodes, serviceTypes, productCodes, exemptOnTaxAmount, ...rest } = val;
      let newExemptOnTaxAmount = exemptOnTaxAmount;
      if (newExemptOnTaxAmount === null && isTaxExemptionOnTaxableAmountProperty) {
        newExemptOnTaxAmount = 'false';
      }
      if (!taxCodes && !serviceTypes && !productCodes) {
        return { ...rest, exemptOnTaxAmount: newExemptOnTaxAmount };
      }
      let newTaxCodes = null;
      let newServiceTypes = null;
      let newProductCodes = null;
      if (taxCodes && taxCodes.length) {
        const existData = taxCodes.filter(val => !val.isNew);
        const newData = taxCodes
          .filter(val => val.isNew)
          .map((item, index) => {
            const { isNew, ...rest } = item;
            return { ...rest, index: getLastIndex({ data: existData }) + index };
          });
        newTaxCodes = [...existData, ...newData];
      }
      if (serviceTypes && serviceTypes.length) {
        const existData = serviceTypes.filter(val => !val.isNew);
        const newData = serviceTypes
          .filter(val => val.isNew)
          .map((item, index) => {
            const { isNew, ...rest } = item;
            return { ...rest, index: getLastIndex({ data: existData }) + index };
          });
        newServiceTypes = [...existData, ...newData];
      }
      if (productCodes && productCodes.length) {
        const existData = productCodes.filter(val => !val.isNew);
        const newData = productCodes
          .filter(val => val.isNew)
          .map((item, index) => {
            const { isNew, ...rest } = item;
            return { ...rest, index: getLastIndex({ data: existData }) + index };
          });
        newProductCodes = [...existData, ...newData];
      }
      return {
        ...rest,
        exemptOnTaxAmount: newExemptOnTaxAmount,
        taxCodes: newTaxCodes,
        serviceTypes: newServiceTypes,
        productCodes: newProductCodes,
      };
    });

    return modifyAccount({ id, taxExemptions: payload }, ({ success }) => {
      if (success) getAccountDetail();
    });
  };

  const onCancel = () => {
    setBase64FileSelect(null);
    setFileSelect(null);
    setIsOpenModalUploadFile(false);
    setItemSelect(null);
  };

  const onHandleUpload = ({ files }) => {
    if (files && files[0]) {
      const query =
        '{"query": "mutation{uploadMultipartFiles(input: {category: ACCOUNT, tenantSubType: CERTIFICATE}){absoluteFile}}"}';
      const formData = new FormData();
      formData.append('graphql', query);
      formData.append('file', files[0]);
      uploadMultiPartFiles(formData, res => {
        if (res && res.success) {
          if (res.data.data && res.data.data.data && res.data.data.data.uploadMultipartFiles) {
            try {
              const pathSelect = res.data.data.data.uploadMultipartFiles[0];
              const newData = cloneDeep(submitData);
              if (!newData[indexSelect].taxCertificate) newData[indexSelect].taxCertificate = {};
              newData[indexSelect].taxCertificate.path = pathSelect.absoluteFile;
              newData[indexSelect].taxCertificate.filename = files[0]?.name || null;
              setSubmitData(newData);
              setIsOpenModalUploadFile(false);
            } catch (error) {
              console.log(error);
            }
          }
          setIsOpenModalUploadFile(false);
        }
      });
    } else {
      setIsOpenModalUploadFile(false);
    }
  };

  useEffect(() => {
    setSubmitData(data || []);
  }, [ccpTime, data]);

  useEffect(() => {
    getPACProductCodesConfig({ page: 1, size: 500 }, ({ success, data }) => {
      if (success && data && data.length) {
        const taxCodeList = data.map(val => ({
          label: `${val.id} (${val.description})`,
          value: val.id,
        }));
        setTaxCodeOptions(taxCodeList);
      }
    });
    // getTaxCodeConfigSelect(({ success, data }) => {
    //   if (success && data && data.productCodes) {
    //     const taxCodeList = data.productCodes.map(val => ({
    //       label: val.productCode,
    //       value: val.productCode,
    //     }));
    //     setTaxCodeOptions(taxCodeList);
    //   }
    // });
    getTaxTypeConfigSelect('', ({ success, data }) => {
      if (success && data && data.taxTypes) {
        const taxTypesList = data.taxTypes.map(val => ({
          label: val.code,
          value: val.code,
        }));
        setTaxTypesOptions(taxTypesList);
      }
    });
  }, [getPACProductCodesConfig, getTaxTypeConfigSelect]);

  useEffect(() => {
    if (!ccpTime) {
      getCcpDateTimeConfig('');
    }
  }, [ccpTime, getCcpDateTimeConfig]);

  const onCancelData = () => {
    setSubmitData(data || []);
  };

  return (
    <div className="col-md-12 mb-30">
      <FormWithTableItem
        title={t('customerPage:sidebarHandleAccountPage.taxExemptions')}
        accountNum={id}
        commercialName={customerInfo?.commercialName || ''}
        isCommercialName={customerInfo?.customerSegment !== 'B2C'}
        currencyLabel={currencyLabel ? `(${currencyLabel})` : ''}
        key="pendingBills"
      >
        <form
          ref={formRef}
          noValidate
          className={`needs-validation ${wasValidated ? 'was-validated' : ''}`}
          onSubmit={onSubmit}
        >
          <div className="col-md-12 pl-2 pr-2 pb-3 mt-4">
            <button type="button" className="button button-border black x-small" onClick={onAddNew}>
              +
              {t('label.addNew')}
            </button>
            <button type="submit" className="button button-border x-small float-right">
              {t('label.save')}
            </button>
            <button type="button" className="button button-border black x-small float-right" onClick={onCancelData}>
              {t('label.cancel')}
            </button>
          </div>
          <CollapsibleTable columns={tableTaxExemptionsColumns} data={submitData || []} indexViewer={activeTab.index}>
            <div className="col-md-4 p-3">
              <DataTable columns={subColumns} data={subData || []} isSupportRemoveIndex />
              <div className="form-group col-md-12 buttons-attibute">
                <button type="button" className="button button-border black x-small" onClick={onAddNewSubData}>
                  +
                  {t('label.addNew')}
                </button>
              </div>
            </div>
          </CollapsibleTable>
        </form>
      </FormWithTableItem>
      <ModalUploadFile
        title="label.uploadFile"
        isOpen={isOpenModalUploadFile}
        onCancel={onCancel}
        onUpload={onHandleUpload}
        itemSelect={itemSelect}
        base64FileSelect={base64FileSelect}
        fileSelect={fileSelect}
        pathFile={itemSelect ? itemSelect.path : null}
        type={itemSelect ? itemSelect.type : null}
        isSupportDownload
      />
    </div>
  );
};

TaxExemptions.propTypes = {
  id: PropTypes.string,
  getPACProductCodesConfig: PropTypes.func.isRequired,
  getTaxTypeConfigSelect: PropTypes.func.isRequired,
  readFileFromS3: PropTypes.func.isRequired,
  getAccountDetail: PropTypes.func.isRequired,
  uploadMultiPartFiles: PropTypes.func.isRequired,
  modifyAccount: PropTypes.func.isRequired,
  currencyOptions: PropTypes.arrayOf(PropTypes.any),
  customerInfo: PropTypes.objectOf(PropTypes.any),
  data: PropTypes.arrayOf(PropTypes.any),
  s3Config: PropTypes.objectOf(PropTypes.any),
};

TaxExemptions.defaultProps = {
  id: '',
  currencyOptions: [],
  customerInfo: {},
  data: [],
  s3Config: {},
};

export default TaxExemptions;
