import constants from './constants';
import { getTypePermission } from '../../utils/utils';

// common prmissions - Begin //
const checkPermissionCreateCustomer = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: constants.groupNamePermissions.createCustomer });
};

const checkPermissionSearchAccount = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: constants.groupNamePermissions.searchAccount });
};

const checkPermissionGetAccount = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: constants.groupNamePermissions.getAccount });
};

const checkPermissionModifyCustomer = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: constants.groupNamePermissions.modifyCustomer });
};
// common prmissions - End //

// Contact Permissions - Begin //
const checkPermissionGetContact = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: constants.groupNamePermissions.getContact });
};

const checkPermissionModifyContact = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: constants.groupNamePermissions.modifyContact });
};

const checkPermissionDeleteContact = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: constants.groupNamePermissions.deleteContact });
};

const checkPermissionAddContact = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: constants.groupNamePermissions.addContact });
};

const checkPermissionModifyPhone = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: constants.groupNamePermissions.modifyPhone });
};

const checkPermissionAddPhone = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: constants.groupNamePermissions.addPhone });
};

const checkPermissionDeletePhone = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: constants.groupNamePermissions.deletePhone });
};
// Contact Permissions - End //

// Address Permission - Begin
const checkPermissionGetAddress = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: constants.groupNamePermissions.getAddress });
};

const checkPermissionModifyAddress = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: constants.groupNamePermissions.modifyAddress });
};

const checkPermissionAddAddress = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: constants.groupNamePermissions.addAddress });
};

const checkPermissionDeleteAddress = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: constants.groupNamePermissions.deleteAddress });
};
// Address Permission - End

// Payment Permisson - Begin
const checkPermissionGetPayment = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: constants.groupNamePermissions.getPayment });
};

const checkPermissionModifyPayment = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: constants.groupNamePermissions.modifyPayment });
};

const checkPermissionModifyCreditCard = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: constants.groupNamePermissions.modifyCreditCard });
};

const checkPermissionAddCreditCard = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: constants.groupNamePermissions.addCreditCard });
};

const checkPermissionDeleteCreditCard = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: constants.groupNamePermissions.deleteCreditCard });
};

// Payment Permisson - End

// Billing Permisson - Begin
const checkPermissionGetBillingProfile = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: constants.groupNamePermissions.getBillingProfile });
};

const checkPermissionModifyBillingProfile = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: constants.groupNamePermissions.modifyBillingProfile });
};
// Billing Permisson - End //

// Service Permisson - Begin //
const checkPermissionSuspendStatus = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: constants.groupNamePermissions.suspendStatus });
};

const checkPermissionResumeStatus = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: constants.groupNamePermissions.resumeStatus });
};

const checkPermissionCloseStatus = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: constants.groupNamePermissions.closeStatus });
};
// Service Permisson - End //

// Subscription - Begin //
const checkPermissionGetSubscription = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: constants.groupNamePermissions.getSubscription });
};

const checkPermissionGetServiceUnit = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: constants.groupNamePermissions.getServiceUnit });
};

const checkPermissionGetPriceUnit = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: constants.groupNamePermissions.getPriceUnit });
};
// Subscription - End //

// Billing & transactions - Begin //
const checkPermissionSearchBills = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: constants.groupNamePermissions.searchBills });
};

const checkPermissionSearchInvoice = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: constants.groupNamePermissions.searchInvoice });
};

const checkPermissionGetInvoice = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: constants.groupNamePermissions.getInvoice });
};

const checkPermissionSearchTransactions = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: constants.groupNamePermissions.searchTransactions });
};

const checkPermissionGetTransactions = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: constants.groupNamePermissions.getTransactions });
};

const checkPermissionGetBalance = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: constants.groupNamePermissions.getBalance });
};
// Billing & transactions - End //

// Credit Profile - Begin //
const checkPermissionSetCreditLimit = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: constants.groupNamePermissions.setCreditLimt });
};

const checkPermissionModifyCreditLimit = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: constants.groupNamePermissions.modifyCreditLimt });
};
// Credit Profile - End //

// Permission Activity - Begin //
const checkPermissionSearchActivity = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: constants.groupNamePermissions.searchActivity });
};

const checkPermissionGetActivity = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: constants.groupNamePermissions.getActivity });
};
// Permission Activity - End //

// Permission Delayed Billing - Begin //

const checkPermissionGetDelayedBillingConfig = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: constants.groupNamePermissions.getDelayedBillingConfig });
};

const checkPermissionModifyDelayedBillingConfig = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: constants.groupNamePermissions.modifyDelayedBillingConfig });
};

const checkPermissionCreateDelayedBillingConfig = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: constants.groupNamePermissions.createDelayedBillingConfig });
};

const checkPermissionSendInvoice = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: constants.groupNamePermissions.sendInvoice });
};

const checkPermissionReInvoice = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: constants.groupNamePermissions.reInvoice });
};

const checkPermissionRegenerateInvoice = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: constants.groupNamePermissions.regenerateInvoice });
};

const checkPermissionViewInvoceTemplate = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: constants.groupNamePermissions.viewInvoceTemplate });
};
const checkPermissionApproveInvoice = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: constants.groupNamePermissions.approveInvoice });
};

const checkPermissionCreateExternalPO = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: constants.groupNamePermissions.createExternalPO });
};

const checkPermissionModifyExternalPO = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: constants.groupNamePermissions.modifyExternalPO });
};

const checkPermissionGetExternalPO = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: constants.groupNamePermissions.getExternalPO });
};

const checkPermissionCreateCustomAttributes = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: constants.groupNamePermissions.createCustomAttributes });
};

const checkPermissionGetCustomAttributes = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: constants.groupNamePermissions.getCustomAttributes });
};

const checkPermissionModifyCustomAttributes = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: constants.groupNamePermissions.modifyCustomAttributes });
};

const checkPermissionResyncInvoice = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: constants.groupNamePermissions.resyncInvoice });
};

const checkPermissionUndoManualInvoice = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: constants.groupNamePermissions.undoManualInvoice });
};

const checkPermissionGetSpecialPricingConfig = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: constants.groupNamePermissions.getSpecialPriceRate });
};

const checkPermissionModifySpecialPricingConfig = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: constants.groupNamePermissions.modifySpecialPriceRate });
};

const checkPermissionCreateSpecialPricingConfig = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: constants.groupNamePermissions.createSpecialPriceRate });
};

const checkPermissionGetAccountExchangeRates = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: constants.groupNamePermissions.getAccountExchangeRates });
};

const checkPermissionModifyAccountExchangeRates = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: constants.groupNamePermissions.modifyAccountExchangeRates });
};

const checkPermissionCreateAccountExchangeRates = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: constants.groupNamePermissions.createAccountExchangeRates });
};

const checkPermissionGenerateInvoice = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: constants.groupNamePermissions.generateInvoice });
};

const checkPermissionCreatePaymentInstallment = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: constants.groupNamePermissions.createPaymentInstallment });
};

const checkPermissionModifyPaymentInstallment = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: constants.groupNamePermissions.modifyPaymentInstallment });
};

const checkPermissionGetPaymentInstallment = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: constants.groupNamePermissions.getPaymentInstallment });
};

const checkPermissionSearchLandingBill = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: constants.groupNamePermissions.searchLandingBill });
};

const checkPermissionViewTransactionDetails = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: constants.groupNamePermissions.VIEW_TRANSACTION_DETAILS });
};

const checkPermissionExtendInvoiceDueDate = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: constants.groupNamePermissions.EXTEND_INVOICE_DUE_DATE });
};

const checkPermissionGetAccountStatement = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: constants.groupNamePermissions.GET_ACCOUNT_STATEMENT });
};

const checkPermissionResendToVendor = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: constants.groupNamePermissions.RESEND_TO_VENDOR });
};

const checkPermissionUndoInvoiceCancel = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: constants.groupNamePermissions.UNDO_INVOICE_CANCEL });
};

const checkPermissionTopupBalance = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: constants.groupNamePermissions.TOPUP_BALANCE });
};

// Permission Delayed Billing - End //

export {
  checkPermissionCreateCustomer,
  checkPermissionSearchAccount,
  checkPermissionGetAccount,
  checkPermissionModifyCustomer,
  checkPermissionModifyContact,
  checkPermissionModifyAddress,
  checkPermissionModifyPhone,
  checkPermissionAddPhone,
  checkPermissionDeletePhone,
  checkPermissionDeleteContact,
  checkPermissionAddContact,
  checkPermissionAddAddress,
  checkPermissionDeleteAddress,
  checkPermissionGetPayment,
  checkPermissionModifyPayment,
  checkPermissionModifyCreditCard,
  checkPermissionAddCreditCard,
  checkPermissionDeleteCreditCard,
  checkPermissionGetContact,
  checkPermissionGetAddress,
  checkPermissionGetBillingProfile,
  checkPermissionModifyBillingProfile,
  checkPermissionSuspendStatus,
  checkPermissionResumeStatus,
  checkPermissionCloseStatus,
  checkPermissionGetSubscription,
  checkPermissionGetServiceUnit,
  checkPermissionGetPriceUnit,
  checkPermissionSearchBills,
  checkPermissionSearchInvoice,
  checkPermissionGetInvoice,
  checkPermissionSearchTransactions,
  checkPermissionGetTransactions,
  checkPermissionGetBalance,
  checkPermissionSetCreditLimit,
  checkPermissionModifyCreditLimit,
  checkPermissionSearchActivity,
  checkPermissionGetActivity,
  checkPermissionGetDelayedBillingConfig,
  checkPermissionModifyDelayedBillingConfig,
  checkPermissionCreateDelayedBillingConfig,
  checkPermissionSendInvoice,
  checkPermissionReInvoice,
  checkPermissionRegenerateInvoice,
  checkPermissionViewInvoceTemplate,
  checkPermissionApproveInvoice,
  checkPermissionCreateExternalPO,
  checkPermissionModifyExternalPO,
  checkPermissionGetExternalPO,
  checkPermissionCreateCustomAttributes,
  checkPermissionGetCustomAttributes,
  checkPermissionModifyCustomAttributes,
  checkPermissionResyncInvoice,
  checkPermissionUndoManualInvoice,
  checkPermissionGetSpecialPricingConfig,
  checkPermissionModifySpecialPricingConfig,
  checkPermissionCreateSpecialPricingConfig,
  checkPermissionGetAccountExchangeRates,
  checkPermissionModifyAccountExchangeRates,
  checkPermissionCreateAccountExchangeRates,
  checkPermissionGenerateInvoice,
  checkPermissionCreatePaymentInstallment,
  checkPermissionModifyPaymentInstallment,
  checkPermissionGetPaymentInstallment,
  checkPermissionSearchLandingBill,
  checkPermissionViewTransactionDetails,
  checkPermissionExtendInvoiceDueDate,
  checkPermissionGetAccountStatement,
  checkPermissionUndoInvoiceCancel,
  checkPermissionResendToVendor,
  checkPermissionTopupBalance,
};
