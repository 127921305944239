import gql from 'graphql-tag';

export default id => gql`
  query {
    getTransactionUnitById(input: { id: "${id}" }) {
      id
      type
      accountId
      description
      billUnitId
      serviceUnitId
      serviceType
      itemId
      sharedTransactionId
      originalTransactionId
      source
      startDate
      endDate
      revenueRecognitionType
      netAmount
      taxCode
      accountingCode
      usageId
      txnGrants {
        id
        balanceUnitId
        offerId
        resourceId
        validStart
        validEnd
        grantType
        amount
        index
        balanceUnitIndex
      }
      txnAccumulators {
        id
        balanceUnitId
        offerId
        accumulatorId
        validStart
        validEnd
        amount
        index
        balanceUnitIndex
      }
      balances{
        index
        balanceUnitId
        unitPrice
        quantity
        amount
        currencyId
        balanceType
        offerId
        assetId
        offerType
        }
      txnUsageData {
        id
        userId
        sessionId
        zoneUnit
        impactUnit
        holidayName
        code
        transactionId
        batchid
        headerid
        usageid
        accountType
        accountSubType
        marketSegment
        salesChannel
        quantity
        scaledQuantity
        rateUnit
        timeUnit
        ratingDescr
        provisioningId
        usageType
        usageClass
        perfBatchId
        source
        destination
        routingNumber
        direction
        fileId
        scaleUnit
      }
    }
  }
`;
