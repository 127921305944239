import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { cloneDeep } from 'lodash';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import listCurrency from '../../../utils/currency.json';
import utils, { getLastIndex, validate } from '../../../utils/utils';
import PageTitle from '../../../components/PageTitle';
import RouteNames from '../../App/RouteNames';
import { searchConfigCreditProfile, getAccumulatorsConfig, getGrantsConfig } from '../../App/actions';
import { modifyConfigCreditProfile, createConfigCreditProfile } from '../actions';
import { FormWithTableItem, DataTable, GenericInput, ModalAccept, CollapsibleTable } from '../../../components/common';
import { makeGetPermissionsRating, makeGetPermissionsBilling } from '../../App/selectors';
import { checkPermissionModifyCreditLimit, checkPermissionSetCreditLimit } from '../CheckPermission';
import SidebarIndex from '../SidebarIndex';

class CreditProfile extends PureComponent {
  state = {
    isSearching: true,
    indexRemove: -1,
    isOpenModalRemoveItem: false,
    creditProfileData: [],
    activeTab: {},
    activeProfilesListTab: -1,
    isFirstSearch: true,
    isModify: false,
    accumulatorList: [],
    grantList: [],
  };

  formRef = React.createRef();

  componentDidMount() {
    const { getAccumulatorsConfig, getGrantsConfig } = this.props;
    this.doGetConfigCreditProfile();

    getAccumulatorsConfig(({ data, success }) => {
      if (success) {
        this.setState({
          accumulatorList:
            data && data.accumulatorList
              ? data.accumulatorList.map(item => ({
                  label: item.accumulatorid,
                  value: item.accumulatorid,
                }))
              : [],
        });
      }
    });

    getGrantsConfig(({ data, success }) => {
      if (success) {
        this.setState({
          grantList:
            data && data.length
              ? data.map(item => ({
                  label: item.resourceId,
                  value: item.resourceId,
                }))
              : [],
        });
      }
    });
  }

  onChangeTable = ({ name, value, index }) => {
    try {
      const { creditProfileData } = this.state;
      const newData = cloneDeep(creditProfileData);
      newData[index][name] = value;
      this.setState({ creditProfileData: newData });
    } catch (error) {
      console.log(error);
    }
  };

  onChangeProfileTable = ({ name, value, index }) => {
    try {
      const { creditProfileData, activeTab } = this.state;
      const newData = cloneDeep(creditProfileData);
      newData[activeTab.index].profilesList[index][name] = value;
      this.setState({ creditProfileData: newData });
    } catch (error) {
      console.log(error);
    }
  };

  onChangeActionsTable = ({ name, value, index }) => {
    try {
      const { creditProfileData, activeTab, activeProfilesListTab } = this.state;
      const newData = cloneDeep(creditProfileData);
      newData[activeTab.index].profilesList[activeProfilesListTab].limitBreachActions[index][name] = value;
      this.setState({ creditProfileData: newData });
    } catch (error) {
      console.log(error);
    }
  };

  doGetConfigCreditProfile = () => {
    const { searchConfigCreditProfile } = this.props;
    searchConfigCreditProfile({ page: 1, size: 100 }, ({ success, data }) => {
      const { isFirstSearch, isModify } = this.state;
      this.setState({
        isSearching: false,
        creditProfileData: success ? data || [] : [],
        isModify: isFirstSearch ? data && data.length : isModify,
        isFirstSearch: false,
      });
    });
  };

  onAddNewProfile = () => {
    const { creditProfileData } = this.state;
    let newData = cloneDeep(creditProfileData);
    const newDataItem = {
      customerSegment: null,
      name: null,
      salesChannel: null,
      marketSegment: null,
      accountType: null,
      accountSubType: null,
      legalEntity: null,
      sellingCompany: null,
      accountCategory: null,
      profilesList: [],
      isNew: true,
    };
    newData = [newDataItem, ...newData];
    this.setState({ creditProfileData: newData });
  };

  onAddConfig = () => {
    try {
      const { creditProfileData, activeTab } = this.state;
      const newData = cloneDeep(creditProfileData);
      const newDataItem = {
        index: getLastIndex({ data: newData[activeTab.index].profilesList }),
        currency: 'USD',
        accumulatorId: null,
        resourceId: null,
        percentageThreshold: null,
        valueThreshold: null,
        isNew: true,
      };
      if (!newData[activeTab.index].profilesList) newData[activeTab.index].profilesList = [];
      newData[activeTab.index].profilesList = [...newData[activeTab.index].profilesList, newDataItem];
      this.setState({ creditProfileData: newData });
    } catch (error) {
      console.log(error);
    }
  };

  onAddNewAction = () => {
    try {
      const { creditProfileData, activeTab, activeProfilesListTab } = this.state;
      const newData = cloneDeep(creditProfileData);
      const newDataItem = {
        index: getLastIndex({ data: newData[activeTab.index].profilesList[activeProfilesListTab].limitBreachActions }),
        action: null,
      };
      if (!newData[activeTab.index].profilesList[activeProfilesListTab].limitBreachActions)
        newData[activeTab.index].profilesList[activeProfilesListTab].limitBreachActions = [];
      newData[activeTab.index].profilesList[activeProfilesListTab].limitBreachActions = [
        ...newData[activeTab.index].profilesList[activeProfilesListTab].limitBreachActions,
        newDataItem,
      ];
      this.setState({ creditProfileData: newData });
    } catch (error) {
      console.log(error);
    }
  };

  onRemoveItemTable = () => {
    try {
      const { creditProfileData, indexRemove } = this.state;
      const newData = cloneDeep(creditProfileData);
      if (newData[indexRemove].isNew) {
        newData.splice(indexRemove, 1);
      } else {
        newData[indexRemove] = { id: newData[indexRemove].id };
      }
      this.setState({ creditProfileData: newData, isOpenModalRemoveItem: false });
    } catch (error) {
      console.log(error);
    }
  };

  onRemoveCurrency = ({ index }) => {
    try {
      const { creditProfileData, activeTab } = this.state;
      const newData = cloneDeep(creditProfileData);
      if (newData[activeTab.index].profilesList[index].isNew) {
        newData[activeTab.index].profilesList.splice(index, 1);
      } else {
        newData[activeTab.index].profilesList[index] = { index: newData[activeTab.index].profilesList[index].index };
      }
      this.setState({ creditProfileData: newData, isOpenModalRemoveItem: false });
    } catch (error) {
      console.log(error);
    }
  };

  onToggleModalRemoveItem = ({ index }) => {
    const { isOpenModalRemoveItem } = this.state;
    this.setState({ isOpenModalRemoveItem: !isOpenModalRemoveItem, indexRemove: index });
  };

  hanldeSubmit = evt => {
    evt.preventDefault();
    const { isModify, creditProfileData } = this.state;
    let newData = cloneDeep(creditProfileData);
    if (newData && newData.length) {
      newData = newData.map(val => {
        const { isNew, profilesList, ...rest } = val;
        let newProfilesList = profilesList;
        if (newProfilesList && newProfilesList.length) {
          newProfilesList = newProfilesList.map(pr => {
            const { isNew, currency, ...rest } = pr;
            return { ...rest, currency: currency || null };
          });
        }
        return { ...rest, profilesList: newProfilesList || null };
      });
    }

    const { modifyConfigCreditProfile, createConfigCreditProfile, t } = this.props;
    this.setState({ wasValidated: true });
    if (!validate(true, this.formRef, t)) {
      return false;
    }
    this.setState({ wasValidated: false });

    if (isModify) {
      const newPayload = { embrixData: newData };
      modifyConfigCreditProfile(newPayload, ({ success }) => {
        if (success) this.doGetConfigCreditProfile();
      });
    } else {
      const newPayloadCreate = cloneDeep(newData).map(item => {
        const newItem = item;
        delete newItem.index;
        return newItem;
      });
      createConfigCreditProfile({ embrixData: newPayloadCreate }, ({ success }) => {
        if (success) this.doGetConfigCreditProfile();
      });
    }
  };

  onToggleTab = (index, item, indexItem, key) => {
    const { activeTab } = this.state;
    if (activeTab.index === indexItem) this.setState({ activeTab: {} });
    if (activeTab.index !== indexItem) {
      this.setState({
        activeTab: { key, index: indexItem },
      });
    }
  };

  onToggleProfileTab = (index, item, indexItem) => {
    const { activeProfilesListTab } = this.state;
    if (activeProfilesListTab === indexItem) this.setState({ activeProfilesListTab: -1 });
    if (activeProfilesListTab !== indexItem) {
      this.setState({ activeProfilesListTab: indexItem });
    }
  };

  render() {
    const {
      activeProfilesListTab,
      isSearching,
      isOpenModalRemoveItem,
      creditProfileData,
      activeTab,
      isModify,
      wasValidated,
      accumulatorList,
      grantList,
    } = this.state;
    const { permissionsRating, t, isComponent, permissionsBilling, currencyOptions, customerInfo, id } = this.props;

    let modeModifyCreditProfile = 0;
    let modeSetCreditProfile = 0;

    if (permissionsRating && permissionsRating.ratingModulePermissions) {
      const listPermissionRating = permissionsRating.ratingModulePermissions;
      modeSetCreditProfile = checkPermissionSetCreditLimit({ listPermission: listPermissionRating });
      modeModifyCreditProfile = checkPermissionModifyCreditLimit({ listPermission: listPermissionRating });
    }
    const creditProfileColumns = [
      {
        name: 'id',
        label: 'label.id',
        style: { minWidth: '150px' },
        render: (colName, item, index) => (
          <GenericInput
            value={item.id}
            wrapperClass="col-md-12"
            menuPortalTarget
            onChange={({ name, value }) => this.onChangeTable({ name, value, index })}
            name="id"
          />
        ),
      },
      {
        name: 'name',
        label: 'label.name',
        style: { minWidth: '150px' },
        required: true,
        render: (colName, item, index) => (
          <GenericInput
            value={item.name}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onChangeTable({ name, value, index })}
            name="name"
            required
          />
        ),
      },
      {
        name: 'sellingCompany',
        label: 'label.sellingCompany',
        style: { minWidth: '180px' },
        render: (colName, item, index) => (
          <GenericInput
            value={item.sellingCompany}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onChangeTable({ name, value, index })}
            name="sellingCompany"
          />
        ),
      },
      {
        name: 'legalEntity',
        label: 'label.legalEntity',
        style: { minWidth: '180px' },
        render: (colName, item, index) => (
          <GenericInput
            value={item.legalEntity}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onChangeTable({ name, value, index })}
            name="legalEntity"
          />
        ),
      },
      {
        name: 'accountCategory',
        label: 'label.accountCategory',
        style: { minWidth: '180px' },
        render: (colName, item, index) => (
          <GenericInput
            value={item.accountCategory}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onChangeTable({ name, value, index })}
            name="accountCategory"
            tOptions="selections:accountCategory"
            type="select"
            menuPortalTarget
          />
        ),
      },
      {
        name: 'customerSegment',
        label: 'label.customerSegment',
        style: { minWidth: '130px' },
        render: (colName, item, index) => (
          <GenericInput
            value={item.customerSegment}
            wrapperClass="col-md-12"
            type="select"
            menuPortalTarget
            tOptions="selections:customerSegment"
            onChange={({ name, value }) => this.onChangeTable({ name, value, index })}
            name="customerSegment"
          />
        ),
      },
      {
        name: 'salesChannel',
        label: 'label.salesChannel',
        style: { minWidth: '180px' },
        render: (colName, item, index) => (
          <GenericInput
            value={item.salesChannel}
            tOptions="selections:salesChannel"
            wrapperClass="col-md-12"
            type="select"
            menuPortalTarget
            onChange={({ name, value }) => this.onChangeTable({ name, value, index })}
            name="salesChannel"
          />
        ),
      },
      {
        name: 'marketSegment',
        label: 'label.marketSegment',
        style: { minWidth: '160px' },
        render: (colName, item, index) => (
          <GenericInput
            value={item.marketSegment}
            tOptions="selections:marketSegment"
            wrapperClass="col-md-12"
            type="select"
            menuPortalTarget
            onChange={({ name, value }) => this.onChangeTable({ name, value, index })}
            name="marketSegment"
          />
        ),
      },
      {
        name: 'accountType',
        label: 'label.accountType',
        style: { minWidth: '200px' },
        render: (colName, item, index) => (
          <GenericInput
            value={item.accountType}
            tOptions="selections:accountType"
            wrapperClass="col-md-12"
            menuPortalTarget
            type="select"
            onChange={({ name, value }) => this.onChangeTable({ name, value, index })}
            name="accountType"
          />
        ),
      },
      {
        name: 'accountSubType',
        label: 'label.accountSubType',
        style: { minWidth: '160px', textAlign: 'center' },
        render: (colName, item, index) => (
          <GenericInput
            value={item.accountSubType}
            menuPortalTarget
            wrapperClass="col-md-12"
            tOptions="selections:accountSubType"
            type="select"
            onChange={({ name, value }) => this.onChangeTable({ name, value, index })}
            name="accountSubType"
          />
        ),
      },
    ];

    const newTableColumns = [
      ...creditProfileColumns,
      {
        name: 'exceptions',
        label: 'label.addResources',
        render: (colName, item, idx, indexParent, activeTab) => (
          <button type="button" className="btn-expand-table mr-3" onClick={() => this.onToggleTab(idx, item, idx)}>
            <i className={`fa ${activeTab ? 'fa-minus' : 'fa-plus'}`} />
          </button>
        ),
      },
      {
        name: 'remove',
        label: 'label.remove',
        render: (colName, item, index) => {
          return (
            <div className="form-group col-md-12">
              <button type="button" className="btn-phone" onClick={() => this.onToggleModalRemoveItem({ index })}>
                <i className="fa fa-trash" />
              </button>
            </div>
          );
        },
      },
    ];

    const profileColumns = [
      {
        name: 'currency',
        label: 'label.currency',
        style: { minWidth: '200px' },
        render: (colName, item, index) => (
          <GenericInput
            value={item.currency}
            wrapperClass="col-md-12"
            menuPortalTarget
            options={utils.convertCurrency2Option(listCurrency.currencies)}
            type="select"
            onChange={({ name, value }) => this.onChangeProfileTable({ name, value, index })}
            name="currency"
            disabled={item.accumulatorId || item.resourceId}
          />
        ),
      },
      {
        name: 'resourceId',
        label: 'label.grantId',
        style: { minWidth: '180px' },
        render: (colName, item, index) => (
          <GenericInput
            value={item.resourceId}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onChangeProfileTable({ name, value, index })}
            name="resourceId"
            disabled={item.currency || item.accumulatorId}
            type="select"
            options={grantList || []}
            menuPortalTarget
          />
        ),
      },
      {
        name: 'accumulatorId',
        label: 'label.accumulatorId',
        style: { minWidth: '180px' },
        render: (colName, item, index) => (
          <GenericInput
            value={item.accumulatorId}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onChangeProfileTable({ name, value, index })}
            name="accumulatorId"
            disabled={item.currency || item.resourceId}
            type="select"
            options={accumulatorList || []}
            menuPortalTarget
          />
        ),
      },
      {
        name: 'creditLimit',
        label: 'label.creditLimit',
        style: { minWidth: '110px' },
        required: true,
        render: (colName, item, index) => (
          <GenericInput
            value={item.creditLimit}
            wrapperClass="col-md-12"
            type="number"
            onChange={({ name, value }) => this.onChangeProfileTable({ name, value, index })}
            name="creditLimit"
            required
          />
        ),
      },
      {
        name: 'valueThreshold',
        label: 'label.valueThreshold',
        tooltip: 'message.valueThresholdInfoExclusive',
        style: { minWidth: '110px' },
        render: (colName, item, index) => (
          <GenericInput
            value={item.valueThreshold}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onChangeProfileTable({ name, value, index })}
            name="valueThreshold"
            disabled={!!item.percentageThreshold}
          />
        ),
      },
      {
        name: 'percentageThreshold',
        label: 'label.percentageThreshold',
        tooltip: 'message.percentageThresholdInfoExclusive',
        style: { minWidth: '110px' },
        render: (colName, item, index) => (
          <GenericInput
            value={item.percentageThreshold}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => this.onChangeProfileTable({ name, value, index })}
            name="percentageThreshold"
            disabled={!!item.valueThreshold}
          />
        ),
      },
      {
        name: 'exceptions',
        label: 'label.addActions',
        render: (colName, item, idx, indexParent, activeTab) => (
          <button
            type="button"
            className="btn-expand-table mr-3"
            onClick={() => this.onToggleProfileTab(idx, item, idx)}
          >
            <i className={`fa ${activeTab ? 'fa-minus' : 'fa-plus'}`} />
          </button>
        ),
      },
      {
        name: 'remove',
        label: 'label.remove',
        render: (colName, item, index) => {
          return (
            <div className="form-group col-md-12">
              <button type="button" className="btn-phone" onClick={() => this.onRemoveCurrency({ index })}>
                <i className="fa fa-trash" />
              </button>
            </div>
          );
        },
      },
    ];

    const actionsColumns = [
      {
        name: 'limitBreachActions',
        label: 'label.limitBreachActions',
        style: { width: '200px', maxWidth: '200px' },
        render: (colName, item, index) => (
          <GenericInput
            value={item.action}
            wrapperClass="col-md-12"
            menuPortalTarget
            tOptions="selections:creditLimitBreachActions"
            type="select"
            onChange={({ name, value }) => this.onChangeActionsTable({ name, value, index })}
            name="action"
          />
        ),
      },
      {
        name: 'exceptionStartTime',
        label: 'label.exceptionStartTime',
        style: { width: '180px' },
        tooltipLabel: t('common:message.exceptionDateInfo'),
        render: (colName, item, index) => (
          <GenericInput
            value={item.exceptionStartTime}
            wrapperClass="col-md-12"
            type="time"
            onChange={({ name, value }) => this.onChangeActionsTable({ name, value, index })}
            name="exceptionStartTime"
            isSupportDefaultTime
          />
        ),
      },
      {
        name: 'exceptionEndTime',
        label: 'label.exceptionEndTime',
        tooltipLabel: t('common:message.exceptionDateInfo'),
        style: { width: '200px' },
        render: (colName, item, index) => (
          <GenericInput
            value={item.exceptionEndTime}
            wrapperClass="col-md-12"
            type="time"
            onChange={({ name, value }) => this.onChangeActionsTable({ name, value, index })}
            name="exceptionEndTime"
            isSupportDefaultTime
          />
        ),
      },
    ];

    if (isComponent) {
      let currencyLabel = '';
      if (currencyOptions && currencyOptions.length && customerInfo?.currency) {
        const currencySelect = currencyOptions.find(val => val.value === customerInfo?.currency);
        currencyLabel = currencySelect ? currencySelect.normalLabel : '';
      }
      return (
        <div className="col-md-12">
          <form
            onSubmit={this.hanldeSubmit}
            className={`needs-validation ${wasValidated ? 'was-validated' : ''}`}
            noValidate
            ref={this.formRef}
          >
            <br />
            <FormWithTableItem
              title={t('label.creditProfile')}
              subClass="border-bottom"
              accountNum={id}
              commercialName={customerInfo?.commercialName || ''}
              isCommercialName={customerInfo?.customerSegment !== 'B2C'}
              currencyLabel={currencyLabel ? `(${currencyLabel})` : ''}
            >
              <div className="form-group col-md-12 buttons-attibute">
                <button type="button" className="button button-border black x-small" onClick={this.onAddNewProfile}>
                  +
                  {t('label.addCreditProfile')}
                </button>
                {modeModifyCreditProfile === 2 && (
                  <button type="submit" className="button button-border x-small float-right">
                    {isModify ? t('label.saveConfig') : t('label.createConfig')}
                  </button>
                )}
                <button
                  type="button"
                  onClick={this.doGetConfigCreditProfile}
                  className="button button-border black x-small float-right"
                >
                  {t('label.cancel')}
                </button>
              </div>
              <div>
                <div className="group-collapsible">
                  <CollapsibleTable
                    indexViewer={activeTab && activeTab.index !== -1 ? activeTab.index : -1}
                    columns={newTableColumns}
                    data={creditProfileData || []}
                    isLoading={isSearching}
                    isViewOnly
                    isFixedHeaderTable
                    isSupportRemoveIndex
                  >
                    <div className="col-md-12 p-3">
                      <CollapsibleTable
                        indexViewer={activeProfilesListTab}
                        columns={profileColumns}
                        data={
                          creditProfileData &&
                          creditProfileData[activeTab.index] &&
                          creditProfileData[activeTab.index].profilesList
                            ? creditProfileData[activeTab.index].profilesList
                            : []
                        }
                        isLoading={isSearching}
                        isViewOnly
                        isFixedHeaderTable
                        isSupportRemoveIndex
                      >
                        <div className="col-md-6 p-3">
                          <DataTable
                            columns={actionsColumns}
                            data={
                              creditProfileData &&
                              creditProfileData[activeTab.index] &&
                              creditProfileData[activeTab.index].profilesList &&
                              creditProfileData[activeTab.index].profilesList[activeProfilesListTab]
                                ? creditProfileData[activeTab.index].profilesList[activeProfilesListTab]
                                    .limitBreachActions || []
                                : []
                            }
                          />
                          <button
                            type="button"
                            className="button button-border black x-small"
                            onClick={this.onAddNewAction}
                          >
                            +
                            {t('label.addAction')}
                          </button>
                        </div>
                      </CollapsibleTable>
                      <button type="button" className="button button-border black x-small" onClick={this.onAddConfig}>
                        +
                        {t('label.addConfig')}
                      </button>
                    </div>
                  </CollapsibleTable>
                </div>
              </div>
              <br />
            </FormWithTableItem>
          </form>
        </div>
      );
    }
    return (
      <div className="container-fluid">
        <div className="row">
          <SidebarIndex />
          {modeModifyCreditProfile !== 0 && modeSetCreditProfile !== 0 && (
            <div className="content-wrapper">
              <div className="col-md-12 mb-30">
                <PageTitle
                  linkTo={RouteNames.customers.path}
                  titleBtn={t('common:label.back')}
                  items={[
                    { name: t('label.customerManagement'), url: RouteNames.customers.path },
                    { name: t('label.creditProfile') },
                  ]}
                />
                <form
                  onSubmit={this.hanldeSubmit}
                  className={`needs-validation ${wasValidated ? 'was-validated' : ''}`}
                  noValidate
                  ref={this.formRef}
                >
                  <FormWithTableItem title={t('label.creditProfile')} subClass="border-bottom">
                    <br />
                    <div className="form-group col-md-12 buttons-attibute">
                      <button
                        type="button"
                        className="button button-border black x-small"
                        onClick={this.onAddNewProfile}
                      >
                        +
                        {t('label.addCreditProfile')}
                      </button>
                      {modeModifyCreditProfile === 2 && (
                        <button type="submit" className="button button-border x-small float-right">
                          {isModify ? t('label.saveConfig') : t('label.createConfig')}
                        </button>
                      )}
                      <button
                        type="button"
                        onClick={this.doGetConfigCreditProfile}
                        className="button button-border black x-small float-right"
                      >
                        {t('label.cancel')}
                      </button>
                    </div>
                    <div>
                      <div className="group-collapsible">
                        <CollapsibleTable
                          indexViewer={activeTab && activeTab.index !== -1 ? activeTab.index : -1}
                          columns={newTableColumns}
                          data={creditProfileData || []}
                          isLoading={isSearching}
                          isViewOnly
                          isFixedHeaderTable
                          isSupportRemoveIndex
                        >
                          <div className="col-md-12 p-3">
                            <CollapsibleTable
                              indexViewer={activeProfilesListTab}
                              columns={profileColumns}
                              data={
                                creditProfileData &&
                                creditProfileData[activeTab.index] &&
                                creditProfileData[activeTab.index].profilesList
                                  ? creditProfileData[activeTab.index].profilesList
                                  : []
                              }
                              isLoading={isSearching}
                              isViewOnly
                              isFixedHeaderTable
                              isSupportRemoveIndex
                            >
                              <div className="col-md-6 p-3">
                                <DataTable
                                  columns={actionsColumns}
                                  data={
                                    creditProfileData &&
                                    creditProfileData[activeTab.index] &&
                                    creditProfileData[activeTab.index].profilesList &&
                                    creditProfileData[activeTab.index].profilesList[activeProfilesListTab]
                                      ? creditProfileData[activeTab.index].profilesList[activeProfilesListTab]
                                          .limitBreachActions || []
                                      : []
                                  }
                                />
                                <button
                                  type="button"
                                  className="button button-border black x-small"
                                  onClick={this.onAddNewAction}
                                >
                                  +
                                  {t('label.addAction')}
                                </button>
                              </div>
                            </CollapsibleTable>
                            <button
                              type="button"
                              className="button button-border black x-small"
                              onClick={this.onAddConfig}
                            >
                              +
                              {t('label.addConfig')}
                            </button>
                          </div>
                        </CollapsibleTable>
                      </div>
                    </div>
                    <br />
                  </FormWithTableItem>
                </form>
              </div>
            </div>
          )}
        </div>

        <ModalAccept
          isOpen={isOpenModalRemoveItem}
          onToggle={this.onToggleModalRemoveItem}
          onAcceptModal={this.onRemoveItemTable}
          message={t('label.messageDelete')}
        />
      </div>
    );
  }
}

CreditProfile.propTypes = {
  searchConfigCreditProfile: PropTypes.func.isRequired,
  createConfigCreditProfile: PropTypes.func.isRequired,
  modifyConfigCreditProfile: PropTypes.func.isRequired,
  permissionsRating: PropTypes.objectOf(PropTypes.any),
};

CreditProfile.defaultProps = {
  permissionsRating: {},
};
const mapStateToProps = createStructuredSelector({
  permissionsRating: makeGetPermissionsRating() || {},
  permissionsBilling: makeGetPermissionsBilling() || {},
});

const newCreditProfile = connect(mapStateToProps, {
  searchConfigCreditProfile,
  modifyConfigCreditProfile,
  createConfigCreditProfile,
  getAccumulatorsConfig,
  getGrantsConfig,
})(CreditProfile);

export default withTranslation('common')(newCreditProfile);
