import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import SearchComponent from '../../PricingHub/SearchComponent';

class BillingSearchForm extends SearchComponent {
  searchInputs = [
    {
      label: 'label.startDate',
      name: 'startDate',
      type: 'date',
    },
    {
      label: 'label.endDate',
      name: 'endDate',
      type: 'date',
    },
    {
      label: 'label.billType',
      name: 'billType',
      type: 'select',
      tOptions: 'selections:billUnitType',
      isClearable: true,
    },
    {
      label: 'label.accountType',
      name: 'accountType',
      type: 'select',
      tOptions: 'selections:accountType',
      isClearable: true,
    },
    {
      label: 'label.billStatus',
      name: 'status',
      tOptions: 'selections:billStatus',
      isClearable: true,
      type: 'select',
    },
    {
      label: 'label.minimumBillThreshold',
      name: 'minBillThreshold',
      type: 'number',
    },
    {
      label: 'label.paymentMethod',
      name: 'paymentMethod',
      tOptions: 'selections:paymentMethod',
      isClearable: true,
      type: 'select',
    },
    {
      label: 'label.organization',
      name: 'company',
    },
    {
      label: 'label.accountId',
      name: 'accountId',
    },
    {
      label: 'label.groupId',
      name: 'groupId',
    },
    {
      label: 'label.lineOfBusiness',
      name: 'lineOfBusiness',
    },
  ];

  btnSpan = 'col-md-3';
}

BillingSearchForm.propTypes = {
  onSubmit: PropTypes.func,
  isSubmitting: PropTypes.bool,
};

BillingSearchForm.defaultProps = {
  isSubmitting: false,
};

export default withTranslation('common')(BillingSearchForm);
