import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { cloneDeep } from 'lodash';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { createStructuredSelector } from 'reselect';
import { getPageTotalCount } from '../../../utils/utils';
import RouteNames from '../../App/RouteNames';
import ItemSort from '../../../constantsApp/pricingManager/ItemSort';
import { makeGetListItems, makeErrorMessage } from '../selectors';
import { makeGetPermissionsPricingManagement } from '../../App/selectors';
import { searchItems, setParamsItems } from '../actions';
import { ItemSearchForm } from '../../../components/PricingHub';
import { Breadcrumb, DataTable, TablePagination } from '../../../components/common';
import {
  checkPermissionCreateItem,
  checkPermissionModifyItem,
  checkPermissionSearchItems,
  checkPermissionGetItem,
} from '../CheckPermission';

class PricingItem extends Component {
  state = {
    page: 0,
    size: 20,
    filter: {},
    sort: '',
    sorted: {},
    totalCount: null,
    isSearching: false,
  };

  static getDerivedStateFromProps(props, state) {
    if (state && props.listItems) {
      return {
        totalCount: getPageTotalCount({ ...state, items: props.listItems }),
      };
    }
    return {};
  }

  componentDidMount() {
    const { location } = this.props;
    if (location && location.state && location.state.filter) {
      this.setState({ filter: location.state.filter }, () => {
        this.doSearchItems();
      });
    } else {
      this.doSearchItems();
    }
  }

  onClickRow = e => {
    e.preventDefault();
  };

  onPageChange = page => {
    this.setState({ page }, () => this.doSearchItems());
  };

  onSizeChange = size => {
    this.setState({ size, page: 0 }, () => this.doSearchItems());
  };

  onSubmitSearch = filteredData => {
    this.setState({ filter: filteredData, page: 0 }, () => this.doSearchItems());
  };

  doSearchItems = () => {
    const { page, size, sorted, filter } = this.state;
    const { searchItems } = this.props;
    const sort =
      sorted && sorted.sortCol && sorted.sortDir && ItemSort[sorted.sortCol]
        ? ItemSort[sorted.sortCol][sorted.sortDir]
        : null;
    this.setState({ isSearching: true });
    const params = {
      page: page + 1,
      size,
      filter,
    };
    if (sort) {
      params.sort = sort;
    }
    searchItems(params, () => {
      this.setState({ isSearching: false, filter, sort });
    });
  };

  onSortColumn = (sortCol, sortDir) => {
    this.setState({ sorted: { sortCol, sortDir } }, () => this.doSearchItems());
  };

  render() {
    const { listItems, permissionsPricing, t, location } = this.props;
    const { sorted, isSearching, page, size, totalCount, filter } = this.state;

    let modeCreateItem = 0;
    let modeModifyItem = 0;
    let modeSearchItems = 0;
    let modeGetItem = 0;

    if (permissionsPricing && permissionsPricing.pricingModulePermissions) {
      const listPermissionPricing = permissionsPricing.pricingModulePermissions;
      modeCreateItem = checkPermissionCreateItem({ listPermission: listPermissionPricing });
      modeModifyItem = checkPermissionModifyItem({ listPermission: listPermissionPricing });
      modeSearchItems = checkPermissionSearchItems({ listPermission: listPermissionPricing });
      modeGetItem = checkPermissionGetItem({ listPermission: listPermissionPricing });
    }
    if (modeSearchItems === 0 || modeGetItem === 0) return '';

    const tableColumns = [
      {
        name: 'id',
        label: 'common:label.id',
        render: (colName, item) => (
          <Link
            className="text-success"
            to={{ pathname: RouteNames.prettifyPath(RouteNames.pricingItemEdit.path, item.id), state: { filter } }}
          >
            {item.id}
          </Link>
        ),
      },
      {
        name: 'name',
        label: 'common:label.name',
        sortable: true,
        style: { minWidth: '140px' },
      },
      {
        name: 'description',
        label: 'common:label.description',
      },
      {
        name: 'type',
        label: 'common:label.itemType',
        isRenderT: true,
        render: (colName, item, t) => {
          const slt = t ? t('selections:itemType')().find(val => val.value === item.type) : '';
          return <span>{slt ? slt.label : ''}</span>;
        },
      },
      {
        name: 'company',
        label: 'common:label.company',
        sortable: true,
        style: { minWidth: '100px' },
      },
      {
        name: 'revenueType',
        label: 'common:label.revenueType',
        isRenderT: true,
        render: (colName, item, t) => {
          const slt = t ? t('selections:revenueType')().find(val => val.value === item.revenueType) : '';
          return <span>{slt ? slt.label : ''}</span>;
        },
      },
      {
        name: 'revenueRecognitionType',
        label: 'common:label.revenueRecognitionType',
        isRenderT: true,
        render: (colName, item, t) => {
          const slt = t ? t('selections:revenueRecognitionType')().find(val => val.value === item.revenueRecognitionType) : '';
          return <span>{slt ? slt.label : ''}</span>;
        },
      },
      {
        name: 'glAccount',
        label: 'common:label.glAccount',
      },
      {
        name: 'taxCode',
        label: 'common:label.taxCode',
      },
      {
        name: 'productFamily',
        label: 'common:label.productFamily',
        sortable: true,
        style: { minWidth: '130px' },
      },
      {
        name: 'productLine',
        label: 'common:label.productLine',
        sortable: true,
        style: { minWidth: '110px' },
      },
      {
        name: 'productType',
        label: 'common:label.productType',
      },
      {
        name: 'productSubType',
        label: 'common:label.productSubType',
      },
      {
        name: 'status',
        label: 'common:label.status',
        isRenderT: true,
        render: (colName, item, t) => {
          const slt = t ? t('selections:statusPricing')().find(val => val.value === item.status) : '';
          return <span>{slt ? slt.label : ''}</span>;
        },
      },
      {
        name: 'action',
        label: 'common:label.action',
        isRenderT: true,
        render: (colName, item, t) => (
          <Link
            className="btn btn-outline-success btn-sm"
            to={{ pathname: RouteNames.prettifyPath(RouteNames.pricingItemEdit.path, item.id), state: { filter } }}
          >
            {t('label.view')}
          </Link>
        ),
      },
    ];
    const newColumnTable = cloneDeep(tableColumns);

    if (modeModifyItem === 0) {
      newColumnTable[0] = {
        name: 'id',
        label: 'Id',
      };
      newColumnTable.splice(newColumnTable.length - 1, 1);
    }

    const tableConfig = {
      columns: newColumnTable,
      sorted: { ...sorted },
      data: listItems,
      onSort: this.onSortColumn,
      onClickRow: this.onClickRow,
    };

    return (
      <div>
        <div className="page-title">
          <div className="row">
            <div className="col-sm-6">
              <Link
                to={
                  modeCreateItem !== 2
                    ? RouteNames.productFamilyPricing.path
                    : { pathname: RouteNames.pricingItemAdd.path, state: { filter } }
                }
                className="button x-small"
              >
                {modeCreateItem !== 2 ? t('label.back') : t('common:label.createNewItem')}
              </Link>
            </div>

            <div className="col-sm-6">
              <Breadcrumb
                items={[
                  { name: t('navbar:pricingHub.subMain.pricingManagement'), url: RouteNames.pricingItem.path },
                  { name: t('pricingPage:sidebar.items') },
                ]}
              />
            </div>
          </div>
        </div>

        <br />
        <div className="col-md-12 mb-30">
          <div className="card card-statistics h-100">
            <div className="card-body">
              <div className="repeater-file">
                <div data-repeater-list="group-a">
                  <ItemSearchForm
                    onSubmit={this.onSubmitSearch}
                    isSubmitting={isSearching}
                    defaultFilter={location && location.state && location.state.filter ? location.state.filter : {}}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-sm-12 mb-30">
          <div className="card card-statistics h-100">
            <div className="card-body">
              <DataTable {...tableConfig} isLoading={isSearching} />
            </div>
          </div>
        </div>
        <div className="mb-30">
          <TablePagination
            pageNumber={page}
            pageSize={size}
            totalCount={totalCount}
            onPageChange={this.onPageChange}
            onSizeChange={this.onSizeChange}
          />
        </div>
      </div>
    );
  }
}

PricingItem.propTypes = {
  permissionsPricing: PropTypes.objectOf(PropTypes.any),
  listItems: PropTypes.arrayOf(PropTypes.any),
  searchItems: PropTypes.func.isRequired,
};

PricingItem.defaultProps = {
  permissionsPricing: {},
  listItems: [],
};

const mapStateToProps = createStructuredSelector({
  listItems: makeGetListItems() || [],
  errorMessage: makeErrorMessage() || '',
  permissionsPricing: makeGetPermissionsPricingManagement() || {},
});

const newPricingItem = connect(mapStateToProps, { searchItems, setParamsItems })(PricingItem);

export default withTranslation(['common', 'navbar', 'pricingPage'])(newPricingItem);
