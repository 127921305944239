import React, { useEffect, useState, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { isEmpty, cloneDeep } from 'lodash';
import { toast } from 'react-toastify';
import moment from 'moment';
import {
  FormWithTableItem,
  DataTable,
  TablePagination,
  ModalWithItem,
  GenericInput,
  SwitchExport,
  ExcelExport,
  ButtonExport,
} from '../../common';
import { getPageTotalCount, validate } from '../../../utils/utils';
import { getUserId } from '../../../localStorages';
import RouteNames from '../../../containers/App/RouteNames';
import TopupDetailsSearchForm from '../TopupDetailsSearchForm';
import convertJson2Sheet from '../../../utils/ExcelHelper/exportExcelFile';
import convertJson2Pdf from '../../../utils/PdfHelper/exportPdfFile';
import { PAGE_SIZE_DOWNLOAD } from '../../../utils/constants';

let fileNameExport = '';

const subscriptionSort = {
  effectiveDate: {
    asc: 'effectiveDate_ASC',
    desc: 'effectiveDate_DESC',
  },
  endDate: {
    asc: 'endDate_ASC',
    desc: 'endDate_DESC',
  },
  startDate: {
    asc: 'startDate_ASC',
    desc: 'startDate_DESC',
  },
  status: {
    asc: 'status_ASC',
    desc: 'status_DESC',
  },
};

const subscriptionSortDetails = {
  createdDate: {
    asc: 'createdDate_ASC',
    desc: 'createdDate_DESC',
  },
  serviceType: {
    asc: 'serviceType_ASC',
    desc: 'serviceType_DESC',
  },
  userId: {
    asc: 'userId_ASC',
    desc: 'userId_DESC',
  },
};

const listFields = [
  {
    name: 'amount',
    label: 'label.amount',
    type: 'number',
    required: true,
  },
  {
    name: 'validityTermUnit',
    label: 'label.validityTerm',
    type: 'select',
    tOptions: 'selections:termUnit',
    required: true,
  },
  {
    name: 'validityTerm',
    label: 'label.termX',
    type: 'number',
    required: true,
  },
  {
    name: 'applyPayment',
    label: 'label.subscriptionOrAddonPurchase',
    type: 'checkbox',
    wrapperClass: 'mt-4 pt-2 col-md-3',
  },
];

const tableTopupDetails = [
  {
    name: 'userId',
    label: 'label.userIdEnergyTopup',
    sortable: true,
  },
  {
    name: 'amount',
    label: 'label.topupAmount',
  },
  {
    name: 'createdDate',
    label: 'label.topupDate',
    sortable: true,
  },
  {
    name: 'paymentSource',
    label: 'label.paymentSourceEnergyTopup',
  },
  {
    name: 'paymentSourceId',
    label: 'label.paymentSourceId',
  },
  {
    name: 'paymentDescription',
    label: 'label.paymentDescription',
  },
  {
    name: 'paymentRefId',
    label: 'label.paymentRefIdEnergyTopup',
  },
  {
    name: 'serviceType',
    label: 'common:label.serviceType',
    fieldOption: 'serviceType',
    sortable: true,
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:serviceType')().find(val => val.value === item.serviceType) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
  },
  {
    name: 'validityTerm',
    label: 'label.validityTerm',
  },
  {
    name: 'validityTermUnit',
    label: 'common:label.validityTermUnit',
    fieldOption: 'termUnit',
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:termUnit')().find(val => val.value === item.validityTermUnit) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
  },
  {
    name: 'applyPayment',
    label: 'common:label.applyPayment',
    isRenderT: true,
    render: (colName, item, t) => {
      let label = null;
      if (typeof item.applyPayment === 'boolean') label = item.applyPayment ? 'TRUE' : 'FALSE';
      const slt = t ? t('selections:selectBool')().find(val => val.value === label) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
  },
];

const Topup = ({
  id,
  searchSubscriptionByAccountId,
  currencyOptions,
  customerInfo,
  subscriptionTopUp,
  history,
  searchSubscriptionTopUp,
  modeTopupBalance,
  modeGetSubscriptionTopupReport,
}) => {
  const formRef = useRef();
  const buttonRef = useRef();

  const [wasValidated, setValidate] = useState(false);

  const [totalCount, setTotalCount] = useState(null);
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(20);
  const [isLoading, setLoading] = useState(false);
  const [sorted, setSorted] = useState({});
  const [data, setData] = useState([]);
  const [isOpenModalSubmit, setIsOpenModalSubmit] = useState(false);
  const [itemSelect, setItemSelect] = useState(null);

  const [totalCountDetails, setTotalCountDetails] = useState(null);
  const [pageDetails, setPageDetails] = useState(0);
  const [sizeDetails, setSizeDetails] = useState(20);
  const [isLoadingDetails, setLoadingDetails] = useState(false);
  const [sortedDetails, setSortedDetails] = useState({});
  const [dataDetails, setDataDetails] = useState([]);
  const [itemDetailsSelect, setItemDetailsSelect] = useState(null);
  const [filterDetails, setFilterDetails] = useState({});
  const [isActivePdf, setIsActivePdf] = useState(false);
  const [dataExport, setDataExport] = useState([]);

  const { t } = useTranslation('common');

  const onPageChange = page => {
    setPage(page);
  };

  const onSizeChange = size => {
    setSize(size);
  };

  const onSortColumn = (sortCol, sortDir) => {
    setSorted({ sortCol, sortDir });
  };

  const onPageChangeDetails = page => {
    setPageDetails(page);
  };

  const onSizeChangeDetails = size => {
    setSizeDetails(size);
  };

  const onSortColumnDetails = (sortCol, sortDir) => {
    setSortedDetails({ sortCol, sortDir });
  };

  const doSearchSubscriptionTopUp = useCallback(
    item => {
      setLoadingDetails(false);
      const newFilter = cloneDeep(filterDetails);
      if (!newFilter.accountId) newFilter.accountId = id;
      if (!newFilter.subscriptionId) newFilter.subscriptionId = itemDetailsSelect?.id || item.id || null;

      searchSubscriptionTopUp(
        {
          page: pageDetails + 1,
          size: sizeDetails,
          filter: newFilter,
          sort: !isEmpty(sortedDetails) ? subscriptionSortDetails[sortedDetails.sortCol][sortedDetails.sortDir] : null,
        },
        ({ success, data }) => {
          setLoadingDetails(false);
          if (success && data) setDataDetails(data);
        }
      );
    },
    [filterDetails, id, itemDetailsSelect, searchSubscriptionTopUp, pageDetails, sizeDetails, sortedDetails]
  );

  const doSearchSubscriptionByAccountId = useCallback(() => {
    setLoading(false);
    const newFilter = {};
    if (!newFilter.accountId) newFilter.accountId = id;
    searchSubscriptionByAccountId(
      {
        page: page + 1,
        size,
        filter: newFilter,
        sort: !isEmpty(sorted) ? subscriptionSort[sorted.sortCol][sorted.sortDir] : null,
      },
      ({ success, data }) => {
        setLoading(false);
        if ((!data || !data.length) && page === 0) {
          toast.info(t('message.noTopupMessage'));
        }
        if (success && data) setData(data);
      }
    );
  }, [id, searchSubscriptionByAccountId, page, size, sorted, t]);

  const onHandleSubmitSearchDetails = filter => {
    setFilterDetails(filter);
  };

  useEffect(() => {
    doSearchSubscriptionByAccountId();
  }, [doSearchSubscriptionByAccountId]);

  useEffect(() => {
    if (itemDetailsSelect) doSearchSubscriptionTopUp();
  }, [doSearchSubscriptionTopUp, itemDetailsSelect]);

  useEffect(() => {
    setTotalCount(getPageTotalCount({ totalCount, page, size, items: data }));
  }, [page, size, data, totalCount]);

  useEffect(() => {
    setTotalCountDetails(
      getPageTotalCount({ totalCount: totalCountDetails, page: pageDetails, size: sizeDetails, items: dataDetails })
    );
  }, [totalCountDetails, pageDetails, sizeDetails, dataDetails]);

  const onSelectItem = ({ item }) => {
    setItemSelect({ ...item, validityTermUnit: 'MONTHS', validityTerm: '1' });
    setIsOpenModalSubmit(true);
  };

  const onChangeDataSubmit = ({ name, value }) => {
    itemSelect[name] = value;
    setItemSelect({ ...itemSelect });
  };

  const onToggleModalSubmit = () => {
    setIsOpenModalSubmit(false);
    setItemSelect(null);
  };

  const onToggleModalDetails = () => {
    setItemDetailsSelect(null);
    setIsActivePdf(false);
    setDataExport([]);
  };

  const doGetAllSubscriptionTopUp = cb => {
    const newFilter = cloneDeep(filterDetails);
    if (!newFilter.accountId) newFilter.accountId = id;
    if (!newFilter.subscriptionId) newFilter.subscriptionId = itemDetailsSelect?.id || null;

    searchSubscriptionTopUp(
      {
        page: pageDetails + 1,
        size: PAGE_SIZE_DOWNLOAD,
        filter: newFilter,
        sort: !isEmpty(sortedDetails) ? subscriptionSort[sortedDetails.sortCol][sortedDetails.sortDir] : null,
      },
      ({ success, data }) => {
        if (success && data) {
          setDataExport(data || []);
          if (cb) cb(data);
        }
      }
    );
  };

  const onExport = () => {
    setDataExport([]);
    doGetAllSubscriptionTopUp(data => {
      if (isActivePdf) {
        convertJson2Pdf({
          data: data || dataExport || [],
          t,
          title: t('label.topupDetails').toLocaleUpperCase(),
          fileName: fileNameExport,
          columnsTable: tableTopupDetails,
          fontSize: 7,
        });
      } else {
        buttonRef.current.click();
      }
    });
  };

  const onSubscriptionTopUp = evt => {
    evt.preventDefault();
    setValidate(true);
    if (!validate(true, formRef, t)) {
      return false;
    }
    setValidate(false);

    return subscriptionTopUp(
      {
        subscriptionId: itemSelect.id || null,
        accountId: id || null,
        userId: getUserId() || null,
        amount: itemSelect.amount || null,
        validityTerm: itemSelect.validityTerm || null,
        validityTermUnit: itemSelect.validityTermUnit || null,
        applyPayment:
          itemSelect.applyPayment || typeof itemSelect.applyPayment === 'boolean' ? itemSelect.applyPayment : null,
      },
      ({ success }) => {
        if (success) {
          history.push(RouteNames.prettifyPath(RouteNames.customerDetailsBalances.path, id));
        }
      }
    );
  };

  const viewDetailsColumn = [];

  if (modeGetSubscriptionTopupReport) {
    viewDetailsColumn.push({
      name: 'topupDetails',
      label: 'label.topupDetails',
      render: (colName, item) => (
        <button
          className="btn btn-outline-success btn-sm"
          type="button"
          onClick={() => {
            setItemDetailsSelect(item);
          }}
        >
          {t('label.view')}
        </button>
      ),
    });
  }

  const tableTopup = [
    {
      name: 'select',
      label: 'label.select',
      render: (colName, item) => (
        <GenericInput
          type="checkbox"
          name={item.id}
          checked={itemSelect && item.id === itemSelect.id}
          value={itemSelect && item.id === itemSelect.id}
          disabled={!item.category || item.category !== 'PREPAID'}
          onChange={() => onSelectItem({ item })}
        />
      ),
    },
    {
      name: 'id',
      label: 'label.id',
      render: (colName, item) =>
        !item.category || item.category !== 'PREPAID' ? (
          <>{item.id}</>
        ) : (
          <button
            className="btn btn-outline-success btn-sm no-border"
            type="button"
            onClick={() => onSelectItem({ item })}
          >
            {item.id}
          </button>
        ),
    },
    {
      name: 'name',
      label: 'label.name',
    },
    {
      name: 'category',
      label: 'label.category',
    },
    {
      name: 'effectiveDate',
      label: 'label.effectiveDate',
      sortable: true,
    },
    {
      name: 'startDate',
      label: 'label.startDate',
      sortable: true,
    },
    {
      name: 'endDate',
      label: 'label.endDate',
      sortable: true,
    },
    {
      name: 'status',
      label: 'label.status',
      sortable: true,
    },
    ...viewDetailsColumn,
  ];
  let currencyLabel = '';
  if (currencyOptions && currencyOptions.length && customerInfo?.currency) {
    const currencySelect = currencyOptions.find(val => val.value === customerInfo?.currency);
    currencyLabel = currencySelect ? currencySelect.normalLabel : '';
  }

  fileNameExport = `${customerInfo?.id}_${itemDetailsSelect ? `${itemDetailsSelect.id}_` : ''}${t('label.topupDetails')
    .toLocaleLowerCase()
    .replace(/ /g, '_')}_${moment(new Date()).format('YYYY_MM_DD')}`;

  return (
    <div className="col-md-12 mb-30">
      <FormWithTableItem
        title={t('customerPage:sidebarHandleAccountPage.topup')}
        accountNum={id}
        commercialName={customerInfo?.commercialName || ''}
        isCommercialName={customerInfo?.customerSegment !== 'B2C'}
        currencyLabel={currencyLabel ? `(${currencyLabel})` : ''}
        key="pendingBills"
      >
        <DataTable isLoading={isLoading} onSort={onSortColumn} sorted={sorted} columns={tableTopup} data={data || []} />
      </FormWithTableItem>
      <div className="mb-30 mt-3">
        <TablePagination
          pageNumber={page}
          pageSize={size}
          totalCount={totalCount}
          onPageChange={onPageChange}
          onSizeChange={onSizeChange}
        />
      </div>

      <ModalWithItem isOpen={isOpenModalSubmit} onToggle={onToggleModalSubmit} modalTitle={t('label.topupDetails')}>
        <form
          ref={formRef}
          noValidate
          className={`needs-validation ${wasValidated ? 'was-validated' : ''}`}
          onSubmit={modeTopupBalance === 2 ? onSubscriptionTopUp : () => {}}
        >
          <div className="col-md-12 row">
            {listFields.map(val => (
              <GenericInput
                {...val}
                value={itemSelect && (itemSelect[val.name] || itemSelect[val.name] === 0) ? itemSelect[val.name] : null}
                onChange={onChangeDataSubmit}
              />
            ))}
          </div>
          <div className="col-sm-12 mt-4 mb-30">
            <button
              type="button"
              className="button mr-2 button-border black x-small float-right"
              onClick={onToggleModalSubmit}
            >
              {t('label.cancel')}
            </button>
            {!!modeTopupBalance && (
              <button type="submit" disabled={modeTopupBalance === 1} className="button x-small mr-2 float-right">
                {t('label.submit')}
              </button>
            )}
          </div>
        </form>
      </ModalWithItem>
      <ModalWithItem
        wrapperClass="modal-custom modal-70"
        isOpen={!!itemDetailsSelect}
        onToggle={onToggleModalDetails}
        modalTitle={t('label.topupDetails')}
      >
        <div className="col-md-12">
          <TopupDetailsSearchForm onSubmit={onHandleSubmitSearchDetails} />
        </div>
        <div className="mt-3 d-flex mb-4 pt-3 col-md-12">
          <div className="ml-auto mt-2 mb-auto mr-3">
            <SwitchExport
              onChange={() => {
                setIsActivePdf(!isActivePdf);
              }}
              wrapperClass={isActivePdf ? 'switch-active' : 'switch-non-active'}
              title={t('label.excel')}
              checked={isActivePdf}
              rightTitle={t('label.pdf')}
              name="SwitchExport"
            />
          </div>
          <>
            <ButtonExport onExport={onExport} />
            <ExcelExport
              element={<button type="button" className="display-none" ref={buttonRef} />}
              nameSheet={t('label.topupDetails').toLocaleUpperCase()}
              multiDataSet={convertJson2Sheet({
                data: dataExport,
                t,
                title: t('label.topupDetails').toLocaleUpperCase(),
                columnsTable: tableTopupDetails,
              })}
              fileName={fileNameExport}
            />
          </>
        </div>
        <div className="col-md-12 mt-5">
          <DataTable
            isLoading={isLoadingDetails}
            onSort={onSortColumnDetails}
            sorted={sortedDetails}
            columns={tableTopupDetails}
            data={dataDetails || []}
          />
        </div>
        <div className="mb-30 mt-3 page-size-modal">
          <TablePagination
            pageNumber={pageDetails}
            pageSize={sizeDetails}
            totalCount={totalCountDetails}
            onPageChange={onPageChangeDetails}
            onSizeChange={onSizeChangeDetails}
          />
        </div>
      </ModalWithItem>
    </div>
  );
};

Topup.propTypes = {
  id: PropTypes.string,
  subscriptionTopUp: PropTypes.func.isRequired,
  searchSubscriptionByAccountId: PropTypes.func.isRequired,
  searchSubscriptionTopUp: PropTypes.func.isRequired,
};

Topup.defaultProps = {
  id: '',
};

export default Topup;
