import gql from 'graphql-tag';

export default () => {
  const getTemplate = 'getTemplate(dummy: "")';
  return gql`
    query {
      ${getTemplate} {
        id
        templates {
          index
          filePath
          name
          type
          fileType
          status
          country
          lineOfBusiness
          accountType
          accountCategory
          name
          supplementaryFiles {
            index
            description
            filePath
          }
        }
      }
    }
  `;
};
