import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import Tooltip from 'rc-tooltip';
import { formatMoneyValue } from '../../../utils/utils';
import { listCommaFields } from '../../../utils/constants';
import { convertBoolLabel } from '../../../utils/exportHelper';

const ColData = ({ name, item, render, indexParent, idx, isRenderT, isSupportAmount, rowClass }) => {
  const { t } = useTranslation(['common', 'selections']);
  let colText = render && !isRenderT ? render(name, item, idx, indexParent) : item[name];
  if (isRenderT) colText = render(name, item, t);
  if (typeof item[name] === 'boolean' && !render) {
    colText = convertBoolLabel({ value: item[name], t });
  }
  return (
    <td className={rowClass || ''}>
      {(listCommaFields.includes(name) && !render) || isSupportAmount ? formatMoneyValue(colText) : colText}
    </td>
  );
};

ColData.propTypes = {
  name: PropTypes.string,
  item: PropTypes.object,
  render: PropTypes.func,
  isRenderT: PropTypes.bool,
};
ColData.defaultProps = {
  name: '',
  item: {},
  render: null,
  isRenderT: false,
};

const RowHeader = ({ columns, sorted, onSort, isLoading, onCheckAll, t }) => {
  const hHtml = columns.map((col, idx) => {
    const onSortCol = !isLoading && onSort && col.sortable ? onSort : () => {};
    return (
      <th
        key={`th-${col.name}-${idx}`}
        className={classNames(col.className, {
          sortable: col.sortable,
          sorting: col.sortable && col.name === sorted.sortCol,
          'sorting-desc': col.sortable && col.name === sorted.sortCol && sorted.sortDir === 'desc',
          'sorting-asc': col.sortable && col.name === sorted.sortCol && sorted.sortDir !== 'desc',
        })}
        onClick={e => onSortCol(col.name, sorted.sortDir === 'desc' ? 'asc' : 'desc')}
        style={col.style ? col.style : {}}
        disabled={isLoading}
      >
        <label className="label-header">
          {col.isCheckable && (
            <input name={col.name} type="checkbox" checked={col.name === sorted.sortCol} onChange={onCheckAll} />
          )}
          {col.label ? t(col.label) : ''}
          {col.label && col.required && <i className="fa fa-star is-required" />}
          {(col?.tooltipLabel || col?.tooltipHtml) && (
            <Tooltip
              placement="bottom"
              overlay={col?.tooltipLabel || col?.tooltipHtml ? col?.tooltipHtml || t(col?.tooltipLabel) : ''}
              arrowContent={<div className="rc-tooltip-arrow-inner" />}
              overlayClassName="tooltip-info-header-tabel"
            >
              <i className="fa fa-info-circle info-header-table-hover" />
            </Tooltip>
          )}
        </label>
      </th>
    );
  });
  return <tr className="text-dark">{hHtml}</tr>;
};

RowHeader.propTypes = {
  columns: PropTypes.array.isRequired,
  sorted: PropTypes.object,
  onSort: PropTypes.func,
  isLoading: PropTypes.bool,
};
RowHeader.defaultProps = {
  columns: [],
  sorted: {},
  isLoading: false,
};

const RowContent = ({
  columns,
  item,
  className,
  onClickRow,
  getRowClass,
  indexParent,
  isSupportRemoveIndex,
  indexItem,
}) => {
  const rowClassNames = `${className} ${getRowClass ? getRowClass(item) : ''}`;
  if (isSupportRemoveIndex && Object.keys(item).length < 2) return '';
  const rowCols = columns.map((col, idx) => (
    <ColData
      key={`${col.key}-${idx}`}
      name={col.name}
      item={item}
      render={col.render}
      indexParent={indexParent}
      idx={indexItem}
      isRenderT={col.isRenderT}
      isAmount={col.isAmount}
      rowClass="row-draging"
      isSupportAmount={col.isSupportAmount}
    />
  ));
  return rowCols;
  // return (
  //   <tr className={rowClassNames} onClick={e => (onClickRow ? onClickRow(e, item) : {})}>
  //     {rowCols}
  //   </tr>
  // );
};

RowContent.propTypes = {
  columns: PropTypes.array.isRequired,
  item: PropTypes.object,
  className: PropTypes.string,
  onClickRow: PropTypes.func,
  getRowClass: PropTypes.func,
};
RowContent.defaultProps = {
  columns: [],
  item: {},
  className: '',
  onClickRow: null,
  getRowClass: null,
};

export { RowHeader, RowContent, ColData };
