import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { cloneDeep } from 'lodash';
import { withTranslation } from 'react-i18next';
import { DataTable, SelectGroup, GenericInput } from '../../../components/common';
import { paymentEnum, paymentTerm } from '../../../constantsApp';
import { validate } from '../../../utils/utils';

const defaultNewItem = { offset: '0', paymentWorkingDay: 'NEXT_WORKING_DAY' };
class PaymentTerms extends Component {
  formRef = React.createRef();

  tableRef = React.createRef();

  constructor() {
    super();
    this.state = {
      newItem: { ...defaultNewItem },
      applying: false,
      paymentTerms: [],
      selectedItem: {},
      wasValidated: false,
      wasTableValidated: false,
    };
    this.lastIndex = 0;
    this.isEmptyListServer = true;
    this.paymentTermsOrigin = [];
  }

  componentDidMount = () => {
    this.doGetPaymentConfigByType();
  };

  doGetPaymentConfigByType = () => {
    const { getPaymentConfigByType } = this.props;
    getPaymentConfigByType(paymentEnum.paymentConfig.PAYMENT_TERMS, ({ response }) => {
      this.convertResponsePaymentConfig(response);
    });
  };

  convertResponsePaymentConfig = response => {
    if (response.id) this.isEmptyListServer = false;
    if (response && response.paymentTerms && response.paymentTerms.length > 0) {
      this.getLastIndex(response.paymentTerms);
      this.paymentTermsOrigin = [...response.paymentTerms];

      this.setState({
        paymentTerms: response.paymentTerms,
        wasValidated: false,
        wasTableValidated: false,
      });
    } else {
      this.setState({
        newItem: { ...defaultNewItem },
        applying: false,
        paymentTerms: [],
        selectedItem: {},
        wasValidated: false,
        wasTableValidated: false,
      });
    }
  };

  getLastIndex = array => {
    array.forEach(item => {
      if (item && item.index > this.lastIndex) {
        this.lastIndex = item.index;
      }
    });
  };

  updateRowValuePaymentTerm = (index, type, val) => {
    try {
      const { paymentTerms } = this.state;
      const newData = cloneDeep(paymentTerms);
      // const index = newData.findIndex(e => {
      //   return e.index === idx;
      // });
      newData[index][type] = val;

      this.setState({
        paymentTerms: newData,
      });
    } catch (error) {
      console.log(error);
    }
  };

  onChangeNewValue = (type, val) => {
    try {
      const { newItem } = this.state;
      newItem[type] = val;

      this.setState({
        newItem: { ...newItem },
      });
    } catch (error) {
      console.log(error);
    }
  };

  handleApplyBtn = async e => {
    e.preventDefault();
    const { paymentTerms } = this.state;
    const { t } = this.props;
    this.setState({ wasTableValidated: true });
    if (!validate(true, this.tableRef, t)) {
      return false;
    }
    this.setState({ applying: true, wasTableValidated: false });
    const resultTerms = paymentTerms.map(el => ({
      index: el.index,
      paymentTerm: el.paymentTerm,
      offset: el.offset,
      days: el.days,
      paymentWorkingDay: el.paymentWorkingDay,
    }));

    const listRemoveTerm = this.paymentTermsOrigin
      .filter(el => !resultTerms.some(subEl => subEl.index === el.index))
      .map(el => ({ index: el.index }));
    const dataTerms = {
      paymentConfigType: paymentEnum.paymentConfig.PAYMENT_TERMS,
      paymentTerms: resultTerms.concat(listRemoveTerm),
    };
    if (this.isEmptyListServer) {
      await this.props.createPaymentConfigTerm(dataTerms, ({ success, response }) => {
        if (success) {
          this.convertResponsePaymentConfig(response);
        }
        this.setState({ applying: false });
      });
    } else {
      await this.props.modifyPaymentConfigTerm(dataTerms, ({ success, response }) => {
        if (success) {
          this.convertResponsePaymentConfig(response);
        }
        this.setState({ applying: false });
      });
    }
  };

  handleClickAddNewRecord = () => {
    const { newItem, paymentTerms } = this.state;
    const { t } = this.props;
    this.setState({ wasValidated: true });
    if (!validate(true, this.formRef, t)) {
      return false;
    }
    const newListItem = [{ ...newItem, index: this.lastIndex + 1 }, ...paymentTerms];
    this.setState({
      paymentTerms: newListItem,
      wasValidated: false,
      newItem: { ...defaultNewItem },
    });
    // this.setState(pre => ({
    //   paymentTerms: [{ ...newItem, index: this.lastIndex + 1 }, ...pre.paymentTerms],
    //   wasValidated: false,
    //   newItem: { ...defaultNewItem },
    // }));
    this.lastIndex += 1;
  };

  handleClickRemoveRecord = ({ itemSelect }) => {
    const { paymentTerms } = this.state;
    const newPaymentTerms = cloneDeep(paymentTerms);
    this.getLastIndex(paymentTerms.filter(e => e !== itemSelect));
    this.setState({
      selectedItem: {},
      paymentTerms: newPaymentTerms.filter(item => item.index !== itemSelect.index),
    });
  };

  onSelectItem = item => {
    let { selectedItem } = this.state;
    if (item.index === selectedItem.index) {
      selectedItem = {};
    } else {
      selectedItem = item;
    }
    this.setState({ selectedItem });
  };

  render() {
    const { paymentTerms, selectedItem, newItem, applying, wasValidated, wasTableValidated } = this.state;
    const { modeModifyPaymentTermsConfig, modeCreatePaymentTermsConfig, t } = this.props;
    const columns = [
      {
        name: 'paymentTerm',
        label: 'common:label.paymentTerm',
        required: true,
        render: (colName, item, index) => {
          return (
            <GenericInput
              key="paymentTerm"
              value={item.paymentTerm}
              index={item.index}
              name="paymentTerm"
              isOnTable
              onChange={({ name, value }) => this.updateRowValuePaymentTerm(index, name, value)}
              wrapperClass="col-md-12"
              required
            />
          );
        },
      },
      {
        name: 'offset',
        label: 'common:label.invoiceDateOffset',
        required: true,
        render: (colName, item, index) => {
          return (
            <GenericInput
              key="Invoice Date Offset"
              value={item.offset}
              index={item.index}
              name="offset"
              isOnTable
              type="number"
              onChange={({ name, value }) => this.updateRowValuePaymentTerm(index, name, value)}
              wrapperClass="col-md-12"
              required
            />
          );
        },
      },
      {
        name: 'paymentWorkingDay',
        label: 'common:label.workingDay',
        required: true,
        style: { minWidth: '180px' },
        render: (colName, item, index) => {
          return (
            <GenericInput
              name="paymentWorkingDay"
              isOnTable
              index={item.index}
              type="select"
              options={t('selections:paymentWorkingDay')()}
              isClearable
              value={item.paymentWorkingDay}
              onChange={({ name, value }) => this.updateRowValuePaymentTerm(index, name, value)}
              wrapperClass="col-md-12"
              required
              isSupportDefaultValue
              menuPortalTarget
            />
          );
        },
      },
      {
        name: 'days',
        label: 'common:label.days',
        required: true,
        render: (colName, item, index) => {
          return (
            <GenericInput
              isOnTable
              index={item.index}
              name="days"
              value={item.days}
              type="number"
              onChange={({ name, value }) => this.updateRowValuePaymentTerm(index, name, value)}
              wrapperClass="col-md-12"
              required
            />
          );
        },
      },
      {
        name: 'Remove',
        label: 'common:label.remove',
        render: (colName, item) => (
          <button
            type="button"
            className="btn-trash"
            onClick={() => this.handleClickRemoveRecord({ itemSelect: item })}
          >
            <i className="fa fa-trash" />
          </button>
        ),
      },
    ];
    const tableConfig = {
      columns,
      data: paymentTerms.map(el => {
        return { ...el, ...paymentTerm.find(e => e.method === el.paymentTerm) };
      }),
      getRowClass: item => {
        return item.index === (selectedItem && selectedItem.index) ? 'selected-row' : '';
      },
    };
    return (
      <div className="col-md-12 p-0 mb-30 form-container">
        <div className="card-body">
          <div className="col-md-12">
            {modeCreatePaymentTermsConfig === 2 && (
              <form
                className={`col-md-12 p-0 mt-3 mb-4 ml-3 row needs-validation ${wasValidated ? 'was-validated' : ''}`}
                ref={this.formRef}
                noValidate
              >
                <div className="col-md-7 row p-0">
                  <GenericInput
                    label="common:label.paymentTerm"
                    value={newItem.paymentTerm}
                    onChange={e => this.onChangeNewValue('paymentTerm', e && e.value)}
                    name="paymentTerm"
                    required
                  />

                  <GenericInput
                    key="Invoice Date Offset"
                    value={newItem.offset}
                    label="common:label.invoiceDateOffset"
                    type="number"
                    onChange={e => this.onChangeNewValue('offset', e && e.value)}
                    name="offset"
                    required
                  />
                  <SelectGroup
                    name="Working Day"
                    label="common:label.workingDay"
                    options={t('selections:paymentWorkingDay')()}
                    placeholder={null}
                    isClearable
                    value={t('selections:paymentWorkingDay')().find(e => e && e.value === newItem.paymentWorkingDay)}
                    onChange={e => this.onChangeNewValue('paymentWorkingDay', e && e.value)}
                    required
                  />
                  <GenericInput
                    key="Days"
                    value={newItem.days}
                    label="common:label.days"
                    type="number"
                    onChange={e => this.onChangeNewValue('days', e && e.value)}
                    name="days"
                    required
                  />
                </div>
                <div className="col-md-5 mt-2 pl-2 pt-3 pr-0 ml-3">
                  <button
                    type="button"
                    className="button button-border black x-small"
                    onClick={this.handleClickAddNewRecord}
                  >
                    {`+ ${t('common:label.addNew')}`}
                  </button>
                  {!!modeModifyPaymentTermsConfig && (
                    <button
                      type="button"
                      className="button button-border x-small float-right"
                      onClick={this.handleApplyBtn}
                    >
                      {t('label.saveConfig')}
                    </button>
                  )}
                  <button
                    type="button"
                    onClick={this.doGetPaymentConfigByType}
                    className="button button-border black x-small float-right"
                  >
                    {t('label.cancel')}
                  </button>
                </div>
              </form>
            )}
          </div>
          <form
            className={`col-md-12 needs-validation ${wasTableValidated ? 'was-validated' : ''}`}
            ref={this.tableRef}
            noValidate
          >
            <DataTable {...tableConfig} onClickRow={this.onClickRow} tableClass="form-focus" />
          </form>
        </div>
      </div>
    );
  }
}

PaymentTerms.propTypes = {
  createPaymentConfigTerm: PropTypes.func.isRequired,
  modifyPaymentConfigTerm: PropTypes.func.isRequired,
  getPaymentConfigByType: PropTypes.func.isRequired,
  modeModifyPaymentTermsConfig: PropTypes.number,
  modeCreatePaymentTermsConfig: PropTypes.number,
};

PaymentTerms.defaultProps = {
  modeModifyPaymentTermsConfig: 0,
  modeCreatePaymentTermsConfig: 0,
};

export default withTranslation('common')(PaymentTerms);
