import gql from 'graphql-tag';
import { parseToMutationRequest } from '../../utils/utils';

export default data => {
  const dataCreate = parseToMutationRequest(data, [
    'index',
    'type',
    'fileType',
    'status',
    'accountType',
    'accountCategory',
  ]);
  const createTemplate = `createTemplate(input: ${dataCreate})`;
  return gql`
    mutation {
      ${createTemplate} {
        id
        templates {
          index
          type
          filePath
          fileType
          status
          country
          name
          supplementaryFiles {
            index
            description
            filePath
          }
        }
      }
    }
  `;
};
