import { cloneDeep } from 'lodash';
import pdfConfig from './helper';
import { getLabelOptions } from '../utils';
import { convertBoolLabel } from '../exportHelper';

const HeaderTable = ({ t }) => [
  t('label.id'),
  t('label.chargeType'),
  t('label.name'),
  t('label.description'),
  t('label.serviceType'),
  t('label.isChild'),
  t('label.itemId'),
  t('label.serviceProductCode'),
  t('label.uom'),
  t('label.uomName'),
  t('label.glAccount'),
  t('label.taxCode'),
  t('label.status'),
  t('label.createdDate'),
  t('label.isParent'),
];

const convertJson2Pdf = ({ data, t, title, fileName, uomList, ...rest }) => {
  const Header = cloneDeep(HeaderTable({ t }));
  const newListData = data.map(item => {
    const newItem = [];
    if (item && typeof item === 'object') {
      newItem.push(item.id ? item.id : '');
      newItem.push(item.chargeType ? item.chargeType : '');
      newItem.push(item.name ? item.name : '');
      newItem.push(item.description ? item.description : '');
      newItem.push(item.serviceType ? item.serviceType : '');
      newItem.push(convertBoolLabel({ t, value: item.isChild }));
      newItem.push(item.itemId ? item.itemId : '');
      newItem.push(item.serviceProductCode ? item.serviceProductCode : '');
      if (item.uom && uomList && uomList.length) {
        newItem.push(
          item.uom
            ? uomList.find(val => val.value === item.uom)
              ? uomList.find(val => val.value === item.uom).label
              : ''
            : ''
        );
      } else {
        newItem.push('');
      }
      newItem.push(item.uomName ? item.uomName : '');
      newItem.push(item.glAccount ? item.glAccount : '');
      newItem.push(item.taxCode ? item.taxCode : '');
      newItem.push(getLabelOptions({ item, t, fieldName: 'status', fieldOption: 'statusPricing' }));
      newItem.push(item.createdDate ? item.createdDate : '');
      newItem.push(convertBoolLabel({ t, value: item.isParent }));
    }
    return newItem;
  });
  return pdfConfig({ data: newListData, Header, title, fileName, fontSize: 3, cellPadding: 2, ...rest });
};

export default convertJson2Pdf;
