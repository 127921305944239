import gql from 'graphql-tag';
import { parseToMutationRequest } from '../../../utils/utils';

export default data => {
  const dataModify = parseToMutationRequest(data, ['subscriptionCategory', 'serviceType', 'index']);
  const modifyConfigMultiRating = `modifyConfigMultiRating(input: ${dataModify})`;
  return gql`
  mutation {
    ${modifyConfigMultiRating} {
      id
    }
  }
`;
};
