import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import SearchComponent from '../../PricingHub/SearchComponent';

class TopupSearchForm extends SearchComponent {
  searchInputs = [
    {
      label: 'label.startDate',
      name: 'startDate',
      type: 'date',
    },
    {
      label: 'label.endDate',
      name: 'endDate',
      type: 'date',
    },
    {
      name: 'accountId',
      label: 'common:label.accountId',
    },
    {
      name: 'userId',
      label: 'common:label.userId',
    },

    {
      name: 'subscriptionId',
      label: 'common:label.subscriptionId',
    },
  ];

  btnSpan = 'col-md-9';
}

TopupSearchForm.propTypes = {
  onSubmit: PropTypes.func,
  isSubmitting: PropTypes.bool,
};

TopupSearchForm.defaultProps = {
  isSubmitting: false,
};

export default withTranslation('common')(TopupSearchForm);
