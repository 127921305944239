import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { GenericInput, DataTable } from '../../common';
import { supportShowNumberValue } from '../../../utils/utils';

export default function TableCollectionSchedule({ data, onHandleChangeTable, onRemove }) {
  const { t } = useTranslation('common');
  const tableColumns = [
    {
      name: 'offsetdays',
      label: t('label.offsetDays'),
      style: { minWidth: '200px' },
      required: true,
      render: (colName, item, index) => (
        <GenericInput
          value={supportShowNumberValue(item.offsetdays)}
          name="offsetdays"
          type="number"
          onChange={({ name, value }) => {
            onHandleChangeTable(index, name, value);
          }}
          wrapperClass="col-md-12"
          required
          isSupportDefaultValue
        />
      ),
    },
    {
      name: 'action',
      label: t('label.action'),
      style: { minWidth: '200px' },
      required: true,
      render: (colName, item, index) => (
        <GenericInput
          value={item.action || null}
          name="action"
          type="select"
          options={t('selections:collectionAction')()}
          onChange={({ name, value }) => {
            onHandleChangeTable(index, name, value);
          }}
          wrapperClass="col-md-12"
          menuPortalTarget
          required
          isSupportDefaultValue
        />
      ),
    },
    {
      name: 'actionWorkingDay',
      label: t('label.workingDay'),
      style: { minWidth: '200px' },
      render: (colName, item, index) => (
        <GenericInput
          value={item.actionWorkingDay || null}
          name="actionWorkingDay"
          type="select"
          options={t('selections:collectionActionWorkingDay')()}
          onChange={({ name, value }) => {
            onHandleChangeTable(index, name, value);
          }}
          wrapperClass="col-md-12"
          menuPortalTarget
        />
      ),
    },
    {
      name: 'description',
      label: t('label.description'),
      style: { minWidth: '200px' },
      render: (colName, item, index) => (
        <GenericInput
          value={item.description}
          name="description"
          type="textarea"
          onChange={({ name, value }) => {
            onHandleChangeTable(index, name, value);
          }}
          wrapperClass="col-md-12"
        />
      ),
    },
    {
      name: 'remove',
      label: 'common:label.remove',
      render: (colName, item) => {
        return (
          <button type="button" className="btn-trash" onClick={() => onRemove(item)}>
            <i className="fa fa-trash" />
          </button>
        );
      },
    },
  ];
  return <DataTable columns={tableColumns} data={data || []} isSupportRemoveIndex />;
}

TableCollectionSchedule.propTypes = {
  data: PropTypes.arrayOf(PropTypes.any),
  onHandleChangeTable: PropTypes.func,
  onRemove: PropTypes.func.isRequired,
};

TableCollectionSchedule.defaultProps = {
  data: [],
  onHandleChangeTable: () => {},
};
