import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, union, cloneDeep, sortBy } from 'lodash';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import PageTitle from '../../../components/PageTitle';
import { messageRoleBilling, getPageTotalCount } from '../../../utils/utils';
import {
  selectAccountId,
  getAccountDetail as getAccountParent,
  getFlexAttributesMappingConfig,
  getCcpDateTimeConfig,
  modifyAccountServiceLocations,
  modifyAccountService,
  getObjectFileById,
  getBillableServicePricing,
  getCurrencyConfigApp,
  getInvoiceUnitByIdSelect,
  getCcpPropertiesConfig,
  getPaymentConfigByTypeApp,
  getCostCenterConfig,
  getConfigOperatorByServiceType,
  getTaxCodeConfigSelect,
  getTaxTypeConfigSelect,
  readFileFromS3,
  uploadMultiPartFiles,
  getPACProductCodesConfig,
} from '../../App/actions';
import AccountSort from '../../../constantsApp/customer/AccountSort';
import Footer from '../../../components/Footer';
import RouteNames from '../../App/RouteNames';
import constant from '../constants';
import SpecialPricing from '../SpecialPricing';
import Sidebar from '../Sidebar';
import CustomerAttributePage from '../CustomerAttribute';
import CreditProfilePage from '../CreditProfile';
import CustomerIndexPage from '../CustomerIndex';
import ManualBilling from '../ManualBilling';
import { UsageTransactionDetail } from '../../ReportManagement/UsageReports';
import { ItemServices } from '../../ReportManagement/SubscriptionReports';
import { checkPermissionForAllPages } from '../../ReportManagement/CheckPermission';
import { checkPermissionForAllPages as allPermissionsTasks } from '../../TaskManagement/CheckPermission';
import { createBillingAccounts } from '../../OrderPage/actions';
import SearchTaskList from '../../TaskManagement/SearchTaskList';

import {
  InfoForm,
  MultiContactForm,
  MultiAddressForm,
  PaymentProfile,
  BillingProfile,
  ServicesForm,
  Bills,
  Hierarchy,
  TransactionsPage,
  ArActivity,
  ActivitiesPage,
  CustomerAttribute,
  AssetsForm,
  BalancesForm,
  ExternalPO,
  AccountStatement,
  CreditNotes,
  ProvisioningData,
  Payments,
  XChangeRates,
  PaymentInstallments,
  RatedUsage,
  LadingBill,
  Topup,
  Attributes,
  InvoicePaymentInstallment,
  TaxExemptions,
  ChargeShare,
  Tasks,
  TreeView,
  AROpsUnit,
} from '../../../components/CustomerHub';
import {
  makeAccountDetail,
  makeGetServiceUnitsAc,
  makeGetSubscription,
  makeGetPriceUnits,
  makeGetBalanceUnit,
  makeGetPendingBills,
  makeGetOCBills,
} from '../selectors';
import {
  getAccountDetail,
  modifyAccount,
  updateAccountStatus,
  getPendingBill,
  getOCBill,
  getAccountInformation,
  runPendingBill,
  getSubscription,
  getPriceUnits,
  getServiceUnitAc,
  sendInvoice,
  regenerateInvoice,
  modifyInvoiceStatus,
  getAccountStatement,
  undoManualInvoice,
  searchOrderInComplete,
  getAccountAssets,
  updateOrderCustomerStatus,
  getOrderSummaryByAccountId,
  getCustomAttributesByObjectType,
  modifyInvoiceDueDate,
  invoiceAccount,
  getInvoiceInstallmentsByInvoiceId,
  createPaymentInstallment,
  modifyPaymentInstallment,
  resendInvoiceVendor,
  undoInvoiceCancel,
  reprocessInvoice,
  getOrderHistory,
  searchLadingBill,
  getPacInterfaceRecordById,
  saveAccountNonBillables,
  moveAccount,
  getInvoiceTransactionSummary,
  getInvoiceTaxSummary,
  syncProjectApp,
  saveManualInvoice,
  resendPaymentVendor,
  resendNotesVendor,
  getClientAccountStatement,
  getUsedCfdiByTaxRegion,
  getSplitTaxPerLine,
  searchAccountAssets,
  searchSubscriptionByAccountId,
  subscriptionTopUp,
  searchBalanceUnitByAccountId,
  getProvisioningByAccountId,
  getMultiInvPymtInstallmentsByAccountId,
  setupMultiInvoicePaymentInstallment,
  generatePaymentInstallmentReport,
  getUnallocatedPayment,
  searchSubscriptionTopUp,
} from '../actions';
import {
  makeGetPermissionsCustomerManagement,
  makeGetPermissionsCustomerManagementSubscription,
  makeGetPermissionsCustomerManagementActivity,
  makeGetPermissionsBilling,
  makeGetPermissionsInvoice,
  makeGetPermissionsRating,
  makeGetPermissionsOrderManagement,
  makeGetCurrencyConfig,
  makeGetPermissionsReports,
  makeGetCcpPropertiesConfig,
  makeGetCcpTime,
  makeGetPermissionsCollectionManagement,
  makeGetS3Config,
  makeGetPermissionsPaymentManagement,
  makeGetPermissionsArOpsManagement,
  makeGetPermissionsPricingManagement,
} from '../../App/selectors';
import {
  checkPermissionSearchAccount,
  checkPermissionModifyCustomer,
  checkPermissionModifyContact,
  checkPermissionGetAddress,
  checkPermissionModifyAddress,
  checkPermissionModifyPhone,
  checkPermissionAddPhone,
  checkPermissionDeletePhone,
  checkPermissionDeleteContact,
  checkPermissionAddContact,
  checkPermissionAddAddress,
  checkPermissionDeleteAddress,
  checkPermissionGetPayment,
  checkPermissionModifyPayment,
  checkPermissionModifyCreditCard,
  checkPermissionAddCreditCard,
  checkPermissionDeleteCreditCard,
  checkPermissionGetAccount,
  checkPermissionGetContact,
  checkPermissionGetBillingProfile,
  checkPermissionModifyBillingProfile,
  checkPermissionSuspendStatus,
  checkPermissionResumeStatus,
  checkPermissionCloseStatus,
  checkPermissionGetSubscription,
  checkPermissionGetServiceUnit,
  checkPermissionGetPriceUnit,
  checkPermissionSearchBills,
  checkPermissionSearchInvoice,
  checkPermissionGetInvoice,
  checkPermissionSearchTransactions,
  checkPermissionGetTransactions,
  checkPermissionGetBalance,
  checkPermissionSearchActivity,
  checkPermissionGetActivity,
  checkPermissionSetCreditLimit,
  checkPermissionSendInvoice,
  checkPermissionRegenerateInvoice,
  checkPermissionViewInvoceTemplate,
  checkPermissionApproveInvoice,
  checkPermissionCreateExternalPO,
  checkPermissionModifyExternalPO,
  checkPermissionGetExternalPO,
  checkPermissionCreateCustomAttributes,
  checkPermissionGetCustomAttributes,
  checkPermissionModifyCustomAttributes,
  checkPermissionResyncInvoice,
  checkPermissionUndoManualInvoice,
  checkPermissionGetAccountExchangeRates,
  checkPermissionModifyAccountExchangeRates,
  checkPermissionCreateAccountExchangeRates,
  checkPermissionGenerateInvoice,
  checkPermissionCreatePaymentInstallment,
  checkPermissionModifyPaymentInstallment,
  checkPermissionGetPaymentInstallment,
  checkPermissionSearchLandingBill,
  checkPermissionCreateCustomer,
  checkPermissionViewTransactionDetails,
  checkPermissionGetAccountStatement,
  checkPermissionTopupBalance,
} from '../CheckPermission';
import { checkPermissionSearchPayment } from '../../PaymentsPage/CheckPermission';
import { checkPermissionSearchArOpsUnit, checkPermissionCreditNoteApproval } from '../../ArOperations/CheckPermission';
import {
  checkPermissionUpdateOrder,
  checkPermissionCancelOrder,
  checkPermissionSubmitOrder,
  checkPermissionApproveOrder,
} from '../../OrderPage/CheckPermission';

import { checkPermissionSearchRatedUsage } from '../../BaseConfigurations/CheckPermission';

let isHasTopup = false;

class CustomerDetails extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      accountDetail: {},
      accountInformation: {},
      childrenAcct: [],
      parentAccount: { contacts: [{ roles: [] }], addresses: [{ roles: [] }] },
      isParentAccount: false,
      wasValidated: false,
      isExistCardToken: false,
      sizeChilAccount: 20,
      pageChildAccount: 0,
      totalChildAccount: null,
      isSearchingChildAccount: false,
      defaulFilter: {},
      currencyOptions: [],
    };
  }

  componentDidMount() {
    const {
      match: {
        params: { id },
      },
      getCurrencyConfigApp,
      currencyConfig,
      ccpPropertyList,
    } = this.props;
    const { currencyOptions } = this.state;
    this.doGetAccountDetails(id);

    if ((!currencyConfig || !currencyConfig.id) && (!currencyOptions || !currencyOptions.length)) {
      getCurrencyConfigApp('', ({ success, data }) => {
        if (success && data && data.currencyListConfig) {
          const currencyOptions = data.currencyListConfig.map(val => ({
            normalLabel: val.name,
            label: `${val.name} (${val.currencyId})`,
            value: val.currencyId,
          }));
          this.setState({ currencyOptions: sortBy(currencyOptions, ['label']) });
        }
      });
    } else {
      const currencyOptions = currencyConfig.currencyListConfig.map(val => ({
        normalLabel: val.name,
        label: `${val.name} (${val.currencyId})`,
        value: val.currencyId,
      }));

      this.setState({ currencyOptions: sortBy(currencyOptions, ['label']) });
    }
    if (!ccpPropertyList || !ccpPropertyList.length) getCcpPropertiesConfig();
  }

  componentWillReceiveProps(nextProps) {
    const {
      accountDetail,

      match: {
        params: { id },
      },
      location,
    } = this.props;

    if (location && location.state && location.state.filter) {
      this.setState({ defaulFilter: location.state.filter });
    }
    if (accountDetail !== nextProps.accountDetail && nextProps.accountDetail.id) {
      const { accountDetail } = nextProps;
      const isExistCardToken = isEmpty(accountDetail.paymentProfiles[0].creditCards);
      // this.doGetParentAndChildAccount(nextProps.accountDetail.parentId, accountDetail.id);
      this.setState({ accountDetail, isExistCardToken });
    }
    if (id !== nextProps.match.params.id) this.doGetAccountDetails(nextProps.match.params.id);
  }

  doGetParentAndChildAccount = data => {
    const { getAccountParent, selectAccountId, accountDetail } = this.props;
    const { id, isMoveOutHierachy, isOnSubmit } = data || {};
    if ((accountDetail.parentId || id) && !isMoveOutHierachy) {
      getAccountParent(id || accountDetail.parentId, ({ data }) => {
        if (data) {
          if (id) this.doGetAccountDetails(accountDetail.id);
          this.setState({ parentAccount: data, childrenAcct: [], isParentAccount: true });
        }
      });
    } else {
      this.doSearchSearchChildAccount(isOnSubmit);
      this.setState({ isParentAccount: false });
      // selectAccountId({ page: 1, size: 10, filter: { parentId: accountDetail.id } }, ({ data }) => {
      //   this.doGetAccountDetails(accountDetail.id);
      //   this.setState({ childrenAcct: data || [], parentAccount: {}, isParentAccount: false });
      // });
    }
  };

  doGetAccountDetails = id => {
    const { getAccountDetail, getAccountInformation } = this.props;
    getAccountDetail(id, ({ success, data }) => {
      if (success) {
        // this.doGetPendingOCBills({ id, billingProfileId: data.billingProfiles.id });
      }
    });
  };

  doGetAccountInfomation = () => {
    const {
      match: {
        params: { id },
      },
      getAccountInformation,
    } = this.props;
    getAccountInformation(id, ({ success, data }) => {
      if (success) {
        this.setState({ accountInformation: data });
      }
    });
  };

  doGetPendingOCBills = ({ id, billingProfileId }) => {
    this.doGetOCBill({ id, billingProfileId });
    this.doGetPendingBill({ id, billingProfileId });
  };

  doGetPendingBill = ({ id, billingProfileId }) => {
    const { getPendingBill } = this.props;
    const filterPendingBills = {
      accountId: id,
      status: 'PENDING',
      billingProfileId: billingProfileId || 'PRIMARY',
    };

    getPendingBill({
      page: 1,
      size: 10,
      filter: filterPendingBills,
    });
  };

  doGetOCBill = ({ id, billingProfileId }) => {
    const { getOCBill } = this.props;
    const filterOCBills = {
      accountId: id,
      billingProfileId: billingProfileId || 'PRIMARY',
    };
    getOCBill({
      page: 1,
      size: 10,
      filter: filterOCBills,
    });
  };

  getAccountDetail = () => {
    const {
      match: {
        params: { id },
      },
      getAccountDetail,
    } = this.props;
    getAccountDetail(id);
  };

  onGetItemNameFromChildRoute = childRoute => {
    const { t } = this.props;
    switch (childRoute) {
      case 'info':
        return t('sidebarHandleAccountPage.accountInfo');
      case 'contacts':
        return t('sidebarHandleAccountPage.contact');
      case 'addresses':
        return t('sidebarHandleAccountPage.addresses');
      case 'payment-profile':
        return t('sidebarHandleAccountPage.paymentProfile');
      case 'bill-profile':
        return t('sidebarHandleAccountPage.billingProfile');
      case 'custom-attributes':
        return t('sidebarHandleAccountPage.customAttributes');
      case 'services':
        return t('sidebarHandleAccountPage.services');
      case 'offers':
        return t('sidebarHandleAccountPage.offers');
      case 'balances':
        return t('sidebarHandleAccountPage.balances');
      case 'bills':
        return t('sidebarHandleAccountPage.bills');
      case 'transactions':
        return t('sidebarHandleAccountPage.transactions');
      case 'ar-activity':
        return t('sidebarHandleAccountPage.ARActivity');
      case 'activities':
        return t('sidebarHandleAccountPage.customerActivity');
      case 'hierarchy':
        return t('sidebarHandleAccountPage.hierarchy');
      case 'credit-profile':
        return t('sidebarSearchPage.config.subTitle.creditProfile');
      case 'custom-attributes-config':
        return t('sidebarSearchPage.config.subTitle.customAttributes');
      case 'manual-billing':
        return t('common:label.manualBilling');
      case 'special-pricing':
        return t('customerPage:sidebarSearchPage.config.subTitle.specialPricing');
      case 'provisioning-data':
        return t('sidebarHandleAccountPage.provisioningData');
      case 'payment-details':
        return t('sidebarHandleAccountPage.payments');
      case 'x-change-rates':
        return t('sidebarHandleAccountPage.xChangeRates');
      case 'payment-installment':
        return t('sidebarHandleAccountPage.paymentInstallment');
      case 'usage-records':
        return t('common:label.usageData');
      case 'lading-bill':
        return t('sidebarHandleAccountPage.ladingBill');
      case 'billable-services':
        return t('reportPage:sidebar.subscriptionBillableServices');
      case 'account-statement':
        return t('sidebarHandleAccountPage.accountStatement');
      case 'tasks':
        return t('taskPage:sidebar.tasks');
      case 'subscription-view':
        return t('sidebarHandleAccountPage.subscriptionView');

      default:
        return t('sidebarHandleAccountPage.accountInfo');
    }
  };

  doUpdateListRole = (roles, index, key) => {
    const { accountDetail } = this.state;
    const newData = cloneDeep(accountDetail);
    newData[key][index].roles = roles;
    this.setState({ accountDetail: newData });
  };

  onHandleSearchChildAccount = filter => {
    this.setState({ filter, pageChildAccount: 0 }, () => this.doSearchSearchChildAccount());
  };

  onPageChangeChildAccount = page => {
    const { pageChildAccount } = this.state;
    if (pageChildAccount === page) return '';
    this.setState({ pageChildAccount: page }, () => this.doSearchSearchChildAccount());
  };

  onSizeChangeChildAccount = size => {
    this.setState({ sizeChilAccount: size, pageChildAccount: 0 }, () => this.doSearchSearchChildAccount());
  };

  onSortChildAccount = (sortCol, sortDir) => {
    this.setState({ sorted: { sortCol, sortDir } }, () => this.doSearchSearchChildAccount());
  };

  doSearchSearchChildAccount = isNeedRefershThisAccount => {
    const { filter, pageChildAccount, sizeChilAccount, sorted, accountDetail, totalChildAccount } = this.state;
    const { selectAccountId } = this.props;
    const payload = {
      page: pageChildAccount + 1,
      size: sizeChilAccount,
      filter: { parentId: accountDetail.id, ...filter },
      sort: !isEmpty(sorted) ? AccountSort[sorted.sortCol][sorted.sortDir] : null,
    };
    this.setState({ isSearchingChildAccount: true });
    selectAccountId(payload, ({ success, data }) => {
      if (success) {
        if (isNeedRefershThisAccount) this.doGetAccountDetails(accountDetail.id);
        this.setState({
          childrenAcct: data,
          parentAccount: {},
          totalChildAccount: getPageTotalCount({
            item: data,
            size: sizeChilAccount,
            page: pageChildAccount,
            totalCount: totalChildAccount,
          }),
        });
      }
      this.setState({ isSearchingChildAccount: false, parentAccount: {}, isParentAccount: false });
    });
  };

  renderContent = ({
    modeModifyCustomer,
    modeModifyContact,
    modeModifyAddress,
    modeAddAddress,
    modeModifyPhone,
    modeAddPhone,
    modeDeletePhone,
    modeDeleteContact,
    modeAddContact,
    modeDeleteAddress,
    modeGetPayment,
    modeModfifyPayment,
    modeModfifyCreditCard,
    modeAddCreditCard,
    modeDeleteCreditCard,
    modeGetContact,
    modeGetAddress,
    modeGetBillingProfile,
    modeModifyBillingProfile,
    modeSuspendStatus,
    modeResumeStatus,
    modeCloseStatus,
    modeGetSubscription,
    modeGetServiceUnit,
    modeGetPriceUnit,
    modeSearchBills,
    modeSearchInvoice,
    modeGetInvoice,
    modeSearchTransactions,
    modeGetTransactions,
    modeGetBalance,
    modeGetActivity,
    modeSearchActivity,
    modeSendInvoice,
    modeRegenerateInvoice,
    modeViewInvoceTemplate,
    modeApproveInvoice,
    modeCreateExternalPO,
    modeModifyExternalPO,
    modeGetExternalPO,
    modeModifyCustomAttributes,
    modeCreateCustomAttributes,
    modeGetCustomAttributes,
    modeUpdateOrder,
    modeCancelOrder,
    modeSubmitOrder,
    modeApproveOrder,
    modeResyncInvoice,
    modeUndoManualInvoice,
    modeModifyAccountExchangeRates,
    modeCreateAccountExchangeRates,
    modeGetAccountExchangeRates,
    modeGenerateInvoice,
    modeModifyPaymentInstallment,
    modeCreatePaymentInstallment,
    modeGetPaymentInstallment,
    modeSearchLandingBill,
    permissionsTasks,
    modeSearchPayment,
    modeGetCreditNoteReport,
    modeViewTransactionDetails,
    modeCreditNoteApproval,
    modeGetAccountStatement,
    modeSearchRatedUsage,
    modeSearchArOpsUnit,
    modeTopupBalance,
    modeGetSubscriptionTopupReport,
  }) => {
    const {
      match: {
        params: { childRoute, id },
      },
      history,
      location,
      subscription,
      serviceUnitsAc,
      priceUnits,
      balanceUnit,
      pendingBills,
      modifyAccount,
      ocBills,
      updateAccountStatus,
      t,
      runPendingBill,
      getOCBill,
      getSubscription,
      getPriceUnits,
      getBalanceUnit,
      getServiceUnitAc,
      sendInvoice,
      regenerateInvoice,
      modifyInvoiceStatus,
      getAccountStatement,
      undoManualInvoice,
      searchOrderInComplete,
      getAccountAssets,
      updateOrderCustomerStatus,
      getOrderSummaryByAccountId,
      getFlexAttributesMappingConfig,
      getCcpDateTimeConfig,
      modifyAccountServiceLocations,
      getCustomAttributesByObjectType,
      modifyInvoiceDueDate,
      getObjectFileById,
      modifyAccountService,
      getBillableServicePricing,
      invoiceAccount,
      getInvoiceInstallmentsByInvoiceId,
      createPaymentInstallment,
      modifyPaymentInstallment,
      resendInvoiceVendor,
      undoInvoiceCancel,
      getInvoiceUnitByIdSelect,
      reprocessInvoice,
      getOrderHistory,
      getCcpPropertiesConfig,
      searchLadingBill,
      getPaymentConfigByTypeApp,
      permissionsReports,
      getPacInterfaceRecordById,
      selectAccountId,
      createBillingAccounts,
      saveAccountNonBillables,
      moveAccount,
      getInvoiceTransactionSummary,
      getInvoiceTaxSummary,
      syncProjectApp,
      saveManualInvoice,
      getCostCenterConfig,
      resendPaymentVendor,
      resendNotesVendor,
      getConfigOperatorByServiceType,
      getClientAccountStatement,
      getUsedCfdiByTaxRegion,
      getSplitTaxPerLine,
      searchAccountAssets,
      ccpPropertyList,
      searchSubscriptionByAccountId,
      subscriptionTopUp,
      searchBalanceUnitByAccountId,
      getProvisioningByAccountId,
      ccpTime,
      getMultiInvPymtInstallmentsByAccountId,
      setupMultiInvoicePaymentInstallment,
      getTaxCodeConfigSelect,
      getTaxTypeConfigSelect,
      readFileFromS3,
      uploadMultiPartFiles,
      getPACProductCodesConfig,
      s3Config,
      generatePaymentInstallmentReport,
      getUnallocatedPayment,
      searchSubscriptionTopUp,
    } = this.props;
    let permissions = {};
    if (permissionsReports && permissionsReports.reportModulePermissions) {
      const listPermission = permissionsReports.reportModulePermissions;
      permissions = checkPermissionForAllPages({ listPermission });
    }
    const {
      accountDetail,
      childrenAcct,
      parentAccount,
      isParentAccount,
      wasValidated,
      isExistCardToken,
      accountInformation,
      isSearchingChildAccount,
      totalChildAccount,
      pageChildAccount,
      sizeChilAccount,
      currencyOptions,
      keyRefreshSerives,
      filter,
      sorted,
    } = this.state;
    if (isEmpty(accountDetail)) return '';
    let dataHierarchy = [];
    let titleHierarchy = '';
    let isChildAccount = false;
    if (!isParentAccount) {
      dataHierarchy = childrenAcct || [];
      isChildAccount = true;
      titleHierarchy = t('common:label.childAccount');
    }

    if (isParentAccount) {
      dataHierarchy = [parentAccount];
      isChildAccount = false;
      titleHierarchy = t('common:label.parentAccount');
    }
    // const allStatuSubScription = subscription.map(item => item.status);
    const isDisabledEdit = isEmpty(accountDetail.contacts[0]);
    const dataContact = isDisabledEdit ? parentAccount.contacts : accountDetail.contacts;
    const dataAddresses = isDisabledEdit ? parentAccount.addresses : accountDetail.addresses;
    let isNonPaying = false;
    if (accountDetail.paymentProfiles && accountDetail.paymentProfiles.length) {
      isNonPaying = accountDetail.paymentProfiles[0].paymentMethod === 'NON_PAYING';
    }

    const messageRoleAddress = messageRoleBilling({
      roles: union(
        Array.prototype.concat.apply(
          [],
          accountDetail.addresses.map(item => item.roles)
        )
      ),
    });

    switch (childRoute) {
      case 'info':
        return (
          <InfoForm
            id={id}
            dataField={constant.accountInfo.accountInfoForm(currencyOptions)}
            data={accountDetail}
            accountInformation={accountInformation}
            wasValidated={wasValidated}
            isDisableSubmit={modeModifyCustomer !== 2}
            modeSuspendStatus={modeSuspendStatus}
            modeResumeStatus={modeResumeStatus}
            modeCloseStatus={modeCloseStatus}
            modifyAccount={modifyAccount}
            updateAccountStatus={updateAccountStatus}
            isDisableCreateOrder={subscription && subscription.length > 0}
            doGetAccountInfomation={this.doGetAccountInfomation}
            selectAccountId={selectAccountId}
            createBillingAccounts={createBillingAccounts}
            doGetAccountDetails={() => this.doGetAccountDetails(id)}
            syncProjectApp={syncProjectApp}
            currencyOptions={currencyOptions}
            customerInfo={accountDetail}
            getCcpDateTimeConfig={getCcpDateTimeConfig}
            getSubscription={getSubscription}
          />
        );

      case 'contacts':
        if (modeGetContact === 0 || modeModifyContact === 0) return '';
        return (
          <MultiContactForm
            id={id}
            dataField={constant.accountContract.accountContactForm}
            dataFieldNew={constant.accountContract.accountNewContactDetailsForm}
            checkRoleGroup={constant.checkRoleGroup}
            modifyAccount={modifyAccount}
            isDisableSubmit={modeModifyCustomer !== 2 || modeModifyContact !== 2}
            modeModifyPhone={modeModifyPhone}
            modeAddPhone={modeAddPhone}
            modeDeletePhone={modeDeletePhone}
            modeDeleteContact={modeDeleteContact}
            modeAddContact={modeAddContact}
            isDisabled={isDisabledEdit}
            data={dataContact}
            doGetAccountDetails={() => this.doGetAccountDetails(id)}
            currencyOptions={currencyOptions}
            customerInfo={accountDetail}
          />
        );

      case 'addresses':
        if (modeGetAddress === 0 || modeModifyAddress === 0) return '';
        return (
          <MultiAddressForm
            id={id}
            isDisabled={isDisabledEdit}
            modifyAccount={modifyAccount}
            isDisableSubmit={modeModifyCustomer !== 2 || modeModifyAddress !== 2}
            modeAddAddress={modeAddAddress}
            modeDeleteAddress={modeDeleteAddress}
            checkRoleGroup={constant.checkRoleGroup}
            data={dataAddresses}
            messageRole={messageRoleAddress}
            doGetAccountDetails={() => this.doGetAccountDetails(id)}
            currencyOptions={currencyOptions}
            customerInfo={accountDetail}
            getCcpPropertiesConfig={getCcpPropertiesConfig}
          />
        );
      case 'payment-profile':
        if (modeGetPayment === 0 || modeModfifyPayment === 0) return '';
        return (
          <PaymentProfile
            id={id}
            accountDetail={accountDetail.paymentProfiles[0]}
            data={accountDetail.paymentProfiles[0]}
            isDisabled={isNonPaying}
            history={history}
            isDisableSubmit={modeModifyCustomer !== 2}
            modeModfifyPayment={modeModfifyPayment}
            modeModfifyCreditCard={modeModfifyCreditCard}
            modeAddCreditCard={modeAddCreditCard}
            modeDeleteCreditCard={modeDeleteCreditCard}
            modifyAccount={modifyAccount}
            isExistCardToken={isExistCardToken}
            getPaymentConfigByTypeApp={getPaymentConfigByTypeApp}
            accountType={accountDetail?.type || ''}
            doGetAccountDetails={() => this.doGetAccountDetails(id)}
            currencyOptions={currencyOptions}
            customerInfo={accountDetail}
          />
        );
      case 'bill-profile':
        if (modeGetBillingProfile === 0 || modeModifyBillingProfile === 0) return '';
        return (
          <BillingProfile
            id={id}
            modifyAccount={modifyAccount}
            isDisableSubmit={modeModifyBillingProfile !== 2 || modeModifyCustomer !== 2}
            dataField={constant.accountBillingProfile.accountBillingProfileForm}
            data={accountDetail.billingProfiles[0]}
            isNonPaying={isNonPaying}
            doGetAccountDetails={() => this.doGetAccountDetails(id)}
            currencyOptions={currencyOptions}
            customerInfo={accountDetail}
            ccpPropertyList={ccpPropertyList}
          />
        );
      case 'services':
        return (
          <ServicesForm
            id={id}
            dataFieldSubscription={constant.accountServices.subsciptionTable}
            dataSubsciption={subscription.length ? subscription : []}
            // isOrtherBtn={allStatuSubScription.indexOf('ACTIVE') !== -1}
            dataFieldService={constant.accountServices.serviceUnitTable}
            isDisableSubmit={modeModifyCustomer !== 2}
            modeGetSubscription={modeGetSubscription}
            getSubscription={getSubscription}
            modeGetServiceUnit={modeGetServiceUnit}
            dataService={serviceUnitsAc}
            searchOrderInComplete={searchOrderInComplete}
            isDisableNewOrder={!isEmpty(subscription) && subscription.includes('ACTIVE')}
            // isCreateBtn={
            //   isEmpty(subscription) ||
            //   (allStatuSubScription.indexOf('CLOSED') > -1 &&
            //     allStatuSubScription.indexOf('ACTIVE') === -1 &&
            //     allStatuSubScription.indexOf('SUSPENDED') === -1)
            // }
            // isResumeBtn={
            //   !isEmpty(subscription) &&
            //   allStatuSubScription.indexOf('SUSPENDED') !== -1 &&
            //   allStatuSubScription.indexOf('ACTIVE') === -1
            // }
            getServiceUnitAc={getServiceUnitAc}
            modeUpdateOrder={modeUpdateOrder}
            modeCancelOrder={modeCancelOrder}
            modeSubmitOrder={modeSubmitOrder}
            updateOrderCustomerStatus={updateOrderCustomerStatus}
            getFlexAttributesMappingConfig={getFlexAttributesMappingConfig}
            getCcpDateTimeConfig={getCcpDateTimeConfig}
            currencyOptions={currencyOptions}
            customerInfo={accountDetail}
            keyRefreshSerives={keyRefreshSerives}
          />
        );

      case 'subscription-view':
        return (
          <TreeView
            id={id}
            dataFieldSubscription={constant.accountServices.subsciptionTable}
            dataSubsciption={subscription.length ? subscription : []}
            dataFieldService={constant.accountServices.serviceUnitTable}
            isDisableSubmit={modeModifyCustomer !== 2}
            modeGetSubscription={modeGetSubscription}
            getSubscription={getSubscription}
            modeGetServiceUnit={modeGetServiceUnit}
            dataService={serviceUnitsAc}
            isDisableNewOrder={!isEmpty(subscription) && subscription.includes('ACTIVE')}
            getServiceUnitAc={getServiceUnitAc}
            getFlexAttributesMappingConfig={getFlexAttributesMappingConfig}
            customerInfo={accountDetail}
            searchAccountAssets={searchAccountAssets}
            offersData={priceUnits}
            columnsPriceUnit={constant.accountAssets.assetsTable}
          />
        );

      case 'offers':
        if (modeGetPriceUnit === 0) return '';
        return (
          <AssetsForm
            accountNum={id}
            id={id}
            t={t}
            columns={constant.accountAssets.assetsTable}
            data={priceUnits}
            // getPriceUnits={getPriceUnits}
            getOrderSummaryByAccountId={getOrderSummaryByAccountId}
            // getAccountAssets={getAccountAssets}
            modifyAccountServiceLocations={modifyAccountServiceLocations}
            modifyAccountService={modifyAccountService}
            getCustomAttributesByObjectType={getCustomAttributesByObjectType}
            getBillableServicePricing={getBillableServicePricing}
            currencyOptions={currencyOptions}
            getOrderHistory={getOrderHistory}
            saveAccountNonBillables={saveAccountNonBillables}
            getCcpDateTimeConfig={getCcpDateTimeConfig}
            customerInfo={accountDetail}
            searchAccountAssets={searchAccountAssets}
            getCcpPropertiesConfig={getCcpPropertiesConfig}
            ccpPropertyList={ccpPropertyList}
            ccpTime={ccpTime}
          />
        );
      case 'balances':
        if (modeGetBalance === 0) return '';
        return (
          <BalancesForm
            grantTable={constant.accountBalances.grantTable}
            accumulatorsTable={constant.accountBalances.accumulatorsTable}
            balancesTable={constant.accountBalances.balancesTable}
            balanceUnit={balanceUnit}
            id={id}
            getServiceUnitAc={getServiceUnitAc}
            currencyOptions={currencyOptions}
            customerInfo={accountDetail}
            searchBalanceUnitByAccountId={searchBalanceUnitByAccountId}
            getUnallocatedPayment={getUnallocatedPayment}
          />
        );
      case 'topup':
        return isHasTopup ? (
          <Topup
            id={id}
            currencyOptions={currencyOptions}
            customerInfo={accountDetail}
            searchSubscriptionByAccountId={searchSubscriptionByAccountId}
            subscriptionTopUp={subscriptionTopUp}
            history={history}
            searchSubscriptionTopUp={searchSubscriptionTopUp}
            modeTopupBalance={modeTopupBalance}
            modeGetSubscriptionTopupReport={modeGetSubscriptionTopupReport}
          />
        ) : null;
      case 'bills':
        if (modeSearchBills === 0) return '';
        return (
          <Bills
            id={id}
            dataPendingBills={pendingBills}
            modeSearchInvoice={modeSearchInvoice}
            dataBillInvoice={ocBills && ocBills.billInvoiceData ? ocBills.billInvoiceData : []}
            modeGetInvoice={modeGetInvoice}
            runPendingBill={runPendingBill}
            getOCBill={getOCBill}
            billingProfileId={accountDetail.billingProfiles.id}
            sendInvoice={sendInvoice}
            regenerateInvoice={regenerateInvoice}
            modifyInvoiceStatus={modifyInvoiceStatus}
            dataContact={dataContact}
            modeSendInvoice={modeSendInvoice}
            modeRegenerateInvoice={modeRegenerateInvoice}
            modeViewInvoceTemplate={modeViewInvoceTemplate}
            modeApproveInvoice={modeApproveInvoice}
            undoManualInvoice={undoManualInvoice}
            modeApproveOrder={modeApproveOrder}
            modeResyncInvoice={modeResyncInvoice}
            modeUndoManualInvoice={modeUndoManualInvoice}
            modifyInvoiceDueDate={modifyInvoiceDueDate}
            getObjectFileById={getObjectFileById}
            history={history}
            location={location}
            invoiceAccount={invoiceAccount}
            modeGenerateInvoice={modeGenerateInvoice}
            getInvoiceInstallmentsByInvoiceId={getInvoiceInstallmentsByInvoiceId}
            doGetPendingOCBills={() =>
              this.doGetPendingOCBills({ id, billingProfileId: accountDetail.billingProfiles.id })
            }
            createPaymentInstallment={createPaymentInstallment}
            modifyPaymentInstallment={modifyPaymentInstallment}
            resendInvoiceVendor={resendInvoiceVendor}
            undoInvoiceCancel={undoInvoiceCancel}
            getInvoiceUnitByIdSelect={getInvoiceUnitByIdSelect}
            reprocessInvoice={reprocessInvoice}
            getCcpPropertiesConfig={getCcpPropertiesConfig}
            getPacInterfaceRecordById={getPacInterfaceRecordById}
            getInvoiceTransactionSummary={getInvoiceTransactionSummary}
            getInvoiceTaxSummary={getInvoiceTaxSummary}
            saveManualInvoice={saveManualInvoice}
            currencyOptions={currencyOptions}
            getCostCenterConfig={getCostCenterConfig}
            getOrderSummaryByAccountId={getOrderSummaryByAccountId}
            customerInfo={accountDetail}
            getSplitTaxPerLine={getSplitTaxPerLine}
            ccpPropertyList={ccpPropertyList}
            modeGetActivity={modeGetActivity}
            modeSearchArOpsUnit={modeSearchArOpsUnit}
            modeGetPaymentInstallment={modeGetPaymentInstallment}
            modeModifyPaymentInstallment={modeModifyPaymentInstallment}
            modeCreatePaymentInstallment={modeCreatePaymentInstallment}
          />
        );
      case 'transactions':
        if (modeSearchTransactions === 0 || modeGetTransactions === 0) return '';
        return (
          <TransactionsPage
            id={id}
            currencyOptions={currencyOptions}
            customerInfo={accountDetail}
            modeViewTransactionDetails={modeViewTransactionDetails}
          />
        );
      case 'rated-usage':
        return modeSearchRatedUsage ? (
          <RatedUsage id={id} currencyOptions={currencyOptions} customerInfo={accountDetail} />
        ) : null;
      case 'ar-ops-unit':
        return (
          <AROpsUnit
            id={id}
            currencyOptions={currencyOptions}
            customerInfo={accountDetail}
            history={history}
            location={location}
            modeSearchArOpsUnit={modeSearchArOpsUnit}
          />
        );

      case 'ar-activity':
        return modeGetActivity === 0 || modeSearchActivity === 0 ? (
          ''
        ) : (
          <ArActivity
            id={id}
            currencyOptions={currencyOptions}
            customerInfo={accountDetail}
            history={history}
            location={location}
            modeSearchArOpsUnit={modeSearchArOpsUnit}
          />
        );
      case 'activities':
        return modeGetActivity === 0 || modeSearchActivity === 0 ? (
          ''
        ) : (
          <ActivitiesPage
            title="Customer Activity"
            id={id}
            currencyOptions={currencyOptions}
            customerInfo={accountDetail}
          />
        );
      case 'account-statement':
        return modeGetAccountStatement ? (
          <AccountStatement
            id={id}
            getAccountStatement={getAccountStatement}
            currencyOptions={currencyOptions}
            customerInfo={accountDetail}
            getClientAccountStatement={getClientAccountStatement}
            getObjectFileById={getObjectFileById}
            history={history}
            location={location}
          />
        ) : null;
      case 'hierarchy':
        return (
          <Hierarchy
            title={titleHierarchy}
            id={id}
            data={dataHierarchy}
            onHandleSearchChildAccount={this.onHandleSearchChildAccount}
            isSearchingChildAccount={isSearchingChildAccount}
            isChildAccount={isChildAccount}
            page={pageChildAccount}
            size={sizeChilAccount}
            onPageChange={this.onPageChangeChildAccount}
            onSizeChange={this.onSizeChangeChildAccount}
            totalCount={totalChildAccount}
            doGetParentAndChildAccount={this.doGetParentAndChildAccount}
            getPaymentConfigByTypeApp={getPaymentConfigByTypeApp}
            moveAccount={moveAccount}
            paymentProfiles={accountDetail.paymentProfiles[0]}
            getCcpDateTimeConfig={getCcpDateTimeConfig}
            currencyOptions={currencyOptions}
            customerInfo={accountDetail}
            searchChildAccount={selectAccountId}
            defaultFilter={filter}
            onSortChildAccount={this.onSortChildAccount}
            sorted={sorted}
            ccpTime={ccpTime}
          />
        );

      case 'tasks':
        return (
          <Tasks id={id} currencyOptions={currencyOptions} customerInfo={accountDetail}>
            <SearchTaskList
              id={id}
              permissions={permissionsTasks}
              isFromCustomer
              accountId={id}
              accountType={accountDetail.type}
            />
          </Tasks>
        );
      case 'custom-attributes':
        return (
          <CustomerAttribute
            account={accountDetail}
            modifyAccount={modifyAccount}
            getAccountDetail={this.getAccountDetail}
            accountNum={id}
            wasValidated={wasValidated}
            modeModifyCustomAttributes={modeModifyCustomAttributes}
            modeCreateCustomAttributes={modeCreateCustomAttributes}
            modeGetCustomAttributes={modeGetCustomAttributes}
            getConfigOperatorByServiceType={getConfigOperatorByServiceType}
            currencyOptions={currencyOptions}
            customerInfo={accountDetail}
            getUsedCfdiByTaxRegion={getUsedCfdiByTaxRegion}
          />
        );
      case 'custom-attributes-config':
        return (
          <CustomerAttributePage isComponent id={id} currencyOptions={currencyOptions} customerInfo={accountDetail} />
        );
      case 'credit-profile':
        return <CreditProfilePage isComponent id={id} currencyOptions={currencyOptions} customerInfo={accountDetail} />;

      case 'search-accounts':
        return <CustomerIndexPage isComponent />;

      case 'manual-billing':
        return (
          <ManualBilling
            id={id}
            history={history}
            doGetPendingOCBills={() =>
              this.doGetPendingOCBills({ id, billingProfileId: accountDetail.billingProfiles.id })
            }
            getCcpDateTimeConfig={getCcpDateTimeConfig}
            currencyOptions={currencyOptions}
            currencyAccount={accountDetail ? accountDetail.currency : ''}
            customerInfo={accountDetail}
            ccpTime={ccpTime}
          />
        );
      case 'external-po':
        return (
          <ExternalPO
            id={id}
            modeCreateExternalPO={modeCreateExternalPO}
            modeModifyExternalPO={modeModifyExternalPO}
            modeGetExternalPO={modeGetExternalPO}
            currencyOptions={currencyOptions}
            customerInfo={accountDetail}
          />
        );
      case 'credit-notes':
        return (
          <CreditNotes
            id={id}
            getObjectFileById={getObjectFileById}
            history={history}
            getPacInterfaceRecordById={getPacInterfaceRecordById}
            resendNotesVendor={resendNotesVendor}
            currencyOptions={currencyOptions}
            customerInfo={accountDetail}
            modeGetCreditNoteReport={modeGetCreditNoteReport}
            modeCreditNoteApproval={modeCreditNoteApproval}
          />
        );

      case 'special-pricing':
        return <SpecialPricing isComponent id={id} currencyOptions={currencyOptions} customerInfo={accountDetail} />;

      case 'provisioning-data':
        return (
          <ProvisioningData
            id={id}
            currencyOptions={currencyOptions}
            customerInfo={accountDetail}
            getFlexAttributesMappingConfig={getFlexAttributesMappingConfig}
          />
        );

      case 'payment-details':
        return (
          <Payments
            id={id}
            getPacInterfaceRecordById={getPacInterfaceRecordById}
            resendPaymentVendor={resendPaymentVendor}
            currencyOptions={currencyOptions}
            customerInfo={accountDetail}
            history={history}
            location={location}
            modeSearchPayment={modeSearchPayment}
            getCcpPropertiesConfig={getCcpPropertiesConfig}
            ccpPropertyList={ccpPropertyList}
          />
        );

      case 'x-change-rates':
        return (
          <XChangeRates
            modeModifyAccountExchangeRates={modeModifyAccountExchangeRates}
            modeCreateAccountExchangeRates={modeCreateAccountExchangeRates}
            modeGetAccountExchangeRates={modeGetAccountExchangeRates}
            id={id}
            currencyAccount={accountDetail ? accountDetail.currency : ''}
            currencyOptions={currencyOptions}
            customerInfo={accountDetail}
          />
        );

      case 'payment-installment':
        return (
          <PaymentInstallments
            modeModifyPaymentInstallment={modeModifyPaymentInstallment}
            modeCreatePaymentInstallment={modeCreatePaymentInstallment}
            modeGetPaymentInstallment={modeGetPaymentInstallment}
            id={id}
            currencyAccount={accountDetail ? accountDetail.currency : ''}
            currencyOptions={currencyOptions}
            customerInfo={accountDetail}
          />
        );

      case 'usage-records':
        return (
          <UsageTransactionDetail
            id={id}
            currencyOptions={currencyOptions}
            customerInfo={accountDetail}
            isRenderHeaderForm
          />
        );

      case 'lading-bill':
        return (
          <LadingBill
            id={id}
            getObjectFileById={getObjectFileById}
            searchLadingBill={searchLadingBill}
            modeSearchLandingBill={modeSearchLandingBill}
            currencyOptions={currencyOptions}
            customerInfo={accountDetail}
          />
        );

      case 'billable-services':
        return (
          <ItemServices
            isFromCustomer
            id={id}
            permissions={permissions}
            accountDetail={accountDetail}
            dataContact={dataContact}
            currencyOptions={currencyOptions}
            customerInfo={accountDetail}
            isRenderHeaderForm
          />
        );

      case 'attributes':
        return (
          <Attributes
            id={id}
            getProvisioningByAccountId={getProvisioningByAccountId}
            currencyOptions={currencyOptions}
            customerInfo={accountDetail}
          />
        );

      case 'invoice-payment-installment':
        return (
          <InvoicePaymentInstallment
            isComponent
            id={id}
            currencyOptions={currencyOptions}
            customerInfo={accountDetail}
            getMultiInvPymtInstallmentsByAccountId={getMultiInvPymtInstallmentsByAccountId}
            setupMultiInvoicePaymentInstallment={setupMultiInvoicePaymentInstallment}
            ccpTime={ccpTime}
            getCcpDateTimeConfig={getCcpDateTimeConfig}
            generatePaymentInstallmentReport={generatePaymentInstallmentReport}
          />
        );

      case 'tax-exemptions':
        return (
          <TaxExemptions
            id={id}
            currencyOptions={currencyOptions}
            customerInfo={accountDetail}
            data={accountDetail.taxExemptions}
            ccpTime={ccpTime}
            getCcpDateTimeConfig={getCcpDateTimeConfig}
            modifyAccount={modifyAccount}
            getTaxCodeConfigSelect={getTaxCodeConfigSelect}
            getTaxTypeConfigSelect={getTaxTypeConfigSelect}
            readFileFromS3={readFileFromS3}
            uploadMultiPartFiles={uploadMultiPartFiles}
            getAccountDetail={this.getAccountDetail}
            getPACProductCodesConfig={getPACProductCodesConfig}
            s3Config={s3Config}
            ccpPropertyList={ccpPropertyList}
          />
        );

      case 'charge-share':
        return (
          <ChargeShare
            isComponent
            id={id}
            currencyOptions={currencyOptions}
            customerInfo={accountDetail}
            getMultiInvPymtInstallmentsByAccountId={getMultiInvPymtInstallmentsByAccountId}
            setupMultiInvoicePaymentInstallment={setupMultiInvoicePaymentInstallment}
            ccpTime={ccpTime}
            getCcpDateTimeConfig={getCcpDateTimeConfig}
            getSubscription={getSubscription}
          />
        );

      default:
        return 'info';
    }
  };

  render() {
    const {
      match: {
        params: { id, childRoute },
      },
      location,
      permissionsCustomer,
      permissionsCustomerSubscription,
      permissionsBilling,
      permissionsInvoice,
      permissionsRating,
      permissionsActivity,
      permissionOrder,
      permissionsReports,
      permissionsCollections,
      permissionsPayment,
      t,
      ccpPropertyList,
      permissionsArOps,
      permissionsPricing,
    } = this.props;
    let permissions = {};
    let permissionsTasks = {};

    if (permissionsReports && permissionsReports.reportModulePermissions) {
      const listPermission = permissionsReports.reportModulePermissions;
      permissions = checkPermissionForAllPages({ listPermission });
    }
    let modeGetAccount = 0;
    let modeModifyCustomer = 0;
    let modeSearchAccount = 0;
    let modeCreateCustomer = 0;

    // Mode contact - Begin //
    let modeModifyContact = 0;
    let modeGetContact = 0;
    let modeAddContact = 0;
    let modeDeleteContact = 0;
    let modeModifyPhone = 0;
    let modeAddPhone = 0;
    let modeDeletePhone = 0;
    // Mode contact - End //

    // Mode address - Begin //
    let modeGetAddress = 0;
    let modeModifyAddress = 0;
    let modeAddAddress = 0;
    let modeDeleteAddress = 0;
    // Mode address - End //

    // Mode payment - Begin //
    let modeGetPayment = 0;
    let modeModfifyPayment = 0;
    let modeModfifyCreditCard = 0;
    let modeAddCreditCard = 0;
    let modeDeleteCreditCard = 0;
    // Mode payment - End //

    // Mode billing - Begin //
    let modeGetBillingProfile = 0;
    let modeModifyBillingProfile = 0;
    // Mode billing - End //

    // Mode billing - Begin //
    let modeSuspendStatus = 0;
    let modeResumeStatus = 0;
    let modeCloseStatus = 0;
    // Mode billing - End //

    // Mode subscription - Begin //
    let modeGetSubscription = 0;
    let modeGetServiceUnit = 0;
    let modeGetPriceUnit = 0;
    // Mode subscription - End //

    // Mode Activity - Begin //
    let modeSearchActivity = 0;
    let modeGetActivity = 0;
    // Mode Activity - End //

    // Mode billing - Begin //
    let modeSearchBills = 0;
    let modeSearchInvoice = 0;
    let modeGetInvoice = 0;
    let modeSearchTransactions = 0;
    let modeGetTransactions = 0;
    let modeGetBalance = 0;
    let modeSendInvoice = 0;
    let modeRegenerateInvoice = 0;
    let modeViewInvoceTemplate = 0;
    let modeApproveInvoice = 0;
    let modeResyncInvoice = 0;
    let modeUndoManualInvoice = 0;

    // Mode billing - End //
    let modeSetCreditProfile = 0;
    let modeCreateExternalPO = 0;
    let modeModifyExternalPO = 0;
    let modeGetExternalPO = 0;
    let modeModifyCustomAttributes = 0;
    let modeCreateCustomAttributes = 0;
    let modeGetCustomAttributes = 0;

    // Mode Order - start //
    let modeUpdateOrder = 0;
    let modeCancelOrder = 0;
    let modeSubmitOrder = 0;
    let modeApproveOrder = 0;
    // Mode Order - End //

    let modeModifyAccountExchangeRates = 0;
    let modeCreateAccountExchangeRates = 0;
    let modeGetAccountExchangeRates = 0;
    let modeGenerateInvoice = 0;

    let modeModifyPaymentInstallment = 0;
    let modeCreatePaymentInstallment = 0;
    let modeGetPaymentInstallment = 0;
    let modeSearchLandingBill = 0;

    let modeSearchPayment = 0;
    let modeViewTransactionDetails = 0;
    let modeSearchArOpsUnit = 0;
    let modeCreditNoteApproval = 0;
    let modeGetAccountStatement = 0;
    let modeSearchRatedUsage = 0;
    let modeTopupBalance = 0;

    const { modeGetCreditNoteReport } = permissions;

    const isBillableServiceEnabled =
      ccpPropertyList &&
      ccpPropertyList.length &&
      ccpPropertyList.find(ccp => ccp.property === 'billableServiceEnabled') &&
      ccpPropertyList.find(ccp => ccp.property === 'billableServiceEnabled').value === 'false';

    if (permissionsCustomer && permissionsCustomer.customerModulePermissions) {
      const listPermission = permissionsCustomer.customerModulePermissions;
      modeSearchAccount = checkPermissionSearchAccount({ listPermission });
      modeModifyCustomer = checkPermissionModifyCustomer({ listPermission });
      modeCreateCustomer = checkPermissionCreateCustomer({ listPermission });
      modeGetContact = checkPermissionGetContact({ listPermission });
      modeModifyContact = checkPermissionModifyContact({ listPermission });
      modeGetAddress = checkPermissionGetAddress({ listPermission });
      modeModifyAddress = checkPermissionModifyAddress({ listPermission });
      modeAddAddress = checkPermissionAddAddress({ listPermission });
      modeDeleteAddress = checkPermissionDeleteAddress({ listPermission });
      modeModifyPhone = checkPermissionModifyPhone({ listPermission });
      modeAddPhone = checkPermissionAddPhone({ listPermission });
      modeDeletePhone = checkPermissionDeletePhone({ listPermission });
      modeDeleteContact = checkPermissionDeleteContact({ listPermission });
      modeAddContact = checkPermissionAddContact({ listPermission });
      modeModfifyPayment = checkPermissionModifyPayment({ listPermission });
      modeGetPayment = checkPermissionGetPayment({ listPermission });
      modeModfifyCreditCard = checkPermissionModifyCreditCard({ listPermission });
      modeAddCreditCard = checkPermissionAddCreditCard({ listPermission });
      modeDeleteCreditCard = checkPermissionDeleteCreditCard({ listPermission });
      modeGetAccount = checkPermissionGetAccount({ listPermission });
      modeGetBillingProfile = checkPermissionGetBillingProfile({ listPermission });
      modeModifyBillingProfile = checkPermissionModifyBillingProfile({ listPermission });
      modeSuspendStatus = checkPermissionSuspendStatus({ listPermission });
      modeResumeStatus = checkPermissionResumeStatus({ listPermission });
      modeCloseStatus = checkPermissionCloseStatus({ listPermission });
      modeCreateExternalPO = checkPermissionCreateExternalPO({ listPermission });
      modeModifyExternalPO = checkPermissionModifyExternalPO({ listPermission });
      modeGetExternalPO = checkPermissionGetExternalPO({ listPermission });
      modeCreateCustomAttributes = checkPermissionCreateCustomAttributes({ listPermission });
      modeGetCustomAttributes = checkPermissionGetCustomAttributes({ listPermission });
      modeModifyCustomAttributes = checkPermissionModifyCustomAttributes({ listPermission });
      modeModifyAccountExchangeRates = checkPermissionModifyAccountExchangeRates({ listPermission });
      modeCreateAccountExchangeRates = checkPermissionCreateAccountExchangeRates({ listPermission });
      modeGetAccountExchangeRates = checkPermissionGetAccountExchangeRates({ listPermission });

      modeModifyPaymentInstallment = checkPermissionModifyPaymentInstallment({ listPermission });
      modeCreatePaymentInstallment = checkPermissionCreatePaymentInstallment({ listPermission });
      modeGetPaymentInstallment = checkPermissionGetPaymentInstallment({ listPermission });
      modeGetAccountStatement = checkPermissionGetAccountStatement({ listPermission });
      permissionsTasks = allPermissionsTasks({ listPermission });
    }

    if (permissionsCustomerSubscription && permissionsCustomerSubscription.subscriptionModulePermissions) {
      const listPermissionSubscription = permissionsCustomerSubscription.subscriptionModulePermissions;
      modeGetSubscription = checkPermissionGetSubscription({ listPermission: listPermissionSubscription });
      modeGetServiceUnit = checkPermissionGetServiceUnit({ listPermission: listPermissionSubscription });
      modeGetPriceUnit = checkPermissionGetPriceUnit({ listPermission: listPermissionSubscription });
    }

    if (permissionsActivity && permissionsActivity.activityModulePermissions) {
      const listPermissionActivity = permissionsActivity.activityModulePermissions;
      modeSearchActivity = checkPermissionSearchActivity({ listPermission: listPermissionActivity });
      modeGetActivity = checkPermissionGetActivity({ listPermission: listPermissionActivity });
    }

    if (permissionsBilling && permissionsBilling.billingModulePermissions) {
      const listPermissionBills = permissionsBilling.billingModulePermissions;
      modeSearchBills = checkPermissionSearchBills({ listPermission: listPermissionBills });
      modeSearchLandingBill = checkPermissionSearchLandingBill({ listPermission: listPermissionBills });
    }

    if (permissionsInvoice && permissionsInvoice.invoicingModulePermissions) {
      const listPermissionInvoice = permissionsInvoice.invoicingModulePermissions;
      modeSearchInvoice = checkPermissionSearchInvoice({ listPermission: listPermissionInvoice });
      modeGetInvoice = checkPermissionGetInvoice({ listPermission: listPermissionInvoice });
      modeSendInvoice = checkPermissionSendInvoice({ listPermission: listPermissionInvoice });
      modeRegenerateInvoice = checkPermissionRegenerateInvoice({ listPermission: listPermissionInvoice });
      modeViewInvoceTemplate = checkPermissionViewInvoceTemplate({ listPermission: listPermissionInvoice });
      modeApproveInvoice = checkPermissionApproveInvoice({ listPermission: listPermissionInvoice });
      modeResyncInvoice = checkPermissionResyncInvoice({ listPermission: listPermissionInvoice });
      modeUndoManualInvoice = checkPermissionUndoManualInvoice({ listPermission: listPermissionInvoice });
      modeGenerateInvoice = checkPermissionGenerateInvoice({ listPermission: listPermissionInvoice });
    }

    if (permissionsRating && permissionsRating.ratingModulePermissions) {
      const listPermissionRating = permissionsRating.ratingModulePermissions;
      modeSearchTransactions = checkPermissionSearchTransactions({ listPermission: listPermissionRating });
      modeGetTransactions = checkPermissionGetTransactions({ listPermission: listPermissionRating });
      modeGetBalance = checkPermissionGetBalance({ listPermission: listPermissionRating });
      modeViewTransactionDetails = checkPermissionViewTransactionDetails({ listPermission: listPermissionRating });
      modeTopupBalance = checkPermissionTopupBalance({ listPermission: listPermissionRating });
    }

    if (permissionsPayment && permissionsPayment.paymentModulePermissions) {
      const listPermission = permissionsPayment.paymentModulePermissions;
      modeSearchPayment = checkPermissionSearchPayment({ listPermission });
    }

    if (permissionsArOps && permissionsArOps.paymentModulePermissions) {
      const listPermission = permissionsArOps.paymentModulePermissions;
      modeCreditNoteApproval = checkPermissionCreditNoteApproval({ listPermission });
    }

    if (permissionsArOps && permissionsArOps.arOpsModulePermissions) {
      const listPermission = permissionsArOps.arOpsModulePermissions;
      modeSearchArOpsUnit = checkPermissionSearchArOpsUnit({ listPermission });
    }

    if (permissionsPricing && permissionsPricing.pricingModulePermissions) {
      const listPermission = permissionsPricing.pricingModulePermissions;
      modeSearchRatedUsage = checkPermissionSearchRatedUsage({ listPermission });
    }

    const { defaulFilter, accountDetail } = this.state;

    const topupChildRoute = [];
    const assetsChildRoute = [];
    const tasksChildRoute = [];
    const paymentRoute = [];
    const creditNoteRoute = [];
    const arChildRoute = [];
    const creditNoteAccStatementRoute = [];
    const ratedUsageChild = [];
    const paymentInstallmentRoute = [];
    if (modeSearchPayment) {
      paymentRoute.push({
        icon: 'fa fa-credit-card-alt',
        label: t('sidebarHandleAccountPage.payments'),
        href: RouteNames.prettifyPath(RouteNames.customerDetailsPayments.path, id),
      });
    }
    if (
      accountDetail?.accountCategory &&
      (accountDetail?.accountCategory === 'PREPAID' || accountDetail?.accountCategory === 'HYBRID')
    ) {
      isHasTopup = true;
      topupChildRoute.push({
        icon: 'fa fa-sign-in',
        label: t('sidebarHandleAccountPage.topup'),
        href: RouteNames.prettifyPath(RouteNames.customerTopup.path, id),
      });
    }
    if (modeGetPriceUnit) {
      assetsChildRoute.push({
        icon: 'fa fa-plus-square-o',
        label: t('sidebarHandleAccountPage.offers'),
        href: RouteNames.prettifyPath(RouteNames.customerDetailsAssets.path, id),
      });
    }
    if (permissions && !!permissions.modeGetBillableService && isBillableServiceEnabled) {
      assetsChildRoute.push({
        icon: 'fa fa-id-card-o',
        label: t('reportPage:sidebar.subscriptionBillableServices'),
        href: RouteNames.prettifyPath(RouteNames.customerDetailsBillableServices.path, id),
      });
    }
    if (modeGetCreditNoteReport) {
      creditNoteAccStatementRoute.push({
        icon: 'ti-credit-card',
        label: t('sidebarHandleAccountPage.creditNotes'),
        href: RouteNames.prettifyPath(RouteNames.customerDetailsCreditNotes.path, id),
      });
    }
    if (modeGetAccountStatement) {
      creditNoteAccStatementRoute.push({
        icon: 'fa fa-user',
        label: t('sidebarHandleAccountPage.accountStatement'),
        href: RouteNames.prettifyPath(RouteNames.accountStatement.path, id),
      });
    }

    if (modeSearchRatedUsage) {
      ratedUsageChild.push({
        icon: 'ti-mouse',
        label: t('sidebarHandleAccountPage.ratedUsage'),
        href: RouteNames.prettifyPath(RouteNames.ratedUsage.path, id),
      });
    }

    if (modeSearchActivity || modeGetActivity) {
      arChildRoute.push({
        icon: 'fa fa-th-list',
        label: t('common:label.arRequestLog'),
        href: RouteNames.prettifyPath(RouteNames.customerDetailsArActivity.path, id),
      });
    }
    if (modeSearchArOpsUnit) {
      arChildRoute.push({
        icon: 'ti-layers-alt',
        label: t('common:label.arOpsUnits'),
        href: RouteNames.prettifyPath(RouteNames.customerDetailsArOpsUnit.path, id),
      });
    }

    if (modeGetPaymentInstallment) {
      paymentInstallmentRoute.push({
        icon: 'fa fa-cc-mastercard',
        label: t('sidebarHandleAccountPage.paymentInstallment'),
        href: RouteNames.prettifyPath(RouteNames.paymentInstallment.path, id),
      });
    }

    tasksChildRoute.push({
      icon: 'ti-menu-alt',
      label: t('taskPage:sidebar.tasks'),
      href: RouteNames.prettifyPath(RouteNames.customerDetailsTasks.path, id),
    });

    const sidePropDetails = {
      isExpand: false,
      items: [
        {
          isHaveExpand: true,
          mainTilte: 'customerPage:sidebarSearchPage.config.title',
          isExpandChild: true,
          childs: [
            {
              icon: 'fa fa-list',
              label: 'customerPage:sidebarSearchPage.config.subTitle.customAttributes',
              href: RouteNames.prettifyPath(RouteNames.customerAttributeDetails.path, id),
            },
            {
              icon: 'ti-credit-card',
              label: 'customerPage:sidebarSearchPage.config.subTitle.creditProfile',
              href: RouteNames.prettifyPath(RouteNames.creditProfileDetails.path, id),
            },
          ],
        },
        {
          isHaveExpand: true,
          mainTilte: 'customerPage:sidebarSearchPage.account.searchAccounts',
          href: RouteNames.prettifyPath(RouteNames.searchAccountInAccountDetails.path, id),
          childs: [],
          icon: 'fa fa-search',
        },
        {
          isHaveExpand: true,
          mainTilte: 'customerPage:sidebarSearchPage.account.accountData',
          isExpandChild: true,
          childs: [
            {
              icon: 'ti-info-alt',
              label: t('sidebarHandleAccountPage.accountInfo'),
              href: RouteNames.prettifyPath(RouteNames.customerDetailsInfo.path, id),
            },
            {
              icon: 'ti-mobile',
              label: t('sidebarHandleAccountPage.contact'),
              href: RouteNames.prettifyPath(RouteNames.customerDetailsContacts.path, id),
            },
            {
              icon: 'ti-map-alt',
              label: t('sidebarHandleAccountPage.addresses'),
              href: RouteNames.prettifyPath(RouteNames.customerDetailsAddresses.path, id),
            },
            {
              icon: 'ti-credit-card',
              label: t('sidebarHandleAccountPage.paymentProfile'),
              href: RouteNames.prettifyPath(RouteNames.customerDetailsPaymentProfiles.path, id),
            },
            {
              icon: 'ti-printer',
              label: t('sidebarHandleAccountPage.billingProfile'),
              href: RouteNames.prettifyPath(RouteNames.customerDetailsBillingProfile.path, id),
            },
            {
              icon: 'ti-bookmark-alt',
              label: t('sidebarHandleAccountPage.customAttributes'),
              href: RouteNames.prettifyPath(RouteNames.customerDetailsCustomAttributes.path, id),
            },
            {
              icon: 'ti-bookmark-alt',
              label: t('sidebarHandleAccountPage.taxExemptions'),
              href: RouteNames.prettifyPath(RouteNames.customerDetailsTaxExemptions.path, id),
            },
            {
              icon: 'fa fa-align-center',
              label: t('sidebarHandleAccountPage.hierarchy'),
              href: RouteNames.prettifyPath(RouteNames.customerDetailsHierarchy.path, id),
            },
            ...tasksChildRoute,
            ...paymentInstallmentRoute,
            {
              icon: 'fa fa-money',
              label: t('sidebarHandleAccountPage.xChangeRates'),
              href: RouteNames.prettifyPath(RouteNames.customerXChangeRates.path, id),
            },
            {
              icon: 'fa fa-sign-out',
              label: t('sidebarHandleAccountPage.externalPO'),
              href: RouteNames.prettifyPath(RouteNames.customerExternalPO.path, id),
            },
            {
              icon: 'fa fa-user-circle-o',
              label: t('sidebarHandleAccountPage.customerActivity'),
              href: RouteNames.prettifyPath(RouteNames.customerDetailsActivities.path, id),
            },
          ],
        },
        {
          isHaveExpand: true,
          isExpandChild: true,
          mainTilte: 'customerPage:sidebarSearchPage.account.subscriptionData',
          isHiddenChild: true,
          isHiddenItems: true,
          subMain: [
            {
              subMainTitle: t('sidebarHandleAccountPage.assets'),
              key: 'assets',
              isHiddenItems: true,
              childs: [
                {
                  label: t('sidebarHandleAccountPage.subscriptionView'),
                  icon: 'ti-map',
                  href: RouteNames.prettifyPath(RouteNames.subscriptionView.path, id),
                },
                {
                  icon: 'fa fa-server',
                  label: t('sidebarHandleAccountPage.services'),
                  href: RouteNames.prettifyPath(RouteNames.customerDetailsServices.path, id),
                },
                ...assetsChildRoute,
                // {
                //   icon: 'ti-map-alt',
                //   label: t('sidebarHandleAccountPage.offers'),
                //   href: RouteNames.prettifyPath(RouteNames.customerDetailsAssets.path, id),
                // },
                // {
                //   icon: 'ti-map',
                //   label: t('reportPage:sidebar.subscriptionBillableServices'),
                //   href: RouteNames.prettifyPath(RouteNames.customerDetailsBillableServices.path, id),
                // },
              ],
            },
            {
              subMainTitle: t('common:label.provisioning'),
              key: 'provisioning',
              isHiddenItems: true,
              childs: [
                {
                  icon: 'ti-layers-alt',
                  label: t('common:label.data'),
                  href: RouteNames.prettifyPath(RouteNames.customerProvisioningData.path, id),
                },
                {
                  icon: 'fa fa-list',
                  label: t('common:label.attributes'),
                  href: RouteNames.prettifyPath(RouteNames.customerAttributes.path, id),
                },
              ],
            },
            {
              subMainTitle: t('sidebarHandleAccountPage.others'),
              key: 'others',
              isHiddenItems: true,
              childs: [
                {
                  icon: 'fa fa-user-secret',
                  label: t('customerPage:sidebarSearchPage.config.subTitle.specialPricing'),
                  href: RouteNames.prettifyPath(RouteNames.specialPricing.path, id),
                },
              ],
            },
          ],
        },
        // {
        //   isHaveExpand: true,
        //   mainTilte: 'customerPage:sidebarSearchPage.account.subscriptionData',
        //   isExpandChild: true,
        //   childs: [
        //     {
        //       icon: 'ti-server',
        //       label: t('sidebarHandleAccountPage.services'),
        //       href: RouteNames.prettifyPath(RouteNames.customerDetailsServices.path, id),
        //     },
        //     {
        //       icon: 'ti-layers-alt',
        //       label: t('sidebarHandleAccountPage.assets'),
        //       href: RouteNames.prettifyPath(RouteNames.customerDetailsAssets.path, id),
        //     },
        //     {
        //       icon: 'ti-layers-alt',
        //       label: t('reportPage:sidebar.subscriptionBillableServices'),
        //       href: RouteNames.prettifyPath(RouteNames.customerDetailsBillableServices.path, id),
        //     },
        //     {
        //       icon: 'ti-layers-alt',
        //       label: t('sidebarHandleAccountPage.provisioningData'),
        //       href: RouteNames.prettifyPath(RouteNames.customerProvisioningData.path, id),
        //     },
        //     {
        //       icon: 'ti-money',
        //       label: 'customerPage:sidebarSearchPage.config.subTitle.specialPricing',
        //       href: RouteNames.prettifyPath(RouteNames.specialPricing.path, id),
        //     },
        //   ],
        // },
        {
          isHaveExpand: true,
          mainTilte: 'customerPage:sidebarSearchPage.account.billingData',
          isExpandChild: false,
          childs: [
            {
              icon: 'fa fa-balance-scale',
              label: t('sidebarHandleAccountPage.balances'),
              href: RouteNames.prettifyPath(RouteNames.customerDetailsBalances.path, id),
            },
            ...topupChildRoute,
            {
              icon: 'fa fa-sticky-note-o',
              label: t('sidebarHandleAccountPage.bills'),
              href: RouteNames.prettifyPath(RouteNames.customerDetailsBills.path, id),
            },
            {
              icon: 'fa fa-book',
              label: t('sidebarHandleAccountPage.ladingBill'),
              href: RouteNames.prettifyPath(RouteNames.ladingBill.path, id),
            },
            {
              icon: 'ti-gallery',
              label: t('sidebarHandleAccountPage.transactions'),
              href: RouteNames.prettifyPath(RouteNames.customerDetailsTransactions.path, id),
            },
            ...ratedUsageChild,
            {
              icon: 'ti-control-record',
              label: t('sidebarHandleAccountPage.usageRecords'),
              href: RouteNames.prettifyPath(RouteNames.usageRecords.path, id),
            },
            ...arChildRoute,
            ...paymentRoute,
            {
              icon: 'fa fa-book',
              label: t('sidebarHandleAccountPage.invoicePaymentInstallment'),
              href: RouteNames.prettifyPath(RouteNames.customerDetailsInvoicePaymentInstallment.path, id),
            },
            ...creditNoteAccStatementRoute,
          ],
        },
        {
          isHaveExpand: true,
          mainTilte: t('sidebarHandleAccountPage.sharing'),
          isExpandChild: false,
          childs: [
            {
              icon: 'fa fa-share-square-o',
              label: t('sidebarHandleAccountPage.chargeShare'),
              href: RouteNames.prettifyPath(RouteNames.chargeShare.path, id),
            },
            {
              icon: 'fa fa-share-alt-square',
              label: t('sidebarHandleAccountPage.discountShare'),
              href: RouteNames.prettifyPath(RouteNames.discountShare.path, id),
              disable: true,
            },
          ],
        },
      ],
    };

    const indexItemBilling = sidePropDetails.items.findIndex(
      val => val.mainTilte === 'customerPage:sidebarSearchPage.account.billingData'
    );

    const indexItemAccountData = sidePropDetails.items.findIndex(
      val => val.mainTilte === 'customerPage:sidebarSearchPage.account.accountData'
    );
    // const indexItemSubscriptionData = sidePropDetails.items.findIndex(
    //   val => val.mainTilte === 'customerPage:sidebarSearchPage.account.subscriptionData'
    // );

    if (modeSearchAccount === 0 || modeModifyCustomer === 0 || modeGetAccount === 0) return '';

    if (modeGetContact === 0) {
      sidePropDetails.items[indexItemAccountData || 0].childs = sidePropDetails.items[
        indexItemAccountData || 0
      ].childs.filter(item => item.label !== 'Contact');
    }

    if (modeGetAddress === 0) {
      sidePropDetails.items[indexItemAccountData || 0].childs = sidePropDetails.items[
        indexItemAccountData || 0
      ].childs.filter(item => item.label !== 'Addresses');
    }

    if (modeGetPayment === 0) {
      sidePropDetails.items[indexItemAccountData || 0].childs = sidePropDetails.items[
        indexItemAccountData || 0
      ].childs.filter(item => item.label !== t('sidebarHandleAccountPage.paymentProfile'));
    }

    if (modeGetBillingProfile === 0) {
      sidePropDetails.items[indexItemAccountData || 0].childs = sidePropDetails.items[
        indexItemAccountData || 0
      ].childs.filter(
        // item => item.label !== 'Billing Profile'
        item => item.label !== t('sidebarHandleAccountPage.billingProfile')
      );
    }

    // if (modeGetPriceUnit === 0) {
    //   sidePropDetails.items[indexItemSubscriptionData || 0].childs = sidePropDetails.items[
    //     indexItemSubscriptionData || 0
    //   ].childs.filter(item => item.label !== t('sidebarHandleAccountPage.assets'));
    // }

    // if (!permissions || !permissions.modeGetBillableService) {
    //   sidePropDetails.items[indexItemSubscriptionData || 0].childs = sidePropDetails.items[
    //     indexItemSubscriptionData || 0
    //   ].childs.filter(item => item.label !== t('reportPage:sidebar.subscriptionBillableServices'));
    // }

    if (modeSearchBills === 0) {
      sidePropDetails.items[indexItemBilling || 0].childs = sidePropDetails.items[indexItemBilling || 0].childs.filter(
        item => item.label !== t('sidebarHandleAccountPage.bills')
      );
    }
    if (modeSearchLandingBill === 0) {
      sidePropDetails.items[indexItemBilling || 0].childs = sidePropDetails.items[indexItemBilling || 0].childs.filter(
        item => item.label !== t('sidebarHandleAccountPage.ladingBill')
      );
    }

    if (modeSearchTransactions === 0 || modeGetTransactions === 0) {
      sidePropDetails.items[indexItemBilling || 0].childs = sidePropDetails.items[indexItemBilling || 0].childs.filter(
        item => item.label !== t('sidebarHandleAccountPage.transactions')
      );
    }

    if (modeGetBalance === 0) {
      sidePropDetails.items[indexItemBilling || 0].childs = sidePropDetails.items[indexItemBilling || 0].childs.filter(
        item => item.label !== t('sidebarHandleAccountPage.balances')
      );
    }
    if (modeGetExternalPO === 0) {
      sidePropDetails.items[indexItemAccountData || 0].childs = sidePropDetails.items[
        indexItemAccountData || 0
      ].childs.filter(item => item.label !== t('sidebarHandleAccountPage.externalPO'));
    }

    if (modeGetAccountExchangeRates === 0) {
      sidePropDetails.items[indexItemAccountData || 0].childs = sidePropDetails.items[
        indexItemAccountData || 0
      ].childs.filter(item => item.label !== t('sidebarHandleAccountPage.xChangeRates'));
    }

    if (modeGetActivity === 0 || modeSearchActivity === 0) {
      sidePropDetails.items[indexItemAccountData || 0].childs = sidePropDetails.items[
        indexItemAccountData || 0
      ].childs.filter(item => item.label !== 'AR Activity');
      sidePropDetails.items[indexItemAccountData || 0].childs = sidePropDetails.items[
        indexItemAccountData || 0
      ].childs.filter(item => item.label !== t('sidebarHandleAccountPage.customerActivity'));
    }

    if (permissionsRating && permissionsRating.ratingModulePermissions) {
      const listPermissionRating = permissionsRating.ratingModulePermissions;
      modeSetCreditProfile = checkPermissionSetCreditLimit({ listPermission: listPermissionRating });
    }

    if (permissionOrder && permissionOrder.orderModulePermissions) {
      const listPermission = permissionOrder.orderModulePermissions;
      modeUpdateOrder = checkPermissionUpdateOrder({ listPermission });
      modeCancelOrder = checkPermissionCancelOrder({ listPermission });
      modeSubmitOrder = checkPermissionSubmitOrder({ listPermission });
      modeApproveOrder = checkPermissionApproveOrder({ listPermission });
    }
    const newSideProps = cloneDeep(constant.sidePropIndex);

    if (modeSetCreditProfile === 0) {
      newSideProps.items[0].childs = newSideProps.items[0].childs.filter(
        item => item.label !== 'customerPage:sidebarSearchPage.config.subTitle.creditProfile'
      );
    }

    let linkTo = { pathname: RouteNames.customers.path, state: { filter: defaulFilter } };
    if (childRoute === 'manual-billing')
      linkTo = {
        pathname: RouteNames.prettifyPath(RouteNames.customerDetailsBills.path, id),
        state: { backLink: location.pathname },
      };
    if (childRoute === 'search-accounts')
      linkTo = { pathname: RouteNames.customersCreateInfo.path, state: { backLink: location.pathname } };

    return (
      <div className="container-fluid">
        <div className="row">
          <div className="content-wrapper">
            <PageTitle
              linkTo={linkTo}
              titleBtn={childRoute === 'search-accounts' ? t('common:label.createNew') : t('common:label.back')}
              items={[
                {
                  name: t('common:label.customerManagement'),
                  url: RouteNames.customers.path,
                },
                { name: this.onGetItemNameFromChildRoute(childRoute) },
              ]}
              isShowNoteConfig={childRoute === 'bill-profile'}
              messageNote={t('common:message.noteBillingProfile')}
            >
              {childRoute === 'services' && (
                <button
                  type="button"
                  className="button button-border x-small"
                  onClick={() => {
                    this.setState({ keyRefreshSerives: Math.random() });
                  }}
                >
                  <i className="ti-reload mr-1" />
                  {t('common:label.refresh')}
                </button>
              )}

              {/* {childRoute === 'services' && (
                <button
                  type="button"
                  className="button button-border x-small"
                  onClick={() => {
                    this.setState({ keyRefreshSerives: Math.random() });
                  }}
                >
                  <i className="ti-reload mr-1" />
                  {t('common:label.refresh')}
                </button>
              )} */}
              {childRoute === 'search-accounts' && modeCreateCustomer === 2 && (
                <Link className="button button-border x-small" to={{ pathname: RouteNames.quickCreateCustomer.path }}>
                  {t('common:label.quickCreate')}
                </Link>
              )}
            </PageTitle>
            <Sidebar sideProps={sidePropDetails} isMaximizeOne />
            <br />
            {this.renderContent({
              modeModifyCustomer,
              modeModifyContact,
              modeModifyAddress,
              modeAddAddress,
              modeModifyPhone,
              modeAddPhone,
              modeDeletePhone,
              modeDeleteContact,
              modeAddContact,
              modeDeleteAddress,
              modeGetPayment,
              modeModfifyPayment,
              modeModfifyCreditCard,
              modeAddCreditCard,
              modeDeleteCreditCard,
              modeGetContact,
              modeGetAddress,
              modeGetBillingProfile,
              modeModifyBillingProfile,
              modeSuspendStatus,
              modeResumeStatus,
              modeCloseStatus,
              modeGetSubscription,
              modeGetServiceUnit,
              modeGetPriceUnit,
              modeSearchBills,
              modeSearchInvoice,
              modeGetInvoice,
              modeSearchTransactions,
              modeGetTransactions,
              modeGetBalance,
              modeGetActivity,
              modeSearchActivity,
              modeSendInvoice,
              modeRegenerateInvoice,
              modeViewInvoceTemplate,
              modeApproveInvoice,
              modeCreateExternalPO,
              modeModifyExternalPO,
              modeGetExternalPO,
              modeModifyCustomAttributes,
              modeCreateCustomAttributes,
              modeGetCustomAttributes,
              modeUpdateOrder,
              modeCancelOrder,
              modeSubmitOrder,
              modeApproveOrder,
              modeResyncInvoice,
              modeUndoManualInvoice,
              modeModifyAccountExchangeRates,
              modeCreateAccountExchangeRates,
              modeGetAccountExchangeRates,
              modeGenerateInvoice,
              modeModifyPaymentInstallment,
              modeCreatePaymentInstallment,
              modeGetPaymentInstallment,
              modeSearchLandingBill,
              permissionsTasks,
              modeSearchPayment,
              modeGetCreditNoteReport,
              modeViewTransactionDetails,
              modeSearchArOpsUnit,
              modeCreditNoteApproval,
              modeGetAccountStatement,
              modeSearchRatedUsage,
              modeTopupBalance,
              modeGetSubscriptionTopupReport: permissions?.modeGetSubscriptionTopupReport || 0,
            })}
            <Footer className="footer-bottom" />
          </div>
        </div>
      </div>
    );
  }
}

CustomerDetails.propTypes = {
  match: PropTypes.objectOf(PropTypes.any),
  getPendingBill: PropTypes.func.isRequired,
  accountDetail: PropTypes.objectOf(PropTypes.any),
  getAccountDetail: PropTypes.func,
  getAccountInformation: PropTypes.func.isRequired,
  getAccountParent: PropTypes.func.isRequired,
  selectAccountId: PropTypes.func.isRequired,
  updateAccountStatus: PropTypes.func.isRequired,
  getOCBill: PropTypes.func.isRequired,
  modifyInvoiceStatus: PropTypes.func.isRequired,
  undoManualInvoice: PropTypes.func.isRequired,
  modifyAccount: PropTypes.func.isRequired,
  history: PropTypes.objectOf(PropTypes.any),
  subscription: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.any), PropTypes.objectOf(PropTypes.any)]),
  serviceUnitsAc: PropTypes.arrayOf(PropTypes.any),
  priceUnits: PropTypes.arrayOf(PropTypes.any),
  balanceUnit: PropTypes.objectOf(PropTypes.any),
  pendingBills: PropTypes.arrayOf(PropTypes.any),
  ocBills: PropTypes.objectOf(PropTypes.any),
  permissionsCustomer: PropTypes.objectOf(PropTypes.any),
  permissionsCustomerSubscription: PropTypes.objectOf(PropTypes.any),
  permissionsBilling: PropTypes.objectOf(PropTypes.any),
  permissionsInvoice: PropTypes.objectOf(PropTypes.any),
  permissionsRating: PropTypes.objectOf(PropTypes.any),
  permissionsActivity: PropTypes.objectOf(PropTypes.any),
  permissionOrder: PropTypes.objectOf(PropTypes.any),
  permissionsArOps: PropTypes.objectOf(PropTypes.any),
};

CustomerDetails.defaultProps = {
  match: {},
  serviceUnitsAc: [],
  priceUnits: [],
  pendingBills: [],
  accountDetail: {},
  history: {},
  subscription: [],
  balanceUnit: {},
  ocBills: {},
  getAccountDetail: () => {},
  permissionsCustomer: {},
  permissionsCustomerSubscription: {},
  permissionsActivity: {},
  permissionsBilling: {},
  permissionsInvoice: {},
  permissionsRating: {},
  permissionOrder: {},
  permissionsArOps: {},
};

const mapStateToProps = createStructuredSelector({
  accountDetail: makeAccountDetail() || {},
  serviceUnitsAc: makeGetServiceUnitsAc() || [],
  subscription: makeGetSubscription() || [],
  priceUnits: makeGetPriceUnits() || [],
  balanceUnit: makeGetBalanceUnit() || {},
  pendingBills: makeGetPendingBills() || [],
  ocBills: makeGetOCBills() || {},
  permissionsCustomer: makeGetPermissionsCustomerManagement() || {},
  permissionsCustomerSubscription: makeGetPermissionsCustomerManagementSubscription() || {},
  permissionsActivity: makeGetPermissionsCustomerManagementActivity() || {},
  permissionsBilling: makeGetPermissionsBilling() || {},
  permissionsInvoice: makeGetPermissionsInvoice() || {},
  permissionsRating: makeGetPermissionsRating() || {},
  permissionOrder: makeGetPermissionsOrderManagement() || {},
  currencyConfig: makeGetCurrencyConfig() || {},
  permissionsReports: makeGetPermissionsReports() || {},
  ccpPropertyList: makeGetCcpPropertiesConfig() || [],
  ccpTime: makeGetCcpTime() || '',
  permissionsCollections: makeGetPermissionsCollectionManagement() || {},
  s3Config: makeGetS3Config() || {},
  permissionsPayment: makeGetPermissionsPaymentManagement() || {},
  permissionsArOps: makeGetPermissionsArOpsManagement() || {},
  permissionsPricing: makeGetPermissionsPricingManagement() || {},
});

const newCustomerDetails = connect(mapStateToProps, {
  getAccountDetail,
  modifyAccount,
  updateAccountStatus,
  getPendingBill,
  getOCBill,
  selectAccountId,
  getAccountParent,
  getAccountInformation,
  runPendingBill,
  getSubscription,
  getPriceUnits,
  getServiceUnitAc,
  sendInvoice,
  regenerateInvoice,
  modifyInvoiceStatus,
  getAccountStatement,
  undoManualInvoice,
  searchOrderInComplete,
  getAccountAssets,
  updateOrderCustomerStatus,
  getOrderSummaryByAccountId,
  getFlexAttributesMappingConfig,
  getCcpDateTimeConfig,
  modifyAccountServiceLocations,
  getCustomAttributesByObjectType,
  modifyInvoiceDueDate,
  getObjectFileById,
  modifyAccountService,
  getBillableServicePricing,
  getCurrencyConfigApp,
  invoiceAccount,
  getInvoiceInstallmentsByInvoiceId,
  createPaymentInstallment,
  modifyPaymentInstallment,
  resendInvoiceVendor,
  undoInvoiceCancel,
  getInvoiceUnitByIdSelect,
  reprocessInvoice,
  getOrderHistory,
  getCcpPropertiesConfig,
  searchLadingBill,
  getPaymentConfigByTypeApp,
  getPacInterfaceRecordById,
  createBillingAccounts,
  saveAccountNonBillables,
  moveAccount,
  getInvoiceTransactionSummary,
  getInvoiceTaxSummary,
  syncProjectApp,
  saveManualInvoice,
  getCostCenterConfig,
  resendPaymentVendor,
  resendNotesVendor,
  getConfigOperatorByServiceType,
  getClientAccountStatement,
  getUsedCfdiByTaxRegion,
  getSplitTaxPerLine,
  searchAccountAssets,
  searchSubscriptionByAccountId,
  subscriptionTopUp,
  searchBalanceUnitByAccountId,
  getProvisioningByAccountId,
  getMultiInvPymtInstallmentsByAccountId,
  setupMultiInvoicePaymentInstallment,
  getTaxCodeConfigSelect,
  getTaxTypeConfigSelect,
  readFileFromS3,
  uploadMultiPartFiles,
  getPACProductCodesConfig,
  generatePaymentInstallmentReport,
  getUnallocatedPayment,
  searchSubscriptionTopUp,
})(CustomerDetails);
export default withTranslation(['customerPage', 'common'])(newCustomerDetails);
