import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { cloneDeep, size } from 'lodash';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { withTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import PageTitle from '../../../components/PageTitle';
import RouteNames from '../../App/RouteNames';
import { getTaxCodeConfig, modifyTaxCodeConfig, createTaxCodeConfig, getTaxTypeConfig } from '../actions';
import { makeGetListTaxCode } from '../selectors';
import { makeGetPermissionsTaxConfig, makeGetCcpPropertiesConfig } from '../../App/selectors';
import { getCcpPropertiesConfig, getAccumulatorsConfig } from '../../App/actions';
import { FormWithTableItem, DataTable } from '../../../components/common';
import { dataFieldViewTaxCode } from '../constants';
import ModalTaxes from './ModalTaxes';
import {
  checkPermissionCreateTaxConfig,
  checkPermissionGetTaxConfig,
  checkPermissionModifyTaxConfig,
} from '../CheckPermission';

class TaxCodeConfig extends PureComponent {
  state = {
    defaultDataFromAPI: [],
    productCodes: [],
    isSearching: true,
    dataSubmit: [],
    isModify: false,
    isOpenModal: false,
    isSearchDataModal: false,
    itemData: {},
    indexSelected: -1,
    wasValidated: false,
    formValid: true,
    id: '',
    isCreateNew: false,
    taxTypesList: [],
    isAccumulatorBasedTaxThresholds: false,
    accumulatorList: [],
  };

  formRef = React.createRef();

  componentDidMount() {
    const { getCcpPropertiesConfig, ccpPropertyList } = this.props;
    this.doGetTaxCodeConfig();
    if (!ccpPropertyList || !ccpPropertyList.length) {
      getCcpPropertiesConfig('', ({ success, data }) => {
        if (success && data.ccpPropertyList && data.ccpPropertyList.length) {
          this.doInitCcpPropertyList(data.ccpPropertyList);
        }
      });
    } else {
      this.doInitCcpPropertyList(ccpPropertyList);
    }
  }

  doInitCcpPropertyList = ccpPropertyList => {
    const { getAccumulatorsConfig } = this.props;
    const accumulatorBasedTaxThresholds = ccpPropertyList.find(val => val.property === 'accumulatorBasedTaxThresholds');
    this.setState({
      isAccumulatorBasedTaxThresholds: accumulatorBasedTaxThresholds?.value
        ? accumulatorBasedTaxThresholds.value
        : null,
    });
    getAccumulatorsConfig(({ data, success }) => {
      if (success) {
        this.setState({ accumulatorList: data && data.accumulatorList ? data.accumulatorList : [] });
      }
    });
  };

  validate = (out = false) => {
    const { t } = this.props;
    const formValid = this.formRef && this.formRef.current.checkValidity();
    this.setState({ formValid });
    const { elements } = this.formRef.current;
    // console.log('validate')
    for (let i = 0; i < elements.length; i++) {
      if (!elements[i].validity.valid) {
        console.log(elements[i].name, 'invalid');
      }
    }
    if (!formValid && out) {
      toast.error(t('message.mandatory'));
    }
    return formValid;
  };

  onChangeTable = ({ name, value, index }) => {
    const { productCodes, dataSubmit } = this.state;
    const newData = cloneDeep(productCodes);
    const indexItemChange = newData.findIndex(item => item.index === index);
    const indexDataSubmit = dataSubmit.findIndex(item => item.index === index);
    if (indexDataSubmit > -1) {
      dataSubmit[indexDataSubmit][name] = value;
    } else {
      dataSubmit.push({ index });
      dataSubmit[dataSubmit.length - 1][name] = value;
    }
    newData[indexItemChange][name] = value;
    this.setState({ productCodes: newData });
  };

  doGetTaxCodeConfig = () => {
    const { getTaxCodeConfig } = this.props;
    getTaxCodeConfig(({ data, success }) => {
      if (success) {
        this.setState({ productCodes: data ? data.productCodes : [] });
      }
      this.setState({ isSearching: false, id: data ? data.id : null, isModify: !!(data && data.id) });
    });
  };

  onAddNewTaxCode = () => {
    let indexNewItem = 0;
    const { productCodes } = this.state;
    const newData = cloneDeep(productCodes);
    if (newData.length) {
      newData.forEach(item => {
        if (item.index > indexNewItem) {
          indexNewItem = item.index;
        }
      });
    }
    const newDataItem = {
      index: indexNewItem + 1,
      productCode: null,
      jurisdiction: null,
      jurisdictionList: null,
      excludeList: null,
      description: null,
      startDate: null,
      endDate: null,
      taxPercent: 0,
      minimumThreshold: 0,
      maximumThreshold: 0,
      additionalTaxType: null,
      additionalTaxDescription: null,
      additionalTaxRate: 0,
      taxCategory: null,
    };
    this.doGetTaxTypeConfig();
    this.setState({
      itemData: newDataItem,
      isOpenModal: true,
      indexSelected: newData ? newData.length + 1 : 1,
      isCreateNew: true,
    });
  };

  onRemoveItemTable = () => {
    const { productCodes, defaultDataFromAPI, dataSubmit, idItemRemove } = this.state;
    const newData = cloneDeep(productCodes);
    const indexItemRemove = newData.findIndex(item => item.index === idItemRemove);
    const indexItemFromAPI = defaultDataFromAPI.findIndex(item => item.index === idItemRemove);
    const indexDataSubmit = dataSubmit.findIndex(item => item.index === idItemRemove);
    if (indexItemFromAPI > -1 && indexItemRemove > -1) {
      newData[indexItemRemove] = { index: idItemRemove };
      if (indexDataSubmit > -1) {
        dataSubmit[indexDataSubmit] = { index: idItemRemove };
      } else {
        dataSubmit.push({ index: idItemRemove });
      }
    }
    if (indexItemFromAPI === -1 && indexItemRemove > -1) {
      newData.splice(indexItemRemove, 1);
      if (indexDataSubmit > -1) {
        dataSubmit.splice(indexDataSubmit, 1);
      }
    }
    this.setState({ productCodes: newData, dataSubmit, isOpenModalRemoveItem: false });
  };

  onHanldeSubmit = e => {
    if (e) e.preventDefault();
    this.setState({ wasValidated: true });
    const { isModify, id, productCodes } = this.state;
    const { modifyTaxCodeConfig, createTaxCodeConfig } = this.props;
    if (!this.validate(true)) {
      return false;
    }
    if (isModify) {
      const newPayloadModify = {
        id,
        productCodes,
      };
      modifyTaxCodeConfig(newPayloadModify);
      this.setState({ wasValidated: false });
    } else {
      const dataRemoveIndex = cloneDeep(productCodes).map(item => {
        const newItem = item;
        delete newItem.index;
        return newItem;
      });
      const newPayloadCreate = {
        type: 'PRODUCT_CODES',
        productCodes: dataRemoveIndex,
      };
      createTaxCodeConfig(newPayloadCreate, ({ success }) => {
        if (success) {
          this.setState({ isModify: true });
          this.doGetTaxCodeConfig();
        }
      });
      this.setState({ wasValidated: false });
    }
  };

  onToggleModal = () => {
    const { isOpenModal } = this.state;
    this.setState({ isOpenModal: !isOpenModal, indexSelected: -1 });
  };

  onSelectItem = (item, idx) => {
    this.doGetTaxTypeConfig();
    this.setState({
      itemData: cloneDeep(item),
      isOpenModal: true,
      indexSelected: idx,
    });
  };

  doGetTaxTypeConfig = () => {
    const { getTaxTypeConfig } = this.props;
    const { taxTypesList } = this.state;
    if (!taxTypesList || !taxTypesList.length) {
      getTaxTypeConfig({}, ({ success, data }) => {
        if (success && data && data.taxTypes) {
          const options = data.taxTypes.map(val => ({ label: val.code, value: val.code }));
          this.setState({ taxTypesList: options });
        }
      });
    }
  };

  onSaveItemSelect = ({ data }) => {
    const { indexSelected, productCodes, dataSubmit, isCreateNew } = this.state;
    let newData = cloneDeep(productCodes);
    if (isCreateNew) {
      newData = [{ ...data }, ...newData];
      if (!dataSubmit[indexSelected]) dataSubmit[indexSelected] = [];
      dataSubmit[indexSelected] = data;
    } else {
      newData[indexSelected] = data;
      if (!dataSubmit[indexSelected]) dataSubmit[indexSelected] = [];
      dataSubmit[indexSelected] = data;
    }

    this.setState(
      {
        productCodes: newData,
        dataSubmit,
        isOpenModal: false,
        indexSelected: -1,
        itemData: {},
        isCreateNew: false,
      },
      () => this.onHanldeSubmit()
    );
  };

  render() {
    const {
      productCodes,
      isSearching,
      isOpenModal,
      itemData,
      wasValidated,
      taxTypesList,
      isAccumulatorBasedTaxThresholds,
      accumulatorList,
    } = this.state;
    const { t, permissionsTaxConfig } = this.props;
    let modeCreateTaxConfig = 0;
    let modeModifyTaxConfig = 0;
    let modeGetTaxConfig = 0;
    if (permissionsTaxConfig && permissionsTaxConfig.taxationModulePermissions) {
      const listPermissionTaxConfig = permissionsTaxConfig.taxationModulePermissions;
      modeModifyTaxConfig = checkPermissionModifyTaxConfig({ listPermission: listPermissionTaxConfig });
      modeGetTaxConfig = checkPermissionGetTaxConfig({ listPermission: listPermissionTaxConfig });
      modeCreateTaxConfig = checkPermissionCreateTaxConfig({ listPermission: listPermissionTaxConfig });
    }
    if (modeGetTaxConfig === 0) return '';
    const creditProfileColumns = [
      {
        name: 'view',
        label: 'label.view',
        render: (colName, item, idx) => {
          return (
            <div className="form-group col-md-12">
              <button
                type="button"
                className="btn btn-outline-success btn-sm"
                onClick={() => this.onSelectItem(item, idx)}
              >
                {t('label.view')}
              </button>
            </div>
          );
        },
      },
      {
        name: 'productCode',
        label: 'label.code',
      },
      {
        name: 'description',
        label: 'label.description',
      },
      {
        name: 'startDate',
        label: 'label.startDate',
      },
      {
        name: 'endDate',
        label: 'label.endDate',
      },
      {
        name: 'jurisdiction',
        label: 'label.jurisdiction',
      },
    ];

    const newDataRemoveIndex = productCodes && productCodes.length ? productCodes.filter(item => size(item) > 1) : [];
    return (
      <div className="col-md-12 mb-30 p-0">
        <PageTitle
          linkTo={RouteNames.taxCodeConfig.path}
          titleBtn={t('label.back')}
          items={[
            { name: t('navbar:billingHub.subMain.taxation'), url: RouteNames.taxCodeConfig.path },
            { name: t('baseConfigurationsPage:sidebar.taxCodeConfig') },
          ]}
        />
        <form
          onSubmit={this.onHanldeSubmit}
          className={`needs-validation col-sm-12 mb-30 ${wasValidated ? 'was-validated' : ''}`}
          noValidate
          ref={this.formRef}
        >
          <FormWithTableItem title={t('baseConfigurationsPage:sidebar.taxCodeConfig')} subClass="border-bottom">
            <br />
            <div className="form-group col-md-12 buttons-attibute">
              {modeCreateTaxConfig === 2 && (
                <button type="button" className="button button-border black x-small" onClick={this.onAddNewTaxCode}>
                  +
                  {t('label.addNewTaxCode')}
                </button>
              )}
            </div>
            <div>
              <DataTable
                columns={creditProfileColumns}
                data={newDataRemoveIndex}
                isLoading={isSearching}
                isFixedHeaderTable
              />
            </div>

            <ModalTaxes
              isOpen={isOpenModal}
              data={cloneDeep(itemData)}
              onToggle={this.onToggleModal}
              dataField={dataFieldViewTaxCode}
              title={t('label.viewTaxCode')}
              onSave={this.onSaveItemSelect}
              wrapperClass="modal-custom modal-70 bd-example-modal-lg modal-selector"
              modeModifyTaxConfig={modeModifyTaxConfig}
              taxTypesList={taxTypesList}
              isAccumulatorBasedTaxThresholds={isAccumulatorBasedTaxThresholds}
              accumulatorList={accumulatorList}
            />
          </FormWithTableItem>
        </form>
      </div>
    );
  }
}

TaxCodeConfig.propTypes = {
  getTaxCodeConfig: PropTypes.func.isRequired,
  modifyTaxCodeConfig: PropTypes.func.isRequired,
  createTaxCodeConfig: PropTypes.func.isRequired,
  productCodes: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.any), PropTypes.objectOf(PropTypes.any)]),
};

TaxCodeConfig.defaultProps = {
  productCodes: [],
};

const mapStateToProps = createStructuredSelector({
  productCodes: makeGetListTaxCode() || {},
  permissionsTaxConfig: makeGetPermissionsTaxConfig() || {},
  ccpPropertyList: makeGetCcpPropertiesConfig() || [],
});

export default withTranslation('common')(
  connect(mapStateToProps, {
    getTaxCodeConfig,
    modifyTaxCodeConfig,
    createTaxCodeConfig,
    getTaxTypeConfig,
    getCcpPropertiesConfig,
    getAccumulatorsConfig,
  })(TaxCodeConfig)
);
