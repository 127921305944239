import gql from 'graphql-tag';

export default id => gql`
  query {
    getCollectionScheduleAudit(id: "${id}") {
      id
      effectiveDate
      collectionProfile
      createdDate
      type
      scheduleDetailJson
    }
  }
`;
